import React from 'react';
import { useAppSelector } from '@hooks/index';
import { DictionaryName } from '@store/dictionary/contracts';
import { selectDictionary } from '@store/dictionary/dictionary.slice';
import { statusProjectColors } from '@components/ui/StatusLabel/StatusLabel';
import styles from './StatusList.module.scss';

export const StatusList: React.FC = () => {
  const statuses = useAppSelector(selectDictionary(DictionaryName.PROJECT_STATUS));

  return (
    <div className={styles.statuses}>
      <ul>
        {statuses.map((status) => (
          <li className={styles.status} key={status.id}>
            <div
              className={styles.status__color}
              style={{ backgroundColor: statusProjectColors.get(status.name) }}></div>
            {status.name}
          </li>
        ))}
      </ul>
    </div>
  );
};
