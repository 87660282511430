import React, { useMemo } from 'react';
import messageLooked from '@assets/img/messages/messageLooked.svg';
import myAvatar from '@assets/img/manager.svg';
import styles from './Message.module.scss';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { Star } from '@components/Star/Star';
import { useSetFavoriteMessageMutation } from '@store/messages/messages.service';
import { useParams } from 'react-router-dom';

interface MessageProps {
  id: string;
  text: string;
  sentAt: Dayjs;
  outgoing: boolean;
  read?: boolean;
  isFavorite: boolean;
  isNextMsgDifferentUser?: boolean;
  scrollToMessage: () => void;
}

export const Message: React.FC<MessageProps> = ({
  text,
  sentAt,
  outgoing,
  read,
  isNextMsgDifferentUser,
  id,
  isFavorite,
  scrollToMessage,
}) => {
  const [setFavoriteMessage] = useSetFavoriteMessageMutation();
  const { messageId } = useParams();

  const isFullWidth = useMemo(() => {
    return text.length > 30;
  }, [text]);

  return (
    <>
      <div
        ref={messageId && messageId === id ? scrollToMessage : undefined}
        id={id}
        className={classNames(
          'd-flex flex-wrap align-items-center pt-1 pb-1',
          outgoing ? 'justify-content-end' : 'justify-content-start',
          messageId && messageId === id ? styles.favorite__message__background : '',
        )}>
        {!outgoing ? (
          <img src={myAvatar} alt="avatar321" className={styles.foreignAvatar} />
        ) : (
          <></>
        )}
        <div
          className={classNames(
            styles.messageBox,
            outgoing ? styles.ownMessageBox : styles.foreignMessageBox,
          )}>
          <div className={isFullWidth ? styles.fullWidthMessage : styles.smallWidthMessage}>
            <span>{text}</span>
            <div
              className={classNames(
                styles.messageTools,
                isFullWidth ? styles.fullWidthMessageTools : '',
              )}>
              <Star
                checked={isFavorite}
                onChange={(value) =>
                  setFavoriteMessage({
                    id: id,
                    requestBody: { status: value },
                  })
                }
                className={styles.star}
              />
              <span className="ms-1 text-secondary">{sentAt.format('HH:MM')}</span>
              {read ? (
                <span className="ms-1">
                  <img src={messageLooked} alt="сообщение просмотрено" />
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {outgoing ? <img src={myAvatar} alt="avatar123" className={styles.ownAvatar} /> : <></>}
      </div>
    </>
  );
};
