import React from 'react';
import { MainInfo } from '@components/MainInfo/MainInfo';
import { NotificationAgreement } from '@components/TermsOfService/NotificationAgreement';

export function MainScreen() {
  return (
    <>
      <MainInfo />
      <NotificationAgreement />
    </>
  );
}
