import React from 'react';
import { Dayjs } from 'dayjs';
import download from '@assets/img/upload.svg';
import styles from './ProfileAboutMe.module.scss';
import { downloadFile } from '@store/docs/utils';
import { FileItem } from '@store/user/contracts';

type ProfileAboutMeProps = {
  birthDate?: Dayjs;
  location?: string;
  userName?: string;
  direction?: string;
  stack?: string[];
  experience?: number;
  experienceDescription?: string;
  resumeUrl?: string;
  resume?: FileItem[];
  certificates?: FileItem[];
  about?: string;
};

export const ProfileAboutMe: React.FC<ProfileAboutMeProps> = ({
  birthDate,
  location,
  userName,
  direction,
  stack,
  experience,
  experienceDescription,
  resumeUrl,
  resume,
  certificates,
  about,
}) => {
  const experienceYearsFormatter = (experience: number) => {
    if (experience === 1) return 'год';
    if (experience > 1 && experience < 5) return 'года';
    if (experience > 4 || experience === 0) return 'лет';
  };

  return (
    <div className={styles.about__me__info}>
      <div>
        <h3 className={styles.about__me__info__title}>Обо мне</h3>
        <div className="d-flex flex-column mb-3">
          <p className="m-0 p-0 color-gray">Дата рождения</p>
          <span className={styles.about__me__info__content}>
            {birthDate?.isValid() ? birthDate?.format('DD.MM.YYYY') : '-'}
          </span>
        </div>
        <div className="d-flex flex-column mb-3">
          <p className="m-0 p-0 color-gray">Местоположение</p>
          <span className={styles.about__me__info__content}>{location ? location : '-'}</span>
        </div>
        <div className="d-flex flex-column mb-3">
          <p className="m-0 p-0 color-gray">Username</p>
          <span className={styles.about__me__info__content}>{userName ? userName : '-'}</span>
        </div>
        <div className="d-flex flex-column mb-3">
          <p className="m-0 p-0 color-gray">Направление</p>
          <span className={styles.about__me__info__content}>{direction ? direction : '-'}</span>
        </div>
        <div className="d-flex flex-column mb-3">
          <p className="m-0 p-0 color-gray">Стэк</p>
          <span className={styles.about__me__info__content}>
            {stack && stack.length > 0 ? stack?.join(', ') : '-'}
          </span>
        </div>
        <div className="d-flex flex-column mb-3">
          <p className="m-0 p-0 color-gray">Опыт работы</p>
          <span className={styles.about__me__info__content}>
            {experience ? `${experience} ${experienceYearsFormatter(experience)}` : '-'}
          </span>
        </div>
        <div className="d-flex flex-column mb-3">
          <p className="m-0 p-0 color-gray">Описание опыта</p>
          <span>{experienceDescription ? `${experienceDescription}` : '-'}</span>
        </div>
        <div className="d-flex flex-column mb-3">
          <p className="m-0 p-0 color-gray">Ссылка на резюме</p>
          <a href="file">{resumeUrl}</a>
        </div>
        <div className="d-flex flex-column mb-3">
          <p className="m-0 p-0 color-gray mb-1">Резюме</p>
          {/*//Todo: бэк должен присылать объект из полей name, id, а не массив айдишников*/}
          {Array.isArray(resume) && resume.length > 0 ? (
            <span className="color-red">Временно недоступно для отображения и скачивания </span>
          ) : resume && Object.keys(resume).length > 0 ? (
            Object.values(resume).map((file: any) => (
              <button
                className={styles.resume}
                key={file?.id}
                onClick={() => downloadFile(file?.id)}>
                <span>{file?.name}</span>
                <img src={download} alt="загрузить" />
              </button>
            ))
          ) : (
            <span>Файлы отсутствуют</span>
          )}
        </div>
        <div className="d-flex flex-column mb-3">
          <p className="m-0 p-0 color-gray mb-1">Сертификаты</p>
          {Array.isArray(certificates) && certificates.length > 0 ? (
            <span className="color-red">Временно недоступно для отображения и скачивания</span>
          ) : certificates && Object.keys(certificates).length > 0 ? (
            Object.values(certificates).map((file: any) => (
              <button
                className={styles.certificate}
                key={file?.id}
                onClick={() => downloadFile(file?.id)}>
                <span>{file?.name}</span>
                <img src={download} alt="загрузить" />
              </button>
            ))
          ) : (
            <span>Файлы отсутствуют</span>
          )}
        </div>
        <div className="d-flex flex-column">
          <p className="m-0 p-0 color-gray">О себе</p>
          <span>{about ? about : '-'}</span>
        </div>
        <hr />
      </div>
    </div>
  );
};
