import Api from '@api-schema';
import { ApiClient } from '@store/api-client';
import { plainToInstance } from 'class-transformer';
import { GetMyVacanciesResponse } from '@store/vacancy/contracts';

export const getPublicVacancies = async (
  request: Api.operations['getExecutorVacancies_1']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getExecutorVacancies_1']['responses']['200']['content']['application/json']
  >('/api/public/vacancy', request);
  return plainToInstance(GetMyVacanciesResponse, data);
};
