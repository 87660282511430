import React from 'react';
import { MenuItem, Typography } from '@mui/material';
import { ReactComponent as PhotoIcon } from '@assets/img/photoIcon.svg';
import { ReactComponent as DeleteIcon } from '@assets/img/deleteIcon.svg';
import { Menu } from '@components/MUI/Menu';
import { AvatarProps } from './Avatar';
import { ReactComponent as EditIcon } from '@assets/img/editIcon.svg';
import styles from './Avatar.module.scss';

type AvatarMenuProps = AvatarProps & {
  open: boolean;
  anchorEl: any;
  handleMenuClose: () => void;
  handleOpenModal: (value?: File) => void;
  openDeletePhotoModal: () => void;
  iconFile?: File;
};

export const AvatarMenu: React.FC<AvatarMenuProps> = ({
  iconUrl,
  open,
  anchorEl,
  handleMenuClose,
  handleOpenModal,
  openDeletePhotoModal,
  iconFile,
}) => {
  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      MenuListProps={{
        onMouseLeave: handleMenuClose,
      }}
      disableAutoFocusItem
      onClose={handleMenuClose}>
      <MenuItem onClick={() => handleOpenModal()} className={styles.menuItem}>
        <EditIcon />
        <Typography variant="body1" color="text.secondary" className={styles.menuText}>
          Обновить фотографию
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => handleOpenModal(iconFile)} className={styles.menuItem}>
        <PhotoIcon />{' '}
        <Typography variant="body1" color="text.secondary" className={styles.menuText}>
          Изменить миниатюру
        </Typography>
      </MenuItem>

      {iconUrl ? (
        <MenuItem onClick={() => openDeletePhotoModal()} className={styles.menuItem}>
          <DeleteIcon />
          <Typography variant="body1" color="text.secondary" className={styles.menuText}>
            Удалить
          </Typography>
        </MenuItem>
      ) : null}
    </Menu>
  );
};
