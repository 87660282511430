import React from 'react';
import { SupportForm, SupportFormProps } from '@pages/support/SupportForm';
import {
  selectIsLoadingSupportMessage,
  selectSupportSuccess,
  supportRequest,
} from '@store/support/support.slice';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './support.module.scss';
import { Button } from '@components/MUI/Button';
import { useScrollToTop } from '@hooks/useScrollToTop';

export function Support() {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoadingSupportMessage);
  const isSuccess = useAppSelector(selectSupportSuccess);
  const topRef = useScrollToTop();

  const handleSupportMessage: SupportFormProps['submit'] = async (SupportForm, form) => {
    dispatch(
      supportRequest({
        username: SupportForm.firstName,
        email: SupportForm.email,
        text: SupportForm.comment,
        attachments: SupportForm.supportFiles,
        topic: 'Обращение пользователя',
      }),
    );
  };
  return (
    <>
      <div className={`form-data mt-5 mb-5 ${styles.form}`} ref={topRef}>
        {isLoading ? (
          <div className="d-flex justify-content-center pb-5 pt-5">
            <CircularProgress />
          </div>
        ) : isSuccess ? (
          <div className={`fs-5 fw-400 ${styles.form_success}`}>
            Ваша заявка успешно отправлена. Мы свяжемся с вами в ближайшее время
            <div className="mt-5 d-flex justify-content-end">
              <Link to="/">
                <Button variant="contained">Вернуться на главную страницу</Button>
              </Link>
            </div>
          </div>
        ) : (
          <div>
            <div className="fw-bold fs-2 color-black mb-4">Свяжитесь с нами</div>
            <div
              style={{
                marginBottom: '24px',
                fontSize: '16px',
              }}>
              Если у вас есть вопросы по работе с сервисом <br /> или вы обнаружили неточности и
              ошибки — <br />
              пишите нам:
            </div>
            <SupportForm
              value={{
                firstName: '',
                email: '',
                supportFiles: [],
                comment: '',
              }}
              submit={handleSupportMessage}
            />
          </div>
        )}
      </div>
    </>
  );
}
