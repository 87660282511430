import React, { useEffect, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import returnBack from '@assets/img/registration/arrow-back.svg';

interface IResettingFormStepTwo {
  resend: (email: string) => Promise<void>;
}

export const ResettingFormStepTwo = ({ resend }: IResettingFormStepTwo) => {
  const [email, setEmail] = useState<string>('');
  const [seconds, setSeconds] = useState<number>(59);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const handleRemoveSessionItem = useCallback(() => {
    sessionStorage.removeItem('email');
  }, []);
  const handleResend = () => {
    resend(email).then(() => {
      setSeconds(59);
      setIsButtonDisabled(true);
    });
  };

  useEffect(() => {
    const savedEmail = sessionStorage.getItem('email');
    if (savedEmail) {
      setEmail(savedEmail);
    }
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds((seconds) => seconds - 1);
      } else {
        setIsButtonDisabled(false);
      }
    }, 1000);
    return () => clearInterval(timer);
  });

  return (
    <>
      <div style={{ marginBottom: '32px' }}>
        <p className="mb-4">
          Отправили письмо на {email}, перейдите по ссылке, чтобы создать новый пароль.
        </p>
        <p className="mb-4">
          Если не получили письмо, нажмите «Отправить повторно» через 00:
          {seconds < 10 ? `0${seconds}` : seconds} или напишите на{' '}
          <span className="color-green text-decoration-underline">hello@easylinker.ru</span>
        </p>
        <Button
          variant="contained"
          className="pt-3 pb-3 w-100 text-white"
          type="submit"
          disabled={isButtonDisabled}
          onClick={handleResend}>
          Отправить повторно
        </Button>
        <Box
          sx={{
            marginTop: '38px',
          }}>
          <Link
            to="/resetting-password"
            className="link__return-back"
            onClick={handleRemoveSessionItem}>
            <img src={returnBack} alt="Вернуться ко входу" />
            Изменить почту
          </Link>
        </Box>
      </div>
    </>
  );
};
