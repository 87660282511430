import React, { useCallback, useState } from 'react';
import { FormWrapper } from '@components/Form/Form';
import { useAppDispatch } from '@hooks/index';
import { Box, Modal } from '@mui/material';
import { closeModal, openModalError } from '@store/ui/ui.slice';
import { AddPositionForm, AddPositionFormProps } from './AddPositionForm';
import { TeamItem } from '@store/team/contracts';
import { useUpdateTeamMutation } from '@store/team/team.slice';

type AddPositionModalProps = {
  team?: TeamItem | null;
};

export const AddPositionModal: React.FC<AddPositionModalProps> = ({ team }) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(true);

  const [updateTeamMutation] = useUpdateTeamMutation();

  const handleSubmit: AddPositionFormProps['submit'] = async (formValues) => {
    if (team && team?.id) {
      try {
        const positions = team?.positions.map((position) => ({
          id: position.id,
          positionId: position.positionId,
        }));
        const newPosition = { positionId: formValues.positionId };
        updateTeamMutation({
          id: team.id,
          positions: [...positions, newPosition],
        }).then(() => {
          setOpen(false);
          dispatch(closeModal());
        });
      } catch (e: any) {
        dispatch(openModalError(e));
      }
    }
  };

  const cancel = useCallback(() => {
    dispatch(closeModal());
    setOpen(false);
  }, [dispatch]);

  return (
    <Modal
      open={open}
      onClose={cancel}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box>
        <FormWrapper label={'Добавление роли'}>
          <AddPositionForm cancel={cancel} submit={handleSubmit} />
        </FormWrapper>
      </Box>
    </Modal>
  );
};
