import { Modal, ModalFooter, ModalProps } from '@components/MUI/Modal/Modal';
import styles from './ConnectTrackerModal.module.scss';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { loadProjects, selectProjects, selectProvider } from '@store/tasks/tracker.slice';
import { useCallback, useEffect, useState } from 'react';
import { closeModal } from '@store/ui/ui.slice';
import { attachProject } from '@store/tasks/tracker.service';
import { FormWrapper } from '@components/Form/Form';
import { Button } from '@components/MUI/Button';

interface ConnectTrackerModalProps {
  modalProps?: ModalProps;
  projectId: string;
}

const ConnectTrackerModal = ({ modalProps, projectId }: ConnectTrackerModalProps) => {
  const dispatch = useAppDispatch();
  const projects = useAppSelector(selectProjects);
  const provider = useAppSelector(selectProvider);
  const [selected, setSelected] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    dispatch(loadProjects({ provider }));
  }, [dispatch, provider]);

  const onSubmit = useCallback(async () => {
    if (!selected) return;
    setError(null);

    const organizationId = projects.find((project) => project.id === selected)!.organizationId;

    await attachProject({
      provider,
      easyProjectId: projectId,
      trackerProjectId: selected!,
      organizationId,
    })
      .catch((e: any) => setError(e?.response?.data?.message || e.message))
      .then(() => dispatch(closeModal()));
  }, [dispatch, provider, projectId, projects, selected]);

  return (
    <Modal {...modalProps}>
      <FormWrapper label="Подключение трекера">
        <div>
          {error && <div className="text-danger mt-2">{error}</div>}
          <div className="display-flex flex-direction-column w-100 mt-3">
            {projects.map((project, index) => (
              <div className="form-check mt-3" key={project.id}>
                <input
                  className="form-check-input"
                  type="radio"
                  id={`tracker${index}`}
                  name="trackerConnect"
                  onChange={() => setSelected(project.id)}
                />
                <label className="form-check-label" htmlFor={`tracker${index}`}>
                  {project.name}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.modal_footer__wrapper}>
          <ModalFooter>
            <Button
              className="btn btn-sm text-secondary me-3"
              onClick={() => dispatch(closeModal())}>
              Отменить
            </Button>
            <Button type="submit" variant="contained" disabled={!selected} onClick={onSubmit}>
              Подключить
            </Button>
          </ModalFooter>
        </div>
      </FormWrapper>
    </Modal>
  );
};

export { ConnectTrackerModal };
