import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Star } from '@components/Star/Star';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { useAppDispatch } from '@hooks/index';
import { useRequestBody } from '@hooks/useRequestBody';
import { User } from '@store/user/contracts';
import { setUsersCount } from '@store/favorite/favorite.slice';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { UserIcon } from './UserIcon';
import { AdaptiveActionButtons } from '@components/adaptives/AdaptiveActionButtons';
import { useNavigateToUserChat } from '@hooks/useNavigateToUserChat';
import { useGetFavoriteProfilesQuery, useSetFavoriteProfileMutation } from '@store/user/user.slice';

export const FavoriteUsers: React.FC = () => {
  const dispatch = useAppDispatch();
  const createChat = useNavigateToUserChat();
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);

  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();
  const [setFavoriteProfile] = useSetFavoriteProfileMutation();

  const { data: usersFavorites } = useGetFavoriteProfilesQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handleSendMessage = (user: User) => {
    createChat(user.id);
  };

  return (
    <>
      <AdaptiveTableMUI
        setRequestBody={setRequestBody}
        setSortColumn={setSortColumn}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        sortColumn={sortColumn}
        columns={{
          about: {
            label: 'ФИО',
            children: (_, row: User) => (
              <div>
                <NavLink to={`/userPage/${row.id}`}>
                  <UserIcon row={row} />
                  {`${row.about?.firstName} ${row.about?.lastName}`}
                </NavLink>
              </div>
            ),
            filterInput: true,
            isSortColumn: false,
          },
          actionsRaw: {
            children: () => <div></div>,
          },
          link: {
            label: 'Telegram',
            children: (value, row) => {
              return (
                <div>
                  {row.link && row.link.telegram ? (
                    <NavLink to={`/telegram/${row.id}`}>{row.link.telegram}</NavLink>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              );
            },
            filterInput: true,
            isSortColumn: false,
          },
          workHour: {
            label: 'Работа в час ',
            children: (_, row) => {
              return (
                <div>
                  {row.work && row.work.hourPrice ? (
                    <span>{row.work.hourPrice} ₽</span>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              );
            },
            filterInput: true,
            isSortColumn: true,
          },
          workMonth: {
            label: 'Работа в месяц ',
            children: (_, row) => {
              return (
                <div>
                  {row.work && row.work.monthPrice ? (
                    <span>{row.work.monthPrice} ₽</span>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              );
            },
            filterInput: true,
            isSortColumn: true,
          },

          statusName: {
            label: 'Статус',
            children: (_, row) => {
              return <div>{row.statusName ? <span>{row.statusName}</span> : <span>-</span>}</div>;
            },
            filterInput: true,
            isSortColumn: false,
          },
          actions: {
            children: (_, row: User) => (
              <AdaptiveActionButtons
                isOnlySend={true}
                handleSendMessage={() => handleSendMessage(row)}
              />
            ),
          },
          star: {
            children: (_, row: User) => (
              <div style={{ marginRight: '1rem' }}>
                <Star
                  checked={true}
                  onChange={(value) =>
                    setFavoriteProfile({ isFavorite: { status: value }, profileId: row.id }).then(
                      () => {
                        if (usersFavorites?.totalCount)
                          dispatch(setUsersCount(usersFavorites?.totalCount - 1));
                      },
                    )
                  }
                />
              </div>
            ),
          },
        }}
        rows={usersFavorites?.data || []}
        emptyLabel={'Пользователей в избранном нет'}>
        <TablePaginationMUI
          limit={limit}
          count={usersFavorites?.totalCount}
          entityLabel="Пользователей"
          page={page}
          handleChangePage={(_, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </AdaptiveTableMUI>
    </>
  );
};
