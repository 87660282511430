import { ApiClient } from '@store/api-client';
import { DictionaryName, DictionaryValue } from '@store/dictionary/contracts';

export async function list() {
  const response = await ApiClient.get<DictionaryName[]>(`/platform/api/dictionary`);
  return response.data;
}

export async function get(dictionaryName: DictionaryName) {
  const response = await ApiClient.get<DictionaryValue[]>(
    `/platform/api/dictionary/${dictionaryName}`,
  );
  return response.data;
}
