import * as RequestService from '@store/requests/requests.service';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  GetApprovedRequestsResponse,
  GetRequests,
  PublishRequestResponse,
  RequestItem,
} from '@store/requests/contracts';
import { api, apiErrorHandler } from '@store/api-client';
import Api from '@api-schema';
import { CreateSaveRequestResponse } from '@store/user/contracts';
import { enqueueSnackbar } from 'notistack';
import { PathParameters, RequestBody, ResponseBody } from '@store/utility';
import { TransferToProjectRequest } from '@store/requests/requests.service';
import { plainToInstance } from 'class-transformer';

type ListRequestSearchParams = {
  managerId?: string;
  start: number;
  limit: number;
  sortField?: 'created';
  sortOrder: 'asc' | 'desc';
};

export const loadRequests = createAsyncThunk(
  'requests/list',
  async (searchParams: ListRequestSearchParams) => {
    try {
      const response = await RequestService.listRequests(searchParams);
      return response.data;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

interface RequestsState {
  list: RequestItem[];
  isLoading: boolean;
  error: string | null;
}

const initialState: RequestsState = {
  list: [],
  isLoading: false,
  error: null,
};

const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadRequests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload || [];
        state.error = null;
      })
      .addCase(loadRequests.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? null;
      });
  },
});

export const requestReducer = requestsSlice.reducer;

export const requestsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    setFavoriteRequest: builder.mutation<
      ResponseBody<Api.operations['changeFavoriteStatus_3']>,
      {
        requestBody: RequestBody<Api.operations['changeFavoriteStatus_3']>;
        requestId: PathParameters<Api.operations['changeFavoriteStatus_3'], 'id'>;
      }
    >({
      query: ({ requestBody, requestId }) => ({
        method: `PUT`,
        url: `/api/request/${requestId}/favorite`,
        body: requestBody,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Избранное заявки успешно обновлено', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['FavoriteRequests', 'RequestList'],
    }),

    detailRequest: builder.query<
      RequestItem,
      { projectId: PathParameters<Api.operations['getRequestDetail'], 'projectId'> }
    >({
      query: ({ projectId }) => ({
        method: 'GET',
        url: `/api/request/${projectId}`,
      }),
      transformResponse: (
        response: Api.operations['getFavoriteMessages']['responses']['200']['content']['application/json'],
      ) => {
        return plainToInstance(RequestItem, response);
      },
      providesTags: ['RequestDetail'],
    }),

    listRequests: builder.query<
      GetRequests,
      RequestBody<Api.operations['getRequestsOnSearchForManager']>
    >({
      query: (requestBody) => ({
        method: 'POST',
        url: `/api/request/open`,
        body: requestBody,
      }),
      transformResponse: (
        response: ResponseBody<Api.operations['getRequestsOnSearchForManager']>,
      ) => {
        return plainToInstance(GetRequests, response);
      },
      providesTags: ['RequestList'],
    }),

    sendProposal: builder.mutation<
      ResponseBody<Api.operations['applyForProjectManagerPosition']>,
      PathParameters<Api.operations['applyForProjectManagerPosition'], 'projectId'>
    >({
      query: (projectId) => ({
        method: 'POST',
        url: `/api/request/proposal/${projectId}`,
        body: projectId,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Операция выполнена', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['RequestDetail', 'RequestList'],
    }),

    transferRequestToProject: builder.mutation<
      ResponseBody<Api.operations['processRequestIntoProject']>,
      TransferToProjectRequest
    >({
      query: (request) => ({
        method: `POST`,
        url: `/api/request/process`,
        body: request,
      }),
      invalidatesTags: [
        'ApprovedRequests',
        'DashboardRequestAnalytics',
        'DashboardProjectStatuses',
        'DashboardTimeAnalytics',
        'DashboardTaskStatuses',
      ],
    }),

    denyRequest: builder.mutation<
      ResponseBody<Api.operations['denyOfferForProjectManagerPosition']>,
      PathParameters<Api.operations['denyOfferForProjectManagerPosition'], 'projectId'>
    >({
      query: (projectId) => ({
        method: 'POST',
        url: `/api/request/proposal/deny/${projectId}`,
        body: projectId,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Заявка отклонена', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['MyAssignedRequests'],
    }),

    acceptRequest: builder.mutation<
      ResponseBody<Api.operations['acceptOfferForProjectManagerPosition']>,
      PathParameters<Api.operations['acceptOfferForProjectManagerPosition'], 'projectId'>
    >({
      query: (projectId) => ({
        method: 'POST',
        url: `/api/request/proposal/accept/${projectId}`,
        body: projectId,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Заявка принята', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['MyAssignedRequests', 'DashboardRequestAnalytics'],
    }),

    listMyAssignedRequests: builder.query<
      GetRequests,
      RequestBody<Api.operations['getRequestsInWhichUserIsManager']>
    >({
      query: (requestBody) => ({
        method: 'POST',
        url: 'api/request/my',
        body: requestBody,
      }),
      transformResponse: (
        response: ResponseBody<Api.operations['getRequestsInWhichUserIsManager']>,
      ) => {
        return plainToInstance(GetRequests, response);
      },
      providesTags: ['MyAssignedRequests'],
    }),

    listRequestsCreatedByMe: builder.query<GetRequests, RequestBody<Api.operations['getRequests']>>(
      {
        query: (requestBody) => ({
          method: 'POST',
          url: 'api/request/list',
          body: requestBody,
        }),
        transformResponse: (response: ResponseBody<Api.operations['getRequests']>) => {
          return plainToInstance(GetRequests, response);
        },
        providesTags: ['RequestsCreatedByMe'],
      },
    ),

    getFavoriteRequests: builder.query<
      GetRequests,
      RequestBody<Api.operations['getFavoriteRequests']>
    >({
      query: (requestBody) => ({
        method: 'POST',
        url: 'api/request/favorite',
        body: requestBody,
      }),
      transformResponse: (response: ResponseBody<Api.operations['getFavoriteRequests']>) => {
        return plainToInstance(GetRequests, response);
      },
      providesTags: ['FavoriteRequests'],
    }),

    denyManager: builder.mutation<
      ResponseBody<Api.operations['denyApplicationForProjectManagerPosition']>,
      {
        projectId: PathParameters<
          Api.operations['denyApplicationForProjectManagerPosition'],
          'projectId'
        >;
        managerId: PathParameters<
          Api.operations['denyApplicationForProjectManagerPosition'],
          'managerId'
        >;
      }
    >({
      query: (requestBody) => ({
        method: 'POST',
        url: `/api/request/deny/${requestBody.projectId}/${requestBody.managerId}`,
        body: requestBody,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Менеджер отклонен', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['RequestsCreatedByMe'],
    }),

    acceptManager: builder.mutation<
      ResponseBody<Api.operations['acceptApplicationForProjectManagerPosition']>,
      Api.operations['acceptApplicationForProjectManagerPosition']['parameters']['path']
    >({
      query: (requestBody) => ({
        method: 'POST',
        url: `/api/request/accept/${requestBody.projectId}/${requestBody.managerId}`,
        body: requestBody,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Менеджер принят', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['RequestsCreatedByMe'],
    }),

    publishRequest: builder.mutation<
      PublishRequestResponse,
      PathParameters<Api.operations['publishRequest'], 'id'>
    >({
      query: (id) => ({
        method: 'POST',
        url: `/api/request/publish/${id}`,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Заявка опубликована', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['RequestDetail', 'RequestList', 'DashboardRequestAnalytics'],
    }),

    createRequest: builder.mutation<
      CreateSaveRequestResponse,
      RequestBody<Api.operations['createRequest']>
    >({
      query: (request) => ({
        method: 'POST',
        url: `/api/request`,
        body: request,
      }),
      invalidatesTags: ['DashboardRequestAnalytics'],
    }),

    updateRequest: builder.mutation<
      ResponseBody<Api.operations['updateRequest']>,
      RequestBody<Api.operations['updateRequest']>
    >({
      query: (requestBody) => ({
        method: 'PATCH',
        url: '/api/request',
        body: requestBody,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Заявка отредактирована', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['RequestDetail'],
    }),

    getApprovedRequests: builder.query<
      GetApprovedRequestsResponse,
      RequestBody<Api.operations['getAcceptedRequests']>
    >({
      query: (requestBody) => ({
        method: 'POST',
        url: '/api/request/accepted',
        body: requestBody,
      }),
      transformResponse: (response: ResponseBody<Api.operations['getAcceptedRequests']>) => {
        return plainToInstance(GetApprovedRequestsResponse, response);
      },
      providesTags: ['ApprovedRequests'],
    }),

    deleteManager: builder.mutation<
      ResponseBody<Api.operations['removeManagerFromRequest']>,
      PathParameters<Api.operations['removeManagerFromRequest'], 'projectId'>
    >({
      query: (requestId) => ({
        method: 'POST',
        url: `api/request/${requestId}/manager/dismiss`,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Менеджер удален из заявки', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['RequestDetail', 'RequestsCreatedByMe', 'DashboardRequestAnalytics'],
    }),
  }),
});

export const {
  useSetFavoriteRequestMutation,
  useDetailRequestQuery,
  useLazyDetailRequestQuery,
  useListRequestsQuery,
  useSendProposalMutation,
  useTransferRequestToProjectMutation,
  useDenyRequestMutation,
  useAcceptRequestMutation,
  useListMyAssignedRequestsQuery,
  useListRequestsCreatedByMeQuery,
  useGetFavoriteRequestsQuery,
  useDenyManagerMutation,
  useAcceptManagerMutation,
  usePublishRequestMutation,
  useCreateRequestMutation,
  useUpdateRequestMutation,
  useGetApprovedRequestsQuery,
  useDeleteManagerMutation,
} = requestsApi;
