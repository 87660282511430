import React from 'react';
import avatar from '@assets/img/avatar.svg';
import { SendMessageIcon } from '@components/ui/Icons/SendMessageIcon';
import { CloseIcon } from '@components/ui/Icons/CloseIcon';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@hooks/index';
import { selectCurrentUserId } from '@store/user/user.slice';
import { useGetPersonalChatMutation } from '@store/messages/messages.service';
import { useDeleteManagerMutation } from '@store/requests/requests.slice';
import styles from './RequestsPageDetail.module.scss';

type ManagerBlockProps = {
  managerName?: string;
  managerId?: string;
  customerId?: string;
  requestId?: string;
};

export const ManagerBlock: React.FC<ManagerBlockProps> = ({
  managerName,
  managerId,
  customerId,
  requestId,
}) => {
  const navigate = useNavigate();
  const myId = useAppSelector(selectCurrentUserId);
  const [createChat] = useGetPersonalChatMutation();
  const [deleteManager] = useDeleteManagerMutation();

  const handleMessage = () => {
    if (managerId) {
      createChat(managerId).then((response) => {
        if ('data' in response) navigate(`/messages/${response.data.id}`);
      });
    }
  };

  const handleDelete = () => {
    if (requestId) {
      deleteManager(requestId);
    }
  };

  if (managerId === undefined) return <></>;

  if (myId === customerId || myId === managerId) {
    return (
      <div className={styles.manager__block}>
        <div className={styles.manager__block__title}>Менеджер</div>
        <div className={myId === customerId ? styles.manager__block__info : ''}>
          <img
            src={avatar}
            alt="Аватар"
            width={52}
            height={52}
            className={styles.manager__block__info__avatar}
          />
          <span>{managerName}</span>
        </div>
        {myId !== managerId ? (
          <div className={styles.manager__block__buttons}>
            <div>
              <button className={styles.manager__block__buttons__message} onClick={handleMessage}>
                <SendMessageIcon />
                <span className={styles.manager__block__buttons__message__title}>Написать</span>
              </button>
            </div>
            <div>
              <button className={styles.manager__block__buttons__delete} onClick={handleDelete}>
                <CloseIcon />
                <span className={styles.manager__block__buttons__delete__title}>
                  Удалить из проекта
                </span>
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  return <></>;
};
