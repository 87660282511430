import React from 'react';
import { Button } from '@components/MUI/Button';
import { ReactComponent as AddGreen } from '@assets/img/addGreen.svg';
import { useNavigate } from 'react-router-dom';

export const AddTeamButton: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Button
      startIcon={<AddGreen />}
      onClick={() => navigate('/teams/new')}
      style={{ display: 'flex', justifyContent: 'start', maxWidth: 165 }}>
      Создать команду
    </Button>
  );
};
