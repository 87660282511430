import React, { useMemo } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Paper, Radio, RadioGroup } from '@mui/material';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import { Button } from '@components/MUI/Button';
import { InputField } from '@components/MUI/InputField/InputField';
import { useAppSelector } from '@hooks/index';
import { selectDictionary } from '@store/dictionary/dictionary.slice';
import { DictionaryName } from '@store/dictionary/contracts';
import { ProfilesRequestBody } from '@store/user/contracts';
import styles from './Aside.module.scss';

type AsideValues = {
  location?: string;
  experienceFrom?: string;
  experienceTo?: string;
  hourPriceFrom?: string;
  hourPriceTo?: string;
  directionIds?: string[];
  stack?: string[];
  roleId?: string;
};

export type AsideFormProps = {
  value: ProfilesRequestBody;
  submit: (
    value: ProfilesRequestBody,
    form: FormikBag<AsideFormProps, ProfilesRequestBody>,
  ) => void;
};

export const Aside: React.FC = () => {
  const form = useFormikContext<AsideValues>();
  const { setFieldValue } = useFormikContext();
  const directions = useAppSelector(selectDictionary(DictionaryName.DIRECTION));
  const roles = useAppSelector(selectDictionary(DictionaryName.ROLE));

  const directionIds = useMemo(() => {
    return form.values.directionIds;
  }, [form]);

  return (
    <Paper className={styles.aside__container} variant="outlined">
      <Form onSubmit={form.handleSubmit}>
        <InputField
          name="location"
          placeholder="Местоположение"
          type="text"
          isLabel={false}
          className={styles.form__location}
          size="small"
          value={form.values.location}
        />

        <div className={styles.form__expirience}>
          <h5 className="fw-600 fs-7">Опыт работы</h5>
          <RadioGroup>
            <FormControlLabel
              control={<Radio className={styles.radio} />}
              label="до 1 года"
              onChange={() => {
                setFieldValue('experienceFrom', undefined);
                setFieldValue('experienceTo', '1');
              }}
              checked={form.values.experienceTo === '1'}
            />
            <FormControlLabel
              control={<Radio className={styles.radio} />}
              label="1-3 года"
              name="experienceTo"
              onChange={() => {
                setFieldValue('experienceFrom', '1');
                setFieldValue('experienceTo', '3');
              }}
              checked={form.values.experienceFrom === '1' && form.values.experienceTo === '3'}
            />
            <FormControlLabel
              control={<Radio className={styles.radio} />}
              label="3-7 лет"
              name="experienceTo"
              onChange={() => {
                setFieldValue('experienceFrom', '3');
                setFieldValue('experienceTo', '7');
              }}
              checked={form.values.experienceFrom === '3' && form.values.experienceTo === '7'}
            />
            <FormControlLabel
              control={<Radio className={styles.radio} />}
              label="от 7 лет"
              name="experienceTo"
              onChange={() => {
                setFieldValue('experienceFrom', '7');
                setFieldValue('experienceTo', undefined);
              }}
              checked={form.values.experienceFrom === '7'}
            />
          </RadioGroup>
        </div>

        <div className={styles.form__payment}>
          <h5 className="fw-600 fs-7">Стоимость часа работы, ₽</h5>
          <RadioGroup>
            <FormControlLabel
              value="1000"
              control={<Radio className={styles.radio} />}
              label="до 1000"
              onChange={() => {
                setFieldValue('hourPriceFrom', undefined);
                setFieldValue('hourPriceTo', '1000');
              }}
              checked={form.values.hourPriceTo === '1000'}
            />
            <FormControlLabel
              value="2000"
              control={<Radio className={styles.radio} />}
              label="от 1000 до 2000"
              onChange={() => {
                setFieldValue('hourPriceFrom', '1000');
                setFieldValue('hourPriceTo', '2000');
              }}
              checked={form.values.hourPriceFrom === '1000' && form.values.hourPriceTo === '2000'}
            />
            <FormControlLabel
              value="3000"
              control={<Radio className={styles.radio} />}
              label="от 2000 до 3000"
              onChange={() => {
                setFieldValue('hourPriceFrom', '2000');
                setFieldValue('hourPriceTo', '3000');
              }}
              checked={form.values.hourPriceFrom === '2000' && form.values.hourPriceTo === '3000'}
            />
            <FormControlLabel
              value="10000"
              control={<Radio className={styles.radio} />}
              label="от 3000"
              onChange={() => {
                setFieldValue('hourPriceFrom', '3000');
                setFieldValue('hourPriceTo', undefined);
              }}
              checked={form.values.hourPriceFrom === '3000'}
            />
          </RadioGroup>
        </div>

        <div className={styles.form__direction}>
          <h5 className="fw-600 fs-7">Направление</h5>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox name="directionIds" className={styles.checkbox} />}
              label="Аналитика"
              onChange={form.handleChange}
              value={directions.find((direction) => direction.name === 'Аналитик')?.id}
              checked={directionIds?.includes(
                directions.find((direction) => direction.name === 'Аналитик')!.id,
              )}
            />
            <FormControlLabel
              control={<Checkbox name="directionIds" className={styles.checkbox} />}
              label="Архитектура"
              onChange={form.handleChange}
              value={directions.find((direction) => direction.name === 'Архитектор')?.id}
              checked={directionIds?.includes(
                directions.find((direction) => direction.name === 'Архитектор')!.id,
              )}
            />
            <FormControlLabel
              control={<Checkbox name="directionIds" className={styles.checkbox} />}
              label="Дизайн"
              onChange={form.handleChange}
              value={directions.find((direction) => direction.name === 'Дизайнер')?.id}
              checked={directionIds?.includes(
                directions.find((direction) => direction.name === 'Дизайнер')!.id,
              )}
            />
            <FormControlLabel
              control={<Checkbox name="directionIds" className={styles.checkbox} />}
              label="Разработка"
              onChange={form.handleChange}
              value={directions.find((direction) => direction.name === 'Разработчик')?.id}
              checked={directionIds?.includes(
                directions.find((direction) => direction.name === 'Разработчик')!.id,
              )}
            />
            <FormControlLabel
              control={<Checkbox name="directionIds" className={styles.checkbox} />}
              label="Тестирование"
              onChange={form.handleChange}
              value={directions.find((direction) => direction.name === 'Тестировщик')?.id}
              checked={directionIds?.includes(
                directions.find((direction) => direction.name === 'Тестировщик')!.id,
              )}
            />
          </FormGroup>
        </div>

        <div className={styles.form__stack}>
          <h5 className="fw-600 fs-7">Стэк технологий</h5>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox name="stack" className={styles.checkbox} />}
              label="PHP"
              onChange={form.handleChange}
              value={'PHP'}
              checked={form.values.stack?.includes('PHP') || false}
            />
            <FormControlLabel
              control={<Checkbox name="stack" className={styles.checkbox} />}
              label="JS"
              onChange={form.handleChange}
              value={'JS'}
              checked={form.values.stack?.includes('JS') || false}
            />
            <FormControlLabel
              control={<Checkbox name="stack" className={styles.checkbox} />}
              label="Angular"
              onChange={form.handleChange}
              value={'Angular'}
              checked={form.values.stack?.includes('Angular') || false}
            />
            <FormControlLabel
              control={<Checkbox name="stack" className={styles.checkbox} />}
              label="Python"
              onChange={form.handleChange}
              value={'Python'}
              checked={form.values.stack?.includes('Python') || false}
            />
          </FormGroup>
        </div>

        <div className={styles.buttons}>
          <Button variant="contained" disableElevation size="large" type="submit">
            Показать
          </Button>
          <Button
            variant="outlined"
            size="large"
            onClick={() => {
              form.setValues({
                location: undefined,
                experienceFrom: undefined,
                experienceTo: undefined,
                hourPriceFrom: undefined,
                hourPriceTo: undefined,
                directionIds: undefined,
                stack: undefined,
                roleId: roles.find((role) => role.code === 'EXECUTOR')?.id || undefined,
              });
            }}>
            Сбросить
          </Button>
        </div>
      </Form>
    </Paper>
  );
};

export const AsideForm = withFormik<AsideFormProps, ProfilesRequestBody>({
  displayName: 'AsideForm',
  mapPropsToValues: (props) => {
    return {
      location: props.value.location,
      experienceFrom: props.value.experienceFrom,
      experienceTo: props.value.experienceTo,
      hourPriceFrom: props.value.hourPriceFrom,
      hourPriceTo: props.value.hourPriceTo,
      directionIds: props.value.directionIds,
      stack: props.value.stack,
      roleId: props.value.roleId,
    };
  },
  enableReinitialize: true,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(Aside);
