import React, { useRef } from 'react';
import styles from './UniversityPage.module.scss';
import tilda from '@assets/img/aboutInfo/tilda.svg';
import { Typography as MTypography } from '@components/AboutInfo/AboutInfo';
import { CircularProgress, styled, useMediaQuery } from '@mui/material';
import mainPic from '@assets/img/university/mainImg.png';
import { Button } from '@components/MUI/Button';
import {
  OrganizationRequestForm,
  OrganizationRequestFormProps,
} from '@components/OrganizationRequestForm/OrganizationRequestForm';
import { useCreateOrganizationConnectRequestMutation } from '@store/user/user.slice';
import { useScrollToTop } from '@hooks/useScrollToTop';

export const UniversityPage = () => {
  const Typography = styled(MTypography)`
    color: var(--count-light);
    font-size: 18px;
    font-weight: 400;
    &:before {
      content: url(${tilda});
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  `;
  const isMobile = useMediaQuery('(max-width: 450px)');

  const [createOrganizationConnect, { isLoading, isSuccess }] =
    useCreateOrganizationConnectRequestMutation();

  const formRef = useRef<HTMLDivElement | null>(null);

  const scrollToForm = () => {
    formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const topRef = useScrollToTop();

  const submitUniversityRequestForm: OrganizationRequestFormProps['submit'] = async (
    OrganizationRequestForm,
    form,
  ) => {
    try {
      await createOrganizationConnect({
        fullName: OrganizationRequestForm.fullName,
        phone: OrganizationRequestForm.phone,
        institutionName: OrganizationRequestForm.institutionName,
        email: OrganizationRequestForm.email,
      });
      form.resetForm();
      form.setStatus('success');
    } catch (e: any) {
      form.setErrors(e);
    }
  };
  return (
    <div className={styles.university__page}>
      <div className={styles.mainInfoBg} ref={topRef}>
        <div className={styles.container}>
          <div className={styles.mainInfo}>
            <div className={styles.mainInfo__block}>
              <h1 className={styles.mainInfo__block_text}>
                <span>«Эджайл»</span> — многофункциональная платформа для ведения и мониторинга
                исполнения проектов
              </h1>
              <Button
                variant="contained"
                className={styles.mainInfo__block_btn}
                onClick={() => scrollToForm()}>
                {isMobile
                  ? 'Подключить университет'
                  : 'Оставить заявку на подключение университета'}
              </Button>
            </div>
            <img src={mainPic} alt="" className={styles.mainInfo__img} />
          </div>
        </div>
      </div>

      <div className={styles.reasonsMain}>
        <div className={styles.container}>
          <div className={styles.reasons}>
            <h2 className={styles.titleUniversity}>Почему Вам нужна платформа Egile </h2>
            <div className={styles.reasons__list}>
              <div className={styles.reasons__list__block}>
                <div className={styles.reasons__list__block_item}>
                  <p className={styles.reasons__list__block_item_number}>01</p>
                  <p className={styles.reasons__list__block_item_text}>
                    Сокращение времени от старта до реализации проектов, благодаря наличию полной
                    инфраструктуры для работы
                  </p>
                </div>
                <div className={styles.reasons__list__block_item}>
                  <p className={styles.reasons__list__block_item_number}>02</p>
                  <p className={styles.reasons__list__block_item_text}>
                    Улучшение управленческих решений за счёт получения аналитической информации и
                    отчетов в режиме реального времени
                  </p>
                </div>
              </div>
              <div className={styles.reasons__list__block}>
                <div className={styles.reasons__list__block_item}>
                  <p className={styles.reasons__list__block_item_number}>03</p>
                  <p className={styles.reasons__list__block_item_text}>
                    Снижение финансовых издержек путем оптимизации расходования технических ресурсов
                  </p>
                </div>
                <div className={styles.reasons__list__block_item}>
                  <p className={styles.reasons__list__block_item_number}>04</p>
                  <p className={styles.reasons__list__block_item_text}>
                    Повышение эффективности проектной деятельности университета за счет
                    централизованной организации процесса
                  </p>
                </div>
              </div>
              <div className={styles.reasons__list__block}>
                <div className={styles.reasons__list__block_item}>
                  <p className={styles.reasons__list__block_item_number}>05</p>
                  <p className={styles.reasons__list__block_item_text}>
                    Работа с аутсорс специалистами без дополнительной юридической нагрузки
                  </p>
                </div>
                <div className={styles.reasons__list__block_item}>
                  <p className={styles.reasons__list__block_item_number}>06</p>
                  <p className={styles.reasons__list__block_item_text}>
                    Уменьшение затрат путем оптимизации финансовых потоков и автоматизации
                    взаимодействия с внешними специалистами
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.benefitsMain}>
        <div className={styles.container}>
          <div className={styles.benefits}>
            <h2 className={styles.titleUniversity}>Польза</h2>
            <div className={styles.benefits__row}>
              <div className={styles.benefits__row__block}>
                <p className={styles.benefits__row__block_header}>Для управления ИТ</p>
                <div className={styles.benefits__row__block_list}>
                  <Typography>Упрощение выделения ресурсов, использование шаблонов</Typography>
                  <Typography>
                    Оптимизация бюджетов Прозрачное и автоматизированное планирование
                  </Typography>
                  <Typography>
                    Дополнение существующей команды недостающими специалистами из базы исполнителей
                    Эджайл
                  </Typography>
                  <Typography>
                    Возможность исполнения проекта аутсорс-специалистами платформы при сохранении
                    полного контроля над командой
                  </Typography>
                </div>
              </div>
              <div className={styles.benefits__row__block}>
                <p className={styles.benefits__row__block_header}>Для университетов</p>
                <div className={styles.benefits__row__block_list}>
                  <Typography>Прозрачность и контроль расходования бюджетов</Typography>
                  <Typography>
                    Хранение больших баз данных по проектной деятельности университета, благодаря
                    интеграции с Yandex Cloud
                  </Typography>
                  <Typography>
                    Централизованное отслеживание выполнения всех проектов включая НИОКР
                  </Typography>
                  <Typography>
                    Создание стартап среды, в том числе инфраструктура для формата «стартап как
                    диплом»
                  </Typography>
                </div>
              </div>
              <div className={styles.benefits__row__peopleBlock}>
                <div className={styles.benefits__row__peopleBlock__item}>
                  <p className={styles.benefits__row__peopleBlock__item_header}>
                    Для преподавателей
                  </p>
                  <div className={styles.benefits__row__peopleBlock__item_list}>
                    <Typography>
                      Раздел «Аналитика» обеспечивает удобный контроль за выполнением студенческих
                      работ со стороны преподавателей и научных руководителей
                    </Typography>
                  </div>
                </div>
                <div className={styles.benefits__row__peopleBlock__item}>
                  <p className={styles.benefits__row__peopleBlock__item_header}>Для студентов</p>
                  <div className={styles.benefits__row__peopleBlock__item_list}>
                    <Typography>
                      Возможность работать в открытых проектах коммерческих компаний и расширять
                      портфолио
                    </Typography>
                    <Typography>
                      Возможность использования платформы для проработки личных проектов и стартапов
                      для дальнейшей реализации
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.yandexPartnershipMain}>
        <div className={styles.container}>
          <div className={styles.yandexPartnership}>
            <h1 className={styles.yandexPartnership_text}>
              Мы являемся партнерами Yandex и интегрировали сервисы Yandex Cloud и Yandex 360,
              включая Yandex Tracker для эффективности вашей работы
            </h1>
          </div>
        </div>
      </div>

      <div className={styles.yandexDiscountMain}>
        <div className={styles.container}>
          <div className={styles.yandexDiscount}>
            <h2 className={styles.yandexDiscount_text}>
              Используйте инструменты <span>Y</span>andex на Egile с дополнительной скидкой
            </h2>
            <p className={styles.yandexDiscount_subtext}>
              Список интегрированных решений постоянно расширяется
            </p>
          </div>
        </div>
      </div>

      <div className={styles.container} ref={formRef}>
        <div className={styles.formMain}>
          {isLoading ? (
            <CircularProgress />
          ) : isSuccess ? (
            <span className={styles.title__color}>
              Ваша заявка на подключение успешно отправлена
            </span>
          ) : (
            <OrganizationRequestForm
              value={{
                fullName: '',
                phone: '',
                institutionName: '',
                email: '',
                agreement: false,
              }}
              submit={submitUniversityRequestForm}
              organizationPlaceholder="Название учебного заведения*"
            />
          )}
        </div>
      </div>
    </div>
  );
};
