import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useOutletContext } from 'react-router-dom';
import { ButtonCreateVacancy } from '@components/teams/Header/ButtonCreateVacancy';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { Dayjs } from 'dayjs';
import { useRequestBody } from '@hooks/useRequestBody';
import { AddVacancyButton } from '../buttons/AddVacancyButton';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { AdaptiveActionButtons } from '@components/adaptives/AdaptiveActionButtons';
import { useNavigateToUserChat } from '@hooks/useNavigateToUserChat';
import { Can } from '@access-control';
import { VacanciesLayoutOutletContext } from '@layouts/vacancies/VacanciesLayout';
import { useAcceptOfferMutation, useDenyOfferMutation } from '@store/offer/offer.slice';
import { useGetVacancyResponsesQuery } from '@store/vacancy/vacancy.slice';

export type VacancyResponse = {
  created: Dayjs;
  name: string;
  positionName: string;
  directionName: string;
  stack: string;
  responseDate: Dayjs;
  executorName: string;
  executorId: string;
  payment: number;
  teamId: string;
  actions: {};
  offerStatus: string;
  id: string;
};

const TeamResponses: React.FC = () => {
  const outletContext = useOutletContext<VacanciesLayoutOutletContext>();

  useEffect(() => {
    outletContext.setRightSideButton(
      <Can I="create" a="Vacancies">
        <ButtonCreateVacancy />
      </Can>,
    );
  }, []);

  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();
  const createChat = useNavigateToUserChat();
  const [denyOffer] = useDenyOfferMutation();
  const [acceptOffer] = useAcceptOfferMutation();
  const { data: vacancies } = useGetVacancyResponsesQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const vacancyResponses: VacancyResponse[] = useMemo(() => {
    const vacancyResponses: VacancyResponse[] = [];

    if (vacancies?.data) {
      for (let i = 0; i < vacancies?.data.length; i++) {
        if (vacancies?.data[i].offers) {
          for (let j = 0; j < vacancies?.data[i].offers.length; j++) {
            vacancyResponses.push({
              offerStatus: vacancies?.data[i].offers[j].offerStatusName,
              created: vacancies?.data[i].created,
              name: vacancies?.data[i].teamName,
              positionName: vacancies?.data[i].name,
              directionName: vacancies?.data[i].directionName,
              stack: vacancies?.data[i].stack,
              responseDate: vacancies?.data[i].offers[j].created,
              executorName: vacancies?.data[i].offers[j].senderName,
              payment: vacancies?.data[i].offers[j].payment,
              teamId: vacancies?.data[i].teamId,
              id: vacancies?.data[i].offers[j].id,
              executorId: vacancies?.data[i].offers[j].senderId,
              actions: {},
            });
          }
        }
      }
    }
    return vacancyResponses;
  }, [vacancies]);

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handleApprove = (row: VacancyResponse) => {
    acceptOffer(row.id);
  };

  const handleClose = (row: VacancyResponse) => {
    denyOffer(row.id);
  };

  const handleSendMessage = (vacancy: VacancyResponse) => {
    createChat(vacancy.executorId);
  };

  return (
    <AdaptiveTableMUI
      sortOrder={sortOrder}
      sortColumn={sortColumn}
      setRequestBody={setRequestBody}
      setSortColumn={setSortColumn}
      setSortOrder={setSortOrder}
      isEmpty={vacancies?.data?.length === 0}
      emptyLabel="Отклики на вакансии отсутствуют"
      columns={{
        created: {
          label: 'Дата публикации',
          format: (value: Dayjs) => value.format('DD.MM.YYYY'),
          filterInput: true,
          isSortColumn: true,
        },
        actionsRaw: {
          children: () => <div></div>,
        },
        name: {
          label: 'Название команды',
          children: (value, row) => <NavLink to={`/teams/${row.teamId}`}>{value}</NavLink>,
          filterInput: true,
          isSortColumn: false,
        },
        positionName: {
          label: 'Роль',
          filterInput: true,
          isSortColumn: false,
        },
        directionName: {
          label: 'Направление',
          filterInput: true,
          isSortColumn: false,
        },
        stack: {
          label: 'Стек',
          filterInput: true,
          isSortColumn: false,
        },
        responseDate: {
          label: 'Дата отклика',
          format: (value: Dayjs) => value.format('DD.MM.YYYY'),
          filterInput: true,
          isSortColumn: true,
        },
        executorName: {
          label: 'Исполнитель',
          filterInput: true,
          isSortColumn: false,
        },
        payment: {
          label: 'Предложение',
          format: (value: string) => `${value} р/ч`,
          filterInput: true,
          isSortColumn: false,
        },
        actions: {
          children: (value, row: VacancyResponse) => (
            <AdaptiveActionButtons
              handleApprove={() => handleApprove(row)}
              handleClose={() => handleClose(row)}
              handleSendMessage={() => handleSendMessage(row)}
              isOnlySend={
                row.offerStatus === 'Оффер от исполнителя' ||
                row.offerStatus === 'Оффер от менеджера'
                  ? false
                  : true
              }
            />
          ),
        },
      }}
      rows={vacancyResponses}
      additionalElement={<AddVacancyButton />}>
      <TablePaginationMUI
        limit={limit}
        count={vacancies?.totalCount}
        entityLabel="откликов"
        page={page}
        handleChangePage={(_, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </AdaptiveTableMUI>
  );
};

export { TeamResponses };
