import React, { useState } from 'react';
import { Box, styled, Tabs } from '@mui/material';
import Tab from '@mui/material/Tab';

export interface TabData {
  label: string;
  content: React.ReactNode;
}

export interface TabsWrappedLabelProps {
  tabs: TabData[];
  defaultValue?: number;
}

const CustomTab = styled(Tab, { shouldForwardProp: (prop) => prop !== 'customIsActive' })<{
  customIsActive: boolean;
}>(({ customIsActive }) => ({
  color: customIsActive ? '#fff !important' : 'inherit',
  background: customIsActive ? '#199b3e' : 'inherit',
  borderRadius: '4px',
  border: 'none',
}));

export const TabsWrappedLabel: React.FC<TabsWrappedLabelProps> = ({ tabs, defaultValue }) => {
  const [value, setValue] = useState(defaultValue ?? 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  };
  return (
    <Box>
      <Tabs
        sx={{
          border: 1,
          borderRadius: '4px',
          borderColor: '#199B3E',
          width: 'fit-content',
          '& .MuiTabs-indicator': { display: 'none' },
        }}
        value={value}
        onChange={handleChange}>
        {tabs.map((tab, index) => (
          <CustomTab
            key={index}
            label={tab.label}
            {...a11yProps(index)}
            customIsActive={value === index}
            onClick={(event) => handleChange(event, index)}
          />
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <div key={index} role="tabpanel" hidden={value !== index}>
          {value === index && <div>{tab.content}</div>}
        </div>
      ))}
    </Box>
  );
};
