import React from 'react';
import { FormWrapper } from '@components/Form/Form';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { Box, Modal } from '@mui/material';
import { closeModal, isModalOpen } from '@store/ui/ui.slice';
import { Button } from '@components/MUI/Button';
import { selectProfile, useDeleteAccountMutation } from '@store/user/user.slice';
import styles from './DeleteAccountModal.module.scss';

export const DeleteAccountModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isModalOpen);
  const myProfile = useAppSelector(selectProfile);
  const [deleteAccount] = useDeleteAccountMutation();

  const handleDeleteAccount = () => {
    deleteAccount({
      email: myProfile?.link?.urls[0] || '',
      username: myProfile?.about?.userName || '',
      topic: 'Запрос на удаление аккаунта',
      text: `id: ${myProfile?.id}`,
    }).finally(() => dispatch(closeModal()));
  };

  return (
    <Modal open={isOpen} onClose={() => dispatch(closeModal())} className={styles.modal}>
      <Box className={styles.container}>
        <FormWrapper
          label={'Вы точно хотите отправить запрос на удаление аккаунта?'}
          isThinTitle
          isSmallModal>
          <div className={styles.buttons}>
            <Button onClick={() => dispatch(closeModal())} className={styles.button__cancel}>
              Отменить
            </Button>
            <Button
              variant="contained"
              className={styles.button__send}
              onClick={handleDeleteAccount}>
              Отправить
            </Button>
          </div>
        </FormWrapper>
      </Box>
    </Modal>
  );
};
