import React, { useCallback, useEffect, useState } from 'react';
import { Select, SelectOption } from '@components/MUI/Select';
import { SelectChangeEvent } from '@mui/material';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { object } from 'yup';
import { Button } from '@components/MUI/Button';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import {
  listProjectInYandexOrganization,
  listYandexOrganization,
} from '@store/user/integration.service';
import styles from './AddTrackerForm.module.scss';

export type AddTrackerValue = {
  organizationId: string;
  projectId: string;
};

export type AddTrackerProps = {
  submit: (value: AddTrackerValue, form: FormikBag<AddTrackerProps, AddTrackerValue>) => void;
};

const addProjectSchema = object({
  organizationId: Yup.string().required('Поле обязательно для заполнения'),
  projectId: Yup.string().required('Поле обязательно для заполнения'),
});

export const AddTracker: React.FC = () => {
  const dispatch = useAppDispatch();
  const form = useFormikContext<AddTrackerValue>();
  const [organizations, setOrganizations] = useState<SelectOption[]>([]);
  const [orgProjects, setOrgProjects] = useState<SelectOption[]>([]);

  useEffect(() => {
    listYandexOrganization().then((organizations) =>
      setOrganizations(organizations.map((org) => ({ value: org.id, label: org.title }))),
    );
  }, []);

  useEffect(() => {
    if (form.values.organizationId) {
      listProjectInYandexOrganization(form.values.organizationId).then((projects) =>
        setOrgProjects(projects.map((project) => ({ value: project.id, label: project.name }))),
      );
    }
  }, [form.values.organizationId]);

  const handleCancel = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <Form onSubmit={form.handleSubmit} className={styles.form}>
      <Select
        name="organizationId"
        values={organizations}
        onChange={(event: SelectChangeEvent) => {
          form.setFieldValue('organizationId', event.target.value);
        }}
        isOutlined={true}
        label="Организации"
        className={styles.select__organization}
      />
      <Select
        name="projectId"
        values={orgProjects}
        onChange={(event: SelectChangeEvent) => {
          form.setFieldValue('projectId', event.target.value);
        }}
        isOutlined={true}
        label="Проекты в организации"
        className={styles.select__project}
      />
      <div className="d-flex justify-content-end mt-5">
        <Button className={styles.button__cancel} onClick={handleCancel}>
          Отменить
        </Button>
        <Button
          disabled={!form.isValid}
          className={styles.button__submit}
          variant="contained"
          type="submit"
          disableElevation={true}>
          Добавить
        </Button>
      </div>
    </Form>
  );
};

export const AddTrackerForm = withFormik<AddTrackerProps, AddTrackerValue>({
  displayName: 'AddTrackerForm',
  mapPropsToValues: (props) => {
    return {
      organizationId: '',
      projectId: '',
    };
  },
  isInitialValid: false,
  enableReinitialize: true,
  validationSchema: addProjectSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(AddTracker);
