import { Form } from 'react-bootstrap';
import styles from './select.module.scss';
import { useFormikContext } from 'formik';
import { DictionaryValue } from '@store/dictionary/contracts';
import React from 'react';

export type SelectOption = { value: any; label: string };

export interface SelectData {
  options: Array<SelectOption>;
  name: string;
  placeholder: string;
  onChange?: (selectedValue: string) => void;
  disabled?: boolean;
}

export function fromDictionaryToSelectOption(dictValue: DictionaryValue): SelectOption {
  return {
    value: dictValue.id,
    label: dictValue.name,
  };
}

export const Select = ({ options, name, placeholder, onChange, disabled }: SelectData) => {
  const form = useFormikContext();
  const meta = form.getFieldMeta<any>(name);
  const hasError = meta.error !== undefined;

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    form.handleChange(event);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <Form.Group controlId={name}>
      <Form.Select
        className={styles.select}
        onChange={handleSelectChange}
        onBlur={form.handleBlur}
        disabled={disabled}
        isInvalid={meta.touched && hasError}>
        <option>{placeholder}</option>
        {options.map((option, index) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
};
