import React from 'react';

export const AnalyticsRequestIcon = () => {
  return (
    <svg
      className="icon me-2"
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 18V16.1L1.5 14.6V18H0ZM4.125 18V12.1L5.625 10.6V18H4.125ZM8.25 18V10.6L9.75 12.125V18H8.25ZM12.375 18V12.125L13.875 10.625V18H12.375ZM16.5 18V8.1L18 6.6V18H16.5ZM0 12.1V9.975L7 3.025L11 7.025L18 0V2.125L11 9.15L7 5.15L0 12.1Z"
        fill="#40E0D0"
      />
    </svg>
  );
};
