import { FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Box as MBox, styled } from '@mui/material';
import { InputField } from '@components/MUI/InputField/InputField';
import { Link } from 'react-router-dom';
import { Button } from '@components/MUI/Button';
import { Checkbox } from '@components/ui/Checkbox/Checkbox';
import styles from './Authentication.module.scss';

export type RegistrationFormProps = {
  value: RegistrationFormValue;
  submit: (
    value: RegistrationFormValue,
    form: FormikBag<RegistrationFormProps, RegistrationFormValue>,
  ) => void;
};

export type RegistrationFormValue = {
  email: string;
  agreement: boolean;
};

const RegistrationFormSchema = Yup.object({
  email: Yup.string().email('Введите E-mail').required('Поле обязательно для заполнения'),
  agreement: Yup.boolean().oneOf([true], 'Вы должны согласиться с условиями'),
});

const Box = styled(MBox)`
  margin-top: 18px;
`;

const checkboxStyle = {
  '&.MuiButtonBase-root': {
    padding: 0,
  },
};

const FormComponent = () => {
  const registrationForm = useFormikContext<RegistrationFormValue>();
  registrationForm.validationSchema = RegistrationFormSchema;
  return (
    <Form onSubmit={registrationForm.handleSubmit}>
      <div className={styles.registration_form}>
        <InputField name="email" type="email" placeholder="Электронная почта" />
        <div className={styles.registration_form__agreement}>
          <Checkbox
            fieldClassName={`align-items-start m-0 ${styles.registration_form__agreement_checkbox}`}
            sx={checkboxStyle}
            name="agreement"
          />
          <p>
            Нажимая кнопку «Далее», я подтверждаю, что ознакомился и даю согласие с условиями{' '}
            <Link to="/legalDocuments/terms/" target="_blank" rel="noopener noreferrer">
              пользовательского соглашения
            </Link>
          </p>
        </div>
      </div>
      <Box>
        <Button
          variant="contained"
          className="pt-3 pb-3 w-100"
          type="submit"
          disabled={!registrationForm.isValid}>
          <Link to="/registration/verify-mail" className="text-white text-decoration-none fw-500">
            Далее
          </Link>
        </Button>
      </Box>
    </Form>
  );
};

export const RegistrationForm = withFormik<RegistrationFormProps, RegistrationFormValue>({
  displayName: 'RegistrationForm',
  mapPropsToValues: (props) => {
    return {
      email: props.value.email,
      agreement: props.value.agreement,
    };
  },
  enableReinitialize: true,
  validationSchema: RegistrationFormSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
