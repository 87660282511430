import React from 'react';

export const InfoAboutVacancyIcon = () => {
  return (
    <svg
      className="icon me-2"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5 21C3.1 21 2.75 20.85 2.45 20.55C2.15 20.25 2 19.9 2 19.5V7.5C2 7.1 2.15 6.75 2.45 6.45C2.75 6.15 3.1 6 3.5 6H8V3.5C8 3.1 8.15 2.75 8.45 2.45C8.75 2.15 9.1 2 9.5 2H14.5C14.9 2 15.25 2.15 15.55 2.45C15.85 2.75 16 3.1 16 3.5V6H20.5C20.9 6 21.25 6.15 21.55 6.45C21.85 6.75 22 7.1 22 7.5V19.5C22 19.9 21.85 20.25 21.55 20.55C21.25 20.85 20.9 21 20.5 21H3.5ZM3.5 19.5H20.5V7.5H3.5V19.5ZM9.5 6H14.5V3.5H9.5V6Z"
        fill="#78858B"
      />
      <path d="M12.8258 18H11.3258V12H12.8258V18Z" fill="#78858B" />
      <path
        d="M12.5883 9.92C12.4299 10.0733 12.234 10.15 12.0003 10.15C11.7667 10.15 11.5709 10.0733 11.4128 9.92C11.2548 9.76667 11.1758 9.57667 11.1758 9.35C11.1758 9.10917 11.2549 8.90729 11.4133 8.74437C11.5716 8.58146 11.7676 8.5 12.0012 8.5C12.2348 8.5 12.4307 8.58146 12.5887 8.74437C12.7468 8.90729 12.8258 9.10917 12.8258 9.35C12.8258 9.57667 12.7466 9.76667 12.5883 9.92Z"
        fill="#78858B"
      />
    </svg>
  );
};
