import React from 'react';
import { useAppDispatch } from '@hooks/index';
import { openModal } from '@store/ui/ui.slice';
import { AddProjectModal } from '../modals/AddProjectModal';
import { ReactComponent as AddIcon } from '@assets/img/add.svg';

type AddProjectButtonProps = {
  teamId: string;
};

export const AddProjectButton: React.FC<AddProjectButtonProps> = ({ teamId }) => {
  const dispatch = useAppDispatch();

  return (
    <button
      className="color-green border-0 bg-transparent mb-3 fw-500"
      onClick={() => dispatch(openModal(<AddProjectModal teamId={teamId} />))}>
      <AddIcon style={{ marginRight: '0.375rem' }} />
      Добавить проект
    </button>
  );
};
