import NotFound from '@assets/img/notMatch/notFound.png';
import styles from './NotMatch.module.scss';
import { Button } from '@components/MUI/Button';
import { ReactComponent as ArrowLeftSmall } from '@assets/img/arrowLeftSmall.svg';
import { Link, useNavigate } from 'react-router-dom';

const navigationBackStyle = {
  '&.MuiButtonBase-root': {
    '&:hover': {
      backgroundColor: 'transparent',
      '& svg': {
        '& path': {
          stroke: 'inherit',
        },
      },
    },
  },
};

const navigationMainStyle = {
  '&.MuiButtonBase-root': {
    padding: '10px 20px',
  },
};

export function NotMatch() {
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container__block}>
          <div className={styles.container__block__info}>
            <h1>404</h1>
            <h3 className="">Такой страницы пока не существует</h3>
          </div>
          <div className={styles.container__block__buttons}>
            <Button
              onClick={handleNavigateBack}
              variant="text"
              color="secondary"
              sx={navigationBackStyle}
              className={styles.container__block__buttons_back}>
              <ArrowLeftSmall />
              <span>Вернуться назад</span>
            </Button>
            <Link to="/">
              <Button variant="contained" sx={navigationMainStyle}>
                На главную
              </Button>
            </Link>
          </div>
        </div>
        <div>
          <img src={NotFound} alt="Ведется разработка" className={styles.container__img} />
        </div>
      </div>
    </>
  );
}
