import React, { useCallback, useEffect, useState } from 'react';
import styles from './iconDropZone.module.scss';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useField, useFormikContext } from 'formik';
import { ApiClient } from '@store/api-client';
import { ConfirmDeleteModal } from '@components/MUI/ConfirmDeleteModal/ConfirmDeleteModal';
import { useAppDispatch } from '@hooks/index';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { CircularProgress } from '@mui/material';
import { IconRemove } from '@pages/teams/createTeam/IconRemove';

interface IIconDropZOne {
  name: string;
  alt?: string;
  defaultSrc: string;
}

export const IconDropZone = ({ name, alt, defaultSrc }: IIconDropZOne) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField<string>(name);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [tempSrc, setTempSrc] = useState<string | null>(null);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setLoading(true);
      const file = acceptedFiles[0];

      if (file instanceof File) {
        setTempSrc(URL.createObjectURL(file));
      } else {
        setError('Загруженный файл не поддерживается');
        setLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      try {
        const res = await ApiClient.post(`/api/file/ICON/upload`, formData);
        const { id } = res.data;
        await setFieldValue(name, id);
      } catch (e: any) {
        setError(e?.response?.data?.message || e.message);
      }

      setLoading(false);
    },
    [name, setFieldValue],
  );

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
    setError(null);
  }, [dispatch]);

  const onDropRejected = useCallback(
    (event: FileRejection[]) => {
      switch (event[0].errors[0].code) {
        case 'file-too-large':
          setError(`Файл ${event[0].file.name} слишком большой`);
          setLoading(false);
          break;
        case 'file-invalid-type':
          setError(`Неверный формат файла ${event[0].file.name}`);
          setLoading(false);
          break;
        default:
          setError(event[0].errors[0].message);
      }
    },
    [setError],
  );

  useEffect(() => {
    if (error) {
      dispatch(
        openModal(
          <ConfirmDeleteModal title="Ошибка" btnProp="OK" onSubmit={handleCloseModal}>
            <div>{error}</div>
          </ConfirmDeleteModal>,
        ),
      );
    }
  }, [error, dispatch, handleCloseModal]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    maxSize: 128 * 1024,
    maxFiles: 1,
    multiple: false,
    noDrag: true,
    accept: {
      'image/*': ['.png', '.jpeg', '.jpg', '.webp'],
    },
  });

  const removeFile = () => {
    setFieldValue(name, null);
    setTempSrc(null);
  };

  return (
    <div className={styles.container}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {loading ? (
          <p>
            <CircularProgress />
          </p>
        ) : (
          <img
            className={styles.icon}
            src={tempSrc || (field.value ? field.value : defaultSrc)}
            alt={alt}
          />
        )}
      </div>
      {field.value && (
        <button className={styles.removeButton} onClick={() => removeFile()}>
          <IconRemove />
        </button>
      )}
    </div>
  );
};
