import { PaginationQuery } from '@store/common';
import { RequestBodyType, releases } from './contracts';
import dayjs from 'dayjs';

export type listAnalyticsRequestsType = PaginationQuery & RequestBodyType;

export const getProjectReleases = async () => {
  const data = releases;
  return data;
};

export const convertDate = (date: string) => {
  const convertedDate = dayjs(date, 'DD.MM.YYYY HH:mm');
  return convertedDate;
};
