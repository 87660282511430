import React, { useMemo } from 'react';
import { More } from '@components/ui/More/More';
import { MenuItem, useMediaQuery } from '@mui/material';
import { AssignVacancyButton } from './buttons/AssignVacancyButton';
import { StatusLabel, statusColors } from '@components/ui/StatusLabel/StatusLabel';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { useLoadTeamQuery } from '@store/team/team.slice';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { Link, useParams } from 'react-router-dom';
import { AddPositionButton } from './buttons/AddPositionButton';
import { selectCurrentUserId } from '@store/user/user.slice';
import { openModal } from '@store/ui/ui.slice';
import { DeletePositionModal } from './modals/DeletePositionModal';
import styles from './TeamPageDetail.module.scss';
import { ActionsModalButton } from '@components/MUI/ActionsModalButton/ActionsModalButton';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';

type TeamPosition = {
  id: string;
  userId: string;
  userName: string | null;
  role: string;
  positionId: string;
  stack: string;
  telegram: string;
  status: JSX.Element;
};

export const TeamPageDetailMembers: React.FC = () => {
  const isTablet = useMediaQuery('(max-width: 991px)');
  const dispatch = useAppDispatch();
  const myId = useAppSelector(selectCurrentUserId);
  const { teamId } = useParams();

  const { data: team } = useLoadTeamQuery(teamId ? teamId : '', {
    refetchOnMountOrArgChange: true,
  });

  const positions: TeamPosition[] = useMemo(() => {
    const positions = team?.positions?.map((position) => {
      const newPosition = {
        id: position.id || '-',
        userId: position.userId || '',
        userName: position.userName || '',
        role: position.positionName,
        positionId: position.positionId || '',
        stack: position.vacancy?.stack || '-',
        telegram: position.telegram || '-',
        status: position.userId ? (
          position.statusName === 'Принят' ||
          position.statusName === 'Оффер от исполнителя' ||
          position.statusName === 'Оффер от менеджера' ? (
            <StatusLabel text={position.statusName} color={statusColors.get(position.statusName)} />
          ) : (
            <StatusLabel text={position.statusName} color={statusColors.get(position.statusName)} />
          )
        ) : position.vacancy ? (
          <StatusLabel text={position.statusName} color={statusColors.get(position.statusName)} />
        ) : (
          <AssignVacancyButton positionId={position.id} teamId={position.teamId} />
        ),
      };
      return newPosition;
    });
    return positions || [];
  }, [team]);

  return (
    <div className={styles.table__container}>
      {team?.teamLead?.userId === myId ? <AddPositionButton team={team} /> : <></>}
      <AdaptiveTableMUI
        isEmpty={positions.length === 0}
        emptyLabel="У вас нет участников"
        columns={{
          userName: {
            label: 'ФИО',
            children: (value: string, row) =>
              value ? <Link to={`/userPage/${row.userId}`}>{value}</Link> : <span>-</span>,
          },
          actionsRaw: {
            children: () => <div></div>,
          },
          role: {
            label: 'Роль',
          },
          stack: {
            label: 'Стэк',
          },
          telegram: {
            label: 'Ник в Telegram',
          },
          status: {
            label: 'Статус',
          },
          actions: {
            label: '',
            children: (_, row: TeamPosition) =>
              !isTablet ? (
                <More>
                  <MenuItem
                    onClick={() =>
                      dispatch(
                        openModal(<DeletePositionModal team={team} participantId={row.userId} />),
                      )
                    }>
                    Исключить
                  </MenuItem>
                  {row?.status?.props.text === 'Оффер от исполнителя' ? (
                    <MenuItem>
                      <Link to="/vacancies/my">
                        Перейти в вакансии для принятия/отклонения исполнителя
                      </Link>
                    </MenuItem>
                  ) : null}
                </More>
              ) : (
                <ActionsModalButton
                  children={() => (
                    <div>
                      <button
                        className={styles.modal__button}
                        onClick={() =>
                          dispatch(
                            openModal(
                              <DeletePositionModal team={team} participantId={row.userId} />,
                            ),
                          )
                        }>
                        Исключить
                      </button>
                    </div>
                  )}
                />
              ),
          },
        }}
        rows={positions}>
        <TablePaginationMUI
          limit={10}
          count={10}
          entityLabel="участников"
          page={1}
          handleChangePage={() => {}}
          handleChangeRowsPerPage={() => {}}
        />
      </AdaptiveTableMUI>
    </div>
  );
};
