import { useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { FormField } from '@components/Form/FormField';
import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import returnBack from '@assets/img/registration/arrow-back.svg';

export type EmailFormProps = {
  value: EmailFormValue;
  submit: (value: EmailFormValue) => void;
};

export type EmailFormValue = {
  email: string;
};

const EmailFormSchema = Yup.object({
  email: Yup.string().email('Введите E-mail').required('Поле обязательно для заполнения'),
});

const FormComponent = () => {
  const emailForm = useFormikContext<EmailFormValue>();
  const handleRemoveSessionItem = useCallback(() => {
    sessionStorage.removeItem('email');
  }, []);
  return (
    <Form onSubmit={emailForm.handleSubmit}>
      <div className="display-flex flex-direction-column">
        <FormField
          name="email"
          type="email"
          placeholder="Электронная почта"
          className="input-classical"
        />
      </div>
      <div className="mb-5">На почту отправим ссылку для восстановления пароля</div>
      <div>
        <Box>
          <Button
            variant="contained"
            className="pt-3 pb-3 w-100 mt-2 text-white"
            type="submit"
            disabled={!emailForm.isValid}>
            Отправить
          </Button>
        </Box>
        <Box
          sx={{
            marginTop: '38px',
          }}>
          <Link to="/authorization" className="link__return-back" onClick={handleRemoveSessionItem}>
            <img src={returnBack} alt="Вернуться ко входу" />
            Вернуться ко входу
          </Link>
        </Box>
      </div>
    </Form>
  );
};

export const ResettingFormStepOne = withFormik<EmailFormProps, EmailFormValue>({
  displayName: 'EmailForm',
  mapPropsToValues: (props) => {
    return {
      email: props.value.email,
    };
  },
  enableReinitialize: true,
  validationSchema: EmailFormSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values);
  },
})(FormComponent);
