import { ToDate } from '@store/common';
import { Dayjs } from 'dayjs';

export class Notification {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  type!: 'REQUEST' | 'PROJECT' | 'OFFER' | 'TEAM';
  text!: string;
  entityId!: string;
  entityName!: string;
}
