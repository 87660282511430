import React from 'react';

type RequiredFieldsIndicatorProps = {
  className?: string;
};

export const RequiredFieldsIndicator: React.FC<RequiredFieldsIndicatorProps> = ({ className }) => {
  return (
    <div className={className}>
      <p className="mt-2 mb-0 color-gray">* обозначены поля обязательные для заполнения</p>
    </div>
  );
};
