import React from 'react';
import { Outlet } from 'react-router-dom';
import { JobsWithoutRegistration } from '@pages/main/JobsWithoutRegistration';
import { useScrollToTop } from '@hooks/useScrollToTop';

const OpenProjectLayout = () => {
  const topRef = useScrollToTop();

  return (
    <div ref={topRef}>
      <div>
        <JobsWithoutRegistration />
        <Outlet />
      </div>
    </div>
  );
};

export { OpenProjectLayout };
