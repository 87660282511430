import React from 'react';
import { Can } from '@access-control';
import { Button } from '@components/MUI/Button';
import { ReactComponent as AddGreen } from '@assets/img/addGreen.svg';
import { useNavigate } from 'react-router-dom';

export const CreateTaskButton: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Can I={'create'} a={'Task'}>
      <Button
        startIcon={<AddGreen />}
        onClick={() => navigate('/tasks/new')}
        style={{ display: 'flex', justifyContent: 'start', maxWidth: 160 }}>
        Создать задачу
      </Button>
    </Can>
  );
};
