import React, { memo, useEffect, useMemo, useState } from 'react';
import { BarPlot } from '@mui/x-charts/BarChart';
import { useAppSelector } from '@hooks/index';
import { selectDictionary } from '@store/dictionary/dictionary.slice';
import { DictionaryName } from '@store/dictionary/contracts';
import { statusProjectColors } from '@components/ui/StatusLabel/StatusLabel';
import { Release } from '@store/dashboard/contracts';
import styles from './TasksReleasesChart.module.scss';
import { IAnimationStyle } from '../../AdvancedAnalytics/components/CustomBar/CustomBar';
import {
  BarSeriesType,
  ChartsAxis,
  ChartsAxisHighlight,
  ResponsiveChartContainer,
} from '@mui/x-charts';

type TasksGanttChartProps = {
  releases: Release[];
};

export const TasksReleasesChart: React.FC<TasksGanttChartProps> = ({ releases }) => {
  const taskStatuses = useAppSelector(selectDictionary(DictionaryName.PROJECT_STATUS)).map(
    (status) => status.name,
  );

  const releasesSeries = useMemo(() => {
    const releasesSeries: BarSeriesType[] = [];
    taskStatuses.map((status) =>
      releasesSeries.push({
        data: [],
        label: status,
        stack: 'Release',
        type: 'bar',
        layout: 'horizontal',
      }),
    );

    releases.forEach((release) => {
      release.statusCountTaskList.forEach((taskStatus, index) => {
        releasesSeries[index]?.data?.push(taskStatus.count);
        releasesSeries[index].id = `${taskStatus.statusName}Id`;
      });
    });

    return releasesSeries;
  }, [taskStatuses, releases]);

  const barsData = Object.fromEntries(releasesSeries.map((item) => [item.id, item]));

  const releasesTitles = useMemo(() => {
    const releasesTitles = releases.map((release) => release.name);
    return releasesTitles;
  }, [releases]);

  const releasesDates = useMemo(() => {
    const releasesDates = releases.map((release) => `${release.startDate} - ${release.endDate}`);
    return releasesDates;
  }, [releases]);

  const statusColor = useMemo(() => {
    return taskStatuses.map((taskStatus) => statusProjectColors.get(taskStatus) || '');
  }, [taskStatuses]);

  const CustomBar = memo((props: any) => {
    const { className, ownerState, style } = props;
    const { id, dataIndex, color } = ownerState;
    const { y, x, height, width } = style;

    const lastKey = Object.keys(barsData)[Object.keys(barsData).length - 1];

    const isLast = ownerState.id === lastKey;

    const clipPath = isLast ? 'inset(0px round 0px 4px 4px 0px)' : '';

    const textContent = useMemo(() => {
      if (barsData[id]?.data[dataIndex]) return barsData[id]?.data[dataIndex];
      return '';
    }, [barsData]);

    const [animationStyle, setAnimationStyle] = useState<IAnimationStyle>({
      height: 0,
      width: 0,
      x: 0,
      y: 0,
    });

    useEffect(() => {
      if (props?.style?.x?.animation) {
        const { height, width, x, y } = props.style;
        setAnimationStyle({
          height: height.animation?.to,
          width: width.animation?.to,
          x: x.animation?.to,
          y: y.animation?.to,
        });
      }
    }, [props.style]);

    return (
      <g>
        {isLast && (
          <svg>
            <g>
              <text
                className={styles.customBar_textOutside}
                fill="#000"
                x={x.animation.to + width.animation.to + 50}
                y={y.animation.to + 10}
                dominantBaseline="hanging"
                textAnchor="middle"
                style={{ fontWeight: '500' }}>
                {releasesTitles[dataIndex]}
              </text>
            </g>
          </svg>
        )}

        <rect
          className={className}
          fill={color}
          height={animationStyle.height}
          width={animationStyle.width}
          x={animationStyle.x}
          y={animationStyle.y}
          clipPath={clipPath}
        />
        <text
          style={{ color: 'white' }}
          className={styles.customBar_textInside}
          x={x.animation.to + width.animation.to / 2}
          y={y.animation.to + height.animation.to / 2}
          dominantBaseline="middle"
          textAnchor="middle">
          {textContent}
        </text>
      </g>
    );
  });

  return (
    <div className={styles.gantt__chart__container}>
      <ResponsiveChartContainer
        margin={{ right: 100, left: 100 }}
        disableAxisListener
        height={300}
        series={
          releasesSeries.length > 0 && releasesSeries[0].data && releasesSeries[0].data.length > 0
            ? releasesSeries
            : [{ data: [0], label: 'a', stack: 'Release', type: 'bar', layout: 'horizontal' }]
        }
        yAxis={[{ scaleType: 'band', data: releasesDates }]}
        colors={statusColor}>
        <BarPlot
          slots={{
            bar: (props) => (
              <>
                <CustomBar {...props} />
              </>
            ),
          }}
        />
        <ChartsAxisHighlight />
        <ChartsAxis />
      </ResponsiveChartContainer>
    </div>
  );
};
