import type { KatexOptions } from 'katex';

export type PluginOptions = {
  runtime:
    | string
    | {
        script: string;
        style: string;
      };
  bundle: boolean;
  validate: boolean;
  classes: string;
  katexOptions: KatexOptions;
};

export type PluginRuntime = PluginOptions['runtime'];

export type TransformMeta = { script?: string[]; style?: string[] } | undefined;

/** @internal */
export function useLatexRuntime(meta: TransformMeta, runtime: PluginRuntime) {
  const [script, style] =
    typeof runtime === 'string' ? [runtime, runtime] : [runtime.script, runtime.style];

  if (meta?.script?.includes(script)) {
    import(/* webpackChunkName: "latex-runtime" */ '@diplodoc/latex-extension/runtime');
  }

  if (meta?.style?.includes(style)) {
    // @ts-expect-error // no types for styles
    import(/* webpackChunkName: "latex-styles" */ '@diplodoc/latex-extension/runtime/styles');
  }
}
