import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import React, { useEffect, useState } from 'react';
import styles from './BudgetAnalytics.module.scss';
import { Helper } from '@components/ui/Helper';
import { getBudgetAnalytics } from '@store/project/project.service';
import { BudgetAnalytics as BudgetAnalyticsModel } from '@store/project/contracts';

export const BudgetAnalytics: React.FC = () => {
  const [data, setData] = useState<BudgetAnalyticsModel[]>([]);

  useEffect(() => {
    getBudgetAnalytics().then((resp) => setData(resp));
  }, []);

  return (
    <div className={styles.budget__analytics}>
      <div className={styles.budget__analytics__title}>
        <h2>Аналитика по бюджету {<Helper />}</h2>
      </div>
      <AdaptiveTableMUI
        columns={{
          planBudget: {
            label: 'Плановый объем бюджета',
          },
          spentBudget: {
            label: 'Освоенный объем бюджета',
          },
          predictionBudget: {
            label: 'Прогнозный объем бюджета',
          },
          costVariance: {
            label: 'Отклонение по стоимости',
          },
          timeDeviation: {
            label: 'Отклонение по срокам',
          },
        }}
        rows={data}></AdaptiveTableMUI>
    </div>
  );
};
