import React from 'react';
import styles from '@pages/cloud/instances/components/ActionButtons/ActionButtons.module.scss';

export const ApproveIcon = () => {
  return (
    <svg
      className={styles.approveIcon}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <circle cx="12" cy="12" r="9.25" stroke="#199B3E" strokeWidth="1.5" />
      <path
        d="M9.75255 14.6743L6.82503 11.7943L5.82812 12.7681L9.75255 16.6289L18.1771 8.34101L17.1872 7.36719L9.75255 14.6743Z"
        fill="#199B3E"
      />
    </svg>
  );
};
