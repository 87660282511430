import styles from './Balance.module.scss';
import { NavLink } from 'react-router-dom';

export const BalanceNavigation = () => {
  const linksInfo = [
    {
      link: '/balance',
      pathD:
        'M20 1.5V14.5C20 14.9 19.85 15.25 19.55 15.55C19.25 15.85 18.9 16 18.5 16H1.5C1.1 16 0.75 15.85 0.45 15.55C0.15 15.25 0 14.9 0 14.5V1.5C0 1.1 0.15 0.75 0.45 0.45C0.75 0.15 1.1 0 1.5 0H18.5C18.9 0 19.25 0.15 19.55 0.45C19.85 0.75 20 1.1 20 1.5ZM1.5 4.225H18.5V1.5H1.5V4.225ZM1.5 7.45V14.5H18.5V7.45H1.5ZM1.5 14.5V1.5V14.5Z',
      title: 'Мои реквизиты',
    },
    {
      link: '/balance/profit',
      pathD:
        'M14 9C14.2833 9 14.5208 8.90417 14.7125 8.7125C14.9042 8.52083 15 8.28333 15 8C15 7.71667 14.9042 7.47917 14.7125 7.2875C14.5208 7.09583 14.2833 7 14 7C13.7167 7 13.4792 7.09583 13.2875 7.2875C13.0958 7.47917 13 7.71667 13 8C13 8.28333 13.0958 8.52083 13.2875 8.7125C13.4792 8.90417 13.7167 9 14 9ZM6 6.5H11V5H6V6.5ZM2.5 19C1.93333 17.1 1.375 15.2042 0.825 13.3125C0.275 11.4208 0 9.48333 0 7.5C0 5.96667 0.533333 4.66667 1.6 3.6C2.66667 2.53333 3.96667 2 5.5 2H10.5C10.9833 1.36667 11.5708 0.875 12.2625 0.525C12.9542 0.175 13.7 0 14.5 0C14.9167 0 15.2708 0.145833 15.5625 0.4375C15.8542 0.729167 16 1.08333 16 1.5C16 1.6 15.9875 1.7 15.9625 1.8C15.9375 1.9 15.9083 1.99167 15.875 2.075C15.8083 2.25833 15.7458 2.44583 15.6875 2.6375C15.6292 2.82917 15.5833 3.025 15.55 3.225L17.825 5.5H20V12.475L17.175 13.4L15.5 19H10V17H8V19H2.5ZM3.625 17.5H6.5V15.5H11.5V17.5H14.375L15.95 12.25L18.5 11.375V7H17.2L14 3.8C14.0167 3.38333 14.0708 2.97917 14.1625 2.5875C14.2542 2.19583 14.35 1.8 14.45 1.4C13.8167 1.56667 13.2167 1.8125 12.65 2.1375C12.0833 2.4625 11.65 2.91667 11.35 3.5H5.5C4.4 3.5 3.45833 3.89167 2.675 4.675C1.89167 5.45833 1.5 6.4 1.5 7.5C1.5 9.21667 1.74167 10.8958 2.225 12.5375C2.70833 14.1792 3.175 15.8333 3.625 17.5Z',
      title: 'Поступления',
    },
  ];

  return (
    <ul className={styles.ws_navigation}>
      {linksInfo.map((item, idx) => (
        <li className={styles.ws_navigation__item} key={idx}>
          <NavLink
            to={item.link}
            className={({ isActive }) =>
              isActive
                ? `${styles.ws_navigation__item_link} ${styles.active}`
                : `${styles.ws_navigation__item_link}`
            }
            end>
            <svg
              className="me-2"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d={item.pathD} fill="#78858B" />
            </svg>
            {item.title}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
