import React from 'react';

export const RequestListIcon = () => {
  return (
    <svg
      className="icon me-2"
      width="20"
      height="20"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.42502 15.05V13.55H18V15.05H6.42502ZM6.42502 8.75V7.25H18V8.75H6.42502ZM6.42502 2.425V0.925H18V2.425H6.42502ZM1.72502 15.975C1.25752 15.975 0.857316 15.8167 0.5244 15.5C0.191483 15.1833 0.0250244 14.7875 0.0250244 14.3125C0.0250244 13.8375 0.187524 13.4375 0.512524 13.1125C0.837524 12.7875 1.23752 12.625 1.71252 12.625C2.18752 12.625 2.58336 12.7915 2.90002 13.1244C3.21669 13.4573 3.37502 13.8575 3.37502 14.325C3.37502 14.7788 3.21347 15.1672 2.89035 15.4903C2.56722 15.8134 2.17877 15.975 1.72502 15.975ZM1.72502 9.675C1.25752 9.675 0.857316 9.51099 0.5244 9.18297C0.191483 8.85496 0.0250244 8.46063 0.0250244 8C0.0250244 7.53937 0.191483 7.14504 0.5244 6.81703C0.857316 6.48901 1.25752 6.325 1.72502 6.325C2.17877 6.325 2.56722 6.48901 2.89035 6.81703C3.21347 7.14504 3.37502 7.53937 3.37502 8C3.37502 8.46063 3.21347 8.85496 2.89035 9.18297C2.56722 9.51099 2.17877 9.675 1.72502 9.675ZM1.70002 3.35C1.23941 3.35 0.845083 3.18599 0.517049 2.85798C0.189033 2.52996 0.0250244 2.13563 0.0250244 1.675C0.0250244 1.21437 0.189033 0.820042 0.517049 0.492025C0.845083 0.164008 1.23941 0 1.70002 0C2.16064 0 2.55497 0.164008 2.883 0.492025C3.21102 0.820042 3.37502 1.21437 3.37502 1.675C3.37502 2.13563 3.21102 2.52996 2.883 2.85798C2.55497 3.18599 2.16064 3.35 1.70002 3.35Z"
        fill="currentColor"
      />
    </svg>
  );
};
