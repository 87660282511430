import React, { memo } from 'react';
import { TableCellOutput } from '@components/Table/TableCellOutput';
import { AdaptiveTableMUIProps } from '@components/Table/TableMUI';
import styles from './ItemsList.module.scss';

export const ItemsList: React.FC<AdaptiveTableMUIProps> = memo(({ columns, rows }) => {
  return (
    <ul>
      {rows.map((row, index) => (
        <li className={styles.item}>
          {Object.keys(columns).map((title) => {
            return (
              <div className={title !== 'actionsRaw' ? styles.section : ''}>
                <div className={styles.title}>{columns[title].label}</div>
                <TableCellOutput
                  titles={columns}
                  value={row[title]}
                  format={columns[title].format}
                  children={columns[title].children}
                  row={row}
                  title={title}
                />
              </div>
            );
          })}
          {index !== rows.length - 1 ? <hr /> : <></>}
        </li>
      ))}
    </ul>
  );
});
