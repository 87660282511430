import { Modal, ModalFooter, ModalProps } from '@components/MUI/Modal/Modal';
import { ReactNode, useCallback } from 'react';
import { FormWrapper } from '@components/Form/Form';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import { Button } from '@components/MUI/Button';

export type TransferToProjectData = {
  name: string;
  id: string;
};

interface TransferToProjectModalProps {
  modalProps?: ModalProps;
  title: string;
  children: ReactNode;
}

export const TransferToProjectModal = ({
  modalProps,
  title,
  children,
}: TransferToProjectModalProps) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <Modal {...modalProps}>
      <FormWrapper label={title} isThinTitle isSmallModal>
        <div>{children}</div>
        <ModalFooter>
          <Button className={`me-3 fw-bold`} onClick={handleCloseModal}>
            Отменить перевод в проект
          </Button>
        </ModalFooter>
      </FormWrapper>
    </Modal>
  );
};
