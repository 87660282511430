import styles from './ToolsPlatform.module.scss';
import yandexLogo from '@assets/img/yandexPublic/yandexCloudLogo.svg';
import yandex360Logo from '@assets/img/yandexPublic/yandex360Logo.svg';
import yandexTrackerLogo from '@assets/img/yandexPublic/TrackerLogo.svg';
import analiticsLogo from '@assets/img/yandexPublic/analitics.svg';
import telegramBot from '@assets/img/yandexPublic/telegramBotLogo.svg';

export function ToolsPlatform() {
  return (
    <>
      <div className={styles.main}>
        <div className={styles.container}>
          <h1 className={styles.titlePlatform}>Инструменты платформы</h1>
          <div className={styles.cardsLayout}>
            <div className={styles.cardPlatform}>
              <img className={styles.logo} src={yandexLogo} alt="Логотип яндекс облако" />
            </div>
            <div className={styles.cardPlatform}>
              <img className={styles.logo} src={yandex360Logo} alt="Логотип яндекс 360" />
            </div>
            <div className={styles.cardPlatform}>
              <img
                className={styles.logo}
                width={233}
                src={yandexTrackerLogo}
                alt="Логотип яндекс трекер"
              />
            </div>
            <div className={styles.cardPlatform}>
              <img className={styles.logoAnalitics} src={analiticsLogo} alt="Логотип аналитики" />
              <span className={styles.cardText}>
                Актуальная <br /> сводка аналитики
              </span>
            </div>
            <div className={styles.cardPlatform}>
              <img className={styles.logoBot} src={telegramBot} alt="Логотип телеграмм бота" />
              <span className={styles.cardText}>
                Телеграм-боты от Egile <br />с различным функционалом
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
