import { NotificationBox } from '@components/ui/NotificationBox/NotificationBox';
import { IconButton, Menu, MenuItem, Badge } from '@mui/material';
import styles from './NotificationsList.module.scss';
import React, { useState } from 'react';
import bell from '@assets/img/bell.svg';
import { Link } from 'react-router-dom';
import { useNotification } from '@hooks/useNotification';

export const NotificationsList: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const { notifications, newNotificationCount, resetCount } = useNotification({
    poolingInterval: 5000,
    maxSize: 5,
  });

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    resetCount();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={(event) => handleMenu(event)}>
        <Badge
          badgeContent={newNotificationCount}
          color="error"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}>
          <img src={bell} alt="Уведомления" />
        </Badge>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              minWidth: '432px',
              overflow: 'visible',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 16,
                width: 10,
                height: 10,
                backgroundColor: 'inherit',
                transform: 'translateY(-50%) rotate(45deg)',
                boxShadow: '-3px -3px 5px -2px rgba(0,0,0,0.1)',
              },
              '& .MuiList-root': {
                padding: '16px',
              },
            },
          },
        }}>
        {notifications.length === 0 ? (
          <MenuItem
            disableRipple
            sx={{
              padding: '16px 24px',
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: 'transparent',
                cursor: 'default',
              },
            }}
            className="d-flex flex-row justify-content-center">
            <p className={styles.noNotifications}>Нет уведомлений</p>
          </MenuItem>
        ) : (
          <div className={styles.notifications_container}>
            {notifications?.map((notification) => {
              return (
                <div className={styles.item} key={notification?.id}>
                  <NotificationBox notification={notification} />
                </div>
              );
            })}
            <div className="d-flex flex-row justify-content-center">
              <Link to="/notifications" onClick={handleClose} className={styles.link}>
                Показать все уведомления
              </Link>
            </div>
          </div>
        )}
      </Menu>
    </>
  );
};
