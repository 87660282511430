import { CommonAnalytics } from '@store/project/contracts';
import { TaskStatusType } from '@store/tasks/contracts';
import { useMemo } from 'react';

export const localisedStatusesMap = new Map([
  ['new', 'Начальный'],
  ['inProgress', 'В работе'],
  ['done', 'Завершен'],
  ['cancelled', 'Отменен'],
  ['paused', 'На паузе'],
]);

export function useProjectCommonAnalytics(commonAnalytics: CommonAnalytics | null) {
  const tasks = useMemo(() => {
    return commonAnalytics?.tasks;
  }, [commonAnalytics]);

  const tasksCount = useMemo(() => {
    const allTasks = Object.values(
      commonAnalytics === null || tasks === undefined ? {} : tasks,
    )?.reduce((sum, value) => sum + value, 0);

    return allTasks;
  }, [commonAnalytics]);

  const localisedStatuses = useMemo(() => {
    const localisedStatuses: Record<string, number> = {};

    for (let key in tasks) {
      const myKey = localisedStatusesMap.get(key);
      if (myKey && tasks[key as TaskStatusType]) {
        localisedStatuses[myKey] = tasks[key as TaskStatusType];
      }
    }

    return localisedStatuses;
  }, [commonAnalytics, tasks]);

  return { tasksCount, localisedStatuses };
}
