import React, { CSSProperties, useState } from 'react';
import { IconButton } from '@components/ui/Button/IconButton';
import { ApproveIcon } from '@components/ui/Icons/ApproveIcon';
import { CloseIcon } from '@components/ui/Icons/CloseIcon';
import styles from './ActionButtons.module.scss';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopIcon from '@mui/icons-material/Stop';
import { useAppDispatch } from '@hooks/index';
import { openModalError } from '@store/ui/ui.slice';
import { CircularProgress } from '@mui/material';

type InstanceButtonsProps = {
  handleBuild: (id: string) => void;
  handleDestroy: (id: string) => void;
  handleBuildAndStart: (id: string) => void;
  handleStart: (id: string) => void;
  handleStop: (id: string) => void;
  objectId: string;
  className?: string;
  style?: CSSProperties;
};

export const ActionButtons: React.FC<InstanceButtonsProps> = ({
  handleBuild,
  handleDestroy,
  handleBuildAndStart,
  handleStart,
  handleStop,
  objectId,
  className,
  style,
}) => {
  const [isBuildVisible, setIsBuildVisible] = useState<boolean>(true);
  const [isDestroyVisible, setIsDestroyVisible] = useState<boolean>(true);
  const [isStartAndBuildVisible, setIsStartAndBuildVisible] = useState<boolean>(true);
  const [isStart, setIsStart] = useState<boolean>(true);
  const [isStop, setIsStop] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  const handleBuildClick = async (objectId: string) => {
    try {
      setIsBuildVisible(false);
      await handleBuild(objectId);
    } catch (e: any) {
      dispatch(openModalError(e));
    } finally {
      setTimeout(() => {
        setIsBuildVisible(true);
      }, 600);
    }
  };
  const handleDestroyClick = async (objectId: string) => {
    try {
      setIsDestroyVisible(false);
      await handleDestroy(objectId);
    } catch (e: any) {
      dispatch(openModalError(e));
    } finally {
      setTimeout(() => {
        setIsDestroyVisible(true);
      }, 600);
    }
  };
  const handleStartAndBuildClick = async (objectId: string) => {
    try {
      setIsStartAndBuildVisible(false);
      await handleBuildAndStart(objectId);
    } catch (e: any) {
      dispatch(openModalError(e));
    } finally {
      setTimeout(() => {
        setIsStartAndBuildVisible(true);
      }, 600);
    }
  };
  const handleStartClick = async (objectId: string) => {
    try {
      setIsStart(false);
      await handleStart(objectId);
    } catch (e: any) {
      dispatch(openModalError(e));
    } finally {
      setTimeout(() => {
        setIsStart(true);
      }, 600);
    }
  };
  const handleStopClick = async (objectId: string) => {
    try {
      setIsStop(false);
      await handleStop(objectId);
    } catch (e: any) {
      dispatch(openModalError(e));
    } finally {
      setTimeout(() => {
        setIsStop(true);
      }, 600);
    }
  };

  return (
    <>
      <span className={className} style={style}>
        {isBuildVisible ? (
          <IconButton className={styles.icon__button} onClick={() => handleBuildClick(objectId)}>
            <ApproveIcon />
          </IconButton>
        ) : (
          <IconButton className={styles.icon__button}>
            <CircularProgress style={{ width: 20, height: 20, verticalAlign: 'middle' }} />
          </IconButton>
        )}
        {isDestroyVisible ? (
          <IconButton className={styles.icon__button} onClick={() => handleDestroyClick(objectId)}>
            <CloseIcon />
          </IconButton>
        ) : (
          <IconButton className={styles.icon__button}>
            <CircularProgress style={{ width: 20, height: 20, verticalAlign: 'middle' }} />
          </IconButton>
        )}
        {isStartAndBuildVisible ? (
          <IconButton
            className={styles.icon__button}
            onClick={() => handleStartAndBuildClick(objectId)}>
            <PlayCircleOutlineIcon className={styles.playCircleOutlineIcon} />
          </IconButton>
        ) : (
          <IconButton className={styles.icon__button}>
            <CircularProgress style={{ width: 20, height: 20, verticalAlign: 'middle' }} />
          </IconButton>
        )}
        {isStart ? (
          <IconButton className={styles.icon__button} onClick={() => handleStartClick(objectId)}>
            <PlayArrowIcon className={styles.playArrowIcon} />
          </IconButton>
        ) : (
          <IconButton className={styles.icon__button}>
            <CircularProgress style={{ width: 20, height: 20, verticalAlign: 'middle' }} />
          </IconButton>
        )}
        {isStop ? (
          <IconButton className={styles.icon__button} onClick={() => handleStopClick(objectId)}>
            <StopIcon className={styles.stopIcon} />
          </IconButton>
        ) : (
          <IconButton className={styles.icon__button}>
            <CircularProgress style={{ width: 20, height: 20, verticalAlign: 'middle' }} />
          </IconButton>
        )}
      </span>
    </>
  );
};
