import React from 'react';
import styles from './Chat.module.scss';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import avatar from '@assets/img/avatar.svg';
import messageLooked from '@assets/img/messages/messageLooked.svg';
import dayjs, { Dayjs } from 'dayjs';
import { Chat, Message } from '@store/messages/contracts';

interface ChatLinkProps {
  chatId: Chat['id'];
  lastMessage?: Message;
  unreadMessagesCount: number;
  chatName: string;
  author: string;
}

function formatLastMessageDate(date: Dayjs) {
  const now = dayjs().format('DD-MM-YYYY');
  if (now === date?.format('DD-MM-YYYY')) return date.format('HH:MM');
  else return date?.format('DD.MM.YYYY');
}

export const ChatLink: React.FC<ChatLinkProps> = ({
  lastMessage,
  unreadMessagesCount,
  chatId,
  chatName,
  author,
}) => {
  return (
    <NavLink
      to={`${chatId}`}
      className={({ isActive }) => (isActive ? styles.chatActive : styles.chatLink)}>
      <div className={classNames('d-flex align-items-center pt-3 pb-3', styles.chat)}>
        <div>
          <img src={avatar} alt="Отправитель" className={styles.avatar} />
        </div>
        {lastMessage !== undefined ? (
          <div className={styles.chatBody}>
            <div className={styles.chatHeader}>
              <span className="text-truncate fw-bold">{lastMessage.author.userName}</span>
              <div className={styles.messageOptions}>
                <img src={messageLooked} alt="сообщение просмотрено" />
                <span className="ms-1 color-gray">
                  {formatLastMessageDate(lastMessage.created)}
                </span>
              </div>
            </div>
            <div className={styles.messageBody}>
              <p className="m-0 color-gray text-truncate">{lastMessage.text}</p>
              {unreadMessagesCount ? (
                <span className={styles.unreadMessagesCount}>{unreadMessagesCount}</span>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.chatBody}>
            <div className={styles.chatHeader}>
              <span className="text-truncate fw-bold">
                {chatName ? chatName : author ? author : ''}
              </span>
            </div>
            <div className={styles.messageBody}>
              <p className="m-0 color-gray text-truncate">Начните чат с собеседником</p>
            </div>
          </div>
        )}
      </div>
    </NavLink>
  );
};
