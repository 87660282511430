import { TransferToProjectForm } from '@pages/requests/forms/TransferToProjectForm';
import { useAppDispatch } from '@hooks/index';
import { useHandleRequestToProject } from '@pages/requests/components/helpersHooksTransferRequestToProject/useHandleRequestToProject';
import { useCallback } from 'react';
import {
  TransferToProjectData,
  TransferToProjectModal,
} from '@pages/requests/components/TransferToProjectModal';
import { openModal } from '@store/ui/ui.slice';

export const useOpenTransferToProjectModal = () => {
  const dispatch = useAppDispatch();
  const handleRequestToProject = useHandleRequestToProject();

  const openTransferToProjectModal = useCallback(
    (data: TransferToProjectData) => {
      dispatch(
        openModal(
          <TransferToProjectModal title={`${data.name} перевести в проект`}>
            <div>
              <TransferToProjectForm
                value={{
                  projectPrefix: '',
                  projectName: data.name,
                  id: data.id || '',
                }}
                submit={handleRequestToProject}
              />
            </div>
          </TransferToProjectModal>,
        ),
      );
    },
    [dispatch, handleRequestToProject],
  );

  return openTransferToProjectModal;
};
