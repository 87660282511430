import React from 'react';
import { TasksAnalytics } from './TasksAnalytics/TasksAnalytics';
import { EmployessAnalytics } from './EmployeesAnalytics/EmployessAnalytics';
import { CommonAnalyticsBlock } from './CommonAnalyticsBlock/CommonAnalyticsBlock';

export const CommonAnalytics: React.FC = () => {
  return (
    <div>
      <CommonAnalyticsBlock />
      <TasksAnalytics />
      <EmployessAnalytics />
    </div>
  );
};
