import { getFile } from '@store/docs/docs.service';

export const downloadFile = async (fileName: string) => {
  try {
    const file = await getFile(fileName);

    const fileBlob = new Blob([file.data as BlobPart]);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(fileBlob);

    const fullExtension: string = file.headers['content-type'];
    const extension = `.${fullExtension.slice(fullExtension.indexOf('/') + 1)}`;
    link.download = fileName + extension;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (err) {
    throw new Error(`Ошибка при попытке загрузки файла: ${err}`);
  }
};

export const convertBitsToGigabytes = (bits: number): number => {
  return bits / 1024 ** 3;
};
