import { CSSProperties } from 'react';
import { IconButton } from '@components/ui/Button/IconButton';
import { ApproveIcon } from '@components/ui/Icons/ApproveIcon';
import { CloseIcon } from '@components/ui/Icons/CloseIcon';
import { SendMessageIcon } from '@components/ui/Icons/SendMessageIcon';
import styles from './ActionButtons.module.scss';

export type ActionButtonsProps = {
  handleApprove?: () => void;
  handleClose?: () => void;
  handleSendMessage?: () => void;
  isOnlySend?: boolean;
  withoutSend?: boolean;
  className?: string;
  style?: CSSProperties;
};

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  handleApprove,
  handleClose,
  handleSendMessage,
  isOnlySend,
  withoutSend,
  className,
  style,
}) => {
  return (
    <div className={styles.action__buttons}>
      {!isOnlySend ? (
        <>
          <span className={className} style={style}>
            <IconButton className={styles.icon__button} onClick={handleApprove} text={'Принять'}>
              <ApproveIcon />
            </IconButton>
          </span>
          <span className={className} style={style}>
            <IconButton className={styles.icon__button} onClick={handleClose} text={'Отклонить'}>
              <CloseIcon />
            </IconButton>
          </span>
        </>
      ) : (
        <></>
      )}
      {!withoutSend ? (
        isOnlySend ? (
          <span className={className} style={style}>
            <IconButton
              className={styles.icon__button}
              onClick={handleSendMessage}
              text={'Сообщение'}>
              <SendMessageIcon />
            </IconButton>
          </span>
        ) : (
          <IconButton
            className={styles.icon__button}
            onClick={handleSendMessage}
            text={'Сообщение'}>
            <SendMessageIcon />
          </IconButton>
        )
      ) : (
        <></>
      )}
    </div>
  );
};
