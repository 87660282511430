import React, { FC, MouseEventHandler } from 'react';

interface ICardButton {
  title: string;
  icon?: React.ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const CardButton: FC<ICardButton> = ({ title, icon, onClick }) => {
  return (
    <button className="btn btn-transparent d-flex" onClick={onClick}>
      <div className="me-2">{icon}</div>
      {title}
    </button>
  );
};

export { CardButton };
