import React from 'react';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';
import * as Yup from 'yup';
import { Paper } from '@mui/material';
import styles from './ProfileContactForm.module.scss';
import { InputField } from '@components/MUI/InputField/InputField';
import { Button } from '@components/MUI/Button';
import { Checkbox } from '@components/ui/Checkbox/Checkbox';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { PhoneInputField } from '@components/MUI/PhoneInputField/PhoneInputField';

export type ProfileContactValue = {
  telegram?: string;
  phones?: string;
  urls?: string;
  email?: string;
  contract?: boolean;
};

export type ProfileFormContactProps = {
  value: ProfileContactValue;
  submit: (
    value: ProfileContactValue,
    form: FormikBag<ProfileFormContactProps, ProfileContactValue>,
  ) => void;
};

const ProfileFormContactSchema = Yup.object({
  email: Yup.string().matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, 'Введите корректную почту'),
  telegram: Yup.string().matches(/^@.*$/, 'Ваш телеграм должен начинаться с @'),
  phones: Yup.string()
    .test('is-valid-phone', 'Введите корректный номер телефона', (value) => {
      if (!value) return false;
      const phoneNumber = parsePhoneNumberFromString(value, 'RU');
      return phoneNumber ? phoneNumber.isValid() : false;
    })
    .nullable()
    .required('Обязательное поле'),
  contract: Yup.boolean()
    .oneOf([true], 'Требуется договор оферты с платформой')
    .required('Требуется договор оферты с платформой'),
});

const FormComponentProfileContact = () => {
  const profileContactForm = useFormikContext<ProfileContactValue>();
  profileContactForm.validationSchema = ProfileFormContactSchema;

  return (
    <Paper elevation={0} className={styles.profile__contact__wrap} variant="outlined">
      <div className={styles.profile__contact__form}>
        <h3>Связь со мной</h3>
      </div>
      <Form onSubmit={profileContactForm.handleSubmit} style={{ marginTop: '2.5rem' }}>
        <div className="d-flex flex-column">
          <InputField name="email" type="text" placeholder="Почта" className={styles.form_field} />

          <InputField
            name="telegram"
            type="text"
            placeholder="Telegram"
            className={styles.form_field}
          />

          <PhoneInputField name="phones" placeholder="Телефон" className={styles.form_field} />
          <Checkbox
            name="contract"
            label="Договор оферты с платформой*"
            fieldClassName={styles.checkbox}
          />
        </div>
        <Button
          type="submit"
          disabled={!profileContactForm.isValid}
          variant="contained"
          size="large">
          Сохранить
        </Button>
        <RequiredFieldsIndicator className={styles.footer} />
      </Form>
    </Paper>
  );
};

export const ProfileContactForm = withFormik<ProfileFormContactProps, ProfileContactValue>({
  displayName: 'ProfileContactForm',
  mapPropsToValues: (props) => {
    return {
      telegram: props.value.telegram,
      phones: props.value.phones,
      urls: props.value.urls,
      contract: props.value.contract,
      email: props.value.email,
    };
  },
  enableReinitialize: true,
  validationSchema: ProfileFormContactSchema,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponentProfileContact);
