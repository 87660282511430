import React from 'react';
import styles from './ProjectTasks.module.scss';
import { Button } from '@components/MUI/Button';
import { Can } from '@access-control';
import { CreateRequestIcon } from '@layouts/requests/Icons/CreateRequestIcon';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as StatusIcon } from '@assets/img/status.svg';

type ProjectTasksHeaderProps = {
  projectName: string;
};

export const ProjectTasksHeader: React.FC<ProjectTasksHeaderProps> = ({ projectName }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.project__tasks__header}>
      <div className={styles.project__tasks__header__title}>
        <h2>{projectName}</h2>
      </div>
      <div className={styles.project__tasks__header__buttons}>
        <Button startIcon={<StatusIcon />} variant="text" onClick={() => {}}>
          Статусы задач
        </Button>
        <Can I="create" a="Task">
          <Button
            startIcon={<CreateRequestIcon />}
            variant="contained"
            onClick={() => navigate('/tasks/new')}>
            Создать задачу
          </Button>
        </Can>
      </div>
    </div>
  );
};
