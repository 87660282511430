import React, { useState } from 'react';
import { Button } from '@components/MUI/Button';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { StatusLabel, statusColors } from '@components/ui/StatusLabel/StatusLabel';
import { useRequestBody } from '@hooks/useRequestBody';
import { RequestItem } from '@store/requests/contracts';
import { Dayjs } from 'dayjs';
import { Link } from 'react-router-dom';
import { CreateRequestButton } from './components/CreateRequestButton';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { useOpenTransferToProjectModal } from '@pages/requests/components/helpersHooksTransferRequestToProject/useOpenTransferToProjectModal';
import { useGetApprovedRequestsQuery } from '@store/requests/requests.slice';

export const ApprovedRequests: React.FC = () => {
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();

  const { data: requests, isLoading } = useGetApprovedRequestsQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  const openTransferToProjectModal = useOpenTransferToProjectModal();

  return (
    <AdaptiveTableMUI
      setRequestBody={setRequestBody}
      setSortColumn={setSortColumn}
      setSortOrder={setSortOrder}
      sortOrder={sortOrder}
      sortColumn={sortColumn}
      isLoading={isLoading}
      columns={{
        name: {
          label: 'Заявка',
          children: (value, row: RequestItem) => <Link to={`/requests/${row.id}`}>{value}</Link>,
          filterInput: true,
          isSortColumn: true,
        },
        customerName: {
          label: 'Заказчик',
          filterInput: true,
          isSortColumn: true,
        },
        created: {
          label: 'Дата отклика',
          filterInput: true,
          isSortColumn: true,
          format: (value: Dayjs) => value.format('DD.MM.YYYY'),
        },
        deadLine: {
          label: 'Дедлайн',
          filterInput: true,
          isSortColumn: true,
          format: (value: Dayjs) => value.format('DD.MM.YYYY'),
        },
        budget: {
          label: 'Бюджет',
          filterInput: true,
          isSortColumn: true,
          format: (value) => `${value}`,
        },
        currencyName: {
          label: 'Валюта',
          filterInput: true,
          isSortColumn: false,
          format: (value) => `${value}`,
        },
        statusName: {
          label: 'Статус',
          children: (value) => (
            <StatusLabel text={value || 'Статус не укзаан'} color={statusColors.get(value)} />
          ),
        },
        inProjectButton: {
          children: (_, row: RequestItem) => (
            <Button
              variant="contained"
              style={{ marginRight: '1rem' }}
              size="small"
              onClick={() => openTransferToProjectModal(row)}>
              В проект
            </Button>
          ),
        },
      }}
      rows={requests?.data || []}
      emptyLabel={'У вас пока нет одобренных заявок'}
      additionalElement={<CreateRequestButton />}>
      <TablePaginationMUI
        limit={limit}
        count={requests?.totalCount || 0}
        entityLabel="заявок"
        page={page}
        handleChangePage={(_, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </AdaptiveTableMUI>
  );
};
