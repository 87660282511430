import { NavLink } from 'react-router-dom';
import { ListItem, ListItemButton } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { ReactNode } from 'react';
import styles from '../teams/SidebarButton/SidebarButton.module.scss';
import classNames from 'classnames';

interface Props {
  href: string;
  text: string;
  open: boolean;
  children: ReactNode;
  style?: React.CSSProperties;
}

export const ButtonSidebar = ({ href, text, open, children, style }: Props) => {
  const sidebarItemHoverClass = classNames(styles.itemSidebar);
  const activeSidebarItemClass = classNames(styles.activeBtn);
  return (
    <NavLink
      to={href}
      className={({ isActive }) =>
        isActive ? `${sidebarItemHoverClass} ${activeSidebarItemClass}` : `${sidebarItemHoverClass}`
      }
      style={style}>
      <ListItem disablePadding>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            paddingLeft: open ? '0' : '18px',
          }}>
          <ListItemIcon sx={{ justifyContent: 'center', minWidth: '42px' }}>
            {children}
          </ListItemIcon>
          <ListItemText sx={{ opacity: open ? 1 : 0 }}>{text}</ListItemText>
        </ListItemButton>
      </ListItem>
    </NavLink>
  );
};
