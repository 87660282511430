import React from 'react';
import { MainContentLayout } from '@layouts/MainContentLayout';
import { Outlet } from 'react-router-dom';
import { ProjectsHeader } from './ProjectsHeader';
import { Can } from '@access-control';
import { EmptyProjects } from '@pages/projects/EmptyProjects';

export const ProjectsLayout = () => {
  return (
    <MainContentLayout>
      <Can I="read" a="Projects">
        <ProjectsHeader />
        <Outlet />
      </Can>
      <Can not I="read" a="Projects">
        <EmptyProjects />
      </Can>
    </MainContentLayout>
  );
};
