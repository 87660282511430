import React, { useCallback } from 'react';
import { Modal } from '@components/MUI/Modal/Modal';
import { FormWrapper } from '@components/Form/Form';
import { AddTrackerForm, AddTrackerValue } from './AddTrackerForm';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import { useConnectYandexTrackerToProjectMutation } from '@store/project/project.slice';

type TrackerModalProps = {
  projectId: string;
};

export const TrackerModal: React.FC<TrackerModalProps> = ({ projectId }) => {
  const dispatch = useAppDispatch();
  const [connectYandexTrackerToProject] = useConnectYandexTrackerToProjectMutation();

  const connectToTracker = useCallback(
    async (value: AddTrackerValue) => {
      connectYandexTrackerToProject({
        projectId,
        payload: {
          organizationId: value.organizationId,
          externalProjectId: value.projectId,
        },
      });

      dispatch(closeModal());
    },
    [dispatch],
  );

  return (
    <Modal>
      <FormWrapper label="Подключение трекера">
        <AddTrackerForm submit={connectToTracker} />
      </FormWrapper>
    </Modal>
  );
};
