import React, { forwardRef } from 'react';

import { classnames } from '@bem-react/classnames';
import { DynamicHtmlContainer } from '@components/DynamicHtmlContainer';

export interface ClassNameProps {
  className?: string;
}

export type YfmHtmlProps = ClassNameProps & {
  html: string;
  linksVisited?: boolean;
  noListReset?: boolean;
  qa?: string;
};

export const YfmHtml = forwardRef<HTMLDivElement, YfmHtmlProps>(function YfmHtml(props, ref) {
  const { html, linksVisited, noListReset, className, qa } = props;

  return (
    <DynamicHtmlContainer
      input={html}
      className={classnames(
        'yfm',
        linksVisited ? 'yfm_links-visited' : undefined,
        noListReset ? 'yfm_no-list-reset' : undefined,
        className,
      )}
      data-qa={qa}
    />
  );
});
