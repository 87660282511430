import { Button as MButton, styled } from '@mui/material';

export const ButtonMUI = styled(MButton)(({ theme, variant }) => ({
  textTransform: 'none',
  whiteSpace: 'nowrap',
  boxShadow: 'none',
  '&.Mui-disabled': {
    color: '#FFFFFF',
  },
  '&:hover': {
    boxShadow: 'none',
    backgroundColor:
      variant === 'contained' ? theme.palette.background.paper : theme.palette.secondary.main,
    color: theme.palette.primary.main,
    transition: 'background-color 0.7s, color 0.7s',
  },
}));
