import { useState, useEffect } from 'react';

export const useContrastingColors = (numColors: number): string[] => {
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    const generateColors = (): string[] => {
      const newColors: string[] = [];
      const hueStep = 360 / numColors;
      //TODO: Надо поднастроить параметры saturation и lightness, чтобы получать приятные цвета
      const saturation = 75;
      const lightness = 62;

      for (let i = 0; i < numColors; i++) {
        const hue = i * hueStep;
        const color = `hsl(${Math.round(hue)}, ${saturation}%, ${lightness}%)`;
        newColors.push(color);
      }

      return newColors;
    };

    setColors(generateColors());
  }, [numColors]);

  return colors;
};
