import React, { useMemo } from 'react';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';
import * as Yup from 'yup';
import { InputField } from '@components/MUI/InputField/InputField';
import { Paper, SelectChangeEvent } from '@mui/material';
import { Select } from '@components/MUI/Select';
import styles from './ProfileAboutMeForm.module.scss';
import { Button } from '@components/MUI/Button';
import { Avatar } from '@components/Avatar/Avatar';
import { useGetFile } from '@hooks/useGetFile';

export type ProfileAboutMeValue = {
  firstName?: string;
  lastName?: string;
  patronymic?: string;
  userName?: string;
  gender?: 'MALE' | 'FEMALE';
  birthDate?: string;
  location?: string;
  icon?: string;
  about?: string;
  roles: string[];
};

export type ProfileFormAboutMeProps = {
  value: ProfileAboutMeValue;
  submit: (
    value: ProfileAboutMeValue,
    form: FormikBag<ProfileFormAboutMeProps, ProfileAboutMeValue>,
  ) => void;
};

const ProfileFormAboutMeSchema = Yup.object({
  firstName: Yup.string()
    .matches(
      /^[A-ZА-ЯЁ][a-zа-яё]+$/,
      'Поле должно содержать только буквы и начинаться с заглавной буквы',
    )
    .required('Поле обязательно для заполнения'),
  lastName: Yup.string()
    .matches(
      /^[A-ZА-ЯЁ][a-zа-яё]+$/,
      'Поле должно содержать только буквы и начинаться с заглавной буквы',
    )
    .required('Поле обязательно для заполнения'),
  patronymic: Yup.string().matches(
    /^[A-ZА-ЯЁ][a-zа-яё]+$/,
    'Поле должно содержать только буквы и начинаться с заглавной буквы',
  ),
  userName: Yup.string().required('Поле обязательно для заполнения'),
  gender: Yup.string().required('Поле обязательно для заполнения'),
  birthDate: Yup.string().required('Поле обязательно для заполнения'),
  location: Yup.string().required('Поле обязательно для заполнения'),
  experience: Yup.number().min(0, 'Введите число больше или равное 0'),
  about: Yup.string(),
});

const FormComponentProfileAboutMe = () => {
  const profileAboutMeForm = useFormikContext<ProfileAboutMeValue>();
  profileAboutMeForm.validationSchema = ProfileFormAboutMeSchema;

  const iconUrl = useMemo(() => {
    return profileAboutMeForm.values.icon;
  }, [profileAboutMeForm.values.icon]);

  const avatarFile = useGetFile(iconUrl);

  return (
    <Paper className={styles.profile__about__me__wrap} variant="outlined">
      <div className={styles.profile__about__me__title}>
        <h3>Фото профиля</h3>
      </div>

      <div className={styles.profile__about__me__avatar}>
        <Avatar iconUrl={iconUrl} iconFile={avatarFile || new File([], '')} />
      </div>

      <div></div>

      <div className={styles.profile__about__me__form}>
        <h3>Обо мне</h3>
      </div>

      <Form onSubmit={profileAboutMeForm.handleSubmit} style={{ marginTop: '2.125rem' }}>
        <div className="d-flex flex-column">
          <InputField
            name="firstName"
            type="text"
            placeholder="Имя"
            className={styles.form_field}
          />
          <InputField
            name="lastName"
            type="text"
            placeholder="Фамилия"
            className={styles.form_field}
          />
          <InputField
            name="patronymic"
            type="text"
            placeholder="Отчество"
            className={styles.form_field}
          />
          <InputField
            name="userName"
            type="text"
            placeholder="Username"
            className={styles.form_field}
          />
          <Select
            name="gender"
            values={[
              { value: 'MALE', label: 'Мужской' },
              { value: 'FEMALE', label: 'Женский' },
            ]}
            onChange={(event: SelectChangeEvent) => {
              profileAboutMeForm.setFieldValue('gender', event.target.value);
            }}
            isOutlined={true}
            label={'Пол'}
            className={styles.form_field}
            value={profileAboutMeForm.values.gender ? profileAboutMeForm.values.gender : undefined}
          />
          <InputField
            name="birthDate"
            type="date"
            isLabel={false}
            className={styles.form_field}
            value={profileAboutMeForm.values.birthDate}
            label="Дата рождения*"
          />
          <InputField
            name="location"
            type="text"
            placeholder="Местоположение"
            className={styles.form_field}
          />
          <InputField
            name="about"
            type="text"
            placeholder="О себе"
            style={{ marginBottom: '3rem' }}
            multiline
            rows={4}
          />
        </div>
        <Button
          type="submit"
          variant="contained"
          disabled={!profileAboutMeForm.isValid}
          size="large"
          style={{ marginBottom: '0.75rem' }}>
          Сохранить
        </Button>
        <RequiredFieldsIndicator className={styles.footer} />
      </Form>
      <div></div>
    </Paper>
  );
};

export const ProfileAboutMeForm = withFormik<ProfileFormAboutMeProps, ProfileAboutMeValue>({
  displayName: 'ProfileAboutMeForm',
  mapPropsToValues: (props) => {
    return {
      firstName: props.value.firstName,
      lastName: props.value.lastName,
      patronymic: props.value.patronymic,
      userName: props.value.userName,
      gender: props.value.gender,
      birthDate: props.value.birthDate,
      location: props.value.location,
      icon: props.value.icon,
      about: props.value.about,
      roles: props.value.roles,
    };
  },
  enableReinitialize: true,
  validationSchema: ProfileFormAboutMeSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponentProfileAboutMe);
