import {
  ChangePasswordRequest,
  DeleteAccountRequest,
  FavoriteUsersResponse,
  IdentifierType,
  ProfileAboutCompanyRequest,
  ProfileAboutCompanyResponse,
  ProfileMeResponse,
  ResetPasswordRequest,
  ResetPasswordUpdateRequest,
  SignInRequest,
  SignInResponse,
  SignOutRequest,
  SignOutResponse,
  SignUpRequest,
  SignUpResponse,
  User,
  UserQueryResponse,
  UserResponse,
} from '@store/user/contracts';
import { ApiClient } from '@store/api-client';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';
import { enqueueSnackbar } from 'notistack';

export const registration = async (request: SignUpRequest) => {
  const { data } = await ApiClient.post<SignUpResponse>(`api/user/registration`, request);
  return data;
};

export const confirmRegistration = async (
  request: Api.operations['registration_1']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['registration_1']['responses']['200']['content']['application/json']
  >(`/api/user/registration/verify`, request);
  return data;
};

export const login = async (request: SignInRequest) => {
  const { data } = await ApiClient.post<SignInResponse>(`/api/auth/token/sign-in`, request);
  return data;
};

export const refreshToken = async (refreshToken: String) => {
  const { data } = await ApiClient.post<SignInResponse>(`/api/auth/token/refreshToken`, {
    refreshToken,
  });
  return data;
};

export const setFavoriteProfile = async (
  isFavorite: Api.operations['changeFavoriteStatus_4']['requestBody']['content']['application/json'],
  profileId: Api.operations['changeFavoriteStatus_4']['parameters']['path']['id'],
) => {
  const { data } = await ApiClient.put<
    Api.operations['changeFavoriteStatus_4']['responses']['200']['content']['application/json']
  >(`/api/profile/${profileId}/favorite`, isFavorite);
  enqueueSnackbar(`Избранное профиля успешно обновлено`, {
    variant: 'success',
  });
  return data;
};

export const getFavoriteProfiles = async (
  request: Api.operations['getFavoriteProfiles']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getFavoriteProfiles']['responses']['200']['content']['application/json']
  >(`/api/profile/favorite`, request);
  return plainToInstance(FavoriteUsersResponse, data);
};

export const setProfile = async (
  request: Api.operations['updateProfile']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.patch<
    Api.operations['updateProfile']['responses']['200']['content']['application/json']
  >(`api/profile/`, request);
  return data;
};

export const setInfoAboutWork = async (
  requestBody: Api.operations['saveProfileWork']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['saveProfileWork']['responses']['200']['content']['application/json']
  >('/api/profile/work', requestBody);
  return data;
};

export const setInfoAboutCompany = async (request: ProfileAboutCompanyRequest) => {
  const { data } = await ApiClient.post<ProfileAboutCompanyResponse>(
    `api/profile/organization`,
    request,
  );
  return data;
};

export const getProfiles = async (
  requestBody?: Api.operations['getProfiles']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getProfiles']['responses']['200']['content']['application/json']
  >('api/profile/list', requestBody);
  return plainToInstance(UserResponse, data);
};

export const setInfoAboutLink = async (
  requestBody?: Api.operations['saveProfileLink']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['saveProfileLink']['responses']['200']['content']['application/json']
  >('api/profile/link', requestBody);
  return data;
};

export const logout = async (request: SignOutRequest) => {
  await ApiClient.post<SignOutResponse>(`api/auth/token/sign-out`, request);
};

export const getProfile = async (id: Api.operations['getProfile']['parameters']['path']['id']) => {
  const { data } = await ApiClient.get<
    Api.operations['getProfile']['responses']['200']['content']['application/json']
  >(`/api/profile/${id}`);
  return plainToInstance(User, data);
};

export const me = async () => {
  const { data } = await ApiClient.get<
    Api.operations['getProfileMe']['responses']['200']['content']['application/json']
  >('api/profile/me');
  return plainToInstance(ProfileMeResponse, data);
};

export const searchProfiles = async (
  queryParams: Api.operations['searchUsers']['parameters']['query'],
) => {
  const { data } = await ApiClient.get<
    Api.operations['searchUsers']['responses']['200']['content']['application/json']
  >('api/profile/search', {
    params: queryParams,
  });
  return plainToInstance(UserQueryResponse, data);
};

export const uploadProfilePhoto = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await ApiClient.post(`/api/file/PROFILE/upload`, formData);
  return data;
};

export const uploadProfileIcon = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await ApiClient.post(`/api/file/ICON/upload`, formData);
  return data;
};

export const resetPasswordRequest = async (request: ResetPasswordRequest) => {
  await ApiClient.post(`/api/user/reset-password`, {
    identifierTypeId: IdentifierType.Email,
    identifierValue: request.email,
  });
};

export const resetPasswordUpdate = async (request: ResetPasswordUpdateRequest) => {
  await ApiClient.put(`/api/user/reset-password`, {
    identifierTypeId: IdentifierType.Email,
    identifierValue: request.email,
    verificationCode: request.code,
    newPassword: request.newPassword,
  });
};

export const changePassword = async (request: ChangePasswordRequest) => {
  await ApiClient.post(`/api/user/change-password`, request);
};

//TODO: убрать интерфейс, использовать api-schema.d.ts, после того, как удаление аватара там появится
export interface RemoveProfileIcon {
  success: boolean;
}

export const removeProfileIcon = async () => {
  return await ApiClient.delete<RemoveProfileIcon>(`/api/profile/icon`);
};

export const getOauthProviders = async (
  redirect: Api.operations['providers']['parameters']['query']['redirect'],
) => {
  const { data } = await ApiClient.get<
    Api.operations['providers']['responses']['200']['content']['application/json']
  >(`/platform/oauth/providers`, {
    params: {
      redirect: redirect,
    },
  });
  return data;
};

export const getOauthToken = async (params: Api.operations['getTokenWithOauth']['parameters']) => {
  const { data } = await ApiClient.get<
    Api.operations['getTokenWithOauth']['responses']['200']['content']['application/json']
  >(`/platform/oauth/${params.path.authProvider}`, {
    params: params.query,
  });
  return data;
};

export const deleteAccount = async (requestBody: DeleteAccountRequest) => {
  const { data } = await ApiClient.post('/api/support/send', requestBody);
  return data;
};
