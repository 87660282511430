import styles from './CardWithTabsAdaptive.module.scss';

import React from 'react';

export interface CardWithTabsAdaptiveProps {
  cardNumber: number | string;
  cardTitle: string;
  cardDescription: string;
  img: string;
}

export const CardWithTabsAdaptive: React.FC<CardWithTabsAdaptiveProps> = (
  CardWithTabsAdaptiveProps,
) => {
  return (
    <>
      <div className={styles.card}>
        <div className={styles.card_number_container}>
          <span className={styles.card_number}>{CardWithTabsAdaptiveProps.cardNumber}</span>
        </div>
        <div className={styles.card_title_container}>
          <h1 className={styles.card_title}>{CardWithTabsAdaptiveProps.cardTitle}</h1>
        </div>
        <div className={styles.card_description_container}>
          <span className={styles.card_description}>
            {CardWithTabsAdaptiveProps.cardDescription}
          </span>
        </div>
        <div className={styles.card_img_container}>
          <img className={styles.card_img} src={CardWithTabsAdaptiveProps.img} alt="" />
        </div>
      </div>
    </>
  );
};
