import React from 'react';
import { StatusTypesList } from './StatusTypesList/StatusTypesList';
import { TasksPie } from './TasksPie/TasksPie';
import { CommonAnalyticsBlockProps } from '../CommonAnalyticsBlock';
import styles from './PieBlock.module.scss';

export const PieBlock: React.FC<CommonAnalyticsBlockProps> = ({ commonAnalytics }) => {
  return (
    <div className={styles.pie__block}>
      <h2 className={styles.pie__block__title}>Количество задач</h2>
      <div className={styles.pie__block__content}>
        <StatusTypesList commonAnalytics={commonAnalytics} />
        <TasksPie commonAnalytics={commonAnalytics} />
      </div>
    </div>
  );
};
