import React, { useState } from 'react';
import { WorkInProgress } from '@pages/project/ProjectAnalytics/AdvancedAnalytics/WIP/WorkInProgress';
import { LeadTime } from './LeadTime/LeadTime';
import { CycleTime } from './CycleTime/CycleTime';
import { WastedTime } from '@pages/project/ProjectAnalytics/AdvancedAnalytics/WastedTime/WastedTime';
import styles from './AdvancedAnalytics.module.scss';
import { RangeDate, RangePicker } from '@components/MUI/RangePicker/RangePicker';
import { Formik, Form } from 'formik';
import dayjs from 'dayjs';
import { TaskBurndown } from './TaskBurndownChart/TaskBurndown';
import { Effectiveness } from './Effectiveness/Effectiveness';
import { Throughput } from './Throughput/Throughput';
import { ComponentSelect } from './components/ComponentSelect/ComponentSelect';
import { SelectChangeEvent } from '@mui/material';
import { BudgetAnalytics } from './BudgetAnalytics/BudgetAnalytics';

export type FormValues = {
  period: RangeDate;
};

enum ChartType {
  TaskBurndown = 'Диаграмма сгорания задач',
  LeadTime = 'Lead Time',
  CycleTime = 'Cycle Time',
  WorkInProgress = 'Work in progress',
  WastedTime = 'Wasted Time',
  Effectiveness = 'Effectiveness',
  Throughput = 'Throughput',
}

const componentsOptions: Record<ChartType, () => JSX.Element> = {
  [ChartType.TaskBurndown]: () => <TaskBurndown />,
  [ChartType.LeadTime]: () => <LeadTime />,
  [ChartType.CycleTime]: () => <CycleTime />,
  [ChartType.WorkInProgress]: () => <WorkInProgress />,
  [ChartType.WastedTime]: () => <WastedTime />,
  [ChartType.Effectiveness]: () => <Effectiveness />,
  [ChartType.Throughput]: () => <Throughput />,
};

export type ComponentOptions = keyof typeof componentsOptions;

export const AdvancedAnalytics: React.FC = () => {
  const [selectedComponents, setSelectedComponents] = useState<ChartType[]>([
    ChartType.TaskBurndown,
    ChartType.LeadTime,
    ChartType.CycleTime,
    ChartType.WorkInProgress,
    ChartType.WastedTime,
    ChartType.Effectiveness,
    ChartType.Throughput,
  ]);
  const handleChange = (e: SelectChangeEvent<ChartType[]>) => {
    const { value } = e.target;
    setSelectedComponents(typeof value === 'string' ? (value.split(',') as ChartType[]) : value);
  };
  return (
    <div className={styles.container}>
      <Formik
        initialValues={{
          period: [dayjs().subtract(12, 'day'), dayjs()],
          amount: 1,
          unit: 'week',
        }}
        onSubmit={() => {}}>
        {() => (
          <Form>
            <div className={styles.controls}>
              <div className={styles.controls_item}>
                <p>Данные</p>
                <ComponentSelect
                  value={selectedComponents}
                  handleChange={handleChange}
                  components={componentsOptions}
                  selectedComponents={selectedComponents}
                />
              </div>
              <div className={styles.controls_item}>
                <p>Период</p>
                <RangePicker name="period" />
              </div>
            </div>
            <div className={styles.charts_container}>
              {selectedComponents.map((selected: ComponentOptions, index) => {
                const ComponentToRender = componentsOptions[selected];
                return <ComponentToRender key={index} />;
              })}
            </div>
          </Form>
        )}
      </Formik>
      <BudgetAnalytics />
    </div>
  );
};
