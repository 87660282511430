import { Button } from '@components/MUI/Button';
import React from 'react';
import { ReactComponent as AddGreen } from '@assets/img/addGreen.svg';
import { useAppDispatch } from '@hooks/index';
import { openModal } from '@store/ui/ui.slice';
import { CreateVacancy } from '@pages/vacancy/CreateVacancy';

export const AddVacancyButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const handleOpenModal = () => {
    dispatch(openModal(<CreateVacancy />));
  };

  return (
    <Button
      startIcon={<AddGreen />}
      onClick={handleOpenModal}
      style={{ display: 'flex', justifyContent: 'start', maxWidth: 175 }}>
      Создать вакансию
    </Button>
  );
};
