import { InputField } from '@components/MUI/InputField/InputField';
import styles from './PeriodPickerForm.module.scss';
import { Select } from '@components/MUI/Select';
import { useField } from 'formik';

interface IUnit {
  value: string;
  label: string;
}

interface IPeriodPicker {
  units: IUnit[];
}

export const PeriodPicker = ({ units }: IPeriodPicker) => {
  const [field] = useField('unit');
  return (
    <>
      <div className={styles.intervalSelect_container}>
        <InputField
          type="number"
          name="amount"
          placeholder=""
          className={styles.intervalSelect_input}
        />
        <Select
          values={units}
          name="unit"
          value={field.value}
          label=""
          onChange={field.onChange}
          isOutlined
          className={styles.intervalSelect_select}
        />
      </div>
    </>
  );
};
