import { AboutCard } from '@components/AboutBenefits/AboutCard/AboutCard';
import { styled, Typography } from '@mui/material';
import styles from './AboutBenefits.module.scss';
import iconLaptop from '@assets/img/aboutInfo/macbookPro.png';

export const Title = styled(Typography)`
  color: #39393a;
  font-size: 38px;
  font-weight: 500;
  line-height: 45px;
  font-family: Roboto, sans-serif;
  span {
    color: #199b3e;
  }
  @media (max-width: 768px) {
    font-size: 26px;
  }
`;

const Description = styled(Typography)`
  color: #39393a;
  font-size: 24px;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export function AboutBenefits() {
  return (
    <>
      <div className={styles.aboutBenefitsBlock}>
        <Title>
          <span>Преимущества </span>работы
        </Title>
        <Description>
          Работа с нашей платформой открывает широкий спектр возможностей и преимуществ,
          профессиональное развитие в командах, а также возможность внести значимый вклад в развитие
          проектов
        </Description>
        <div className={styles.gridLayout}>
          <AboutCard countCard="01" description="Наличие базы специалистов под любой запрос" />
          <AboutCard
            countCard="02"
            description="Аналитика для отслеживания актуального статуса работы удаленной команды"
          />
          <AboutCard countCard="03" description="Уменьшение затрат на содержание штатных команд" />
          <AboutCard
            countCard="04"
            description="Быстрая и гибкая масштабируемость бизнеса, благодаря исполнению проектов на аутсорсе"
          />
          <div className={styles.cardWithImage}>
            <AboutCard
              countCard="05"
              image={iconLaptop}
              description="Прозрачность и безопасность финансовых отношений благодаря сервису «Безопасная сделка»"
            />
          </div>
        </div>
      </div>
    </>
  );
}
