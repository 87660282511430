import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MSelect,
  styled,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AnySchema, reach } from 'yup';

export type SelectOption = { value: any; label: string };

type SelectProps = {
  values: SelectOption[];
  onChange?: (event: any, child: React.ReactNode) => void;
  value?: any;
  name: string;
  className?: string;
  isOutlined?: boolean;
  label?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  multiple?: boolean;
};

const StyledSelect = styled(MSelect)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  minWidth: '214px',
  '&.Mui-disabled': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#A7A7A7',
    },
    opacity: 0.6,
    pointerEvents: 'none',
    background: '#A7A7A7',
  },
});

const StyledOutlinedSelect = styled(MSelect)({
  '&:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#21B84B',
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #21B84B',
    boxShadow: '-2px -2px 4px 0 hsla(174, 71%, 88%, 0.3), 2px 2px 4px 0 hsla(174, 71%, 88%, 0.3)',
  },
  outline: 'none',
  '&.Mui-disabled': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#A7A7A7',
    },
    opacity: 0.6,
    pointerEvents: 'none',
    background: '#A7A7A7',
  },
});

export const Select: React.FC<SelectProps> = ({
  values,
  name,
  className,
  isOutlined,
  label,
  style,
  disabled,
  multiple,
}) => {
  const SelectComponent = isOutlined ? StyledOutlinedSelect : StyledSelect;

  const [field, meta] = useField(name);
  const contextForm = useFormikContext();

  const fieldSchema: AnySchema | null = useMemo(() => {
    return contextForm.validationSchema ? reach(contextForm.validationSchema, name) : null;
  }, [contextForm.validationSchema, name]);

  const isRequiredField = useMemo(
    () => Boolean(fieldSchema?.tests.find((test) => test.OPTIONS.name === 'required')),
    [fieldSchema],
  );

  const hasError = useMemo(() => {
    return meta.touched && meta.error !== undefined;
  }, [meta.touched, meta.error]);

  const textFieldPlaceholder = useMemo(() => {
    return isRequiredField ? `${label}*` : `${label}`;
  }, [isRequiredField, label]);

  return (
    <FormControl fullWidth className={className} style={style}>
      <InputLabel
        id={name}
        sx={{
          top: '0',
          '&.MuiInputLabel-shrink': {
            top: '15px',
          },
        }}
        error={hasError}>
        {label ? textFieldPlaceholder : ''}
      </InputLabel>
      <SelectComponent
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          autoFocus: false,
          MenuListProps: {
            disablePadding: true,
          },
          PaperProps: {
            sx: {
              '& .MuiMenuItem-root:hover': {
                backgroundColor: 'rgba(25, 155, 62, 0.05)',
              },
            },
            elevation: 0,
            style: {
              border: '1px solid #DCDCDC',
            },
          },
        }}
        onBlur={field.onBlur}
        error={hasError}
        value={field.value}
        IconComponent={ExpandMoreIcon}
        onChange={field.onChange}
        name={name}
        placeholder={label ? textFieldPlaceholder : ''}
        multiline={multiple}
        disabled={disabled}>
        {values.map((value) => (
          <MenuItem
            value={value.value}
            sx={{ borderTop: '1px solid #DCDCDC' }}
            style={{ padding: '16px 11px 15px' }}
            key={value.value}>
            {value.label}
          </MenuItem>
        ))}
      </SelectComponent>
      {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
    </FormControl>
  );
};
