import React from 'react';
import styles from './Notifications.module.scss';
import { NotificationBox } from '@components/ui/NotificationBox/NotificationBox';
import { useNotification } from '@hooks/useNotification';

export const Notifications: React.FC = () => {
  const { notifications } = useNotification({
    poolingInterval: 5000,
    maxSize: 10,
  });

  return (
    <div className="col container-content">
      <div className={styles.container}>
        <h2 className={styles.header}>Уведомления</h2>
        <div className={styles.notifications}>
          {notifications?.map((notification) => (
            <div className={styles.item} key={notification.id}>
              <NotificationBox key={notification?.id} notification={notification} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
