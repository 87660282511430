import React, { useState } from 'react';
import { Modal } from '@components/MUI/Modal/Modal';
import { FormControlLabel, Paper, Radio } from '@mui/material';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import { Columns } from '@components/Table/TableMUI';
import styles from './FilterModal.module.scss';

type FilterModalProps = {
  filterColumns: [string, Columns][];
  filterColumn: [string, Columns];
  setFilterColumn: React.Dispatch<React.SetStateAction<[string, Columns]>>;
};

export const FilterModal: React.FC<FilterModalProps> = ({
  filterColumns,
  setFilterColumn,
  filterColumn,
}) => {
  const dispatch = useAppDispatch();
  const [filterColumnModal, setFilterColumnModal] = useState(filterColumn);

  const handleFilterModal = (column: [string, Columns]) => {
    setFilterColumn(column);
    setFilterColumnModal(column);
    dispatch(closeModal());
  };

  return (
    <Modal>
      <Paper variant="outlined">
        <div className={styles.filter__modal}>
          {filterColumns.map((column) => (
            <div className={styles.filter__modal__item}>
              <FormControlLabel
                control={<Radio />}
                label={column[1].label}
                onChange={() => handleFilterModal(column)}
                checked={column[1].label === filterColumnModal[1].label ? true : false}
              />
            </div>
          ))}
        </div>
      </Paper>
    </Modal>
  );
};
