import React, { useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import { AnySchema, reach } from 'yup';
import { FormControl, FormHelperText, styled, TextField, TextFieldProps } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import objectSupport from 'dayjs/plugin/objectSupport';
import duration from 'dayjs/plugin/duration';

dayjs.extend(objectSupport);
dayjs.extend(utc);
dayjs.extend(duration);

export type DurationInputdProps = TextFieldProps & {
  name: string;
  placeholder?: string;
  className?: string;
  isLabel?: boolean;
};

export function DurationInput({
  placeholder,
  name,
  className,
  isLabel = true,
  ...props
}: DurationInputdProps) {
  const contextForm = useFormikContext();
  const [field, meta] = useField(name);

  const fieldSchema: AnySchema | null = useMemo(() => {
    return contextForm.validationSchema ? reach(contextForm.validationSchema, name) : null;
  }, [contextForm.validationSchema, name]);

  const isRequiredField = useMemo(
    () => Boolean(fieldSchema?.tests.find((test) => test.OPTIONS.name === 'required')),
    [fieldSchema],
  );

  const hasError = useMemo(() => {
    return meta.touched && meta.error !== undefined;
  }, [meta.touched, meta.error]);

  const durationInputPlaceholder = useMemo(() => {
    return isRequiredField ? `${placeholder}*` : `${placeholder}`;
  }, [isRequiredField, placeholder]);

  const StyledDurationInput = useMemo(() => {
    return styled(TextField)({
      '& label.Mui-focused': {
        top: '10px',
        borderWidth: '1px',
      },
      '& label.MuiInputLabel-shrink': {
        top: '10px',
      },
      legend: {
        display: 'none',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--text-light-green) !important',
        borderWidth: '1px',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: 'var(--text-light-green)',
          borderWidth: '1px',
        },
      },
    });
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputSymbolsArray = e.currentTarget.value.replaceAll(' ', '').toLowerCase().split('');

    const days = timeHandler(inputSymbolsArray, 'д');
    const weeks = timeHandler(inputSymbolsArray, 'н');
    const hours = timeHandler(inputSymbolsArray, 'ч');
    const minutes = timeHandler(inputSymbolsArray, 'м');
    const seconds = timeHandler(inputSymbolsArray, 'с');

    const duration = dayjs
      .duration({
        days: days,
        weeks: weeks,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      })
      .toISOString();
    contextForm.setFieldValue(name, duration);
  };

  const formattedDefaultValue = React.useMemo(() => {
    let formattedValue = '';
    if (field.value) formattedValue = dayjs.duration(field.value).format('D[д] H[ч] m[м] s[с]');
    return formattedValue;
  }, [field.value]);

  return (
    <FormControl id={name} className={className}>
      <StyledDurationInput
        name={name}
        placeholder={'Введите в формате 1н 2д 4ч 30м 20с'}
        defaultValue={formattedDefaultValue}
        id={name}
        label={isLabel ? durationInputPlaceholder : undefined}
        error={hasError}
        type={'text'}
        onChange={handleChange}
        onBlur={field.onBlur}
        variant="outlined"
        multiline={props.multiline}
        {...props}
      />
      {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
    </FormControl>
  );
}

function timeHandler(symbolsArray: string[], timeType: string) {
  const timeIndex = symbolsArray.indexOf(timeType);
  let timeCount = 0;

  if (timeIndex !== -1) {
    if (
      !isNaN(Number(symbolsArray[timeIndex - 1])) &&
      !isNaN(Number(symbolsArray[timeIndex - 2])) &&
      !isNaN(Number(symbolsArray[timeIndex - 3])) &&
      !isNaN(Number(symbolsArray[timeIndex - 4]))
    ) {
      timeCount = Number(
        symbolsArray[timeIndex - 4] +
          symbolsArray[timeIndex - 3] +
          symbolsArray[timeIndex - 2] +
          symbolsArray[timeIndex - 1],
      );
      return timeCount;
    }
    if (
      !isNaN(Number(symbolsArray[timeIndex - 1])) &&
      !isNaN(Number(symbolsArray[timeIndex - 2])) &&
      !isNaN(Number(symbolsArray[timeIndex - 3]))
    ) {
      timeCount = Number(
        symbolsArray[timeIndex - 3] + symbolsArray[timeIndex - 2] + symbolsArray[timeIndex - 1],
      );
      return timeCount;
    }
    if (
      !isNaN(Number(symbolsArray[timeIndex - 1])) &&
      !isNaN(Number(symbolsArray[timeIndex - 2]))
    ) {
      timeCount = Number(symbolsArray[timeIndex - 2] + symbolsArray[timeIndex - 1]);
      return timeCount;
    } else {
      timeCount = Number(symbolsArray[timeIndex - 1]);
      return timeCount;
    }
  }
  return timeCount;
}
