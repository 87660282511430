import React, { useMemo, useState } from 'react';
import { ReactComponent as ArrowDown } from '@assets/img/ArrowDownLittle.svg';
import { useAppDispatch } from '@hooks/index';
import { openModal } from '@store/ui/ui.slice';
import { SortModal } from './SortModal';
import { ReactComponent as ArrowUpIcon } from '@assets/img/sortArrowUp.svg';
import { AdaptiveTableMUIProps, Columns } from '@components/Table/TableMUI';
import styles from './Sort.module.scss';

export const Sort: React.FC<AdaptiveTableMUIProps> = ({
  columns,
  setSortOrder,
  sortOrder,
  setRequestBody,
  setSortColumn,
}) => {
  const dispatch = useAppDispatch();

  const sortColumns = useMemo(() => {
    const sortColumns = Object.entries(columns).filter((column) => column[1].isSortColumn);
    return sortColumns;
  }, [columns]);

  const [sortColumn, setSortColumnHook] = useState<[string, Columns]>(sortColumns[0]);

  const handleSortModal = () => {
    dispatch(
      openModal(
        <SortModal
          sortColumns={sortColumns}
          sortColumn={sortColumn}
          setSortColumn={setSortColumnHook}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          onFilterChange={setRequestBody}
          setSortColumnHook={setSortColumn}
        />,
      ),
    );
  };

  return (
    <button className={styles.sort} onClick={handleSortModal}>
      {sortColumn ? (
        <div>
          <span className={styles.sort__title}>
            {sortColumn ? sortColumn[1].label : <></>}{' '}
            {sortOrder === 'asc' ? '(возрастание)' : '(убывание)'}
          </span>
          {sortOrder === 'asc' ? <ArrowUpIcon /> : <ArrowDown />}
        </div>
      ) : (
        <></>
      )}
    </button>
  );
};
