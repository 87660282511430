import React from 'react';
import styles from './NotificationBox.module.scss';
import { Dayjs } from 'dayjs';
import { Link } from 'react-router-dom';

type Notification = {
  id: string;
  created: Dayjs;
  changed: Dayjs;
  type: 'REQUEST' | 'PROJECT' | 'OFFER' | 'TEAM';
  text: string;
  entityName: string;
  entityId: string;
};

type NotificationBoxProps = {
  notification: Notification;
};

export const NotificationBox: React.FC<NotificationBoxProps> = ({ notification }) => {
  const categoryVariants = {
    REQUEST: {
      title: 'Заявка',
      url: `/requests/`,
    },
    PROJECT: {
      title: 'Проект',
      url: '/projects/',
    },
    OFFER: {
      title: 'Вакансия',
      url: '/teams/offers/',
    },
    TEAM: {
      title: 'Команда',
      url: '/teams/',
    },
  };

  const getCorrectDate = (date: Dayjs) => {
    if (!date) return null;

    const formattedDate = date.format('DD.MM.YYYY');
    const formattedTime = date.format('HH:mm');
    return `${formattedDate} в ${formattedTime}`;
  };
  const entityName = notification?.entityName || categoryVariants[notification?.type].title;
  return (
    <>
      <div className={styles.container}>
        <p className={styles.notification_text}>
          <strong>{categoryVariants[notification?.type].title}.&#8200;</strong>
          <Link
            to={`${categoryVariants[notification?.type].url}${notification?.entityId}`}
            className={styles.notification_link}>
            {entityName}
          </Link>
          &#8200;-&#8200;
          {notification?.text}
        </p>
        <p className={styles.notification_date}>{getCorrectDate(notification?.changed)}</p>
      </div>
    </>
  );
};
