import styles from './WastedTime.module.scss';
import { Tooltip } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { ReactComponent as HelpIcon } from '@assets/img/help.svg';
import { useFormikContext } from 'formik';
import { FormValues } from '../AdvancedAnalytics';
import { CustomBar } from '../components/CustomBar/CustomBar';
import { useParams } from 'react-router-dom';
import { useGetWastedTimeAnalyticQuery } from '@store/project/project.slice';
import { useChartData } from '@hooks/useChartData';
import { WastedTimeAnalyticResponse } from '@store/project/contracts';

export const WastedTime = () => {
  const { values } = useFormikContext<FormValues>();

  const { projectId } = useParams();
  const projectIdToUse = projectId || '';

  const { data } = useGetWastedTimeAnalyticQuery({
    path: {
      projectId: projectIdToUse,
    },
    query: {
      startDate: values.period[0].format('YYYY-MM-DD'),
      endDate: values.period[1].format('YYYY-MM-DD'),
    },
  });

  const { chartData, axisXData, isEmpty } = useChartData<WastedTimeAnalyticResponse>({
    data: data ?? [],
    chartProperty: 'hours',
    axisXProperty: 'date',
  });

  return (
    <>
      <div
        style={{
          order: isEmpty ? 2 : 1,
        }}>
        <div className={styles.block}>
          <h4 className={styles.block__title}>Потраченное время (Wasted Time)</h4>
          <Tooltip title="Диаграмма показывает время, которое задача проводит в различных очередях, а не непосредственно в работе">
            <HelpIcon />
          </Tooltip>
        </div>
        <div>
          <div className={styles.period}>
            {values.period[0].format('DD.MM.YYYY')} - {values.period[1].format('DD.MM.YYYY')}
          </div>
          {!isEmpty ? (
            <>
              <div className={styles.subBlock}>
                <h6 className={styles.subtitle}>Время на уточнение</h6>
              </div>
              <BarChart
                series={[
                  {
                    color: '#FE5959',
                    data: chartData,
                  },
                ]}
                height={290}
                xAxis={[
                  {
                    data: axisXData,
                    scaleType: 'band',
                  },
                ]}
                margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                slotProps={{
                  bar: {
                    clipPath: `inset(0px round 4px 4px 0px 0px)`,
                  },
                }}
                slots={{
                  bar: (props) => (
                    <>
                      <CustomBar barChartProps={props} seriesData={[]} />
                    </>
                  ),
                }}
              />
              <div className={styles.periodBlock}>
                <h6 className={styles.subtitle}>Дни периода</h6>
              </div>

              <div>
                <li className={styles.status}>
                  <div className={styles.status__color}></div>
                  Задачи
                </li>
              </div>
            </>
          ) : (
            <div className={styles.emptyData}>
              <p>Нет данных</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
