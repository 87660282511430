import styles from './PictureCropper.module.scss';
import { useCallback, useRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { useFileReader } from '@hooks/useFileReader';
import { Typography } from '@mui/material';
import { styled } from '@mui/material';
import 'cropperjs/dist/cropper.css';
import { Button } from '@components/MUI/Button';
import { useGetMyRoles } from '@hooks/useGetMyRoles';

interface PictureCropperProps {
  value: File;
  handleClose: () => void;
  onSelect: (value: { full: File; mini: File; roles: string[] }) => void;
}

const CancelButton = styled(Button)(() => ({
  color: '#78858B',
  textTransform: 'none',
  lineHeight: '1.125rem',
}));

const Header = styled(Typography)(() => ({
  textTransform: 'none',
  lineHeight: '1.125rem',
  fontWeight: '500',
  fontFamily: 'Roboto',
}));

const ChooseButton = styled(Button)(() => ({
  textTransform: 'none',
  padding: '0.625rem 1.25rem;',
  lineHeight: '1.125rem',
  '&:hover': {
    backgroundColor: '#21B84B',
  },
  '&:active': {
    backgroundColor: '#148C36',
  },
}));

export const PictureCropper = ({ value, handleClose, onSelect }: PictureCropperProps) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const fileDataURL = useFileReader({ file: value, readAs: 'readAsDataURL' });
  const { formattedRoles } = useGetMyRoles();

  const onSave = useCallback(() => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const canvas = cropperRef.current.cropper.getCroppedCanvas();
      if (canvas) {
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const file = new File([blob], value.name, { type: 'image/webp' });

              const croppedValue = {
                full: file,
                mini: file,
                roles: formattedRoles,
              };
              onSelect(croppedValue);
            }
          },
          'image/webp',
          1,
        );
        handleClose();
      }
    }
  }, [cropperRef, onSelect, value.name]);

  return (
    <div className={styles.photo_modal}>
      <Header color="text.primary">Выберите область для маленьких фотографий.</Header>
      <div className={styles.photoContainer}>
        <div className={styles.cropper}>
          <Cropper ref={cropperRef} src={fileDataURL} preview=".img-preview" aspectRatio={1} />
        </div>
        <div className={styles.preview_container}>
          <div
            className="img-preview"
            style={{
              width: '8rem',
              float: 'left',
              height: '8rem',
              borderRadius: '0.25rem',
              overflow: 'hidden',
            }}
          />
          <div
            className="img-preview"
            style={{
              width: '3rem',
              float: 'left',
              height: '3rem',
              borderRadius: '3rem',
              overflow: 'hidden',
            }}
          />
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <CancelButton variant="text" color="primary" onClick={() => handleClose()} disableRipple>
          Отменить
        </CancelButton>
        <ChooseButton variant="contained" onClick={() => onSave()} disableRipple>
          Сохранить
        </ChooseButton>
      </div>
    </div>
  );
};
