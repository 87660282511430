import { Checkbox, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ComponentOptions } from '../../AdvancedAnalytics';
import styles from './ComponentSelect.module.scss';

type ComponentSelectProps = {
  components: Record<string, () => JSX.Element>;
  selectedComponents: string[];
  handleChange: (e: SelectChangeEvent<ComponentOptions[]>) => void;
  value: ComponentOptions[];
};

export const ComponentSelect = ({
  value,
  components,
  selectedComponents,
  handleChange,
}: ComponentSelectProps) => {
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const allComponents = Object.keys(components);
    if (event.target.checked) {
      handleChange({ target: { value: allComponents } } as SelectChangeEvent<ComponentOptions[]>);
    } else {
      handleChange({ target: { value: [] } } as unknown as SelectChangeEvent<ComponentOptions[]>);
    }
  };
  return (
    <>
      <Select
        multiple
        displayEmpty
        onChange={handleChange}
        value={value ?? ['Test']}
        renderValue={(selected) => {
          if (selected.length === 0 || !selected) return 'Выберите графики';
          else return selected.length > 1 ? `Выбрано: ${selected.length}` : selected[0];
        }}
        MenuProps={{
          classes: {
            paper: styles.menu,
          },
        }}
        sx={{
          minWidth: '293px',
          '& .MuiPaper-root': {
            boxShadow: 'none',
          },
        }}>
        <MenuItem
          value={Object.keys(components)}
          sx={{
            '&.MuiMenuItem-root': {
              padding: '10px 8px',
              gap: '6px',
            },
            '&.Mui-selected': {
              backgroundColor: 'transparent',
            },
            '&.Mui-focusVisible': {
              backgroundColor: 'transparent !important',
            },
          }}>
          <Checkbox
            disableRipple
            checked={selectedComponents.length === Object.keys(components).length}
            sx={{
              '&.MuiCheckbox-root': {
                padding: '0px',
              },
            }}
            onChange={handleSelectAll}
          />
          <ListItemText primary={'Все'} />
        </MenuItem>
        {Object.keys(components).map((component) => {
          return (
            <MenuItem
              value={component}
              key={component}
              sx={{
                '&.MuiMenuItem-root': {
                  padding: '10px 8px',
                  gap: '6px',
                },
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-focusVisible': {
                  backgroundColor: 'transparent !important',
                },
              }}>
              <Checkbox
                disableRipple
                checked={selectedComponents.indexOf(component) > -1}
                sx={{
                  '&.MuiCheckbox-root': {
                    padding: '0px',
                  },
                }}
              />
              <ListItemText primary={component} />
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};
