import React from 'react';
import logoCompany from '@assets/img/userPage/companyLogo.png';
import styles from './ProfileAboutCompany.module.scss';

type ProfileAboutCompanyProps = {
  logoUrl?: string;
  name?: string;
  url?: string;
  officeLocation?: string;
  email?: string;
  phone?: string;
  description?: string;
  rules?: string;
  inn?: string;
};

export const ProfileAboutCompany: React.FC<ProfileAboutCompanyProps> = ({
  logoUrl,
  name,
  url,
  officeLocation,
  email,
  phone,
  description,
  rules,
  inn,
}) => {
  return (
    <div className={styles.about__company__info}>
      <h3 className={styles.about__company__info__title}>О компании</h3>
      <div className={styles.about__company__info__logo}>
        <img
          width={180}
          height={180}
          src={logoUrl ? logoUrl : logoCompany}
          alt="логотип компании"
        />
        <div className={styles.about__company__main__info}>
          <p className="m-0 p-0">
            <b>{name ? name : '-'}</b>
          </p>
          <a
            href={url ? url : '#'}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.about__company__url}>
            {url ? url : '-'}
          </a>
          <span>{officeLocation ? officeLocation : '-'}</span>
        </div>
      </div>
      <div className="d-flex mb-4">
        <div className="d-flex flex-column">
          <p className="m-0 p-0 color-gray">Почта</p>
          <span className={styles.about__company__email}>{email ? email : '-'}</span>
        </div>
        <div className="ms-5">
          <p className="m-0 p-0 color-gray">Телефон</p>
          <span className={styles.about__company__phone}>{phone ? phone : '-'}</span>
        </div>
      </div>
      <div className="d-flex flex-column mb-4">
        <p className="m-0 p-0 color-gray">Описание организации</p>
        <p className="p-0 m-0">{description ? description : '-'}</p>
      </div>
      <div className="d-flex flex-column mb-3">
        <p className="m-0 p-0 color-gray">Основные правила взаимодействия по разработке</p>
        <p className="p-0 m-0">{rules ? rules : '-'}</p>
      </div>
      <div className="d-flex flex-column mb-4">
        <p className="m-0 p-0 color-gray">ИНН</p>
        <p className="p-0 m-0">{inn ? inn : '-'}</p>
      </div>
      <hr />
    </div>
  );
};
