import React, { useState } from 'react';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { StatusLabel, statusTaskColors } from '@components/ui/StatusLabel/StatusLabel';
import { useRequestBody } from '@hooks/useRequestBody';
import { Task } from '@store/tasks/contracts';
import { Dayjs } from 'dayjs';
import { Link, useParams } from 'react-router-dom';
import { ProjectTasksHeader } from './ProjectTasksHeader';
import styles from './ProjectTasks.module.scss';
import { useDetailProjectQuery } from '@store/project/project.slice';
import { useGetTasksQuery } from '@store/tasks/tracker.slice';

export const ProjectTasks: React.FC = () => {
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } = useRequestBody();
  const { projectId } = useParams();
  const { data: project } = useDetailProjectQuery(projectId!);

  const { data: tasks } = useGetTasksQuery({
    limit: limit,
    start: (page - 1) * limit,
    projects: [projectId!],
  });

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <div className={styles.project__tasks}>
      <ProjectTasksHeader projectName={project?.name || ''} />
      <AdaptiveTableMUI
        setRequestBody={setRequestBody}
        setSortColumn={setSortColumn}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        sortColumn={sortColumn}
        columns={{
          key: {
            label: 'Ключ Задачи',
            children: (value, row: Task) => <Link to={`/tasks/${row.id}`}>{value}</Link>,
            filterInput: true,
            isSortColumn: false,
          },
          name: {
            label: 'Задача',
            children: (value, row: Task) => <Link to={`/tasks/${row.id}`}>{value}</Link>,
            filterInput: true,
            isSortColumn: false,
          },
          status: {
            label: 'Статус',
            children: (value: any, row: Task) => (
              <StatusLabel text={value.display} color={statusTaskColors.get(row.statusType)} />
            ),
            filterInput: true,
            isSortColumn: false,
          },
          executor: {
            label: 'Исполнитель',
            children: (value, row: Task) =>
              value ? <Link to={`/userPage/`}>{value}</Link> : <span>-</span>,
            filterInput: true,
            isSortColumn: false,
          },
          created: {
            label: 'Дата создания',
            format: (value: Dayjs) => value.format('DD.MM.YYYY'),
            filterInput: true,
            isSortColumn: true,
          },
          deadline: {
            label: 'Дата завершения',
            format: (value: Dayjs) => (value ? value?.format('DD.MM.YYYY') : '-'),
            filterInput: true,
            isSortColumn: true,
          },
        }}
        rows={tasks?.data || []}>
        <TablePaginationMUI
          limit={limit}
          count={tasks?.totalCount}
          entityLabel="задач"
          page={page}
          handleChangePage={(_, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </AdaptiveTableMUI>
    </div>
  );
};
