import { useState } from 'react';

export function useStack<T>(maxSize: number, uniqueBy?: keyof T) {
  const [stack, setStack] = useState<T[]>([]);

  const push = (newData: T) => {
    setStack((prevStack) => {
      if (uniqueBy && prevStack.some((item) => item[uniqueBy] === newData[uniqueBy])) {
        return prevStack;
      }
      if (prevStack.length >= maxSize) {
        prevStack.pop();
      }
      return [newData, ...prevStack];
    });
  };

  const isStackEmpty = () => stack.length === 0;

  return {
    stack,
    push,
    isStackEmpty,
  };
}
