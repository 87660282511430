import { ApiClient } from '@store/api-client';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';
import { DocumentsResponse } from './contracts';
import { PathParameters, RequestBody, ResponseBody } from '@store/utility';

export const getDocsFavorite = async (request: RequestBody<Api.operations['getOrderProjects']>) => {
  const { data } = await ApiClient.post<ResponseBody<Api.operations['getOrderProjects']>>(
    `/api/document/favorite`,
    request,
  );
  return plainToInstance(DocumentsResponse, data);
};

export const getFile = async (key: PathParameters<Api.operations['getFile'], 'key'>) => {
  const { data, headers } = await ApiClient.get<ResponseBody<Api.operations['getFile']>>(
    `/api/file/${key}`,
    { responseType: 'blob' },
  );
  return { data, headers };
};
