import { Modal } from '@components/MUI/Modal/Modal';
import styles from './PictureSelector.module.scss';
import { closeModal } from '@store/ui/ui.slice';
import { useAppDispatch } from '@hooks/index';
import { useCallback, useState, useRef } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material';
import 'cropperjs/dist/cropper.css';
import { FormWrapper } from '@components/Form/Form';
import { Button } from '@components/MUI/Button';
import { PictureCropper } from '@components/PictureCropper/PictureCropper';

interface PictureSelectorProps {
  value?: File;
  onSelect: (value: { full: File; mini: File; roles: string[] }) => void;
  allowedExtensions: string[];
  allowedSize: number;
}

const CancelButton = styled(Button)(() => ({
  color: '#78858B',
  textTransform: 'none',
  lineHeight: '1.125rem',
}));

const ChooseButton = styled(Button)(() => ({
  textTransform: 'none',
  padding: '0.625rem 1.25rem;',
  lineHeight: '1.125rem',
  '&:hover': {
    backgroundColor: '#21B84B',
  },
  '&:active': {
    backgroundColor: '#148C36',
  },
}));

const ErrorMessage = styled(Typography)(() => ({
  marginTop: '10px',
  fontSize: '0.875rem',
}));

export const PictureSelector = ({
  value,
  onSelect,
  allowedExtensions,
  allowedSize,
}: PictureSelectorProps) => {
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | undefined>(value);
  const [error, setError] = useState<string | undefined>();

  const handleCancel = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const verifyImage = useCallback(
    (file: File) => {
      setError(undefined);
      const fileType = file.type;
      const fileSize = file.size;
      if (!allowedExtensions.includes(fileType)) {
        setError('Тип выбранного файла не поддерживается');
        return false;
      }
      if (fileSize > allowedSize) {
        setError(`Допустимый размер файла ${Math.ceil(allowedSize / 1024 / 1024)}Мб`);
        return false;
      }
      return true;
    },
    [allowedExtensions, allowedSize],
  );

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let files = event.target.files;

      if (files && files.length > 0) {
        const isVerified = verifyImage(files[0]);
        if (isVerified) {
          setFile(files[0]);
        }
      }
    },
    [verifyImage],
  );

  return (
    <Modal>
      {!file ? (
        <FormWrapper label="Загрузка новой фотографии" isThinTitle>
          <div className="mt-2">
            Выберите файл в одном из форматов:
            <p className="fw-500">.png &nbsp;.jpeg &nbsp;.jpg &nbsp;.webp</p>
          </div>

          <div className={styles.bottomContainer}>
            <CancelButton
              variant="text"
              color="primary"
              onClick={() => handleCancel()}
              disableRipple>
              Отменить
            </CancelButton>
            <ChooseButton
              variant="contained"
              onClick={() => fileInputRef.current?.click()}
              disableRipple>
              Выбрать файл
            </ChooseButton>
            <input
              accept="image/*"
              onChange={handleFileChange}
              type="file"
              multiple={false}
              ref={fileInputRef}
              hidden
            />
          </div>
          {error ? (
            <ErrorMessage align="center" color="error">
              {error}
            </ErrorMessage>
          ) : (
            <></>
          )}
        </FormWrapper>
      ) : (
        <>
          <PictureCropper value={file} handleClose={handleCancel} onSelect={onSelect} />
        </>
      )}
    </Modal>
  );
};
