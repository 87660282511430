import React from 'react';
import { IconButton } from '@components/ui/Button/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';

interface EMenuProps {
  handleEdit: () => void;
  handleCancel: () => void;
  handleDelete: () => void;
  isEditDisabled?: boolean;
  isCancelDisabled?: boolean;
  isDeleteDisabled?: boolean;
}

export const EMenu: React.FC<EMenuProps> = ({
  handleEdit,
  handleCancel,
  handleDelete,
  isEditDisabled = false,
  isCancelDisabled = false,
  isDeleteDisabled = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (menuAction: () => void) => {
    menuAction();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => handleMenu(handleEdit)} disabled={isEditDisabled}>
          Редактировать
        </MenuItem>
        <MenuItem onClick={() => handleMenu(handleCancel)} disabled={isCancelDisabled}>
          Отменить
        </MenuItem>
        <MenuItem onClick={() => handleMenu(handleDelete)} disabled={isDeleteDisabled}>
          Удалить
        </MenuItem>
      </Menu>
    </div>
  );
};
