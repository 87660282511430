import React from 'react';
import sender2 from '@assets/img/messages/sender2.svg';
import { DeleteChat } from './DeleteChat';
import { SearchMessage } from './SearchMessage';

export interface ChatHeaderProps {
  title: string;
}

export const ChatHeader: React.FC<ChatHeaderProps> = ({ title }) => {
  return (
    <div className="d-flex justify-content-between align-items-center border-bottom bg-white rounded-top p-2">
      <div>
        <img src={sender2} alt="Отправитель" />
        <span className="ms-2 fw-bold">{title}</span>
      </div>
      <div>
        <SearchMessage />
        <DeleteChat />
      </div>
    </div>
  );
};
