import React from 'react';
import { CreateRequestForm, CreateRequestFormProps } from '@pages/requests/forms/CreateRequestForm';
import { useNavigate } from 'react-router-dom';
import { useCreateRequestMutation } from '@store/requests/requests.slice';
import dayjs from 'dayjs';
import { Card } from '@mui/material';
import classNames from 'classnames';
import styles from './CreateRequestForm.module.scss';

function CreateRequest() {
  const navigate = useNavigate();
  const [createRequest] = useCreateRequestMutation();

  const createSaveRequest: CreateRequestFormProps['submit'] = async (CreateRequestForm, form) => {
    try {
      const { id } = await createRequest({
        name: CreateRequestForm.name,
        projectTypeId: CreateRequestForm.projectTypeId,
        deadLine: dayjs(CreateRequestForm.deadLine).format('DD.MM.YYYY HH:mm'),
        description: CreateRequestForm.description,
        budget: CreateRequestForm.budget,
        tag: CreateRequestForm.tag,
        currencyId: CreateRequestForm.currencyId,
        stack: CreateRequestForm.stack,
        requirement: CreateRequestForm.requirement,
        task: CreateRequestForm.task,
        headId: CreateRequestForm.headId.value,
        managerNeed: !CreateRequestForm.managerNeed,
        attachments: CreateRequestForm.attachments.map((item) => item.id),
        visibilityStatusId: CreateRequestForm.visibilityStatusId,
      }).unwrap();
      navigate(`/requests/${id}`);
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    }
  };
  return (
    <Card
      className={classNames('form-container', styles.form__card)}
      elevation={0}
      variant="outlined">
      <div className={'row'}>
        <div className={styles.form__page__container}>
          <div className={styles.form__page__title}>
            <h2 className={'form-title'}>Создание заявки</h2>
          </div>
          <CreateRequestForm
            value={{
              name: '',
              projectTypeId: '',
              deadLine: dayjs(),
              description: '',
              budget: Number(''),
              tag: '',
              currencyId: '',
              visibilityStatusId: '',
              stack: '',
              requirement: '',
              task: '',
              headId: { label: '', value: '' },
              managerNeed: false,
              attachments: [],
            }}
            submit={createSaveRequest}
          />
        </div>
      </div>
    </Card>
  );
}

export { CreateRequest };
