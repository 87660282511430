import React, { useState } from 'react';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { StatusLabel, statusColors } from '@components/ui/StatusLabel/StatusLabel';
import { useRequestBody } from '@hooks/useRequestBody';
import { Manager, RequestItem } from '@store/requests/contracts';
import { Dayjs } from 'dayjs';
import { Link } from 'react-router-dom';
import { Star } from '@components/Star/Star';
import { useAppDispatch } from '@hooks/index';
import { setRequestsCount } from '@store/favorite/favorite.slice';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import {
  useGetFavoriteRequestsQuery,
  useSetFavoriteRequestMutation,
} from '@store/requests/requests.slice';

export const FavoriteRequests: React.FC = () => {
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();
  const [setFavoriteRequest] = useSetFavoriteRequestMutation();
  const { data: favoriteRequests } = useGetFavoriteRequestsQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <AdaptiveTableMUI
      columns={{
        name: {
          label: 'Проект',
          filterInput: true,
          children: (value, row: RequestItem) => <Link to={`/requests/${row.id}`}>{value}</Link>,
        },
        projectType: {
          label: 'Тип проекта',
          filterInput: true,
        },
        created: {
          label: 'Дата создания',
          format: (value: Dayjs) => value.format('DD.MM.YYYY'),
          isSortColumn: true,
          filterInput: true,
        },
        deadLine: {
          label: 'Дедлайн',
          filterInput: true,
          isSortColumn: true,
          format: (value: Dayjs) => value.format('DD.MM.YYYY'),
        },
        budget: {
          label: 'Бюджет',
          filterInput: true,
          isSortColumn: true,
          format: (value) => `${value} ₽`,
        },
        statusName: {
          label: 'Статус',
          filterInput: true,
          children: (value) => <StatusLabel text={value} color={statusColors.get(value)} />,
        },
        managers: {
          label: 'Менеджер',
          filterInput: true,
          children: (value: Manager[], row: RequestItem) => {
            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {value?.map((manager) => (
                  <Link to={`/userPage/${manager.managerId}`}>{manager.managerName}</Link>
                ))}
              </div>
            );
          },
        },
        star: {
          children: (_, row: RequestItem) => (
            <Star
              checked={true}
              onChange={() =>
                setFavoriteRequest({ requestBody: { status: false }, requestId: row.id }).then(
                  () => {
                    if (favoriteRequests?.totalCount) {
                      dispatch(setRequestsCount(favoriteRequests?.totalCount - 1));
                    }
                  },
                )
              }
              style={{ marginRight: '1rem' }}
            />
          ),
        },
      }}
      rows={favoriteRequests?.data || []}
      setRequestBody={setRequestBody}
      setSortColumn={setSortColumn}
      setSortOrder={setSortOrder}
      sortOrder={sortOrder}
      sortColumn={sortColumn}>
      <TablePaginationMUI
        limit={limit}
        count={favoriteRequests?.totalCount || 0}
        entityLabel="заявок"
        page={page}
        handleChangePage={(_, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </AdaptiveTableMUI>
  );
};
