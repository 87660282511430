import { useEffect, useState } from 'react';

export function useGetFile(fileUrl?: string) {
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    if (fileUrl) {
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });
          setFile(file);
        });
    }
  }, [fileUrl]);

  return file;
}
