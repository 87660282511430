import { ReactNode } from 'react';
import { Columns } from './TableMUI';

interface ITableCellOutput {
  format?: (value: any) => string | number;
  children?: (value: string | number, row: any) => ReactNode;
  value: string | number;
  row?: any;
  titles: Record<string, Columns>;
  title?: string;
}

export const AdaptiveStar = ({ format, children, value, row }: ITableCellOutput) => {
  if (format !== undefined) {
    value = format(value);
  }

  return <>{(children && children(value, row)) ?? value}</>;
};
