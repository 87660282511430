import React, { useCallback } from 'react';
import { Button } from '@components/MUI/Button';
import { useAppSelector } from '@hooks/index';
import { RequestItem } from '@store/requests/contracts';
import { selectCurrentUserId, selectMyRoles } from '@store/user/user.slice';
import { STATUS_REQUEST } from '@store/dictionary/contracts';
import { useNavigate, useParams } from 'react-router-dom';
import { usePublishRequestMutation, useSendProposalMutation } from '@store/requests/requests.slice';
import { useOpenTransferToProjectModal } from '@pages/requests/components/helpersHooksTransferRequestToProject/useOpenTransferToProjectModal';

type RequestDetailButtonProps = {
  requestDetail: RequestItem | null;
};

export const RequestDetailButton: React.FC<RequestDetailButtonProps> = ({ requestDetail }) => {
  const myId = useAppSelector(selectCurrentUserId);
  const { requestId } = useParams();
  const navigate = useNavigate();
  const myRoles = useAppSelector(selectMyRoles);
  const openTransferToProjectModal = useOpenTransferToProjectModal();
  const [sendProposal] = useSendProposalMutation();
  const [publishRequest] = usePublishRequestMutation();

  const fetchPublishRequest = useCallback(async () => {
    if (requestId) {
      await publishRequest(requestId);
      navigate('/requests');
    } else {
      navigate('*');
    }
  }, [navigate, requestId]);

  return (
    <div>
      {myId === requestDetail?.customer?.id &&
      requestDetail?.statusName === STATUS_REQUEST.DRAFT ? (
        <Button variant="contained" onClick={fetchPublishRequest} size="large">
          Опубликовать
        </Button>
      ) : myRoles?.find((role) => role.name === 'Менеджер') &&
        requestDetail?.statusName === STATUS_REQUEST.ACCEPTED &&
        requestDetail.managers.find((manager) => manager.managerId === myId) ? (
        <Button
          variant="contained"
          onClick={() => openTransferToProjectModal(requestDetail)}
          size="large">
          Перевести в проект
        </Button>
      ) : myId !== requestDetail?.customer?.id &&
        myRoles?.find((role) => role.name === 'Менеджер') &&
        requestDetail?.statusName === STATUS_REQUEST.IN_SEARCH &&
        requestDetail?.managers === null ? (
        <Button variant="contained" onClick={() => sendProposal(requestDetail.id)} size="large">
          Откликнуться
        </Button>
      ) : myId !== requestDetail?.customer?.id &&
        myRoles?.find((role) => role.name === 'Менеджер') &&
        requestDetail?.statusName === STATUS_REQUEST.ACCEPTED &&
        requestDetail.managers.find((manager) => manager.managerId === myId) ? (
        <Button variant="contained" onClick={() => {}} size="large">
          Перевести в проект
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};
