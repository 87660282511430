import styles from './TermsOfUsePage.module.scss';
import { Link } from 'react-router-dom';

export const TermsOfUsePage = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Правила защиты информации о пользователях Egile</h2>
      <div>
        <p className={styles.subTitle}>1. Общие положения</p>
        <div className={styles.info}>
          <p>
            1.1. Настоящие Правила являются официальным документом Общества с ограниченной
            ответственностью «ИНФОСЕРВИС», расположенного по адресу Россия, 420111, Республика
            Татарстан, г. Казань, ул. Пушкина д.18, офис 113 (далее – Администрация Сайта), и
            определяют порядок обработки и защиты информации о физических лицах (далее –
            Пользователи), пользующихся услугами интернет-сайта Egile.ru (далее – Сайт) и его
            сервисов.
          </p>
          <p>
            1.2. Целью настоящих Правил является обеспечение надлежащей защиты информации о
            пользователях, а также их персональных данных, от несанкционированного доступа и
            разглашения.
          </p>
          <p>
            1.3. Отношения, связанные со сбором, хранением, распространением и защитой информации о
            пользователях Сайта, регулируются настоящими Правилами, иными официальными документами
            Администрации Сайта и действующим законодательством Российской Федерации.
          </p>
          <p>
            1.4. Действующая редакция Правил является публичным документом и находится в открытом
            доступе для любого пользователя сети Интернет при переходе по ссылке
            https://egile.ru/legal/privacy.html. Администрация Сайта вправе вносить изменения в
            настоящие Правила. Если Администрация Сайта вносила какие-либо изменения в настоящие
            Правила, то она информирует об этом пользователей Сайта размещением новой редакции
            Правил по адресу{' '}
            <Link className="color-green color-green_hover" to="/legal/privacy">
              https://egile.ru/legal/privacy.html
            </Link>
          </p>
          <p>
            1.5 Настоящие Правила разработаны и используются в соответствии с Правилами пользования
            сайтом{' '}
            <Link className="color-green color-green_hover" to="/">
              https://egile.ru
            </Link>{' '}
            В случае наличия противоречий между настоящими Правилами и иными официальными
            документами Администрации Сайта применению подлежат настоящие Правила.
          </p>
          <p>
            1.6. При регистрации и использовании Сайта, Пользователь выражает свое согласие с
            условиями настоящих Правил.
          </p>
          <p>
            1.7. В случае несогласия Пользователя с условиями настоящих Правил использование Сайта и
            его сервисов должно быть немедленно прекращено.
          </p>
        </div>

        <p>2. Условия пользования Сайтом</p>
        <div className={styles.info}>
          <p>
            2.1. Оказывая услуги по использованию Сайта и его сервисов (далее – Услуги Сайта),
            Администрация Сайта считает, что Пользователь: <br />
            -обладает всеми необходимыми правами, позволяющими ему осуществлять регистрацию и
            использовать настоящий Сайт;
            <br />
            -указывает достоверную информацию о себе в объемах, необходимых для пользования Услугами
            Сайта;
            <br />
            -осознает, что некоторые виды информации, переданные им другим Пользователям, не могут
            быть удалены самим Пользователем;
            <br />
            -ознакомлен с настоящими Правилами, выражает свое согласие с ними и принимает на себя
            указанные в них права и обязанности.
          </p>
          <p>
            2.2. Администрация Сайта не проверяет достоверность получаемой (собираемой) информации о
            пользователях, за исключением случаев, когда такая проверка необходима в целях
            исполнения Администрацией Сайта обязательств перед пользователем.
          </p>
        </div>

        <p>3. Цели обработки информации</p>
        <div className={styles.info}>
          <p>
            3.1 Администрация Сайта осуществляет обработку информации о Пользователях, в том числе
            их персональных данных, в целях выполнения обязательств Администрации Сайта перед
            Пользователями в отношении использования Сайта и его сервисов.
          </p>
        </div>

        <p>4. Состав информации о пользователях</p>
        <div className={styles.info}>
          <p>
            4.1. Персональные данные Пользователей Персональные данные Пользователей включают в
            себя:
          </p>
          <p>
            4.1.1. предоставляемые Пользователями и минимально необходимые для регистрации на Сайте:
            имя, фамилия, e-mail, телефон, наименование компании;
          </p>
          <p>
            4.1.2. предоставляемые Пользователями с использованием раздела редактирования своего
            профиля на Сайте (в том числе пол, дата рождения, город, адрес, телефон, наименование
            компании);
          </p>
          <p>
            4.1.3. дополнительно предоставляемые Пользователями по запросу Администрации Сайта в
            целях исполнения Администрацией Сайта обязательств перед Пользователями, вытекающих из
            договора на оказание Услуг Сайта.
          </p>
          <p>
            4.2. Иная информация о Пользователях, обрабатываемая Администрацией Сайта. Администрация
            Сайта может также обрабатывать иную информацию о Пользователях, которая включает в себя:
          </p>
          <p>
            4.2.1. дополнительные данные, получаемые при доступе к Сайту, включающие в себя данные о
            технических средствах (устройствах), технологическом взаимодействии с Сайтом (в т.ч.
            IP-адрес пользователя, вид операционной системы пользователя, тип браузера,
            географическое положение, поставщик услуг Интернет), и последующих действиях
            Пользователя на Сайте;
          </p>
          <p>
            4.2.2. информация, автоматически получаемая при доступе к Сайту с использованием
            закладок (cookies);
          </p>
          <p>
            4.2.3. информация, создаваемая пользователями на Сайте вне раздела редактирования
            профиля (в том числе проекты, заявки, команды, задачи, фотографии, сообщения,
            комментарии);
          </p>
          <p>
            4.2.4. информация, полученная в результате действий Пользователя на Сайте (в частности,
            информация о добавлении других Пользователей или информации в избранное, размещение
            заявок в проектах, аналитические данные по проекту и задачам);
          </p>
        </div>

        <p>5. Обработка информации о пользователях</p>
        <div className={styles.info}>
          <p>5.1. Обработка персональных данных осуществляется на основе принципов:</p>
          <p>а) законности целей и способов обработки персональных данных;</p>
          <p>б) добросовестности;</p>
          <p>
            в) соответствия целей обработки персональных данных целям, заранее определенным и
            заявленным при сборе персональных данных, а также полномочиям Администрации Сайта; г)
            соответствия объема и характера обрабатываемых персональных данных, способов обработки
            персональных данных целям обработки персональных данных;
          </p>
          <p>
            д) недопустимости объединения созданных для несовместимых между собой целей баз данных,
            содержащих персональные данные.
          </p>
          <p>
            5.1.1. Условия и цели обработки персональных данных Администрация Сайта осуществляет
            обработку персональных данных пользователя в целях исполнения договора между
            Администрацией Сайта и Пользователем на оказание Услуг Сайта. В силу статьи 6
            Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» отдельное согласие
            пользователя на обработку его персональных данных не требуется. В силу п.п. 2 п. 2
            статьи 22 указанного закона Администрация Сайта вправе осуществлять обработку
            персональных данных без уведомления уполномоченного органа по защите прав субъектов
            персональных данных.
          </p>
          <p>
            5.1.2. Сбор персональных данных Сбор персональных данных Пользователя осуществляется на
            Сайте при регистрации, а также в дальнейшем при внесении пользователем по своей
            инициативе дополнительных сведений о себе с помощью инструментария Сайта. Персональные
            данные, предусмотренные п. 4.1.1. настоящих Правил, предоставляются Пользователем и
            являются минимально необходимыми при регистрации. Персональные данные, предусмотренные
            п. 4.1.2. настоящих Правил, дополнительно предоставляются Пользователем по собственной
            инициативе с использованием раздела профиля «Общая информация».
          </p>
          <p>
            5.1.3. Хранение и использование персональных данных Персональные данные пользователей
            хранятся исключительно на электронных носителях и обрабатываются с использованием
            автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка
            персональных данных необходима в связи с исполнением требований законодательства.
          </p>
          <p>
            5.1.4. Передача персональных данных Персональные данные Пользователей не передаются
            каким-либо третьим лицам, за исключением случаев, прямо предусмотренных настоящими
            Правилами. При указании пользователя или при наличии согласия пользователя возможна
            передача персональных данных пользователя третьим лицам-контрагентам Администрации Сайта
            с условием принятия такими контрагентами обязательств по обеспечению конфиденциальности
            полученной информации. Предоставление персональных данных Пользователей по запросу
            государственных органов (органов местного самоуправления) осуществляется в порядке,
            предусмотренном законодательством. В целях исполнения соглашения между Пользователем и
            Администрацией Сайта и предоставления Пользователю доступа к использованию функционала
            Сайта, Администрация Сайта развивает предоставляемые сервисы и продукты, разрабатывает и
            внедряет новые сервисы и продукты, оптимизирует качество сервисов и продуктов,
            совершенствует доступный функционал Сайта и сервисов. Для обеспечения реализации
            указанных целей Пользователь соглашается на осуществление Администрацией Сайта с
            соблюдением применимого законодательства сбора, хранения, накопления, систематизации,
            извлечения, сопоставления, использования, наполнения (уточнения) их данных, а также на
            получение и передачу аффилированным лицам и партнерам результатов автоматизированной
            обработки таких данных с применением различных моделей оценки информации, в виде
            целочисленных и/или текстовых значений и идентификаторов, соответствующих заданным в
            запросах оценочным критериям, для обработки данных Администрацией Сайта и/или лицами,
            указанными в настоящем пункте. Или Персональные данные Пользователей не передаются
            каким-либо третьим лицам, за исключением случаев, прямо предусмотренных настоящими
            Правилами. При указании пользователя или при наличии согласия пользователя возможна
            передача персональных данных пользователя третьим лицам-контрагентам Администрации Сайта
            с условием принятия такими контрагентами обязательств по обеспечению конфиденциальности
            полученной информации.
          </p>
          <p>
            5.1.5. Уничтожение персональных данных Персональные данные пользователя уничтожаются
            при: – самостоятельном удалении Пользователем данных из своего профиля; –
            самостоятельном удалении Пользователем своего профиля с использованием функциональной
            возможности «Удаление аккаунта», доступной Пользователю в разделе «Общая информация»; –
            удалении Администрацией Сайта информации, размещаемой Пользователем, а также профиля
            Пользователя в случаях, установленных Правилами пользования сайтом. В случае удаления
            профиля Администрация Сайта хранит на своих электронных носителях персональные и иные
            необходимые данные Пользователя в течение необходимого и установленного действующим
            законодательством Российской Федерации срока. или Персональные данные пользователя
            уничтожаются при удалении Администрацией Сайта информации, размещаемой Пользователем, а
            также персональной страницы Пользователя в случаях, установленных Правилами пользования
            сайтом http://egile.ru.
          </p>
          <p>
            5.2. Размещая информацию на Сайте, в том числе свои персональные данные, Пользователь
            осознает и соглашается с тем, что указанная информация может быть доступна другим
            пользователям сети Интернет с учетом особенностей архитектуры и функционала Сайта. 5.3.
            Удаление вашей личной информации. Удаление данных Вы можете самостоятельно удалить
            данные из своей учетной записи и аккаунт в разделе Настройки в личном кабинете (пункт
            "Удалить аккаунт"). Эти данные немедленно удаляются из системы. Данные все еще могут
            оставаться в системных резервных файлах, которые будут периодически удаляться. Также вы
            можете отправить запрос на удаление ваших данных на email help@egile.ru Мы обязуемся
            выполнить удаление в течение одного месяца (30 календарных дней) и отправим вам
            подтверждение по электронной почте, как только информация будет удалена.
          </p>
        </div>
        <p>6. Права и обязанности пользователей</p>
        <div className={styles.info}>
          <p>6.1. Пользователи вправе:</p>
          <p>
            6.1.1. осуществлять свободный доступ к информации о себе посредством загрузки своего
            профиля на Сайте с использованием логина и пароля;
          </p>
          <p>
            6.1.2. самостоятельно вносить изменения и исправления в информацию о себе в профиле на
            Сайте, при условии, что такие изменения и исправления содержат актуальную и достоверную
            информацию;
          </p>
          <p>6.1.3. удалять информацию о себе из своего профиля на Сайте;</p>
          <p>
            6.1.4. требовать от Администрации Сайта уточнения своих персональных данных, их
            блокирования или уничтожения в случае, если такие данные являются неполными,
            устаревшими, недостоверными, незаконно полученными или не являются необходимыми для
            заявленной цели обработки и если невозможно самостоятельно выполнить действия,
            предусмотренные п.п. 6.1.2. и 6.1.3. настоящих Правил;
          </p>
          <p>
            6.1.5. на основании запроса получать от Администрации Сайта информацию, касающуюся
            обработки его персональных данных.
          </p>
          <p>
            6.2. Поскольку Сайт является средством коммуникации и поиска людей, следующая информация
            о зарегистрированном Пользователе всегда доступна любому зарегистрированному
            Пользователю Сайта:
          </p>
          <p>6.2.1. фамилия и имя Пользователя;</p>
          <p>
            6.2.2. дата рождения, профильная фотография, город, если Пользователь разместил эту
            информацию на Сайте в разделе «Учетные данные»;
          </p>
        </div>

        <p>7. Меры по защите информации о Пользователях</p>
        <div className={styles.info}>
          <p>
            7.1. Администрация Сайта принимает технические и организационно-правовые меры в целях
            обеспечения защиты персональных данных Пользователя от неправомерного или случайного
            доступа к ним, уничтожения, изменения, блокирования, копирования, распространения, а
            также от иных неправомерных действий.
          </p>
          <p>
            7.2. Для авторизации доступа к Сайту используется логин (email) и пароль Пользователя.
            Ответственность за сохранность данной информации несет Пользователь. Пользователь не
            вправе передавать собственный логин и пароль третьим лицам, а также обязан предпринимать
            меры по обеспечению их конфиденциальности.
          </p>
          <p>
            7.3. В целях обеспечения более надежной защиты информации о Пользователях Администрация
            Сайта может использовать систему привязки страницы к мобильному телефону. Для
            осуществления данной системы Пользователь должен предоставить Администрации Сайта номер
            своего мобильного телефона.
          </p>
        </div>

        <p>8. Правила использования файлов cookie</p>
        <div className={styles.info}>
          <p>
            8.1. Файлы cookie – небольшой фрагмент текстовых файлов, который принимается и
            обрабатывается на вашем устройстве, когда вы заходите на сайты в сети «Интернет». Кроме
            того, при посещении сайтов происходит автоматический сбор иных данных, таких как:
            IP-адрес, информации о браузере и языке, адресов запрашиваемых страниц и иной подобной
            информации.
          </p>
          <p>
            8.2. При посещении Сайта сookie могут использоваться для: обеспечения функционирования и
            безопасности сайта, регистрации в системе, улучшения качества сайта продуктов и услуг.
          </p>
          <p>
            8.3. Чтобы управлять файлами cookie, Пользователи Сайта могут воспользоваться
            инструкцией, предоставляемой разработчиком браузера или производителем устройства.
          </p>
          <p>
            8.4. Браузер или устройство могут позволять блокировать, удалять или ограничивать
            использование cookie. Но эти файлы являются частью Сайта и блокировка, удаление или
            ограничение их использования может привести к тому, что Пользователь не будет иметь
            доступ ко всем функциям Системы.
          </p>
        </div>

        <p>9. Ограничение действия Правил</p>
        <div className={styles.info}>
          <p>
            Действие настоящих Правил не распространяется на действия и интернет-ресурсы третьих
            лиц. Администрация Сайта не несет ответственности за действия третьих лиц, получивших в
            результате использования Интернета или Услуг Сайта доступ к информации о Пользователе,
            за последствия использования информации, которая, в силу природы Сайта, доступна любому
            пользователю сети Интернет. Администрация Сайта рекомендует Пользователям ответственно
            подходить к решению вопроса об объеме информации о себе, размещаемой на Сайте.
          </p>
        </div>
      </div>
    </div>
  );
};
