import { FC } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

type WithAdaptiveProps<T> = {
  desktop?: FC<T>;
  tablet?: FC<T>;
  mobile?: FC<T>;
  default: FC<T>;
};

export function withAdaptive<T>(adaptives: WithAdaptiveProps<T>) {
  const AdaptiveComponent: FC<T> = (props: T) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const tablet = useMediaQuery(theme.breakpoints.down('tablet'));
    const desktop = useMediaQuery(theme.breakpoints.up('tablet'));

    if (mobile && adaptives.mobile) return <adaptives.mobile {...props} key="Mobile" />;

    if (tablet && adaptives.tablet) return <adaptives.tablet {...props} key="Tablet" />;

    if (desktop && adaptives.desktop) return <adaptives.desktop {...props} key="Desktop" />;

    return <adaptives.default {...props} key="Default" />;
  };
  return AdaptiveComponent;
}
