import React from 'react';
import { SpentTimeItemProps } from './SpentTimeItem';
import { Modal } from '@components/MUI/Modal/Modal';
import { FormWrapper } from '@components/Form/Form';
import { Button } from '@components/MUI/Button';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import { TimeItem } from '@store/tasks/contracts';
import styles from './DeleteTimeModal.module.scss';

export const DeleteTimeModal: React.FC<SpentTimeItemProps> = ({ timeItem }) => {
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const handleDelete = (timeItem: TimeItem) => {
    dispatch(closeModal());
  };

  return (
    <Modal>
      <FormWrapper label="Вы уверены, что хотите удалить затраченное время?" isThinTitle>
        <div className={styles.buttons}>
          <Button onClick={handleCancel} className={styles.buttons__cancel}>
            Отменить
          </Button>
          <Button onClick={() => handleDelete(timeItem)} variant="contained">
            Удалить
          </Button>
        </div>
      </FormWrapper>
    </Modal>
  );
};
