import React from 'react';
import { Outlet } from 'react-router-dom';
import { MainContentLayout } from '@layouts/MainContentLayout';
import { RequestNavigationBar } from '@layouts/requests/RequestNavigationBar';

export function RequestsLayout() {
  return (
    <MainContentLayout>
      <RequestNavigationBar />
      <Outlet />
    </MainContentLayout>
  );
}
