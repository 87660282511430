import React from 'react';
import { Tooltip } from '@mui/material';
import { ReactComponent as HelpIcon } from '@assets/img/help.svg';

type HelperProps = {
  title?: string;
};

export const Helper: React.FC<HelperProps> = ({ title }) => {
  return (
    <Tooltip title={title}>
      <HelpIcon />
    </Tooltip>
  );
};
