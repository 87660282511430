import { CardMechanism } from '@components/AboutMechanism/CardMechanism/CardMechanism';
import styles from './AboutMechanism.module.scss';
import client from '@assets/img/AboutMechanism/Сlient.svg';
import manager from '@assets/img/AboutMechanism/Manager.svg';
import developer from '@assets/img/AboutMechanism/Developer.svg';
import { Typography } from '@components/AboutInfo/AboutInfo';
import { Title } from '@components/AboutBenefits/AboutBenefits';

export function AboutMechanism() {
  return (
    <div className={styles.aboutMechanismBlock}>
      <div className={styles.container}>
        <div>
          <Title>
            <span>Механизм </span>работы
          </Title>
          <div className={styles.gridLayout}>
            <CardMechanism avatar={client} titleCard="Заказчик">
              <div className={styles.gap1}>
                <Typography>Размещение технического задания</Typography>
                <Typography>Подбор менеджера проекта</Typography>
                <Typography>
                  Отслеживание статуса выполнения проекта и отчетов с помощью сводки аналитики
                </Typography>
                <Typography>Приём результата проекта</Typography>
                <Typography>
                  Оплата готового проекта <sup>скоро</sup>
                </Typography>
              </div>
            </CardMechanism>
            <CardMechanism avatar={manager} titleCard="Менеджер проекта">
              <div className={styles.gap1}>
                <Typography>Принятие заявки ТЗ</Typography>
                <Typography>Подбор команды исполнителей</Typography>
                <Typography>Распределение задач в команде</Typography>
                <Typography>Контроль исполнения проекта</Typography>
                <Typography>Принятие результатов</Typography>
                <Typography>Отчет заказчику о выполнении проекта</Typography>
                <Typography>
                  Оплата работы исполнителей <sup>скоро</sup>
                </Typography>
              </div>
            </CardMechanism>
            <CardMechanism avatar={developer} titleCard="Исполнитель">
              <div className={styles.gap1}>
                <Typography>Выбор проекта и команды</Typography>
                <Typography>Получение задач от менеджера проекта</Typography>
                <Typography>Выполнение задач и отчёт по их актуальному статусу</Typography>
                <Typography>Отчет менеджеру проекта о выполненной работе</Typography>
                <Typography>
                  Получение оплаты <sup>скоро</sup>
                </Typography>
              </div>
            </CardMechanism>
          </div>
        </div>
      </div>
    </div>
  );
}
