import React from 'react';
import styles from './ChoiceYaCard.module.scss';
export interface ChoiceYaCardProps {
  title: string;
  description: string;
}

export const ChoiceYaCard: React.FC<ChoiceYaCardProps> = ({ title, description }) => {
  return (
    <>
      <div className={styles.choiceCard}>
        <div>
          <p className={styles.title}>{title}</p>
        </div>
        <div>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
    </>
  );
};
