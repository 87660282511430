import React, { useState } from 'react';
import { Paper, Tooltip } from '@mui/material';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { TimeProjectAnalytics } from '@store/dashboard/contracts';
import { Link } from 'react-router-dom';
import { ReactComponent as HelpIcon } from '@assets/img/help.svg';
import { useRequestBody } from '@hooks/useRequestBody';
import styles from './TimeExpensesAnalytics.module.scss';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { useGetTimeAnalyticsQuery } from '@store/dashboard/dashboard.slice';

export const TimeExpensesAnalytics: React.FC = () => {
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();

  const { data: projects } = useGetTimeAnalyticsQuery({
    limit: 5,
    start: ((page - 1) * limit) / 2,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <Paper elevation={0} variant="outlined" className={styles.container}>
      <h2 className={styles.title}>Аналитика временных затрат</h2>

      <AdaptiveTableMUI
        setRequestBody={setRequestBody}
        setSortColumn={setSortColumn}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        sortColumn={sortColumn}
        columns={{
          name: {
            label: 'Проект',
            filterInput: true,
            children: (value, row: TimeProjectAnalytics) => (
              <Link to={`/projects/${row.id}/details`}>{value}</Link>
            ),
          },
          spentTime: {
            label: 'Затраченное время',
            isSortColumn: true,
            format: (value) => (value === null ? `0 ч` : `${value} ч`),
            additionalItem: (
              <Tooltip
                title="Затраченное время - это время, суммарно потраченное на все задачи проекта"
                className={styles.question__icon}>
                <HelpIcon />
              </Tooltip>
            ),
          },
          remainingTime: {
            label: 'Оставшееся время',
            isSortColumn: true,
            format: (value) => (value === null ? `0 ч` : `${value} ч`),
            additionalItem: (
              <Tooltip
                title="Оставшееся время - это суммарное оставшееся время незакрытых задач проекта"
                className={styles.question__icon}>
                <HelpIcon />
              </Tooltip>
            ),
          },
        }}
        rows={projects?.data || []}>
        <TablePaginationMUI
          limit={5}
          count={projects?.totalCount}
          entityLabel="проектов"
          page={page}
          handleChangePage={(_, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          withoutChangingItemsCount={true}
        />
      </AdaptiveTableMUI>
    </Paper>
  );
};
