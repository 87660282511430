import { FormWrapper } from '@components/Form/Form';
import { Box, Divider, Modal } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useAppDispatch } from '@hooks/index';
import { closeModal, openModalError } from '@store/ui/ui.slice';
import { CreateOfferForm, CreateOfferFormProps } from './CreateOfferForm';
import styles from './CreateOffer.module.scss';
import { useCreateOfferMutation } from '@store/offer/offer.slice';
import { Link } from 'react-router-dom';

type CreateOfferProps = {
  executorName?: string;
  executorDirection?: string;
  executorStack?: string;
  executorPayment?: number | null;
  executorId: string;
  avatarInitials: string;
  icon: string;
};

export const CreateOfferModal: React.FC<CreateOfferProps> = ({
  executorName,
  avatarInitials,
  executorDirection,
  executorStack,
  executorPayment,
  executorId,
  icon,
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(true);
  const [createOffer] = useCreateOfferMutation();

  const handleSubmit: CreateOfferFormProps['submit'] = async (formValues) => {
    try {
      createOffer({
        fromManager: true,
        receiverId: executorId,
        vacancyId: formValues.vacancyId,
      }).then(() => {
        setOpen(false);
        dispatch(closeModal());
      });
    } catch (e: any) {
      dispatch(openModalError(e));
    }
  };

  const cancel = useCallback(() => {
    dispatch(closeModal());
    setOpen(false);
  }, [dispatch]);

  return (
    <Modal
      open={open}
      onClose={cancel}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box style={{ maxWidth: '480px', width: '100%', height: '90%', overflow: 'auto' }}>
        <FormWrapper label={'Отправка предложения'}>
          <div className={styles.executor}>
            {icon ? (
              <div>
                <img src={icon} alt="avatar" className={styles.executor__avatar} />
              </div>
            ) : (
              <div className={styles.executor__avatar}>{avatarInitials}</div>
            )}
            <Link className={styles.executor__name} to={`/userPage/${executorId}`} onClick={cancel}>
              {executorName}
            </Link>
          </div>
          <div className={styles.direction}>
            <div className={styles.direction__label}>Направление</div>
            <div className={styles.direction__value}>{executorDirection || 'Отсутствует'}</div>
          </div>
          <div className={styles.stack}>
            <div className={styles.stack__label}>Стек</div>
            <div className={styles.stack__value}>{executorStack || 'Отсутствует'}</div>
          </div>
          <div className={styles.payment}>
            <div className={styles.payment__label}>Стоимость часа работы</div>
            <div className={styles.payment__value}>
              {executorPayment ? `${executorPayment}` : 'Отсутствует'}
            </div>
          </div>

          <Divider style={{ marginBottom: '1.125rem' }} />
          <h5 className={styles.offer__title}>Предложение</h5>

          <CreateOfferForm cancel={cancel} submit={handleSubmit} />
        </FormWrapper>
      </Box>
    </Modal>
  );
};
