import { AbilityBuilder, createMongoAbility, MongoAbility } from '@casl/ability';
import { UserRole } from './contracts';

type Actions = 'create' | 'read' | 'update' | 'delete' | 'leave' | 'close';

type TeamSubjects =
  | 'TeamsTable'
  | 'VacancyResponses'
  | 'SpecialistsTable'
  | 'MyVacancies'
  | 'OfferedVacancies'
  | 'Vacancies'
  | 'Team'
  | 'VacanciesSection';

type DashboardSubjects =
  | 'AnalyticsRequest'
  | 'AnalyticsProjects'
  | 'InfoAboutVacancy'
  | 'InfoAboutTasks'
  | 'Dashboard'
  | 'InfoAboutPay';

type RequestSubjects =
  | 'MyAssignedRequests'
  | 'RequestList'
  | 'RequestsCreatedByMe'
  | 'Request'
  | 'ApprovedRequests';

type TaskSubjects = 'Task';

type ProjectSubjects = 'CustomerProjects' | 'ManagerProjects' | 'ExecutorProjects' | 'Projects';

type RequisiteSubjects = 'Requisite';

type Subjects =
  | TeamSubjects
  | DashboardSubjects
  | RequestSubjects
  | TaskSubjects
  | ProjectSubjects
  | RequisiteSubjects;

export type AppAbility = MongoAbility<[Actions, Subjects]>;

export function defineAbilityFor(roles: UserRole[]) {
  const { build, can } = new AbilityBuilder<AppAbility>(createMongoAbility);

  if (roles.find((role) => role.name === 'Менеджер')) {
    can('read', 'TeamsTable');
    can('read', 'VacancyResponses');
    can('read', 'SpecialistsTable');
    can('read', 'MyVacancies');
    can('read', 'AnalyticsRequest');
    can('read', 'InfoAboutTasks');
    can('read', 'InfoAboutPay');
    can('read', 'MyAssignedRequests');
    can('read', 'RequestList');
    can('read', 'RequestsCreatedByMe');
    can('update', 'Team');
    can('delete', 'Team');
    can('create', 'Team');
    can('close', 'Vacancies');
    can('create', 'Vacancies');
    can('read', 'ApprovedRequests');
    can('read', 'ManagerProjects');
    can('read', 'Projects');
    can('read', 'Dashboard');
    can('read', 'ApprovedRequests');
    can('create', 'Task');
    can('read', 'AnalyticsProjects');
    can('create', 'Requisite');
    can('read', 'VacanciesSection');
  }

  if (roles.find((role) => role.name === 'Исполнитель')) {
    can('read', 'TeamsTable');
    can('read', 'OfferedVacancies');
    can('read', 'Vacancies');
    can('read', 'InfoAboutVacancy');
    can('read', 'InfoAboutTasks');
    can('leave', 'Team');
    can('read', 'ExecutorProjects');
    can('read', 'Projects');
    can('read', 'Dashboard');
    can('create', 'Task');
    can('create', 'Requisite');
    can('read', 'VacanciesSection');
  }

  if (roles.find((role) => role.name === 'Заказчик')) {
    can('read', 'AnalyticsRequest');
    can('read', 'AnalyticsProjects');
    can('read', 'InfoAboutPay');
    can('read', 'MyAssignedRequests');
    can('read', 'RequestList');
    can('read', 'RequestsCreatedByMe');
    can('create', 'Request');
    can('update', 'Request');
    can('read', 'CustomerProjects');
    can('read', 'Projects');
    can('read', 'Dashboard');
    can('update', 'Request');
    can('create', 'Requisite');
  }

  return build();
}
