import React from 'react';

import { Outlet } from 'react-router-dom';
import { CloudHeader } from '@layouts/cloud/CloudHeader';

const CloudsLayout = () => {
  return (
    <div className="col container-content">
      <CloudHeader />
      <Outlet />
    </div>
  );
};

export { CloudsLayout };
