import { InputAdornment, Paper, TextField } from '@mui/material';
import React, { useState } from 'react';
import { FavoriteMessageItem } from './FavoriteMessageItem';
import { useGetFavoriteMessagesQuery } from '@store/messages/messages.service';
import { ReactComponent as SearchGray } from '@assets/img/searchGray.svg';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import styles from './FavorieMessages.module.scss';
import { useDebounce } from '@hooks/useDebounce';

export const FavoriteMessages: React.FC = () => {
  const [filterText, setFilterText] = useState('');
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const debouncedFilter = useDebounce(filterText);
  const { data: favoriteMessages, refetch } = useGetFavoriteMessagesQuery(
    {
      limit: limit,
      sortOrder: 'asc',
      start: (page - 1) * limit,
      text: {
        searchType: 'LIKE',
        sort: false,
        value: debouncedFilter,
      },
    },
    { refetchOnMountOrArgChange: true },
  );

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handleLastMessage = (totalCount: number, index: number) => {
    if (totalCount === index + 1) return true;
    if (limit === 10 && index + 1 === 10) return true;
    if (limit === 10 && totalCount > 10 && index + 1 === totalCount) return true;
    if (limit > 10 && index + 1 === totalCount) return true;
    if (limit === 30 && totalCount > 30 && index + 1 === 30) return true;
    return false;
  };

  return (
    <>
      <Paper elevation={0} variant="outlined" className={styles.wrapper}>
        <TextField
          size="small"
          placeholder="Поиск"
          className={styles.search__message}
          onChange={(e) => setFilterText(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchGray />
              </InputAdornment>
            ),
          }}
        />
        <ul className={styles.message__list}>
          {favoriteMessages?.data.map((message, index) => (
            <FavoriteMessageItem
              key={message.id}
              id={message.id}
              authorName={message.author.userName}
              autorId={message.author.userId}
              messageText={message.text}
              sendDate={message.date}
              refetch={refetch}
              isLastMessage={handleLastMessage(favoriteMessages.totalCount, index)}
              totalMessages={favoriteMessages.totalCount}
              chatId={message.chatId}
            />
          ))}
        </ul>
      </Paper>
      <TablePaginationMUI
        limit={limit}
        count={favoriteMessages?.totalCount ? favoriteMessages.totalCount : 10}
        entityLabel="сообщений"
        page={page}
        handleChangePage={(_, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};
