import React from 'react';
import { FieldArray, Form, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

export type Props = {
  value: Participants;
  submit: (value: Participants) => void;
  managerId: string | null;
};

export type Participants = {
  participants: Array<{
    userName: string;
    positionName: string;
    telegram?: string;
    userId?: string;
  }>;
};

const Schema = Yup.object({
  participants: Yup.array().of(
    Yup.object().shape({
      userName: Yup.string().required('Поле обязательно для заполнения'),
      positionName: Yup.string().required('Поле обязательно для заполнения'),
      telegram: Yup.string().required('Поле обязательно для заполнения'),
    }),
  ),
});

const FormComponent: React.FC<Props> = () => {
  const form = useFormikContext<Participants>();

  return (
    <Form onSubmit={form.handleSubmit} className={''}>
      <div className="display-flex flex-direction-column w-100">
        <div className="form-table-container">
          <table className="form-table w-700">
            <thead>
              <tr>
                <th>Исполнитель</th>
                <th>Роль</th>
                <th>Ник в Telegram</th>
              </tr>
            </thead>
            <tbody>
              <FieldArray
                name="participants"
                render={() => (
                  <>
                    {form.values.participants.length > 0 ? (
                      form.values.participants.map((participant, index) => (
                        <tr key={index}>
                          <td>
                            {participant.userName ? (
                              <Link
                                to={`/userPage/${participant.userId}`}
                                style={{ color: '#199B3E' }}>
                                {participant.userName}
                              </Link>
                            ) : (
                              'Не назначено'
                            )}
                          </td>
                          <td>{participant.positionName}</td>
                          <td>{participant.telegram ? participant.telegram : '-'}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    )}
                  </>
                )}
              />
            </tbody>
          </table>
        </div>
      </div>
    </Form>
  );
};

export const ParticipantsForm = withFormik<Props, Participants>({
  displayName: 'ParticipantsForm',
  mapPropsToValues: (props) => {
    return {
      participants: props.value.participants,
    };
  },
  enableReinitialize: true,
  validationSchema: Schema,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values);
  },
})(FormComponent);
