import React from 'react';
import { Checkbox, FormControlLabel, SelectChangeEvent } from '@mui/material';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import { InputField } from '@components/MUI/InputField/InputField';
import { Select } from '@components/MUI/Select';
import { DragAndDrop } from '@components/ui/DranAndDrop/DragAndDrop';
import { Button } from '@components/MUI/Button';
import { DictionaryName } from '@store/dictionary/contracts';
import { useDictionary } from '@hooks/useDictionary';
import { fromDictionaryToSelectOption } from '@components/ui/Select/Select';
import { Role } from '@store/user/contracts';
import * as Yup from 'yup';
import styles from './EditRequestForm.module.scss';
import { useAppSelector } from '@hooks/index';
import { selectDictionary } from '@store/dictionary/dictionary.slice';
import { YfmEditor } from '@components/ui/YfmEditor/YfmEditor';
import { useGetFavoriteProfilesQuery } from '@store/user/user.slice';

export type CreateRequestValue = {
  name: string;
  deadLine: string;
  description: string;
  budget: number;
  currencyId: string;
  url: string;
  stack: string;
  tag?: string;
  requirement: string;
  task: string;
  headId: string | undefined;
  managerNeed: boolean;
  attachments: string[];
  projectTypeId: string;
  id: string;
  visibilityStatusId: string;
};

export type CreateRequestProps = {
  value: CreateRequestValue;
  submit: (
    value: CreateRequestValue,
    form: FormikBag<CreateRequestProps, CreateRequestValue>,
  ) => void;
};

const Schema = Yup.object({
  name: Yup.string().required('Поле обязательно для заполнения'),
  projectTypeId: Yup.string().required('Поле обязательно для заполнения'),
  visibilityStatusId: Yup.string().required('Поле обязательно для заполнения'),
  deadLine: Yup.string().required('Поле обязательно для заполнения'),
  description: Yup.string().required('Поле обязательно для заполнения'),
  budget: Yup.string().required('Поле обязательно для заполнения'),
  tag: Yup.string().optional(),
  currencyId: Yup.string().required('Поле обязательно для заполнения'),
  attachments: Yup.array(),
  stack: Yup.string().required('Поле обязательно для заполнения'),
  requirement: Yup.string().required('Поле обязательно для заполнения'),
  task: Yup.string().required('Поле обязательно для заполнения'),
  headId: Yup.string(),
  managerNeed: Yup.boolean(),
});

export const FormComponent: React.FC = () => {
  const selectCurrencies = useDictionary(DictionaryName.CURRENCY, fromDictionaryToSelectOption);
  const selectProjectTypes = useDictionary(
    DictionaryName.PROJECT_TYPE,
    fromDictionaryToSelectOption,
  );
  const selectVisibilityStatuses = useDictionary(
    DictionaryName.VISIBILITY_STATUS,
    fromDictionaryToSelectOption,
  );
  const form = useFormikContext<CreateRequestValue>();
  form.validationSchema = Schema;
  const managerRoleId = useAppSelector(selectDictionary(DictionaryName.ROLE)).find(
    (role) => role.name === Role.Manager,
  )?.id;

  const { data: managers } = useGetFavoriteProfilesQuery({
    start: 0,
    sortOrder: 'desc',
    limit: 50,
    roleId: {
      value: managerRoleId,
      sort: false,
    },
  });

  return (
    <Form className={styles.edit__form} onSubmit={form.handleSubmit}>
      <InputField
        name="name"
        placeholder="Название проекта"
        type="text"
        className={styles.form__item}
      />
      <Select
        values={selectProjectTypes}
        name={'projectTypeId'}
        label={'Тип проекта'}
        onChange={(event: SelectChangeEvent) => {
          form.setFieldValue('projectTypeId', event.target.value);
        }}
        value={form.values.projectTypeId}
        isOutlined={true}
        className={styles.form__item}
      />
      <Select
        values={selectVisibilityStatuses}
        name={'visibilityStatusId'}
        onChange={(event: SelectChangeEvent) => {
          form.setFieldValue('visibilityStatusId', event.target.value);
        }}
        value={form.values.visibilityStatusId}
        label="Доступность"
        isOutlined={true}
        className={styles.form__item}
      />
      <InputField
        name="deadLine"
        placeholder="Дедлайн проекта"
        type="datetime-local"
        className={styles.form__item}
      />
      <div className="mb-4">
        <YfmEditor name="description" />
      </div>
      <InputField name="tag" placeholder="Тег" type="text" className={styles.form__item} />
      <InputField
        name="budget"
        placeholder="Бюджет проекта"
        type="number"
        className={styles.form__item}
      />
      <Select
        values={selectCurrencies}
        name={'currencyId'}
        label={'Валюта'}
        onChange={(event: SelectChangeEvent) => {
          form.setFieldValue('currencyId', event.target.value);
        }}
        value={form.values.currencyId}
        isOutlined={true}
        className={styles.form__item}
      />
      <DragAndDrop name={'attachments'} tag={'REQUEST'} />
      <InputField name="stack" placeholder="Стек технологий" type="text" className={styles.stack} />
      <InputField
        name="requirement"
        placeholder="Требования к разработке"
        multiline={true}
        rows={4}
        type="text"
        className={styles.form__item}
      />
      <InputField
        name="task"
        placeholder="Что необходимо сделать"
        multiline={true}
        rows={4}
        type="text"
        className={styles.form__item}
      />
      <Select
        values={
          managers?.data?.map((manager) => ({
            value: manager.id,
            label: `${manager.about?.firstName} ${manager.about?.lastName}`,
          })) || []
        }
        name={'headId'}
        label={'Руководитель проекта'}
        onChange={(event: SelectChangeEvent) => {
          form.setFieldValue('headId', event.target.value);
        }}
        isOutlined={true}
        className={styles.form__item}
      />
      <FormControlLabel
        control={<Checkbox name="managerNeed" className={styles.checkbox} />}
        label="Руководитель проекта не требуется"
        onChange={form.handleChange}
        value={form.values.managerNeed}
        checked={form.values.managerNeed}
      />
      <Button
        size="large"
        variant="contained"
        className={styles.button__save}
        type="submit"
        disabled={!form.isValid}>
        Сохранить
      </Button>
      <p className={styles.form__description}>* обозначены поля обязательные для заполнения</p>
    </Form>
  );
};

export const EditRequestForm = withFormik<CreateRequestProps, CreateRequestValue>({
  displayName: 'CreateRequestForm',
  mapPropsToValues: (props) => {
    return {
      name: props.value.name,
      deadLine: props.value.deadLine,
      description: props.value.description,
      tag: props.value.tag,
      budget: props.value.budget,
      currencyId: props.value.currencyId,
      url: props.value.url,
      stack: props.value.stack,
      requirement: props.value.requirement,
      task: props.value.task,
      headId: props.value.headId,
      managerNeed: props.value.managerNeed,
      attachments: props.value.attachments,
      projectTypeId: props.value.projectTypeId,
      visibilityStatusId: props.value.visibilityStatusId,
      id: props.value.id,
    };
  },
  isInitialValid: true,
  enableReinitialize: true,
  validationSchema: Schema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
