import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetAvailablePositionsResponse, TeamItem, TeamListResponse } from './contracts';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';
import { PathParameters, RequestBody, ResponseBody } from '@store/utility';
import { api } from '@store/api-client';
import { enqueueSnackbar } from 'notistack';

type TeamState = {
  teams: TeamItem[];
  team: TeamItem | null;
};

const initialState: TeamState = {
  teams: [],
  team: null,
};

type CreateActionPayload = {
  team: TeamItem;
};

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    create: (state, action: PayloadAction<CreateActionPayload>) => {
      state.teams = [...state.teams, action.payload.team];
    },
    update: (state, action: PayloadAction<CreateActionPayload>) => {
      const updatedTeam = action.payload.team;

      let index = state.teams.findIndex((team) => team.id === updatedTeam.id);
      if (~index) {
        state.teams[index] = updatedTeam;
      }
    },
    remove: (state, action: PayloadAction<string>) => {
      const deletedTeam = action.payload;

      let index = state.teams.findIndex((team) => team.id === deletedTeam);
      if (~index) {
        delete state.teams[index];
      }
    },
    setTeams: (state, action: PayloadAction<{ teams: TeamItem[] }>) => {
      state.teams = [...action.payload.teams];
    },
    setTeam: (state, action: PayloadAction<TeamItem>) => {
      state.team = action.payload;
    },
  },
});
export const { create, update, remove, setTeams, setTeam } = teamSlice.actions;
export const teamReducer = teamSlice.reducer;

export const teamApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createTeam: builder.mutation<
      ResponseBody<Api.operations['createTeam']>,
      RequestBody<Api.operations['createTeam']>
    >({
      query: (request) => ({
        method: 'POST',
        url: 'api/team',
        body: {
          name: request.name,
          teamTypeId: request.teamTypeId,
          telegramUrl: request.telegramUrl,
          positions: request.positions,
        },
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Команда создана', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['TeamsList'],
    }),

    saveTeam: builder.mutation<
      ResponseBody<Api.operations['saveTeam']>,
      RequestBody<Api.operations['saveTeam']>
    >({
      query: (request) => ({
        method: `PUT`,
        url: `api/team`,
        body: request,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Команда отредактирована', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['TeamDetail', 'TeamsList'],
    }),

    updateTeam: builder.mutation<
      ResponseBody<Api.operations['updateTeam']>,
      RequestBody<Api.operations['updateTeam']>
    >({
      query: (request) => ({
        method: `PATCH`,
        url: `api/team`,
        body: request,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Участники команды изменены', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['TeamDetail', 'TeamsList', 'TeamAvailablePositions'],
    }),

    removeParticipantTeam: builder.mutation<
      ResponseBody<Api.operations['deleteParticipant']>,
      {
        teamId: PathParameters<Api.operations['deleteParticipant'], 'teamId'>;
        participantId: PathParameters<Api.operations['deleteParticipant'], 'participantId'>;
      }
    >({
      query: ({ teamId, participantId }) => ({
        method: `DELETE`,
        url: `/api/team/${teamId}/participant/${participantId}`,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Участник команды исключен', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['TeamDetail', 'TeamsList', 'TeamAvailablePositions'],
    }),

    setFavoriteTeam: builder.mutation<
      ResponseBody<Api.operations['changeFavoriteStatus_1']>,
      {
        requestBody: RequestBody<Api.operations['changeFavoriteStatus_1']>;
        teamId: PathParameters<Api.operations['changeFavoriteStatus_1'], 'id'>;
      }
    >({
      query: ({ requestBody, teamId }) => ({
        method: `PUT`,
        url: `/api/team/${teamId}/favorite`,
        body: requestBody,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Избранное команд обновлено', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['TeamDetail', 'TeamsList', 'FavoriteTeams'],
    }),

    leaveTeam: builder.mutation<
      ResponseBody<Api.operations['leaveTeam']>,
      { id: PathParameters<Api.operations['leaveTeam'], 'id'> }
    >({
      query: ({ id }) => ({
        method: `POST`,
        url: `api/team/${id}/leave`,
        body: { id },
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Команда успешно покинута', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['TeamDetail', 'TeamsList'],
    }),

    getTeamList: builder.query<TeamListResponse, RequestBody<Api.operations['getTeams']>>({
      query: (request) => ({
        method: 'POST',
        url: 'api/team/list',
        body: request,
      }),
      transformResponse: (response) => {
        return plainToInstance(TeamListResponse, response);
      },
      providesTags: ['TeamsList'],
    }),

    getFavoriteTeams: builder.query<
      TeamListResponse,
      RequestBody<Api.operations['getFavoriteTeams']>
    >({
      query: (requestBody) => ({
        url: '/api/team/favorite',
        method: 'POST',
        body: requestBody,
      }),
      transformResponse: (response: ResponseBody<Api.operations['getFavoriteTeams']>) => {
        return plainToInstance(TeamListResponse, response);
      },
      providesTags: ['FavoriteTeams'],
    }),

    getAvailablePositions: builder.query<
      GetAvailablePositionsResponse[],
      { id: PathParameters<Api.operations['getFreeTeamPositions'], 'id'> }
    >({
      query: ({ id }) => ({
        url: `api/team/${id}/positions`,
        method: 'GET',
      }),
      providesTags: ['TeamAvailablePositions'],
    }),

    teamRemoving: builder.mutation<
      ResponseBody<Api.operations['deleteTeam']>,
      { id: PathParameters<Api.operations['deleteTeam'], 'id'> }
    >({
      query: ({ id }) => ({
        url: `api/team/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Команда удалена', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['TeamsList'],
    }),

    loadTeam: builder.query<TeamItem, PathParameters<Api.operations['getTeam'], 'id'>>({
      query: (teamId) => ({
        url: `/api/team/${teamId}`,
        method: 'GET',
      }),
      transformResponse: (response: ResponseBody<Api.operations['getTeam']>) => {
        return plainToInstance(TeamItem, response);
      },
      providesTags: ['TeamDetail'],
    }),
  }),
});

export const {
  useCreateTeamMutation,
  useSaveTeamMutation,
  useUpdateTeamMutation,
  useSetFavoriteTeamMutation,
  useLeaveTeamMutation,
  useGetTeamListQuery,
  useGetFavoriteTeamsQuery,
  useGetAvailablePositionsQuery,
  useLazyGetAvailablePositionsQuery,
  useTeamRemovingMutation,
  useLoadTeamQuery,
  useRemoveParticipantTeamMutation,
} = teamApi;
