import { plainToInstance } from 'class-transformer';
import { ApiClient } from '../api-client';
import {
  CreatingVacancyRequest,
  CreatingVacancyResponse,
  VacancyResponse,
  Vacancy,
  GetMyOffersResponse,
  GetMyVacanciesResponse,
  GetVacanciesResponse,
  EditVacancyRequest,
  EditVacancyResponse,
} from './contracts';
import Api from '@api-schema';
import { enqueueSnackbar } from 'notistack';

export const setFavoriteVacancy = async (
  requestBody: Api.operations['changeFavoriteStatus']['requestBody']['content']['application/json'],
  vacancyId: Api.operations['changeFavoriteStatus']['parameters']['path']['id'],
) => {
  const { data } = await ApiClient.put<
    Api.operations['changeFavoriteStatus']['responses']['200']['content']['application/json']
  >(`/api/vacancy/${vacancyId}/favorite`, requestBody);

  enqueueSnackbar(`Избранное вакансии успешно обновлено`, {
    variant: 'success',
  });
  return data;
};

export const getVacancy = async (vacancyId: string) => {
  const { data } = await ApiClient.get<
    Api.operations['getVacancy']['responses']['200']['content']['application/json']
  >(`api/vacancy/${vacancyId}`);
  return plainToInstance(Vacancy, data);
};

export const createVacancy = async (request: CreatingVacancyRequest) => {
  const { data } = await ApiClient.post<CreatingVacancyResponse>('api/vacancy', request);
  return data;
};

export const getVacancyResponses = async (
  request: Api.operations['getManagerOwnVacancies']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getManagerOwnVacancies']['responses']['200']['content']['application/json']
  >('/api/vacancy/manager/own', request);
  return plainToInstance(VacancyResponse, data);
};

export const getMyVacancies = async (
  request: Api.operations['getManagerOwnVacancies']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getManagerOwnVacancies']['responses']['200']['content']['application/json']
  >('api/vacancy/manager', request);
  return plainToInstance(GetMyVacanciesResponse, data);
};

export const getFavoriteVacancies = async (
  requestBody: Api.operations['getFavoriteVacancies']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getFavoriteVacancies']['responses']['200']['content']['application/json']
  >('/api/vacancy/favorite', requestBody);
  return plainToInstance(GetMyVacanciesResponse, data);
};

export const getVacancies = async (
  request: Api.operations['getExecutorVacancies']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getExecutorVacancies']['responses']['200']['content']['application/json']
  >('/api/vacancy/executor', request);
  return plainToInstance(GetVacanciesResponse, data);
};

export const getMyOffers = async (
  request: Api.operations['getExecutorOwnVacancies']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getExecutorOwnVacancies']['responses']['200']['content']['application/json']
  >('api/vacancy/executor/own', request);
  return plainToInstance(GetMyOffersResponse, data);
};

export const editVacancy = async (requestBody: EditVacancyRequest) => {
  const { data } = await ApiClient.post<EditVacancyResponse>(`/api/vacancy/edit`, requestBody);
  return data;
};
export const closeVacancy = async (
  id: Api.operations['closeVacancy']['parameters']['path']['id'],
) => {
  const { data } = await ApiClient.patch<
    Api.operations['closeVacancy']['responses']['200']['content']['application/json']
  >(`/api/vacancy/${id}`, id);
  return data;
};
