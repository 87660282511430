import React, { useCallback } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';

export const ModalError = ({ errorMessage }: any) => {
  const dispatch = useAppDispatch();
  const cancel = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <div>
      <Modal show={true}>
        <ModalBody>
          <h6 className="fw-bold">Произошла ошибка</h6>
          <div className="d-flex pt-2 pb-5">{errorMessage}</div>
          <div className="d-flex justify-content-end">
            <button onClick={cancel} className="btn btn-cyan">
              Понятно
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
