import { useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Button } from '@components/MUI/Button';
import { PasswordInput } from '@components/MUI/PasswordInput';

export type PasswordFormProps = {
  value: PasswordFormValue;
  submit: (value: PasswordFormValue) => void;
};

export type PasswordFormValue = {
  password: string;
  password_repeat: string;
};

const PasswordFormSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Придумайте пароль, в котором содержится от 8 символов')
    .required('Пароль обязателен для заполнения')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$#@*&()!%?]).*$/,
      'Пароль должен содержать минимум одну заглавную букву, одну строчную букву, одну цифру и один из символов $, #, @, *, &, !, (, ), %, ?',
    ),
  password_repeat: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
    .min(8, 'Придумайте пароль, в котором содержится от 8 символов')
    .required('Пароль обязателен для заполнения'),
});

const FormComponent: React.FC = () => {
  const passwordForm = useFormikContext<PasswordFormValue>();

  return (
    <Form onSubmit={passwordForm.handleSubmit}>
      <div className="display-flex flex-direction-column">
        <PasswordInput
          name="password"
          type="password"
          className="w-100 mt-2 mb-2"
          placeholder="Пароль"
        />
        <div className="mb-4">Придумайте пароль, в котором содержится от 8 символов</div>
        <PasswordInput
          name="password_repeat"
          type="password"
          className="w-100 mb-5"
          placeholder="Повторите пароль"
        />
      </div>
      <div>
        <Button
          variant="contained"
          size="large"
          className="w-100"
          type="submit"
          disabled={!passwordForm.isValid}>
          Продолжить
        </Button>
      </div>
    </Form>
  );
};

export const ResettingFormStepThree = withFormik<PasswordFormProps, PasswordFormValue>({
  displayName: 'PasswordForm',
  mapPropsToValues: (props) => {
    return {
      password: props.value.password,
      password_repeat: props.value.password_repeat,
    };
  },
  enableReinitialize: true,
  validationSchema: PasswordFormSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values);
  },
})(FormComponent);
