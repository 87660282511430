import React from 'react';

export const EditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_8616_17979)">
        <path
          d="M15 3.5L12.5 1L2.5 11L1.5 14.5L5 13.5L15 3.5ZM10.5 3L13 5.5L10.5 3ZM2.5 11L5 13.5L2.5 11Z"
          stroke="#199B3E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8616_17979">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
