import styles from './Balance.module.scss';
import { useNavigate } from 'react-router-dom';
import { BalanceNavigation } from './BalanceNavigation';
import { Button } from '@components/MUI/Button';
import { Can } from '@access-control';

export const BalanceHeader = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.ws_header}>
      <BalanceNavigation />

      <Can I="create" a="Requisite">
        <div className={styles.button__container}>
          <Button
            title="Добавить реквизиты"
            startIcon={
              <svg
                className="icon"
                width="20"
                height="20"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  className="icon"
                  d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                  fill="white"
                />
              </svg>
            }
            onClick={() => navigate('/balance/requisites/new')}
            variant="contained"
            className={styles.add_button}>
            Добавить реквизиты
          </Button>
        </div>
      </Can>
    </div>
  );
};
