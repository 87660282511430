import styles from './components/Requisite.module.scss';
import { Card } from 'react-bootstrap';
import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import { useCreateRequisiteMutation } from '@store/requisite/requisite.slice';
import { useNavigate } from 'react-router-dom';
import { RequisiteForm, RequisiteFormValue } from './components/RequisiteForm';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@mui/material';
import React from 'react';

const BalanceCreateRequisite = () => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [createRequisite, { isLoading }] = useCreateRequisiteMutation();

  const submitRequisite = async (value: RequisiteFormValue) => {
    await createRequisite({
      accountNumber: value.accountNumber,
      bankName: value.bankName,
      bik: value.bik?.toString() || '',
      corporateAccount: value.corporateAccount,
      inn: value.inn?.toString() || '',
      kpp: value.kpp?.toString() || '',
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar('Реквизиты успешно созданы', { variant: 'success' });
      });
    navigate('/balance');
  };
  return (
    <div className={'col'}>
      <NavigationBack />
      <Card className={styles.requisites_form}>
        <div className={'row'}>
          <div className={styles.requisites_block}>
            <h4 className={'col-12 col-md-2 mt-0 form-title'}>Новые реквизиты</h4>
            <div className="col-12 col-md-5">
              {isLoading ? (
                <CircularProgress />
              ) : (
                <RequisiteForm
                  value={{
                    accountNumber: '',
                    bankName: '',
                    bik: null,
                    corporateAccount: '',
                    inn: null,
                    kpp: null,
                  }}
                  submit={submitRequisite}
                />
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export { BalanceCreateRequisite };
