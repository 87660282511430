import type { AnyAction, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejected } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';

const ignoredErrors: Set<string> = new Set(['ConditionError']);

function isNotAuth(action: AnyAction) {
  return (
    action['payload'] === undefined ||
    action['payload']['status'] === undefined ||
    action['payload']['status'] !== 401
  );
}

function isNotificationErrorAction(action: AnyAction) {
  return isRejected(action) && !ignoredErrors.has(action?.error.name || '') && isNotAuth(action);
}

function isRtkErrorAction(action: AnyAction) {
  return action.type.startsWith('api');
}

function extractErrorMessage(action: AnyAction) {
  if (isRtkErrorAction(action)) {
    return action['payload']['data']['message'] || action['payload']['data']['code'];
  }
  return action.error.message;
}

export const errorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action: AnyAction) => {
  if (isNotificationErrorAction(action)) {
    const message = extractErrorMessage(action);
    enqueueSnackbar(message, {
      variant: 'error',
    });
  }

  return next(action);
};
