import { FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { Form } from 'react-bootstrap';
import { InputField } from '@components/MUI/InputField/InputField';
import { DragAndDrop } from '@components/ui/DranAndDrop/DragAndDrop';
import styles from './support.module.scss';
import { Button } from '@components/MUI/Button';

export type SupportFormProps = {
  value: SupportFormValue;
  submit: (value: SupportFormValue, form: FormikBag<SupportFormProps, SupportFormValue>) => void;
};

export type SupportFormValue = {
  firstName: string;
  email: string;
  supportFiles: string[];
  comment?: string;
};

const SupportFormSchema = Yup.object({
  firstName: Yup.string()
    .required('Поле обязательно для заполнения')
    .matches(/^[^\d]+$/, 'Имя не должно содержать цифры'),
  comment: Yup.string().required('Поле обязательно для заполнения'),
  email: Yup.string().email('Введите E-mail').required('Поле обязательно для заполнения'),
});

const FormComponent = () => {
  const SupportForm = useFormikContext<SupportFormValue>();
  SupportForm.validationSchema = SupportFormSchema;

  return (
    <Form onSubmit={SupportForm.handleSubmit}>
      <div className="d-flex flex-column gap-4">
        <InputField name="firstName" type="text" placeholder="Имя" />
        <InputField name="email" type="email" placeholder="Email" />
        <DragAndDrop name="supportFiles" tag="REQUEST" />
        <InputField
          name="comment"
          type="textarea"
          rows={4}
          multiline
          placeholder="Текст сообщения"
        />
      </div>
      <div className={styles.btnBlock}>
        <Button variant="contained" className="w-100" type="submit" disabled={!SupportForm.isValid}>
          Отправить
        </Button>
      </div>
    </Form>
  );
};

export const SupportForm = withFormik<SupportFormProps, SupportFormValue>({
  displayName: 'SupportForm',
  mapPropsToValues: (props) => {
    return {
      firstName: props.value.firstName,
      email: props.value.email,
      supportFiles: props.value.supportFiles,
      comment: props.value.comment,
    };
  },
  enableReinitialize: true,
  validationSchema: SupportFormSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
