import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { Project } from '@store/project/contracts';
import { Dayjs } from 'dayjs';
import { StatusLabel, statusColors } from '@components/ui/StatusLabel/StatusLabel';
import { RequestParams } from '@components/Table/TableHeadCell';
import { AddProjectButton } from './buttons/AddProjectButton';
import { useRequestBody } from '@hooks/useRequestBody';
import { useAppSelector } from '@hooks/index';
import { selectCurrentUserId } from '@store/user/user.slice';
import { useLoadTeamQuery } from '@store/team/team.slice';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { useGetManagerProjectsQuery } from '@store/project/project.slice';

function projectRequestBody(teamId: string | undefined) {
  const projectRequestBody: Record<string, RequestParams> = {
    teamId: {
      value: teamId,
      searchType: 'LIKE',
      sort: false,
    },
  };
  return projectRequestBody;
}

export const TeamPageDetailProjects: React.FC = () => {
  const { teamId } = useParams();
  const myId = useAppSelector(selectCurrentUserId);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody(projectRequestBody(teamId));

  const { data: projects } = useGetManagerProjectsQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });
  const { data: team } = useLoadTeamQuery(teamId || '');

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(0);
  };

  return (
    <>
      {team?.teamLead?.userId === myId ? <AddProjectButton teamId={teamId || ''} /> : <></>}
      <AdaptiveTableMUI
        setRequestBody={setRequestBody}
        setSortColumn={setSortColumn}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        sortColumn={sortColumn}
        emptyLabel="У вас нет проектов"
        filter={projectRequestBody(teamId)}
        columns={{
          name: {
            label: 'Проект',
            children: (value, row: Project) => (
              <NavLink to={`/projects/${row.id}/details`}>{value}</NavLink>
            ),
            filterInput: true,
            isSortColumn: true,
          },
          teamPositions: {
            label: 'Участники',
            format: (value: [] | null) => (value?.length ? value.length : 0),
            filterInput: true,
            isSortColumn: true,
          },
          deadLine: {
            label: 'Сдача отчета',
            format: (value: Dayjs) => value.format('DD.MM.YY'),
            filterInput: true,
            isSortColumn: true,
          },
          projectStatusName: {
            label: 'Статус',
            children: (value) => <StatusLabel color={statusColors.get(value)} text={value} />,
            filterInput: true,
            isSortColumn: true,
          },
        }}
        rows={projects?.data || []}>
        <TablePaginationMUI
          limit={limit}
          count={projects?.totalCount}
          entityLabel="проектов"
          page={page}
          handleChangePage={(_, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </AdaptiveTableMUI>
    </>
  );
};
