import React from 'react';
import { ChatList } from './ChatList';
import { SearchChat } from './SearchChat';
import { Outlet } from 'react-router-dom';

export const MessagesLayout: React.FC = () => {
  return (
    <div className="col container-content">
      <div className="row border-1 border m-4 bg-white rounded-1" style={{ height: '765px' }}>
        <div className="col-sm-12 col-lg-4">
          <SearchChat />
          <ChatList />
        </div>
        <Outlet />
      </div>
    </div>
  );
};
