import React, { useMemo } from 'react';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';

const StyledCount = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 42,
  fontWeight: 700,
}));

const StyledText = styled('text')(() => ({
  fill: '#78858B',
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 14,
  fontWeight: 500,
}));

type PieCenterLabelProps = {
  count: number;
  label: string;
};

export const PieCenterLabel: React.FC<PieCenterLabelProps> = ({ count, label }) => {
  const { width, height, left, top } = useDrawingArea();

  const countPosition = useMemo(() => {
    const x = left + width / 2;
    const y = top + height / 2.2;
    return {
      x: x,
      y: y,
    };
  }, [width, height, left, top]);

  const labelPosition = useMemo(() => {
    const x = left + width / 2;
    const y = top + height / 1.6;
    return {
      x: x,
      y: y,
    };
  }, [width, height, left, top]);

  return (
    <>
      <StyledCount x={countPosition.x} y={countPosition.y}>
        {count}
      </StyledCount>
      <StyledText x={labelPosition.x} y={labelPosition.y}>
        {label}
      </StyledText>
    </>
  );
};
