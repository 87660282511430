import React from 'react';
import { InputField, InputFieldProps } from './InputField/InputField';
import { PasswordVisibilityController } from './PasswordVisibilityController';

export const PasswordInput: React.FC<InputFieldProps> = ({
  name,
  placeholder,
  className,
  ...props
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <InputField
      {...props}
      name={name}
      className={className}
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <PasswordVisibilityController
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
          />
        ),
      }}
      type={showPassword ? 'text' : 'password'}></InputField>
  );
};
