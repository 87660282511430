import React from 'react';
import { Outlet } from 'react-router-dom';
import { ProfileHeader } from '@components/ProfileHeader/ProfileHeader';
import styles from '@pages/profile/MyProfile.module.scss';

const ProfileLayout = () => {
  return (
    <div className="col container-content">
      <div className={styles.profile__content}>
        <ProfileHeader />
        <Outlet />
      </div>
    </div>
  );
};

export { ProfileLayout };
