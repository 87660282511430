import { LineChart } from '@mui/x-charts';
import styles from './TaskBurndownChart.module.scss';

interface ITaskBurndownChart {
  realData: number[];
  idealData: number[];
  xAxisData: string[];
}

export const TaskBurndownChart = ({ realData, idealData, xAxisData }: ITaskBurndownChart) => {
  return (
    <>
      <div className={styles.yLabel}>
        <p>Кол-во часов</p>
      </div>
      <LineChart
        height={300}
        slotProps={{
          legend: {
            hidden: true,
          },
        }}
        xAxis={[
          {
            scaleType: 'point',
            data: xAxisData,
            label: 'Дни периода',
          },
        ]}
        sx={{
          '.MuiLineElement-series-idealData': {
            strokeDasharray: '5 5',
          },
          '.MuiChartsAxis-label': {
            text: {
              fill: '#78858B',
              fontSize: '14px',
              lineHeight: '18px',
              fontWeight: '500',
              fontFamily: 'Roboto, sans-serif',
            },
          },
          '.MuiChartsAxis-tick': {
            display: 'none',
          },
          '.MuiChartsAxis-line': {
            stroke: '#DCDCDC',
          },
          '.MuiChartsAxis-tickLabel': {
            fill: '#78858B',
          },
        }}
        series={[
          {
            data: realData,
            label: 'Реальный ход работы по задачам',
            id: 'realData',
            color: '#EEBA00',
          },
          {
            data: idealData,
            label: 'Идеальный ход работы над задачами',
            id: 'idealData',
            color: '#189CED',
          },
        ]}
      />
    </>
  );
};
