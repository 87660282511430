import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { Button } from '@components/MUI/Button';
import bgPremiumRate from '@assets/img/rates/Premium.png';
import styles from '../Rates.module.scss';
import { useAppDispatch } from '@hooks/index';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { ConfirmDeleteModal } from '@components/MUI/ConfirmDeleteModal/ConfirmDeleteModal';
import { Helper } from '@components/ui/Helper';

interface rateOption {
  value: string | number;
  label: string;
}

export interface RatesCardPremiumProps {
  rateOptions: rateOption[];
  list: string[];
}

export const RatesCardPremium: React.FC<RatesCardPremiumProps> = ({ rateOptions, list }) => {
  const dispatch = useAppDispatch();
  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const openRateModal = useCallback(() => {
    dispatch(
      openModal(
        <ConfirmDeleteModal
          title="Данный функционал находится в разработке и представлен в ознакомительных целях"
          btnProp="OK"
          onSubmit={handleCloseModal}>
          <div></div>
        </ConfirmDeleteModal>,
      ),
    );
  }, [dispatch]);

  return (
    <Box sx={{ maxWidth: '360px', borderRadius: '4px', marginTop: '38px' }}>
      <div className={`position-relative ${styles.rateBg}`}>
        <img className="w-100" src={bgPremiumRate} alt="background basic" />
        <span className={`position-absolute top-0 start-0 text-white ${styles.basic}`}>
          Тариф «Расширенный»
        </span>
      </div>
      <div className={styles.rateMain}>
        <h4 className={styles.titleCard}>
          150 ₽ за пользователя{' '}
          {<Helper title={'Учитывается стоимость 1 уникального курируемого пользователя'} />}
        </h4>

        {/* <RadioGroup className={styles.radioGroup}>
          {rateOptions.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio />}
              label={<Typography className={styles.radioLabel}>{option.label}</Typography>}
              onChange={() => {}}
            />
          ))}
        </RadioGroup> */}
        <ul className={styles.listBenefits}>
          {list.map((benefit, index) => (
            <li key={index} className={styles.itemBenefit}>
              {benefit}
            </li>
          ))}
        </ul>
        <div className={`d-flex justify-content-between ${styles.btns}`}>
          <Button
            onClick={openRateModal}
            variant="contained"
            sx={{ padding: '14px 24px', fontSize: '16px', fontWeight: '500', lineHeight: '20px' }}>
            Подключить
          </Button>
        </div>
      </div>
    </Box>
  );
};
