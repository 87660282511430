import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { DictionaryName, DictionaryValue } from '@store/dictionary/contracts';
import * as DictionaryService from '@store/dictionary/dictionary.service';
import { PayloadAction } from '@reduxjs/toolkit/src/createAction';

export interface DictionaryState {
  [key: string]: DictionaryValue[];
}

type SetDictionary = {
  name: string;
  value: DictionaryValue[];
};

const initialState: DictionaryState = {};

export const loadDictionaries = createAsyncThunk('dictionary/load', async (_, { dispatch }) => {
  const names = await DictionaryService.list();
  await Promise.all(
    names.map(async (name) => {
      const value = await DictionaryService.get(name);
      return dispatch(
        setDictionary({
          name,
          value,
        }),
      );
    }),
  );
});

export const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState,
  reducers: {
    setDictionary: (state, { payload }: PayloadAction<SetDictionary>) => {
      state[payload.name] = payload.value;
    },
  },
});

export const selectDictionary = (name: DictionaryName) => (state: RootState) =>
  state.dictionary[name] || [];
export const { setDictionary } = dictionarySlice.actions;
export const dictionaryReducer = dictionarySlice.reducer;
