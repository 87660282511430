import React, { useEffect, useMemo, useRef, useState } from 'react';

import transform from '@diplodoc/transform';
// import { useThemeValue } from '@gravity-ui/uikit';
import { YfmHtml } from './YfmHtml';
import { withLatex } from './withLatex/withLatex';
import { withMermaid } from './withMermaid';
import { MermaidConfig } from './withMermaid';
import { debounce } from 'lodash';
import { useYfmEditor } from '@doc-tools/yfm-editor/_/bundle';
import { plugins } from './md-plugins';

export const LATEX_RUNTIME = 'extension:latex';
export const MERMAID_RUNTIME = 'extension:mermaid';

const ML_ATTR = 'data-ml';
const mermaidConfig: MermaidConfig = { theme: 'forest' };

const Html = withMermaid({ runtime: MERMAID_RUNTIME })(
  withLatex({ runtime: LATEX_RUNTIME })(YfmHtml),
);

export type SplitModePreviewProps = {
  value: string;
};

export const YfmPreview: React.FC<SplitModePreviewProps> = (props) => {
  const { value } = props;
  const [html, setHtml] = useState('');
  const [meta, setMeta] = useState<object | undefined>({});
  const divRef = useRef<HTMLDivElement>(null);

  // const theme = useThemeValue();

  const editor = useYfmEditor({
    linkify: true,
    allowHTML: true,
    initialMarkup: value,
    initialToolbarVisible: true,
    initialEditorType: 'markup',
    initialSplitModeEnabled: true,
  });

  const render = useMemo(
    () =>
      debounce(() => {
        const res = transform(editor.getValue(), {
          allowHTML: true,
          breaks: false,
          plugins: plugins,
          linkify: true,
          linkifyTlds: '',
          needToSanitizeHtml: true,
          linkAttrs: [[ML_ATTR, true]],
          defaultClassName: 'yfm-colorify',
        }).result;
        setHtml(res.html);
        setMeta(res.meta);
      }, 2),
    [editor], //[editor, theme]
  );

  useEffect(() => {
    render();
  }, [props, render]);

  useEffect(() => {
    if (props.value !== editor.getValue()) {
      editor.clear();
      editor.append(props.value);
    }
  }, [props.value, editor]);

  return (
    <Html
      ref={divRef}
      html={html}
      meta={meta}
      noListReset
      mermaidConfig={mermaidConfig}
      className="demo-preview"
    />
  );
};
