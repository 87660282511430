import { useGetPersonalChatMutation } from '@store/messages/messages.service';
import { closeModal } from '@store/ui/ui.slice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '.';

export function useNavigateToUserChat() {
  const [createChat] = useGetPersonalChatMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function navigateToChat(userId?: string) {
    if (userId) {
      createChat(userId).then((response) => {
        if ('data' in response) navigate(`/messages/${response.data.id}`);
      });
      dispatch(closeModal());
    }
  }

  return navigateToChat;
}
