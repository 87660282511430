import { useGetNewsTagsQuery } from '@store/news/news';
import styles from './NewsTags.module.scss';
import { CircularProgress } from '@mui/material';
import { useNewsSearchParams } from '@hooks/useNewsSearchParams';
import { Link } from 'react-router-dom';

export type Tag =
  | {
      readonly id: number;
      name: string;
    }
  | undefined;

const activeLinkStyle = {
  outline: '1px solid #199B3E',
  backgroundColor: '#F0F0F0',
  color: '#199B3E',
};

export const NewsTags = () => {
  const {
    data: newsListTags,
    isLoading: isLoadingTags,
    isError: isErrorInTags,
  } = useGetNewsTagsQuery({});

  const { searchParams } = useNewsSearchParams();

  if (isErrorInTags || !newsListTags || newsListTags.length === 0) {
    return null;
  } else
    return (
      <>
        {!isLoadingTags ? (
          <div className={`${styles.tagList} d-flex flex-no-wrap`}>
            <Link
              style={!searchParams.get('tags__id') ? activeLinkStyle : {}}
              to={'/news'}
              replace
              className={`${styles.tag} me-3`}>
              Все
            </Link>

            {newsListTags?.map((tag: Tag) => (
              <Link
                key={tag?.id}
                replace
                to={{
                  pathname: '/news',
                  search: `tags__id=${tag?.id}`,
                }}
                className={`${styles.tag} me-3`}
                style={searchParams.get('tags__id') === String(tag?.id) ? activeLinkStyle : {}}>
                {tag?.name}
              </Link>
            ))}
          </div>
        ) : (
          <CircularProgress />
        )}
      </>
    );
};
