import React from 'react';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';
import { Paper } from '@mui/material';
import { Form, Formik } from 'formik';
import styles from './ProfilePasswordForm.module.scss';
import { Button } from '@components/MUI/Button';
import { useChangePasswordMutation } from '@store/user/user.slice';
import { useAppDispatch } from '@hooks/index';
import { openModal } from '@store/ui/ui.slice';
import { ModalError } from '@components/ModalError';
import * as Yup from 'yup';
import { PasswordInput } from '@components/MUI/PasswordInput';
import { useNavigate } from 'react-router-dom';

interface FormData {
  curPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const validationSchema = Yup.object({
  curPassword: Yup.string().required('Обязательно для заполнения'),
  newPassword: Yup.string()
    .min(8, 'Придумайте пароль, в котором содержится от 8 символов')
    .required('Пароль обязателен для заполнения')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$#@*&()!%?]).*$/,
      'Пароль должен содержать минимум одну заглавную букву, одну строчную букву, одну цифру и один из символов $, #, @, *, &, !, (, ), %, ?',
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Пароли не совпадают')
    .required('Обязательно для заполнения'),
});

export const ProfilePasswordForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [changePassword] = useChangePasswordMutation();

  const handleSubmit = async (data: FormData) => {
    if (data.newPassword !== data.confirmPassword) return;

    try {
      changePassword({
        oldPassword: data.curPassword,
        newPassword: data.newPassword,
      }).then(() => navigate('/'));
    } catch {
      dispatch(openModal(<ModalError errorMessage="Не удалось изменить пароль." />));
    }
  };

  return (
    <Paper elevation={0} className={styles.profile__password__wrapper} variant="outlined">
      <div className={styles.profile__password__title}>
        <h3>Пароль</h3>
      </div>
      <Formik
        initialValues={{ curPassword: '', newPassword: '', confirmPassword: '' }}
        isInitialValid={false}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}>
        {({ errors, touched, getFieldProps, isValid }) => (
          <Form style={{ marginTop: '2.5rem' }}>
            <div className="d-flex flex-column">
              <PasswordInput
                type="password"
                placeholder={'Текущий пароль*'}
                {...getFieldProps('curPassword')}
                className={'mb-4'}
              />
              <PasswordInput
                type="password"
                placeholder={'Новый пароль*'}
                {...getFieldProps('newPassword')}
                className={'mb-4'}
              />
              <PasswordInput
                type="password"
                placeholder={'Повторите пароль*'}
                {...getFieldProps('confirmPassword')}
                className={'mb-4'}
              />
            </div>
            <Button type="submit" variant="contained" size="large" disabled={!isValid}>
              Поменять
            </Button>
            <RequiredFieldsIndicator className={styles.footer} />
          </Form>
        )}
      </Formik>
      <div></div>
    </Paper>
  );
};
