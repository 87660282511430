import React from 'react';
import { CycleTimeChart } from './CycleTimeChart';
import styles from './CycleTime.module.scss';
import { Helper } from '@components/ui/Helper';
import { useFormikContext } from 'formik';
import { FormValues } from '../AdvancedAnalytics';
import { useParams } from 'react-router-dom';
import { useGetCycleTimeAnalyticQuery } from '@store/project/project.slice';
import { useChartData } from '@hooks/useChartData';
import { CycleTimeAnalyticResponse } from '@store/project/contracts';

export const CycleTime: React.FC = () => {
  const { values } = useFormikContext<FormValues>();
  const { projectId } = useParams();
  const projectIdToUse = projectId || '';

  const { data } = useGetCycleTimeAnalyticQuery({
    path: {
      projectId: projectIdToUse,
    },
    query: {
      startDate: values.period[0].format('YYYY-MM-DD'),
      endDate: values.period[1].format('YYYY-MM-DD'),
    },
  });

  const { chartData, axisXData, isEmpty } = useChartData<CycleTimeAnalyticResponse>({
    data: data ?? [],
    chartProperty: 'data',
    axisXProperty: 'userName',
  });

  return (
    <div
      className={styles.cycle__time}
      style={{
        order: isEmpty ? 2 : 1,
      }}>
      <h2 className={styles.cycle__time__title}>
        Производительность команды (Cycle Time){' '}
        {
          <Helper title="Производительность команды - насколько быстро выполняются задачи по пользователям, то есть как быстро задачи переходят от статуса открыто да статуса закрыто." />
        }
      </h2>
      <div className={styles.cycle__time__dates}>
        {values.period[0].format('DD.MM.YYYY')} - {values.period[1].format('DD.MM.YYYY')}
      </div>
      {!isEmpty ? (
        <>
          <div className={styles.cycle__time__tasks__count}>
            <span>Время выполнения</span>
          </div>
          <CycleTimeChart chartData={chartData} axisData={axisXData} />
          <div className={styles.cycle__time__list}>
            <div className={styles.cycle__time__list__color}></div>
            <span className={styles.cycle__time__list__text}>Задачи</span>
          </div>
        </>
      ) : (
        <div className={styles.emptyData}>
          <p>Нет данных</p>
        </div>
      )}
    </div>
  );
};
