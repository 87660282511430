import { Card } from 'react-bootstrap';
import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import { useNavigate, useParams } from 'react-router-dom';
import { RequisiteForm, RequisiteFormValue } from './components/RequisiteForm';
import styles from './components/Requisite.module.scss';
import {
  useGetRequisiteDetailQuery,
  useUpdateRequisiteMutation,
} from '@store/requisite/requisite.slice';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';

export const BalanceUpdateRequisite = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const requisiteDetailQuery = useGetRequisiteDetailQuery({ id: id || '' });
  const requisiteData = requisiteDetailQuery.isSuccess ? requisiteDetailQuery.data : null;

  const { enqueueSnackbar } = useSnackbar();

  const [updateRequisite, { isLoading }] = useUpdateRequisiteMutation();

  const submitRequisite = async (value: RequisiteFormValue) => {
    if (id) {
      await updateRequisite({
        accountNumber: value.accountNumber,
        bankName: value.bankName,
        bik: value.bik?.toString(),
        corporateAccount: value.corporateAccount,
        inn: value.inn?.toString(),
        kpp: value.kpp?.toString(),
        id,
      })
        .unwrap()
        .then(() => {
          enqueueSnackbar('Реквизиты успешно обновлены', { variant: 'success' });
        });
      navigate('/balance');
    }
  };

  if (id && requisiteData) {
    return (
      <div className={'col'}>
        <div className="ms-0">
          <NavigationBack />
        </div>
        <Card className={styles.requisites_form}>
          <div className={'row'}>
            <div className={'d-flex'}>
              <h4 className={'col-12 col-md-3 mt-3 form-title'}>Редактирование реквизита</h4>
              <div className="col-12 col-md-5">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <RequisiteForm
                    value={{
                      id: requisiteData.id,
                      accountNumber: requisiteData.accountNumber,
                      bankName: requisiteData.bankName,
                      bik: Number(requisiteData.bik),
                      corporateAccount: requisiteData.corporateAccount,
                      inn: Number(requisiteData.inn),
                      kpp: Number(requisiteData.kpp),
                    }}
                    submit={submitRequisite}
                  />
                )}
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  } else return <>Произошла ошибка при получении реквизита</>;
};
