import { useLocation, useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';
import { IdentityForm, IdentityFormValue } from '@pages/authentication/IdentityForm';
import styles from '@pages/authentication/Authentication.module.scss';

export function Identity() {
  const navigate = useNavigate();
  const location = useLocation().search;

  const handleSubmitNameAndSurname = useCallback((formData: IdentityFormValue) => {
    const { firstName, lastName } = formData;
    sessionStorage.setItem('firstName', firstName);
    sessionStorage.setItem('lastName', lastName);
    const storedFirstName = sessionStorage.getItem('firstName');
    const storedLastName = sessionStorage.getItem('lastName');
    if (storedFirstName && storedLastName) {
      navigate('/registration/password');
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location);
    const userId = searchParams.get('userId');
    const code = searchParams.get('code');
    if (userId && code) {
      sessionStorage.setItem('userId', userId);
      sessionStorage.setItem('code', code);
    }
  }, [location]);

  return (
    <div className={`form-data mt-5 mb-5 ${styles.formAuth}`}>
      <div className="fw-bold fs-2 color-black mb-4">Как вас зовут?</div>
      <div className="display-flex flex-direction-column mt-2">
        <IdentityForm
          value={{
            firstName: '',
            lastName: '',
          }}
          submit={handleSubmitNameAndSurname}
        />
      </div>
    </div>
  );
}
