import React, { useEffect, useState } from 'react';
import { NavLink, useOutletContext } from 'react-router-dom';
import { useGetTeamListQuery, useSetFavoriteTeamMutation } from '@store/team/team.slice';
import { TeamItem } from '@store/team/contracts';
import { Dayjs } from 'dayjs';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import styles from './Teams.module.scss';
import { useRequestBody } from '@hooks/useRequestBody';
import { Star } from '@components/Star/Star';
import { AddTeamButton } from './buttons/AddTeamButton';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { TeamsLayoutOutletContext } from '@layouts/teams/TeamsLayout';
import { Can } from '@access-control';
import { ButtonCreateTeam } from '@components/teams/Header/ButtonCreateTeam';

const Teams: React.FC = () => {
  const outletContext = useOutletContext<TeamsLayoutOutletContext>();
  useEffect(() => {
    outletContext.setRightSideButton(
      <Can I="create" a="Team">
        <ButtonCreateTeam />
      </Can>,
    );
  }, []);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder, requestBody } =
    useRequestBody();
  const [setFavoriteTeamMutation] = useSetFavoriteTeamMutation();

  const { data: teams, isLoading } = useGetTeamListQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <div className={styles.table__wrap}>
      <AdaptiveTableMUI
        setRequestBody={setRequestBody}
        setSortColumn={setSortColumn}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        sortColumn={sortColumn}
        isLoading={isLoading}
        columns={{
          name: {
            label: 'Название команды',
            children: (value, row) => <NavLink to={`/teams/${row.id}`}>{value}</NavLink>,
            filterInput: true,
          },
          created: {
            label: 'Дата создания',
            format: (value: Dayjs) => value.format('DD.MM.YYYY'),
            filterInput: true,
            isSortColumn: true,
          },
          membersCount: {
            label: 'Участники',
            filterInput: true,
          },
          teamTypeName: {
            label: 'Тип команды',
            filterInput: true,
          },
          star: {
            children: (_, row: TeamItem) => (
              <Star
                checked={row.favorite}
                onChange={(value) =>
                  setFavoriteTeamMutation({ requestBody: { status: value }, teamId: row.id })
                }
                style={{ marginRight: '1rem' }}
              />
            ),
          },
        }}
        rows={teams?.data || []}
        emptyLabel={'У вас пока нет команд'}
        additionalElement={<AddTeamButton />}>
        <TablePaginationMUI
          limit={limit}
          count={teams?.totalCount}
          entityLabel="команд"
          page={page}
          handleChangePage={(_, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </AdaptiveTableMUI>
    </div>
  );
};

export { Teams };
