import React, { useEffect, useRef, useState } from 'react';
import styles from './DelegationForm.module.scss';
import { Card, Skeleton } from '@mui/material';
import classNames from 'classnames';
import { DelegationForm, DelegationFormProps } from './DelegationForm';
import { useAppDispatch } from '@hooks/index';
import { useNavigate, useParams } from 'react-router-dom';
import { editDelegation } from '@store/cloud/cloud.slice';
import { CloudDelegation } from '@store/cloud/contracts';
import { getCloudDelegation } from '@store/cloud/cloud.service';
import dayjs from 'dayjs';

export const EditDelegationForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { delegationId } = useParams();
  const fetched = useRef(false);

  const [delegation, setDelegation] = useState<CloudDelegation | null>(null);

  useEffect(() => {
    if (!fetched.current) {
      (async () => {
        if (delegationId) {
          const item = await getCloudDelegation(delegationId);
          setDelegation(item);
        }
      })();
      fetched.current = true;
    }
  }, [dispatch]);

  const editSaveDelegation: DelegationFormProps['submit'] = async (DelegationForm, form) => {
    try {
      await dispatch(
        editDelegation({
          id: delegationId,
          name: DelegationForm.name,
          parentId: DelegationForm.parentId,
          organization: {
            resourceId: DelegationForm.organizationId,
            resourceName: DelegationForm.organizationName,
            resourceType: 'organization',
          },
          cloud: {
            resourceId: DelegationForm.cloudId,
            resourceName: DelegationForm.cloudName,
            parentResourceId: DelegationForm.organizationId,
            resourceType: 'cloud',
          },
          billingAccount: {
            resourceId: DelegationForm.billingAccountId,
            resourceName: DelegationForm.billingAccountName,
            resourceType: 'billing_account',
          },
          projectId: DelegationForm.projectId,
          user: { email: DelegationForm.userEmail.value },
          isEnabledCreateInfrastructure: DelegationForm.isEnabledCreateInfrastructure,
          infrastructureId: DelegationForm.infrastructureId,
          allocateBudget: DelegationForm.allocateBudget,
          currencyId: DelegationForm.currencyId,
          budget: DelegationForm.budget,
          budgetLimitActionId: DelegationForm.budgetLimitActionId,
          resetBudgetTypeId: DelegationForm.resetBudgetTypeId,
          budgetEndDate: dayjs(DelegationForm.budgetEndDate),
        }),
      ).unwrap();
      navigate(`/clouds/delegations/${delegationId}`);
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    }
  };

  if (!delegation) {
    return <Skeleton />;
  }

  return (
    <Card
      className={classNames('form-container', styles.form__card)}
      elevation={0}
      variant="outlined">
      <div className={'row'}>
        <div className={styles.form__page__container}>
          <div className={styles.form__page__title}>
            <h2 className={'form-title'}>Редактирование делегирования</h2>
          </div>
          <DelegationForm
            value={{
              name: delegation.name,
              parentId: delegation.parent?.id || '',
              organizationId: delegation.organization?.id || '123',
              organizationName: delegation.organization?.resourceName || '',
              cloudId: delegation.cloud?.id || '',
              cloudName: delegation.cloud?.resourceName || '',
              billingAccountId: delegation.billingAccount?.resourceId || '',
              billingAccountName: delegation.billingAccount?.resourceName || '',
              projectId: delegation.project?.id || '',
              userEmail: {
                label: delegation.user?.link?.urls[0] || '',
                value: delegation.user?.link?.urls[0] || '',
              },
              isEnabledCreateInfrastructure: delegation?.infrastructure ? true : false,
              infrastructureId: delegation.infrastructure?.id || '',
              allocateBudget: delegation.allocateBudget || false,
              currencyId: delegation?.currency?.id || '',
              budget: delegation.budget || 0,
              budgetLimitActionId: delegation.budgetLimitAction?.id || '',
              resetBudgetTypeId: delegation.resetBudgetTypeId || '',
              budgetEndDate: delegation?.budgetEndDate ? dayjs(delegation?.budgetEndDate) : dayjs(),
            }}
            submit={editSaveDelegation}
          />
        </div>
      </div>
    </Card>
  );
};
