import styles from './MainInfo.module.scss';
import { Button } from '@components/MUI/Button';
import { Link } from 'react-router-dom';
import { Typography } from '@components/AboutInfo/AboutInfo';
import main_popular from '@assets/img/mainInfo/mainPeople.png';
import React from 'react';
import university from '@assets/img/mainRoles/Universities.png';
import client from '@assets/img/mainRoles/clients.png';
import developer from '@assets/img/mainRoles/developers.png';
import {
  OrganizationRequestForm,
  OrganizationRequestFormProps,
} from '../OrganizationRequestForm/OrganizationRequestForm';
import { useCreateOrganizationConnectRequestMutation } from '@store/user/user.slice';
import { CircularProgress } from '@mui/material';

export function MainInfo() {
  const [createOrganizationConnect, { isLoading, isSuccess }] =
    useCreateOrganizationConnectRequestMutation();

  const submitOrganizationRequestForm: OrganizationRequestFormProps['submit'] = async (
    OrganizationRequestForm,
    form,
  ) => {
    try {
      await createOrganizationConnect({
        fullName: OrganizationRequestForm.fullName,
        phone: OrganizationRequestForm.phone,
        institutionName: OrganizationRequestForm.institutionName,
        email: OrganizationRequestForm.email,
      });
      form.resetForm();
      form.setStatus('success');
    } catch (e: any) {
      form.setErrors(e);
    }
  };
  return (
    <>
      <div className={styles.mainInfoBackground}>
        <div className={styles.container}>
          <div className={styles.mainInfoBlock}>
            <div>
              <p className={styles.title}>
                Ищите{' '}
                <span className={styles.title__color}>
                  таланты для своих проектов <br className={styles.hideBr} />
                </span>
                и управляйте ими с помощью <span className={styles.title__color}>«Эджайл»</span>
              </p>
              <div className={styles.gapBenefits}>
                <Typography>Формируем команды под задачу</Typography>
                <Typography>Предоставляем инструменты для управления проектами</Typography>
                <Typography>Способствуем привлечению и развитию талантов</Typography>
              </div>
              <div>
                <Link to="/about">
                  <Button variant="contained">Присоединиться</Button>
                </Link>
              </div>
            </div>
            <div>
              <img src={main_popular} className={styles.iconMain_popular} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.mainRoles}>
        <div className={styles.container}>
          <div className={styles.mainRoles__row}>
            <div className={styles.mainRoles__row__card}>
              <div className={styles.mainRoles__row__card_header}>
                <img src={client} alt="" />
                <h4>Заказчикам</h4>
              </div>
              <div className={styles.mainRoles__row__card_block}>
                <Typography>Поможем подобрать исполнителей и контролировать ход работ</Typography>
                <Link to="/specialist">
                  <Button variant="contained" className={styles.mainRoles__row__card_block_btn}>
                    Подробнее
                  </Button>
                </Link>
              </div>
            </div>
            <div className={styles.mainRoles__row__card}>
              <div className={styles.mainRoles__row__card_header}>
                <img src={developer} alt="" />
                <h4>Исполнителям</h4>
              </div>
              <div className={styles.mainRoles__row__card_block}>
                <Typography>Поможем подобрать сложный и интересный проект</Typography>
                <Link to="/customer">
                  <Button variant="contained" className={styles.mainRoles__row__card_block_btn}>
                    Подробнее
                  </Button>
                </Link>
              </div>
            </div>
            <div className={styles.mainRoles__row__card}>
              <div className={styles.mainRoles__row__card_header}>
                <img src={university} alt="" />
                <h4>Учебным заведениям</h4>
              </div>
              <div className={styles.mainRoles__row__card_block}>
                <Typography>Контролируйте студенческие стартапы и внутренние НИОКР</Typography>
                <Link to="/university">
                  <Button variant="contained" className={styles.mainRoles__row__card_block_btn}>
                    Подробнее
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.form}>
        <div className={styles.container}>
          <div className={styles.formMain}>
            {isLoading ? (
              <CircularProgress />
            ) : isSuccess ? (
              <span className={styles.title__color}>
                Ваша заявка на подключение успешно отправлена
              </span>
            ) : (
              <OrganizationRequestForm
                value={{
                  fullName: '',
                  phone: '',
                  institutionName: '',
                  email: '',
                  agreement: false,
                }}
                submit={submitOrganizationRequestForm}
                organizationPlaceholder="Название организации*"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
