import React from 'react';
import { FormWrapper } from '@components/Form/Form';
import { Modal } from '@components/MUI/Modal/Modal';
import { AddTimeForm } from './AddTimeForm';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';

export const AddTimeModal = () => {
  const dispatch = useAppDispatch();
  const submitAddTime = () => {
    dispatch(closeModal());
  };

  return (
    <Modal>
      <FormWrapper label="Добавить затраченное время">
        <AddTimeForm submit={submitAddTime} />
      </FormWrapper>
    </Modal>
  );
};
