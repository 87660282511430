import { Box, Typography } from '@mui/material';
import styles from './CardOptionRates.module.scss';
import { Button } from '@components/MUI/Button';
import { useAppDispatch } from '@hooks/index';
import React, { useCallback } from 'react';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { ConfirmDeleteModal } from '@components/MUI/ConfirmDeleteModal/ConfirmDeleteModal';

interface CardOptionsRatesProps {
  title: string;
  subTitle: string;
  description: string;
  rate: string;
  allRates: string;
  price: string;
  priceForAnotherRates: string;
}

export const CardOptionsRates = ({
  title,
  subTitle,
  description,
  rate,
  allRates,
  price,
  priceForAnotherRates,
}: CardOptionsRatesProps) => {
  const dispatch = useAppDispatch();
  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const openOptionModal = useCallback(() => {
    dispatch(
      openModal(
        <ConfirmDeleteModal
          title="Данный функционал находится в разработке и представлен в ознакомительных целях"
          btnProp="OK"
          onSubmit={handleCloseModal}>
          <div></div>
        </ConfirmDeleteModal>,
      ),
    );
  }, [dispatch]);
  return (
    <Box className={styles.bgBox}>
      <div className={styles.mainTitle}>
        <Typography className={styles.title}>{title}</Typography>
        <Typography className={styles.subTitle}>{subTitle}</Typography>
      </div>
      <div className={styles.bodyCard}>
        <p className={styles.description}>{description}</p>
        <h5 className={styles.rate}>{rate}</h5>
        <span className={styles.price}>{price}</span>
        <hr />
        <h5 className={styles.allRates}>{allRates}</h5>
        <span className={styles.price}>{priceForAnotherRates}</span>
        <div className={styles.buttonBlock}>
          <Button className={styles.button} variant="contained" onClick={openOptionModal}>
            Подключить
          </Button>
        </div>
      </div>
    </Box>
  );
};
