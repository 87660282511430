import { Star } from '@components/Star/Star';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { useRequestBody } from '@hooks/useRequestBody';
import { Vacancy } from '@store/vacancy/contracts';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { AdaptiveActionButtons } from '@components/adaptives/AdaptiveActionButtons';
import { useNavigateToUserChat } from '@hooks/useNavigateToUserChat';
import { useOutletContext } from 'react-router-dom';
import { Can } from '@access-control';
import { ButtonCreateVacancy } from '@components/teams/Header/ButtonCreateVacancy';
import { VacanciesLayoutOutletContext } from '@layouts/vacancies/VacanciesLayout';
import { useAcceptOfferMutation, useDenyOfferMutation } from '@store/offer/offer.slice';
import { useGetMyOffersQuery, useSetFavoriteVacancyMutation } from '@store/vacancy/vacancy.slice';

type MyOffer = {
  id: string;
  teamName: string;
  positionName: string;
  directionName: string;
  stack: string;
  payment: string;
  comment: string;
  actions: {};
  offerStatus: string;
  managerId: string;
};

export const TeamMyOffers: React.FC = () => {
  const outletContext = useOutletContext<VacanciesLayoutOutletContext>();
  useEffect(() => {
    outletContext.setRightSideButton(
      <Can I="create" a="Vacancies">
        <ButtonCreateVacancy />
      </Can>,
    );
  }, []);

  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();
  const createChat = useNavigateToUserChat();
  const [denyOffer] = useDenyOfferMutation();
  const [acceptOffer] = useAcceptOfferMutation();
  const [setFavoriteVacancy] = useSetFavoriteVacancyMutation();
  const { data: vacancies } = useGetMyOffersQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const myOffers = useMemo(() => {
    if (vacancies?.data) {
      const myOffers: MyOffer[] = [];

      for (let i = 0; i < vacancies.data.length; i++) {
        for (let j = 0; j < vacancies.data[i].offers.length; j++) {
          myOffers.push({
            id: vacancies.data[i].id,
            teamName: vacancies.data[i].teamName,
            positionName: vacancies.data[i].positionName,
            directionName: vacancies.data[i].directionName,
            stack: vacancies.data[i].stack,
            payment: String(vacancies.data[i].payment),
            comment: vacancies.data[i].comment,
            offerStatus: vacancies.data[i].offers[j].offerStatusName,
            managerId: vacancies.data[i].offers[j].senderId,
            actions: {},
          });
        }
      }
      return myOffers;
    }
    return [];
  }, [vacancies]);

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handleApprove = (row: MyOffer) => {
    acceptOffer(row.id);
    dispatch(closeModal());
  };

  const handleClose = (row: MyOffer) => {
    denyOffer(row.id);
    dispatch(closeModal());
  };

  const handleSendMessage = (managerId: string) => {
    createChat(managerId);
  };

  return (
    <AdaptiveTableMUI
      sortOrder={sortOrder}
      sortColumn={sortColumn}
      setRequestBody={setRequestBody}
      setSortColumn={setSortColumn}
      setSortOrder={setSortOrder}
      isEmpty={vacancies?.data.length === 0}
      emptyLabel="Предложенные вакансии отсутствуют"
      columns={{
        teamName: {
          label: 'Название команды',
          filterInput: true,
        },
        actionsRaw: {
          children: () => <div></div>,
        },
        positionName: {
          label: 'Роль',
          filterInput: true,
        },
        directionName: {
          label: 'Направление',
          filterInput: true,
          isSortColumn: false,
        },
        stack: {
          label: 'Стэк',
          filterInput: true,
        },
        payment: {
          label: 'Ставка',
          isSortColumn: true,
          filterInput: true,
          format: (value) => `${value} р/ч`,
        },
        comment: {
          label: 'Комментарий',
          filterInput: true,
        },
        actions: {
          children: (value, row: MyOffer) => (
            <AdaptiveActionButtons
              handleApprove={() => handleApprove(row)}
              handleClose={() => handleClose(row)}
              isOnlySend={
                row.offerStatus === 'Оффер от исполнителя' ||
                row.offerStatus === 'Оффер от менеджера'
                  ? false
                  : true
              }
              handleSendMessage={() => handleSendMessage(row.managerId)}
            />
          ),
        },
        star: {
          children: (_, row: Vacancy) => (
            <Star
              checked={row.favorite}
              onChange={(value) =>
                setFavoriteVacancy({ requestBody: { status: value }, vacancyId: row.id })
              }
              style={{ marginRight: '1rem' }}
            />
          ),
        },
      }}
      rows={myOffers}>
      <TablePaginationMUI
        limit={limit}
        count={vacancies?.totalCount}
        entityLabel="вакансий"
        page={page}
        handleChangePage={(_, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </AdaptiveTableMUI>
  );
};
