import { JobCharacteristicsForm } from '@components/JobCharacteristicsForm/JobCharacteristicsForm';
import React from 'react';
import styles from './JobsWithoutRegistration.module.scss';
import { Button } from '@components/MUI/Button';
import { useNavigate } from 'react-router-dom';

export const JobsWithoutRegistrationFilters = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.filterSearch}>
        <div className={styles.filterBlock}>
          <h3 className={styles.filterTitle}>Фильтр вакансий</h3>
        </div>
        <JobCharacteristicsForm
          value={{
            start: 0,
            limit: 10,
            sortOrder: 'desc',
            stack: [],
            experienceFrom: 1,
            experienceTo: 2,
            hourPriceFrom: 0,
            hourPriceTo: 1000,
            monthPriceFrom: 1000,
            monthPriceTo: 5000,
            statusId: [],
            rating: 10,
            location: 'Russia',
            sortField: '',
            roleId: 'customer',
          }}
          submit={() => {}}
        />
        <div className="d-flex justify-content-center mt-5 mb-5">
          <Button className={styles.filterBtn} variant="contained" onClick={handleGoBack}>
            Найти
          </Button>
        </div>
      </div>
    </div>
  );
};
