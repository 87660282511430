import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { createApi } from '@reduxjs/toolkit/query/react';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_PUBLIC_API_URL,
});

export const publicApi = createApi({
  reducerPath: `publicApi`,
  baseQuery,
  endpoints: () => ({}),
});
