import { PropsWithChildren } from 'react';
import classNames, { ArgumentArray } from 'classnames';

type MainContentLayoutProps = PropsWithChildren & {
  classes?: ArgumentArray;
};

export function MainContentLayout(
  { children, classes }: MainContentLayoutProps = {
    classes: [],
  },
) {
  const mainContentLayout = classNames('col container-content', ...(classes || []));
  return <div className={mainContentLayout}>{children}</div>;
}
