import { RouterProvider } from 'react-router-dom';
import { useAppSelector } from '@hooks/index';
import { AbilityContext } from '@access-control';
import { useAppRoutes } from './routes/useAppRoutes';
import { selectAbility } from '@store/user/user.slice';

function App() {
  const ability = useAppSelector(selectAbility);
  const router = useAppRoutes();

  return (
    <AbilityContext.Provider value={ability}>
      <RouterProvider router={router} />
    </AbilityContext.Provider>
  );
}

export { App };
