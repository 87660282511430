import React, { FC } from 'react';
import styles from '@styles/Projects.module.scss';
import { Manager } from '@store/requests/contracts';

interface Props {
  managers?: Manager[];
}

const Managers: FC<Props> = ({ managers }) => {
  if (managers && managers.length) {
    return (
      <div>
        {managers.map((item) => (
          <div className="mb-1">
            <img
              className={styles.managerAvatar}
              src="https://placehold.co/52x52/40e0d0/FFFFFF/png"
              alt=""
            />
            <span>{item.managerName}</span>
          </div>
        ))}
      </div>
    );
  } else return <i>Нет менеджеров</i>;
};

export { Managers };
