import React, { FC, MutableRefObject, useImperativeHandle } from 'react';
import { Form } from 'react-bootstrap';
import { FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import styles from '@styles/Projects.module.scss';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { TeamItem } from '@store/team/contracts';

export interface AssignTeamFormRef {
  submitForm: () => Promise<void>;
  isValid: boolean;
}

interface AssignTeamFormProps {
  value?: AssignTeamFormValue;
  submit: (
    value: AssignTeamFormValue,
    form: FormikBag<AssignTeamFormProps, AssignTeamFormValue>,
  ) => void;
  formRef: MutableRefObject<AssignTeamFormRef | undefined>;
  teams: TeamItem[];
}

export interface AssignTeamFormValue {
  teamId: string | undefined;
}

const LoginFormSchema = Yup.object({
  teamId: Yup.string().required('Нужно выбрать одну команду'),
});

const AssignTeamFormComponent: FC<AssignTeamFormProps> = ({ formRef, teams }) => {
  const { handleSubmit, submitForm, isValid, errors, handleChange } =
    useFormikContext<AssignTeamFormValue>();

  useImperativeHandle(formRef, () => ({
    submitForm,
    isValid,
  }));

  const errorSummary = Object.values(errors).join(', ');

  return (
    <Form className={classNames({ 'is-invalid': !isValid })} onSubmit={handleSubmit}>
      <Form.Control.Feedback type="invalid" className={classNames({ 'd-block': !isValid })}>
        {errorSummary}
      </Form.Control.Feedback>
      <div>
        <RadioGroup>
          {teams.length > 0 ? (
            teams.map((team) => (
              <div key={team.id} className={`py-1 ${styles.controlLabel}`}>
                <FormControlLabel
                  className={styles.formControl}
                  control={<Radio className={styles.radio} />}
                  id={`check-team-${team.id}`}
                  label={team.name}
                  name="teamId"
                  onChange={handleChange}
                  value={team.id}
                />
              </div>
            ))
          ) : (
            <div className={styles.emptyBlock}>Ничего не найдено</div>
          )}
        </RadioGroup>
      </div>
    </Form>
  );
};

export const AssignTeamForm = withFormik<AssignTeamFormProps, AssignTeamFormValue>({
  mapPropsToValues: () => {
    return {
      teamId: '',
    };
  },
  validationSchema: LoginFormSchema,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(AssignTeamFormComponent);
