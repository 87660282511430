import { PaginationClass, ToDate } from '@store/common';
import { Type } from 'class-transformer';
import { Dayjs } from 'dayjs';

export interface AnalyticsResponse {
  pages: number;
  page: number;
  totalCount: number;
  queriedCount: number;
  startRecord: number;
}

export interface AnalyticsRequestsResponse extends AnalyticsResponse {
  data: AnalyticsRequests[];
}

export interface AnalyticsRequests {
  id: string;
  name: string;
  projectType: string;
  deadLine: string;
  budget: number;
  managers: Manager[];
  statusName: string;
  created: string;
}

export interface Manager {
  managerId: string;
  managerName: string;
  statusId: string;
  statusName: string;
}

export interface AnalyticsProjectsResponse extends AnalyticsResponse {
  data: AnalyticsProjects[];
}

export interface AnalyticsProjects {
  id: string;
  name: string;
  projectTypeName: string;
  deadLine: string;
  budget: number;
  totalCount: number;
  teamLeadId: string;
  teamLeadName: string;
  projectStatusName: string;
  created: string;
}

export interface AnalyticsVananciesResponse extends AnalyticsResponse {
  data: AnalyticsVacancies[];
}

export interface AnalyticsVacancies {
  id: string;
  vacancyName: string;
  offerStatusName: string;
  payment: string;
  vacancyId: string;
}

export interface AnalyticsTasks {
  team: string;
  project: string;
  newTasks: number;
  inProgress: number;
  inTesting: number;
  done: number;
  overdue: number;
}

export type AdditionalProperty = {
  [key: string | symbol]: {
    value: string;
    searchType: 'LIKE' | 'EQUAL' | 'LESS' | 'GREATER';
    sort: boolean;
  };
};

export type RequestBodyType =
  | AdditionalProperty
  | {
      start: number;
      limit: number;
      sortOrder?: string;
    };

export type DashboardProjects = {
  projectsCount?: number;
  statusCountList?: ProjectStatus[];
};

type ProjectStatus = {
  statusType?: string;
  taskCount?: number;
};

export type ProjectTasksAnalytics = {
  projectId?: string;
  projectName?: string;
  statusCountTaskList?: TaskStatus[];
};

type TaskStatus = {
  statusType?: string;
  taskCount?: number;
};

export class TimeProjectAnalytics {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  projectName!: string;
  spentTime!: number;
  remainingTime!: number;
}

export class TimeProjectAnalyticsResponse extends PaginationClass {
  @Type(() => TimeProjectAnalytics)
  data!: TimeProjectAnalytics[];
}

export type Status = {
  statusName: string;
  count: number;
};

export type Release = {
  name: string;
  startDate: string;
  endDate: string;
  statusCountTaskList: Status[];
};

export const releases: Release[] = [
  {
    name: 'Релиз 1',
    startDate: '01.01',
    endDate: '05.02',
    statusCountTaskList: [
      {
        statusName: 'Новое',
        count: 10,
      },
      {
        statusName: 'В работе',
        count: 1,
      },
      {
        statusName: 'Завершено',
        count: 1,
      },
      {
        statusName: 'Приемка',
        count: 1,
      },
      {
        statusName: 'Принято',
        count: 1,
      },
      {
        statusName: 'Неактивный',
        count: 1,
      },
    ],
  },
  {
    name: 'Релиз 2',
    startDate: '08.01',
    endDate: '12.02',
    statusCountTaskList: [
      {
        statusName: 'Новое',
        count: 2,
      },
      {
        statusName: 'В работе',
        count: 2,
      },
      {
        statusName: 'Завершено',
        count: 2,
      },
      {
        statusName: 'Приемка',
        count: 2,
      },
      {
        statusName: 'Принято',
        count: 2,
      },
      {
        statusName: 'Неактивный',
        count: 2,
      },
    ],
  },
  {
    name: 'Релиз 3',
    startDate: '16.01',
    endDate: '22.02',
    statusCountTaskList: [
      {
        statusName: 'Новое',
        count: 3,
      },
      {
        statusName: 'В работе',
        count: 3,
      },
      {
        statusName: 'Завершено',
        count: 3,
      },
      {
        statusName: 'Приемка',
        count: 1,
      },
      {
        statusName: 'Принято',
        count: 3,
      },
      {
        statusName: 'Неактивный',
        count: 2,
      },
    ],
  },
  {
    name: 'Релиз 4',
    startDate: '28.01',
    endDate: '31.02',
    statusCountTaskList: [
      {
        statusName: 'Новое',
        count: 4,
      },
      {
        statusName: 'В работе',
        count: 4,
      },
      {
        statusName: 'Завершено',
        count: 2,
      },
      {
        statusName: 'Приемка',
        count: 3,
      },
      {
        statusName: 'Принято',
        count: 1,
      },
      {
        statusName: 'Неактивный',
        count: 2,
      },
    ],
  },
];
