import React from 'react';
import styles from './ProfileWork.module.scss';

type ProfileWorkProps = {
  status?: string;
  hourPrice?: number | null;
  monthPrice?: number;
};

export const ProfileWork: React.FC<ProfileWorkProps> = ({ status, hourPrice, monthPrice }) => {
  return (
    <div className={styles.work__info}>
      <h3 className={styles.work__info__title}>Работа</h3>
      <div className={styles.status__block}>
        <p className="m-0 p-0 color-gray">Статус</p>
        <span className={styles.status}>{status}</span>
      </div>
      <div className={styles.hour__price__block}>
        <p className="m-0 p-0 color-gray">Стоимость часа работы</p>
        <span className={styles.work__info__content}>{hourPrice ? `${hourPrice} ₽` : '-'}</span>
      </div>
      <div className={styles.month__price__block}>
        <p className="m-0 p-0 color-gray">Стоимость месяца работы</p>
        <span className={styles.work__info__content}>{monthPrice ? `${monthPrice} ₽` : '-'}</span>
      </div>
      <hr />
    </div>
  );
};
