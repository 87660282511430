import React from 'react';
import { ReactComponent as SearchGray } from '@assets/img/searchGray.svg';
import { InputAdornment, TextField } from '@mui/material';
import styled from '@emotion/styled';
import styles from './SearchChat.module.scss';

const SearchInput = styled(TextField)(() => ({
  borderColor: 'transparent',
  borderRadius: '4px',
  padding: '8px 216px 8px 12px',
  width: '100%',
}));

export const SearchChat: React.FC = () => {
  return (
    <div className="inputSearch mt-3 mb-3">
      <SearchInput
        placeholder="Поиск"
        fullWidth
        className={styles.searchForm}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchGray />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
