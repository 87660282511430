import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Project } from '@store/project/contracts';
import { RequestItem } from '@store/requests/contracts';
import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import { ProjectsHeader } from '@components/ui/ProjectsHeader/ProjectsHeader';
import styles from '../projects/Project/Project.module.scss';
import { useDetailProjectQuery } from '@store/project/project.slice';

export interface ProjectData {
  project: Project;
  request: RequestItem;
}

export type ProjectContext = [
  ProjectData,
  React.Dispatch<React.SetStateAction<ProjectData | undefined>>,
];

export const ProjectDetail = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const { data: project } = useDetailProjectQuery(projectId || '');

  if (!projectId) {
    navigate('/projects');
    return null;
  }

  if (project) {
    return (
      <div className="col container-content">
        <div className={styles.wrapper}>
          <div className={styles.nav__back}>
            <NavigationBack />
          </div>
          <div className={styles.container}>
            <ProjectsHeader projectId={projectId} />
            <Outlet />
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};
