import { FC, PropsWithChildren } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { MainHeader } from '@components/MainHeader/MainHeader';
import { MainFooter } from '@components/MainFooter/MainFooter';
import styles from './layout.module.scss';

export const AuthLayout: FC<PropsWithChildren> = function AuthLayout() {
  return (
    <div>
      <MainHeader />
      <div className={styles.backgroundLayout}>
        <Outlet />
        <ScrollRestoration />
      </div>
      <MainFooter />
    </div>
  );
};
