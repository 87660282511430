import { FC } from 'react';

const Profit: FC = () => {
  return (
    <div>
      <div className="mt-4 text-center">
        <h6 className="text-secondary">У вас пока нет поступлений</h6>
      </div>
    </div>
  );
};

export { Profit };
