import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Api from '@api-schema';
import * as SupportService from '@store/support/support.service';
import { RootState } from '@store/index';

const initialState = {
  loading: false,
  error: null,
  success: false,
};

export const supportSlice = createSlice({
  name: 'publicVacancy',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSuccess: (state, action: PayloadAction<boolean>) => {
      state.success = action.payload;
    },
  },
});

export const supportRequest = createAsyncThunk(
  'support/message',
  async (
    request: Api.operations['sendSupportMessage']['requestBody']['content']['application/json'],
    { dispatch },
  ) => {
    dispatch(setLoading(true));
    const response = await SupportService.sendSupportMessage({
      username: request.username,
      email: request.email,
      text: request.text,
      attachments: request.attachments,
      topic: request.topic,
    });
    dispatch(setSuccess(true));
    dispatch(setLoading(false));
    return response;
  },
);

export const { setLoading, setSuccess } = supportSlice.actions;

export const supportReducer = supportSlice.reducer;
export const selectIsLoadingSupportMessage = (state: RootState) => state.support.loading;
export const selectSupportSuccess = (state: RootState) => state.support.success;
