import { PaginationClass, PaginationQuery, ToDate } from '@store/common';
import { Vacancy } from '@store/vacancy/contracts';
import { Type } from 'class-transformer';
import { Dayjs } from 'dayjs';

export interface TeamCreationRequest {
  id: string | null;
  name: string;
  teamLeadId: string;
  teamTypeId: string;
  telegramUrl: string;
  participants: Array<Participant>;
}

export interface TeamCreationResponse {
  status: string;
  message: string;
  team: Team;
}

export interface TeamsGettingRequest extends PaginationQuery {
  name: string;
}

export interface TeamUpdatingRequest {
  id: string | null;
  name?: string;
  teamLeadId?: string;
  teamTypeId?: string;
  telegramUrl?: string;
  positions?: Array<Participant>;
}

export interface TeamUpdatingResponse extends Partial<TeamCreationResponse> {}

export interface TeamRemovingRequest {
  id: string;
}

export interface TeamRemovingResponse {
  code: string;
  message: string;
}

export class Participant {
  id!: string;
  userId!: string;
  positionId!: string;
  positionName!: string;
  teamId!: string;
  userName!: null | string;
  @Type(() => Vacancy)
  vacancy!: null | Vacancy;
  telegram!: null | string;
  statusName!: ParticipantStatus;
}

export enum ParticipantStatus {
  Cancel = 'Отказ',
  Accepted = 'Принят',
  Approval = 'На рассмотрении',
  Open = 'Открыт',
  OfferFromExecutor = 'Оффер от исполнителя',
  OfferFromManager = 'Оффер от менеджера',
}

export interface Team {
  id: string;
  created: string;
  name: string;
  teamLeadId: string;
  teamLeadName: string;
  participantsNum: number;
  teamTypeName: string;
  telegramUrl: string;
  projects: Array<{ name: string }>;
  positions: Array<Participant>;
  roles: Array<{ role: string; count: number }>;
}

export interface TeamListRequest {
  start: number;
  limit: number;
}

export class TeamItem {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  favorite!: boolean;
  name!: string;
  @Type(() => TeamLead)
  teamLead!: TeamLead;
  teamTypeId!: string;
  teamTypeName!: string;
  telegramUrl!: string | null;
  @Type(() => Participant)
  positions!: Participant[];
  membersCount!: number;
}

export class TeamLead {
  userId!: string;
  userName!: string;
  storedFileId!: string;
}

export class TeamListResponse extends PaginationClass {
  @Type(() => TeamItem)
  data!: TeamItem[];
  recordsTotal!: number;
  recordsFiltered!: number;
}

export interface GetAvailablePositionsRequest {
  id: string;
}

export interface GetAvailablePositionsResponse {
  id: string;
  positionId: string;
  positionName: string;
  teamId: string;
  userId: string | null;
  userName: string | null;
  vacancy: string | null;
}
