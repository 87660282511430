import React from 'react';
import { User } from '@store/user/contracts';
import avatar from '@assets/img/avatar.svg';

type UserIconProps = {
  row: User;
};

export const UserIcon: React.FC<UserIconProps> = ({ row }) => {
  return (
    <img
      style={{
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        marginRight: '8px',
      }}
      src={
        !row.about?.icon
          ? avatar
          : `http://api.easy.parnas-it.com/easy-core-web-1.0/api/file/${row.about?.icon}`
      }
      alt="аватар"
    />
  );
};
