import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateRequestIcon } from '@layouts/requests/Icons/CreateRequestIcon';
import { Button } from '@components/MUI/Button';

export const ButtonCreateTeam = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Button
        variant="contained"
        startIcon={<CreateRequestIcon />}
        onClick={() => navigate('/teams/new')}>
        Создать команду
      </Button>
    </div>
  );
};
