import React from 'react';
import { useParams } from 'react-router-dom';
import { Link, Organization } from '@store/user/contracts';
import styles from '@styles/Projects.module.scss';
import { useDetailProjectQuery } from '@store/project/project.slice';

export function Customer() {
  const { projectId } = useParams();
  const { data: project } = useDetailProjectQuery(projectId!);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-start">
        <h3 className={styles.title}>{project?.name ? project.name : '-'}</h3>
      </div>
      <h6 className={styles.commonCardLabel}>Заказчик</h6>
      <h6>
        {project?.customer?.about?.firstName ? project.customer.about?.firstName : '-'}
        &nbsp;
        {project?.customer.about?.lastName ? project?.customer.about?.lastName : '-'}
      </h6>
      <h6 className={styles.commonCardLabel}>Телефон</h6>
      <h6>
        <CustomerPhone link={project?.customer?.link} />
      </h6>
      <h6 className={styles.commonCardLabel}>Наименование организации</h6>
      <h6>
        <CustomerOrganisation org={project?.customer?.organization} />
      </h6>
      <h6 className={styles.commonCardLabel}>Оплата</h6>
      <h6>
        <i>Не указано</i>
      </h6>
      <h6 className={styles.commonCardLabel}>Прочие отчетности</h6>
      <h6>
        <i>Не указано</i>
      </h6>
      <h6 className={styles.commonCardLabel}>Бюджет проекта</h6>
      <h6>{project?.budget} ₽</h6>
    </div>
  );
}

function CustomerPhone(props: { link?: Link | null }) {
  return props.link && props.link.phones ? <>{props.link.phones.join(', ')}</> : <i>Не указано</i>;
}

function CustomerOrganisation(props: { org?: Organization | null }) {
  return props.org && props.org.name ? <>{props.org.name}</> : <i>Не указано</i>;
}
