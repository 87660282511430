import React from 'react';
import styles from './EmptyChat.module.scss';

export const EmptyChat: React.FC = () => {
  return (
    <div
      className="col-sm-12 col-lg-8 d-none d-md-block border-start p-0  position-relative"
      style={{ background: '#FAFAFA' }}>
      <div className={styles.mainBlock}>
        <span className={styles.mainText}>Выберите, кому хотите написать</span>
      </div>
    </div>
  );
};
