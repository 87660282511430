import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { InputField } from '@components/MUI/InputField/InputField';
import React from 'react';
import styles from './TransferToProjectForm.module.scss';
import { Button } from '@components/MUI/Button';

export type TransferToProjectFormProps = {
  value: TransferToProjectFormValue;
  submit: (
    value: TransferToProjectFormValue,
    form: FormikBag<TransferToProjectFormProps, TransferToProjectFormValue>,
  ) => void;
};

export type TransferToProjectFormValue = {
  projectPrefix: string;
  projectName?: string;
  id: string;
};

const TransferToProjectFormSchema = Yup.object({
  projectPrefix: Yup.string()
    .required('Поле обязательно для заполнения')
    .max(10, 'Название префикса не должно превышать 10-ти символов')
    .matches(/^[a-zA-Z\d]+$/, 'Используйте только латинский буквы и цифры'),
  projectName: Yup.string().required('Поле обязательно для заполнения'),
});

const FormComponent = () => {
  const form = useFormikContext<TransferToProjectFormValue>();

  return (
    <Form onSubmit={form.handleSubmit}>
      <div className={styles.form}>
        <InputField
          name="projectPrefix"
          placeholder="Префикс проекта: Например: OCHERED1*"
          type="text"
        />
        <InputField name="projectName" placeholder="Название проекта" type="text" />

        <Button
          type="submit"
          disabled={!form.isValid}
          color="success"
          variant="contained"
          size="large"
          className={styles.submit__button}>
          Перевести в проект
        </Button>
      </div>
    </Form>
  );
};

export const TransferToProjectForm = withFormik<
  TransferToProjectFormProps,
  TransferToProjectFormValue
>({
  displayName: 'TransferToProjectForm',
  mapPropsToValues: (props) => {
    return {
      projectPrefix: props.value.projectPrefix,
      projectName: props.value.projectName,
      id: props.value.id,
    };
  },
  enableReinitialize: true,
  validationSchema: TransferToProjectFormSchema,
  validateOnMount: true,
  validateOnChange: true,
  validateOnBlur: false,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
