import { ReactNode } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { AdaptiveStar } from './AdaptiveStar';
import { Columns } from './TableMUI';

export interface ITableCellOutput {
  format?: (value: any) => string | number;
  children?: (value: string | number, row: any) => ReactNode;
  value: string | number;
  row?: any;
  titles: Record<string, Columns>;
  title?: string;
}

export const TableCellOutput = ({
  format,
  children,
  value,
  row,
  titles,
  title,
}: ITableCellOutput) => {
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down('tablet'));

  if (format !== undefined) {
    value = format(value);
  }

  if (tablet && title === 'star') {
    return <></>;
  }

  if (tablet && titles['star'] && title === 'name') {
    return (
      <div style={{ display: 'flex' }}>
        {(children && children(value, row)) ?? value}
        <div style={{ marginRight: '8px' }}></div>
        <AdaptiveStar
          value={row['star']}
          titles={titles}
          children={titles['star'].children}
          row={row}
          title={title}
        />
      </div>
    );
  }

  if (tablet && title === 'actions') {
    return <></>;
  }

  if (tablet && titles['actions'] && title === 'actionsRaw') {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', height: 0 }}>
        {(children && children(value, row)) ?? value}
        <div style={{ marginRight: '8px', height: '24px', zIndex: '10' }}>
          {(titles['actions'].children && titles['actions'].children(value, row)) ?? value}
        </div>
      </div>
    );
  }

  return <>{(children && children(value, row)) ?? value}</>;
};
