import { Helper } from '@components/ui/Helper';
import styles from './TaskBurndown.module.scss';
import { TaskBurndownChart } from './TaskBurndownChart';
import { useFormikContext } from 'formik';
import { FormValues } from '../AdvancedAnalytics';
import { useParams } from 'react-router-dom';
import { useGetBurnoutAnalyticQuery } from '@store/project/project.slice';
import { useMemo } from 'react';
import dayjs from 'dayjs';

export const TaskBurndown = () => {
  const { values } = useFormikContext<FormValues>();

  const { projectId } = useParams();
  const projectIdToUse = projectId || '';

  const { data } = useGetBurnoutAnalyticQuery({
    path: {
      projectId: projectIdToUse,
    },
    query: {
      startDate: values.period[0].format('YYYY-MM-DD'),
      endDate: values.period[1].format('YYYY-MM-DD'),
    },
  });

  const realData = useMemo(() => {
    if (data) return data.map((user) => user.closedTasks);
    return [];
  }, [data]);

  const idealData = useMemo(() => {
    if (data) return data.map((user) => user.mustCloseTasks);
    return [];
  }, [data]);

  const axisXData = useMemo(() => {
    if (data) return data.map((user) => dayjs(user.date).format('DD.MM'));
    return [];
  }, [data]);

  const isEmpty = realData.length === 0 && idealData.length === 0 && axisXData.length === 0;
  return (
    <>
      <div
        className={styles.container}
        style={{
          order: isEmpty ? 2 : 1,
        }}>
        <h2 className={styles.header}>
          Диаграмма сгорания задач{' '}
          {
            <Helper title="Диаграмма, показывающая количество сделанной и оставшейся работы по проекту." />
          }
        </h2>
        <p className={styles.period}>
          {values.period[0].format('DD.MM.YYYY')} - {values.period[1].format('DD.MM.YYYY')}
        </p>
        {!isEmpty ? (
          <>
            <TaskBurndownChart realData={[]} idealData={[]} xAxisData={[]} />
            <div className={styles.chart_legend}>
              <div className={styles.chart_legend__container}>
                <div className={styles.chart_legend__container__first_mark}></div>
                <p className={styles.chart_legend__label}>Реальный ход работы по задачам</p>
              </div>
              <div className={styles.chart_legend__container}>
                <div className={styles.chart_legend__container__second_mark}></div>
                <p className={styles.chart_legend__label}>Реальный ход работы по задачам</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.emptyData}>
              <p>Нет данных</p>
            </div>
          </>
        )}
      </div>
    </>
  );
};
