import React, { useCallback } from 'react';
import { Button } from '@components/MUI/Button';
import { openModalError } from '@store/ui/ui.slice';
import { useAppDispatch } from '@hooks/index';
import { useCreateOfferMutation } from '@store/offer/offer.slice';

interface Props {
  vacancyId: string;
  receiverId: string;
  fromManager: boolean;
}

const CreateVacancyOffer = ({ vacancyId, receiverId, fromManager }: Props) => {
  const dispatch = useAppDispatch();
  const [createOffer] = useCreateOfferMutation();

  const handleClick = useCallback(async () => {
    try {
      createOffer({
        vacancyId,
        receiverId,
        fromManager,
      });
    } catch (e: any) {
      dispatch(openModalError(e));
    }
  }, [dispatch]);

  return (
    <Button variant="contained" size="small" disableElevation={true} onClick={handleClick}>
      Откликнуться
    </Button>
  );
};

export { CreateVacancyOffer };
