import React from 'react';
import { NavLink } from 'react-router-dom';
import { TeamsIcom } from './Icons/TeamsIcom';
import { Can } from '@access-control';
import { useMediaQuery } from '@mui/material';
import styles from './TeamsHeader.module.scss';

type TeamsHeaderProps = {
  rightSideButton?: React.ReactNode;
};

const TeamsHeader: React.FC<TeamsHeaderProps> = ({ rightSideButton }) => {
  const isTablet = useMediaQuery('(max-width: 991px)');

  return (
    <header className={styles.header}>
      <ul>
        <Can I="read" a="TeamsTable">
          <li>
            <NavLink to="/teams" className={({ isActive }) => (isActive ? 'active' : '')} end>
              <TeamsIcom />
              Мои Команды
            </NavLink>
          </li>
        </Can>
      </ul>
      {!isTablet ? rightSideButton : <></>}
    </header>
  );
};

export { TeamsHeader };
