import { PaginationClass, ToDate } from '@store/common';
import { Type } from 'class-transformer';
import { Dayjs } from 'dayjs';

export class Offer {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  vacancyId!: string;
  vacancyName!: string;
  senderId!: string;
  senderName!: string;
  receiverId!: string;
  receiverName!: string;
  offerStatusId!: string;
  offerStatusName!: string;
  payment!: number;
  @Type(() => Executor)
  executor!: Executor;
}

export class GetOffersResponse extends PaginationClass {
  @Type(() => Offer)
  data!: Offer[];
  recordsTotal!: number;
  recordsFiltered!: number;
}

export class Executor {
  userId!: string;
  userName!: string;
  storedFileId!: string;
}
