import { configureStore } from '@reduxjs/toolkit';
import { TOKEN_KEY, userReducer } from './user/user.slice';
import { vacancyReducer } from './vacancy/vacancy.slice';
import { teamReducer } from './team/team.slice';
import { SIDEBAR_VISIBILITY_KEY, uiReducer } from '@store/ui/ui.slice';
import { trackerReducer } from '@store/tasks/tracker.slice';
import { requestReducer } from './requests/requests.slice';
import { dictionaryReducer } from './dictionary/dictionary.slice';
import { cloudReducer } from '@store/cloud/cloud.slice';
import { chatApi } from '@store/messages/messages.service';
import { projectReducer } from './project/project.slice';
import { errorLogger } from './middlewares/errorLogger';
import { favoriteReducer } from './favorite/favorite.slice';
import { supportReducer } from '@store/support/support.slice';
import { publicVacancyReducer } from '@store/publicVacancy/publicVacancy.slice';
import { api } from '@store/api-client';
import { publicApi } from '@store/public-api';
import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { AppAbility } from './user/ability';

const userToken = JSON.parse(localStorage.getItem(TOKEN_KEY) || 'null');
const sidebar_visibility = JSON.parse(localStorage.getItem(SIDEBAR_VISIBILITY_KEY) || 'null');
const sidebarVisibility = sidebar_visibility !== null ? sidebar_visibility : true;

const { build } = new AbilityBuilder<AppAbility>(createMongoAbility);

export const store = configureStore({
  preloadedState: {
    user: {
      user: null,
      token: userToken,
      ability: build(),
      providers: null,
    },
    ui: {
      sidebarVisibility: sidebarVisibility,
      modal: null,
    },
  },
  reducer: {
    requests: requestReducer,
    user: userReducer,
    vacancy: vacancyReducer,
    publicVacancy: publicVacancyReducer,
    team: teamReducer,
    ui: uiReducer,
    tracker: trackerReducer,
    dictionary: dictionaryReducer,
    teams: teamReducer,
    [chatApi.reducerPath]: chatApi.reducer,
    cloud: cloudReducer,
    projects: projectReducer,
    favorite: favoriteReducer,
    support: supportReducer,
    [api.reducerPath]: api.reducer,
    [publicApi.reducerPath]: publicApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware, publicApi.middleware, chatApi.middleware, errorLogger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
