import React, { useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';
import { InputField } from '@components/MUI/InputField/InputField';
import { Button } from '@components/MUI/Button';
import styles from './DeploymentResourceForm.module.scss';
import { Select } from '@components/MUI/Select';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { ConfirmDeleteModal } from '@components/MUI/ConfirmDeleteModal/ConfirmDeleteModal';
import { FormControlLabel } from '@mui/material';
import { MuiSwitch } from '@components/ui/Switch/MuiSwith';

import {
  loadInfrastructures,
  selectInfrastructureOptions,
  loadTemplates,
  selectTemplateOptions,
  loadCloudDeploymentRules,
  selectDeploymentRuleOptions,
  loadCloudInstances,
  selectCloudInstanceOptions,
  loadImages,
  selectImageOptions,
  loadShedules,
  selectSheduleOptions,
  loadDeploymentActions,
  selectDeploymentActionOptions,
  loadZones,
  selectZoneOptions,
  loadPlatforms,
  selectPlatformOptions,
} from '@store/cloud/cloud.slice';

export type DeploymentResourceFormProps = {
  value: DeploymentResourceFormValue;
  submit: (
    value: DeploymentResourceFormValue,
    form: FormikBag<DeploymentResourceFormProps, DeploymentResourceFormValue>,
  ) => void;
};

export type DeploymentResourceFormValue = {
  name: string; // Наименование
  infrastructureId: string; // Инфраструктура
  instanceParamsId: string; // Шаблон виртуальной машины
  instances: string[]; // Список виртуальных машин
  ruleId: string; // Правила развертывания
  numberInstance: number; // Количество виртуальных машин для развертывания
  isUseMasterInstance: boolean; // Использовать мастер машину для образа
  masterInstanceId: string; // Мастер виртуальная машина, с которой копируется образ
  imageId: string; // Образ вртуальной машины
  sheduleId: string; // Расписание
  startActionId: string; // Действие на старте расписания
  endActionId: string; // Действие на окончании расписания
  zoneId: string; // Ресурс зоны
  zoneName: string; // Ресурс зоны
  platformId: string; // Ресурс платформы
  platformName: string; // Ресурс платформы
};

const DeploymentResourceFormSchema = Yup.object({
  name: Yup.string().required('Поле обязательно для заполнения'),
  infrastructureId: Yup.string().required('Поле обязательно для заполнения'),
  instanceParamsId: Yup.string().required('Поле обязательно для заполнения'),
  ruleId: Yup.string().required('Поле обязательно для заполнения'),
  numberInstance: Yup.number().when('ruleId', {
    is: '594265c2-b98f-43aa-a3ee-98a11d8f7b18',
    then: Yup.number().min(1, 'Количество виртуальных машин должно быть больше 0'),
  }),
  masterInstanceId: Yup.string().when('isUseMasterInstance', {
    is: false,
    then: Yup.string().required('Поле обязательно для заполнения'),
  }),
  imageId: Yup.string().when('isUseMasterInstance', {
    is: true,
    then: Yup.string().required('Поле обязательно для заполнения'),
  }),
  sheduleId: Yup.string(),
  startActionId: Yup.string(),
  endActionId: Yup.string(),
  zoneId: Yup.string(),
  platformId: Yup.string(),
  isUseMasterInstance: Yup.boolean(),
});

const FormComponent = () => {
  const form = useFormikContext<DeploymentResourceFormValue>();

  const dispatch = useAppDispatch();
  const infrastructureOptions = useAppSelector(selectInfrastructureOptions);
  const templateOptions = useAppSelector(selectTemplateOptions);
  const deploymentRuleOptions = useAppSelector(selectDeploymentRuleOptions);
  const cloudInstanceOptions = useAppSelector(selectCloudInstanceOptions);
  const imageOptions = useAppSelector(selectImageOptions);
  const sheduleOptions = useAppSelector(selectSheduleOptions);
  const deploymentActionOptions = useAppSelector(selectDeploymentActionOptions);
  const zoneOptions = useAppSelector(selectZoneOptions);
  const platformOptions = useAppSelector(selectPlatformOptions);

  const ruleIdWithNumberInstances = deploymentRuleOptions?.find(
    (rule) => rule.label === 'Указать количество экземпляров',
  )?.value;

  form.validationSchema = DeploymentResourceFormSchema;

  useEffect(() => {
    dispatch(loadInfrastructures({}));
    dispatch(loadTemplates({}));
    dispatch(loadCloudDeploymentRules({}));
    dispatch(loadCloudInstances({}));
    dispatch(loadImages({}));
    dispatch(loadShedules({}));
    dispatch(loadDeploymentActions({}));
    dispatch(loadZones({}));
    dispatch(loadPlatforms({}));
  }, [dispatch]);

  useEffect(() => {
    form.setFieldValue('zoneName', form.values.zoneId);
  }, [form.values.zoneId]);

  useEffect(() => {
    form.setFieldValue('platformName', form.values.platformId);
  }, [form.values.platformId]);

  const disableUseMasterInstance = useCallback(async () => {
    form.setFieldValue('isUseMasterInstance', false);
    dispatch(closeModal());
  }, []);

  const confirmTurnOffUseMasterInstance = useCallback(() => {
    dispatch(
      openModal(
        <ConfirmDeleteModal
          title="Вы действительно хотите отключить образ виртуальной машины?"
          btnProp="Отключить"
          onSubmit={disableUseMasterInstance}>
          <div></div>
        </ConfirmDeleteModal>,
      ),
    );
  }, [dispatch]);

  const toggleUseMasterInstance = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      confirmTurnOffUseMasterInstance();
    } else form.setFieldValue('isUseMasterInstance', event.target.checked);
  };

  return (
    <Form onSubmit={form.handleSubmit}>
      <div className={styles.form__wrapper}>
        <InputField
          name="name"
          placeholder="Название правила"
          type="text"
          className={styles.form__field}
        />
        <Select
          name="platformId"
          values={platformOptions}
          label={platformOptions.length === 0 ? 'Отсутствует интеграция с Яндекс' : 'Платформа'}
          disabled={platformOptions.length === 0}
          isOutlined
          className={styles.form__field}
        />
        <Select
          name="infrastructureId"
          values={infrastructureOptions}
          label={
            infrastructureOptions.length === 0
              ? 'Создайте инфраструктуру в разделе инфраструктура'
              : 'Инфраструктура'
          }
          isOutlined
          disabled={infrastructureOptions.length === 0}
          className={styles.form__field}
        />
        <Select
          name="zoneId"
          values={zoneOptions}
          label={zoneOptions.length === 0 ? 'Выполните интеграцию' : 'Зона'}
          disabled={zoneOptions.length === 0}
          isOutlined
          className={styles.form__field}
        />
        <Select
          name="instanceParamsId"
          values={templateOptions}
          label={templateOptions.length === 0 ? 'Создайте шаблон в разделе Шаблоны' : 'Шаблон'}
          disabled={templateOptions.length === 0}
          isOutlined
          className={styles.form__field}
        />
        <Select
          name="ruleId"
          values={deploymentRuleOptions}
          label="Правила"
          isOutlined
          className={styles.form__field}
        />
        {form.values.ruleId === ruleIdWithNumberInstances ? (
          <InputField
            name="numberInstance"
            placeholder="Количество виртуальных машин"
            type="number"
            className={styles.form__field}
          />
        ) : (
          <></>
        )}
        <div className="d-flex gap-4 align-items-center mb-4">
          Использовать образ виртуальной машины{' '}
          <FormControlLabel
            control={
              <MuiSwitch
                checked={form.values.isUseMasterInstance}
                onChange={toggleUseMasterInstance}
                name="isUseMasterInstance"
              />
            }
            label=""
          />
        </div>
        {!form.values.isUseMasterInstance ? (
          <Select
            name="masterInstanceId"
            values={cloudInstanceOptions}
            label="Мастер машина образа"
            isOutlined
            className={styles.form__field}
          />
        ) : (
          <></>
        )}
        {form.values.isUseMasterInstance ? (
          <Select
            name="imageId"
            values={imageOptions}
            label="Образ машины"
            isOutlined
            className={styles.form__field}
          />
        ) : (
          <></>
        )}
        <Select
          name="sheduleId"
          values={sheduleOptions}
          label="Расписание"
          isOutlined
          className={styles.form__field}
        />
        <Select
          name="startActionId"
          values={deploymentActionOptions}
          label="Действие на старте расписание"
          isOutlined
          className={styles.form__field}
        />
        <Select
          name="endActionId"
          values={deploymentActionOptions}
          label="Действие на окончании расписания"
          isOutlined
          className={styles.form__field}
        />
      </div>
      <Button
        type="submit"
        disabled={!form.isValid}
        color="success"
        variant="contained"
        size="large"
        className={styles.submit__button}>
        Сохранить
      </Button>
      <RequiredFieldsIndicator />
    </Form>
  );
};

export const DeploymentResourceForm = withFormik<
  DeploymentResourceFormProps,
  DeploymentResourceFormValue
>({
  displayName: 'DeploymentResourceForm',
  mapPropsToValues: (props) => {
    return {
      name: props.value.name,
      infrastructureId: props.value.infrastructureId,
      instanceParamsId: props.value.instanceParamsId,
      instances: props.value.instances,
      ruleId: props.value.ruleId,
      numberInstance: props.value.numberInstance,
      isUseMasterInstance: props.value.isUseMasterInstance,
      masterInstanceId: props.value.masterInstanceId,
      imageId: props.value.imageId,
      sheduleId: props.value.sheduleId,
      startActionId: props.value.startActionId,
      endActionId: props.value.endActionId,
      zoneId: props.value.zoneId,
      zoneName: props.value.zoneName,
      platformId: props.value.platformId,
      platformName: props.value.platformName,
    };
  },
  enableReinitialize: true,
  validationSchema: DeploymentResourceFormSchema,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
