import { Card } from 'react-bootstrap';
import {
  useDetailRequestQuery,
  useSetFavoriteRequestMutation,
} from '@store/requests/requests.slice';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '@hooks/index';
import { Button } from '@components/MUI/Button';
import { ReactComponent as EditIcon } from '@assets/img/editIconGreen.svg';
import { Can } from '@access-control';
import { selectCurrentUserId } from '@store/user/user.slice';
import { Star } from '@components/Star/Star';
import { StatusLabel, statusColors } from '@components/ui/StatusLabel/StatusLabel';
import download from '@assets/img/upload.svg';
import { downloadFile } from '@store/docs/utils';
import { RequestDetailButton } from './RequestDetailButton';
import styles from './RequestsPageDetail.module.scss';
import { ManagerBlock } from './ManagerBlock';
import { CustomerBlock } from './CustomerBlock';
import { CircularProgress } from '@mui/material';

export const RequestPageDetail: React.FC = () => {
  const navigate = useNavigate();
  const { requestId } = useParams();
  const myId = useAppSelector(selectCurrentUserId);
  const [setFavoriteRequest] = useSetFavoriteRequestMutation();

  const { data, isLoading } = useDetailRequestQuery({ projectId: requestId || '' });

  return (
    <>
      {isLoading ? (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      ) : (
        <Card className="form-container mb-5">
          <div className="mt-32 d-flex justify-content-between">
            <div className="d-flex">
              <h2 className="form-title m-0 me-2">{data?.name}</h2>
              {requestId && (
                <Star
                  checked={data?.favorite || false}
                  onChange={(value) =>
                    setFavoriteRequest({ requestBody: { status: value }, requestId: requestId })
                  }
                />
              )}
            </div>
            <Can I="update" a="Request">
              {myId === data?.customer?.id ? (
                data?.statusName !== 'Переведено в проект' ? (
                  <div className="me-4">
                    <Button
                      variant="outlined"
                      startIcon={<EditIcon />}
                      onClick={() => navigate(`/requests/${requestId}/edit`)}>
                      Редактировать
                    </Button>
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </Can>
          </div>
          {data?.statusName && (
            <StatusLabel
              text={data?.statusName || ''}
              color={statusColors.get(data?.statusName || '')}
              className={styles.status}
            />
          )}
          <div className="d-flex mt-2 mb-3 gap-4">
            <div className="d-flex flex-column">
              <span className="color-gray fs-6 fw-normal">Дата создания</span>
              <span className="fs-6 fw-500">{data?.created.format('DD.MM.YYYY')}</span>
            </div>
          </div>
          <CustomerBlock
            customerName={`${data?.customer?.about?.firstName}${data?.customer?.about?.lastName}`}
            customerId={data?.customer?.id}
            managerId={
              data?.managers && data?.managers.length > 0 ? data.managers[0].managerId : undefined
            }
          />
          <div className="d-flex flex-column mb-2">
            <h6 className="color-gray fs-6 fw-normal mb-0">Название проекта</h6>
            <h6 className="p-0 m-0 mb-2">{data?.name}</h6>
          </div>
          <div className="d-flex flex-column mb-2">
            <h6 className="color-gray fs-6 fw-normal mb-0">Тег</h6>
            <h6 className="p-0 m-0 mb-2">{data?.tag}</h6>
          </div>
          <div className="d-flex flex-column mb-2">
            <h6 className="color-gray fs-6 fw-normal mb-0">Доступность</h6>
            <h6 className="p-0 m-0 mb-2">{data?.visibilityStatusName}</h6>
          </div>
          <div className="d-flex gap-5 me-4 mb-4">
            <div className="d-flex flex-column mb-0">
              <h6 className="color-gray fs-6 fw-normal mb-0">Тип проекта</h6>
              <h6 className="p-0 m-0">{data?.projectTypeName}</h6>
            </div>
            <div className="d-flex flex-column mb-0">
              <h6 className="color-gray fs-6 fw-normal mb-0">Дедлайн проекта</h6>
              <h6 className="p-0 m-0">{data?.deadLine.format('DD.MM.YYYY')}</h6>
            </div>
          </div>
          <hr className={styles.divider} />
          <ManagerBlock
            managerName={
              data?.managers && data?.managers.length > 0
                ? data.managers.find((manager) => manager.statusName === 'Запрос принят')
                    ?.managerName
                : undefined
            }
            managerId={
              data?.managers && data?.managers.length > 0
                ? data.managers.find((manager) => manager.statusName === 'Запрос принят')?.managerId
                : undefined
            }
            customerId={data?.customer?.id}
            requestId={data?.id}
          />
          <div className="pb-5">
            <div className="d-flex flex-column">
              <h6 className="color-gray fs-6 fw-normal mb-0">Описание проекта</h6>
              <p className="p-0 m-0">{data?.description}</p>
            </div>
            <div className="d-flex gap-5 mt-3 mb-3">
              <div className="d-flex flex-column">
                <h6 className="color-gray fs-6 fw-normal mb-0">Бюджет проекта</h6>
                <h6 className="p-0 m-0">{data?.budget}</h6>
              </div>
              <div className="d-flex flex-column">
                <h6 className="color-gray fs-6 fw-normal mb-0">Валюта</h6>
                <h6 className="p-0 m-0">{data?.currencyName}</h6>
              </div>
            </div>
            <div className="d-flex flex flex-column mb-3">
              <h6 className="color-gray fs-6 fw-normal mb-0">Стек технологий</h6>
              <h6 className="p-0 m-0">{data?.stack}</h6>
            </div>
            {data?.attachments && data?.attachments.length > 0 ? (
              <div className={styles.attachments}>
                <p className={styles.attachments__title}>Вложения</p>
                {data?.attachments.map((attachment) => (
                  <button
                    className={styles.attachments__button}
                    key={attachment.id}
                    onClick={() => downloadFile(attachment.id || '')}>
                    <span className={styles.attachments__name}>{attachment.name}</span>
                    <img src={download} alt="загрузить" />
                  </button>
                ))}
              </div>
            ) : (
              <></>
            )}
            <div className="d-flex flex flex-column mb-3">
              <h6 className="color-gray fs-6 fw-normal mb-0">Требования к разработке</h6>
              <p className="p-0 m-0">{data?.requirement}</p>
            </div>
            <div className="d-flex flex flex-column">
              <h6 className="color-gray fs-6 fw-normal mb-0">Что необходимо сделать</h6>
              <p className="m-0 p-0">{data?.task}</p>
            </div>
          </div>
          <RequestDetailButton requestDetail={data || null} />
        </Card>
      )}
    </>
  );
};
