import React from 'react';
import { AmountOfProjects } from './Projects/AmountOfProjects/AmountOfProjects';
import { TimeExpensesAnalytics } from './Projects/TimeExpensesAnalytics/TimeExpensesAnalytics';
import { TasksByStatus } from './Projects/TasksByStatus/TasksByStatus';
import styles from './DashboardAnalyticsProjects.module.scss';

export const DashboardAnalyticsProjects: React.FC = () => {
  return (
    <div className={styles.projects__page}>
      <AmountOfProjects />
      <TimeExpensesAnalytics />
      <TasksByStatus />
    </div>
  );
};
