import { Outlet } from 'react-router-dom';
import { MainContentLayout } from '@layouts/MainContentLayout';
import { BalanceHeader } from '@layouts/balance/BalanceHeader';

export const BalanceLayout = () => {
  return (
    <MainContentLayout>
      <div>
        <BalanceHeader />
        <Outlet />
      </div>
    </MainContentLayout>
  );
};
