import { RatesCardBasic } from '@pages/rates/ratesCard/RatesCardBasic';
import { RatesCardPersonal } from '@pages/rates/ratesCard/RatesCardPersonal';
import styles from '../Rates.module.scss';
import { RatesCardPremium } from '@pages/rates/ratesCard/RatesCardPremium';

const benefits = [
  'До 10 проектов в портфолио',
  'Вывод проектов в топ (доп. опция)',
  'Открытый доступ к проектам для всех пользователей',
  'Выбор 1 направления',
  'До 3 откликов в сутки',
];

const benefitsPersonal = [
  'До 55 проектов в портфолио',
  'Вывод  до 10 проектов в топ',
  'Возможность ограничить доступ к проектам',
  'Выбор 5 направлений',
  'До 20 откликов в сутки',
];

const benefitsPremium = [
  'До 100 проектов в портфолио',
  'Вывод  до 15 проектов в топ',
  'Возможность ограничить доступ к проектам',
  'Неограниченный выбор направлений',
  'Неограниченное число откликов',
];

const rateOptionsPersonal = [
  { value: '600', label: '1 месяц - 600 ₽' },
  { value: '3240', label: '6 месяц — 3240 ₽' },
  { value: '5760', label: '12 месяцев — 5760 ₽' },
];

const rateOptionsPremium = [
  { value: '1199', label: '1 месяц - 1199 ₽' },
  { value: '6475', label: '6 месяц — 6475 ₽' },
  { value: '11510', label: '12 месяцев — 11510 ₽' },
];

export const PrivateProfileRatesForExecutor = () => {
  return (
    <div className={styles.gridLayout}>
      <RatesCardBasic list={benefits} title="6 месяцев бесплатно" />
      <RatesCardPersonal rateOptions={rateOptionsPersonal} list={benefitsPersonal} />
      <RatesCardPremium rateOptions={rateOptionsPremium} list={benefitsPremium} />
    </div>
  );
};
