import { FileUploadHandler } from '@doc-tools/yfm-editor/_/utils/upload';

function randomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function delay(ms: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, ms);
  });
}

function randomDelay(from: number, to: number): Promise<void> {
  const ms = randomInt(from, to);
  return delay(ms);
}

export const fileUploadHandler: FileUploadHandler = async (file) => {
  await randomDelay(1000, 3000);
  return { url: URL.createObjectURL(file) };
};
