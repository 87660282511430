import { DependencyList, useEffect } from 'react';

type UseAbortControllerOptions<T> = {
  fetch: (signal: AbortSignal) => Promise<T>;
  onError?: Parameters<Promise<T>['catch']>[0];
};

export function useAbortController<T>(
  { fetch, onError }: UseAbortControllerOptions<T>,
  deps: DependencyList,
) {
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetch(signal).catch((error: Error) => {
      if (onError) {
        return onError(error);
      }
      return Promise.reject(error);
    });
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, [...deps]);
}
