import { PaginationClass, ToDate } from '@store/common';
import { Type } from 'class-transformer';
import { Dayjs } from 'dayjs';

export class DocumentsResponse extends PaginationClass {
  @Type(() => Document)
  data!: Document[];
}

export class Document {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  name!: string;
  projectId!: string;
  projectName!: string;
}
