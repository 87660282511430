import React, { useMemo } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { ProjectTasksAnalytics } from '@store/dashboard/contracts';
import { localisedTaskStatuses } from './TaskStatusList';
import { statusTaskColors } from '@components/ui/StatusLabel/StatusLabel';

type ProjectBarsProps = {
  projects: ProjectTasksAnalytics[] | null;
};

type TasksCountByStatuses = {
  [statusName: string]: number | undefined;
};

export const ProjectBars: React.FC<ProjectBarsProps> = ({ projects }) => {
  const taskStatuses: string[] = ['new', 'paused', 'inProgress', 'done', 'cancelled'];

  const tasks = useMemo(() => {
    if (projects) {
      const projectsTasksStatuses = projects.map((project) => project?.statusCountTaskList);

      const tasksStatuses: TasksCountByStatuses[] = projectsTasksStatuses.map((project) => {
        const tasksCount: TasksCountByStatuses = {};
        for (let i = 0; i < taskStatuses.length; i++) {
          tasksCount[taskStatuses[i]] = project?.find(
            (status) => status?.statusType === taskStatuses[i],
          )?.taskCount;
        }
        return tasksCount;
      });
      return tasksStatuses;
    }
    return [];
  }, [projects]);

  const projectNames = useMemo(() => {
    if (projects) return projects.map((project) => project?.projectName);
    return [];
  }, [projects]);

  const statusColor = useMemo(() => {
    return Object.entries(localisedTaskStatuses).map(
      ([key, value]) => statusTaskColors.get(value.statusType) || '',
    );
  }, [taskStatuses]);

  const barChartSeries = useMemo(() => {
    return Object.entries(localisedTaskStatuses).map(([key, value]) => ({
      dataKey: value.statusType,
      label: value.name,
    }));
  }, [taskStatuses]);

  return (
    <div
      style={
        tasks.length > 8
          ? { width: '95%' }
          : tasks.length > 3
          ? { width: '80%' }
          : tasks.length === 1
          ? { width: '30%' }
          : { width: '50%' }
      }>
      <BarChart
        dataset={tasks.length > 0 ? tasks : [{ dataKey: '' }]}
        xAxis={[{ scaleType: 'band', data: projectNames }]}
        series={barChartSeries}
        height={350}
        colors={statusColor}
        slotProps={{
          legend: { hidden: true },
          bar: {
            clipPath: `inset(0px round 4px 4px 0px 0px)`,
          },
        }}
      />
    </div>
  );
};
