import { Pagination, PaginationClass, ToDate } from '@store/common';
import { Type } from 'class-transformer';
import { Dayjs } from 'dayjs';
import { About } from '@store/user/contracts';

export interface Request {
  id: string;
  created: string;
  changed: string;
  name: string;
  projectTypeId: string;
  projectType: string;
  deadLine: string;
  description: string;
  budget: number;
  currencyId: string;
  currency: string;
  url: string;
  stack: string;
  requirement: string;
  task: string;
  headId: string | null;
  head: string | null;
  customerId: string;
  customerName: string;
  managers?: Manager[];
}

export class RequestItem {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  name!: string;
  projectTypeId!: string;
  projectTypeName!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  deadLine!: Dayjs;
  description!: string;
  favorite!: boolean;
  budget!: number;
  currencyId!: string;
  currencyName!: string;
  url!: string;
  stack!: string;
  requirement!: string;
  task!: string;
  tag!: string;
  customer!: Customer;
  @Type(() => Manager)
  managers!: Manager[];
  attachments!: Attachment[];
  statusId!: string;
  statusName!: string;
  visibilityStatusId!: string;
  visibilityStatusName!: string;
}

export class Customer {
  about!: About;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  id!: string;
}

export class Manager {
  managerId!: string;
  managerName!: string;
  statusId!: string;
  statusName!: string;
}

export type ListRequestResponse = Pagination<Request>;

export interface PublishRequestResponse {
  success: boolean;
}

export class Attachment {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  name!: string;
  size!: number;
  mime!: string;
  extension!: string;
  tag!: string;
  @Type(() => Owner)
  owner!: Owner;
}

class Owner {
  userId!: string;
  userName!: string;
}

export interface RequestDetail {
  id: string;
  created: string;
  changed: string;
  name: string;
  projectTypeId: string;
  projectType: string;
  deadLine: string;
  description: string;
  favorite: boolean;
  budget: number;
  currencyId: string;
  currency: string;
  url: string;
  stack: string;
  requirement: string;
  task: string;
  customerId: string;
  customerName: string;
  managers: Manager[];
  attachments: Attachment[];
  statusId: string;
  statusName: string;
}

export class GetApprovedRequestsResponse extends PaginationClass {
  @Type(() => RequestItem)
  data!: RequestItem[];
}

export class GetRequests extends PaginationClass {
  @Type(() => RequestItem)
  data!: RequestItem[];
}
