import { Button } from '@components/MUI/Button';
import React from 'react';
import { ReactComponent as AddGreen } from '@assets/img/addGreen.svg';

type CreateTemplateButtonProps = {
  action?: () => void;
};

export const CreateTemplateButton: React.FC<CreateTemplateButtonProps> = ({ action }) => {
  return (
    <Button
      startIcon={<AddGreen />}
      onClick={action}
      style={{ display: 'flex', justifyContent: 'start', maxWidth: 160 }}>
      Создать шаблон
    </Button>
  );
};
