import React, { CSSProperties, useEffect, useState } from 'react';
import { ReactComponent as StarIcon } from '@assets/img/star.svg';
import styles from './Star.module.scss';
import classNames from 'classnames';

type StarProps = {
  checked: boolean;
  onChange: (value: boolean) => Promise<any>;
  className?: string;
  style?: CSSProperties;
};

export const Star: React.FC<StarProps> = ({ checked, onChange, className, style }) => {
  const [isActive, setIsActive] = useState(checked);

  useEffect(() => {
    setIsActive(checked);
  }, [checked]);

  return (
    <button
      className={
        isActive
          ? classNames(styles.star, styles.active, className)
          : classNames(styles.star, className)
      }
      onClick={() => {
        onChange(!isActive).then(() => setIsActive((prev) => !prev));
      }}
      style={style}>
      <StarIcon />
    </button>
  );
};
