import React from 'react';
import { TimeItem } from '@store/tasks/contracts';
import { ReactComponent as DeleteEcon } from '@assets/img/deleteTrashIcon.svg';
import { useAppDispatch } from '@hooks/index';
import { openModal } from '@store/ui/ui.slice';
import { DeleteTimeModal } from './DeleteTimeModal';
import styles from './SpentTimeItem.module.scss';

export type SpentTimeItemProps = {
  timeItem: TimeItem;
};

export const SpentTimeItem: React.FC<SpentTimeItemProps> = ({ timeItem }) => {
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    dispatch(openModal(<DeleteTimeModal timeItem={timeItem} />));
  };

  return (
    <div className={styles.time__item}>
      <div>{timeItem?.timeSpent}</div>
      <div>{timeItem?.date}</div>
      <div>
        {timeItem?.comment === null ? (
          <span className={styles.time__item__empty__comment}>Отсутствует</span>
        ) : (
          timeItem?.comment
        )}
      </div>
      <button onClick={handleDelete}>
        <DeleteEcon />
      </button>
    </div>
  );
};
