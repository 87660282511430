import React from 'react';
import { Modal } from '@components/MUI/Modal/Modal';
import { TableChildren } from '@components/Table/TableMUI';
import styles from './ActionsModal.module.scss';

type ActionsModalProps = {
  children?: TableChildren;
  value?: string | number;
  row?: any;
};

export const ActionsModal: React.FC<ActionsModalProps> = ({ children, value, row }) => {
  return (
    <Modal>
      <div className={styles.actions__modal__wrapper}>
        <div className={styles.actions__modal}>{children && children(value, row)}</div>
      </div>
    </Modal>
  );
};
