import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { TextField } from '@mui/material';
import { useAppDispatch } from '@hooks/index';
import { FilterModal } from './FilterModal';
import { openModal } from '@store/ui/ui.slice';
import { ReactComponent as ArrowDown } from '@assets/img/ArrowDownLittle.svg';
import { debounce } from 'lodash';
import { AdaptiveTableMUIProps, Columns } from '@components/Table/TableMUI';
import styles from './Filter.module.scss';

export const Filter: React.FC<AdaptiveTableMUIProps> = ({ setRequestBody, columns }) => {
  const dispatch = useAppDispatch();

  const filterColumns = useMemo(() => {
    const filterColumns = Object.entries(columns).filter((column) => column[1].filterInput);
    return filterColumns;
  }, [columns]);

  const [filterColumn, setFilterColumn] = useState<[string, Columns]>(filterColumns[0]);

  const handleFilter = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      const column = filterColumn;
      const requestBody = {
        [column[0]]: {
          searchType: 'LIKE',
          sort: false,
          value: event.target.value.trim(),
        },
      };
      setRequestBody?.(requestBody);
    }, 500),
    [setRequestBody, filterColumn],
  );

  const handleFilterModal = () => {
    dispatch(
      openModal(
        <FilterModal
          filterColumns={filterColumns}
          setFilterColumn={setFilterColumn}
          filterColumn={filterColumn}
        />,
      ),
    );
  };

  return (
    <>
      {filterColumn ? (
        <div className={styles.filter__wrapper}>
          <div className={styles.search__by}>Поиск по</div>
          <button className={styles.filter__choosing} onClick={handleFilterModal}>
            <span className={styles.filter__choosing__title}>
              {filterColumn ? filterColumn[1].label : <></>}
            </span>
            <ArrowDown />
          </button>
          <TextField
            placeholder="Поиск"
            className={styles.search__input}
            size="small"
            onChange={handleFilter}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
