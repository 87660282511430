import React from 'react';

export const AnalyticsPropjectsIcon = () => {
  return (
    <svg
      className="icon me-2"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.025 0.0498047C13.4084 0.283138 15.4336 1.23487 17.1009 2.905C18.7681 4.57514 19.7178 6.5984 19.95 8.9748H12.85C12.7167 8.54147 12.4875 8.1748 12.1625 7.8748C11.8375 7.5748 11.4584 7.35814 11.025 7.2248V0.0498047ZM12.525 1.9498V6.2998C12.7584 6.4498 12.9834 6.62064 13.2 6.8123C13.4167 7.00397 13.5917 7.2248 13.725 7.4748H18.05C17.6334 6.14147 16.9417 4.9873 15.975 4.0123C15.0084 3.0373 13.8584 2.3498 12.525 1.9498ZM8.97503 0.0498047V7.2248C8.39169 7.45814 7.92086 7.8347 7.56253 8.3545C7.20419 8.8743 7.02502 9.44774 7.02502 10.0748C7.02502 10.6762 7.20419 11.2182 7.56253 11.7008C7.92086 12.1835 8.39169 12.5339 8.97503 12.752V19.9498C6.42502 19.7165 4.29586 18.6581 2.58752 16.7748C0.879191 14.8915 0.0250244 12.6581 0.0250244 10.0748C0.0250244 7.49147 0.875024 5.2373 2.57502 3.3123C4.27502 1.3873 6.40836 0.299805 8.97503 0.0498047ZM7.47503 1.9498C5.65836 2.4498 4.21252 3.45814 3.13752 4.9748C2.06252 6.49147 1.52502 8.19147 1.52502 10.0748C1.52502 11.9248 2.07502 13.5873 3.17502 15.0623C4.27502 16.5373 5.70836 17.5415 7.47503 18.0748V13.7248C6.85836 13.3081 6.37919 12.7831 6.03753 12.1498C5.69586 11.5165 5.52502 10.8248 5.52502 10.0748C5.52502 9.32481 5.69169 8.61647 6.02502 7.9498C6.35836 7.28314 6.84169 6.73314 7.47503 6.2998V1.9498ZM12.85 11.0248H19.95C19.7167 13.4081 18.765 15.4334 17.0948 17.1006C15.4247 18.7678 13.4014 19.7176 11.025 19.9498V12.7498C11.4584 12.6165 11.8375 12.404 12.1625 12.1123C12.4875 11.8206 12.7167 11.4581 12.85 11.0248ZM13.7292 12.5248C13.5598 12.7581 13.3792 12.9831 13.1875 13.1998C12.9959 13.4165 12.775 13.5915 12.525 13.7248V18.0498C13.8584 17.6665 15.0084 16.9831 15.975 15.9998C16.9417 15.0165 17.6334 13.8581 18.05 12.5248H13.7292Z"
        fill="#78858B"
      />
    </svg>
  );
};
