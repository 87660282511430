import Box from '@mui/material/Box';
import styles from './ModeSelection.module.scss';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

export function ModeSelection() {
  const activeLink = classNames(styles.activeLink);
  const isMobile = useMediaQuery('(max-width: 450px)');
  return (
    <>
      <Box
        sx={{
          padding: '11px 72px',
          '@media (min-width: 768px) and (max-width: 1200px)': {
            paddingRight: '36px',
            paddingLeft: '36px',
          },
          '@media (max-width: 767px)': {
            padding: '11px 15px',
          },
        }}>
        <div className={styles.container}>
          <div className={styles.modes}>
            <NavLink
              to="/specialist"
              className={({ isActive }) =>
                isActive ? `${activeLink} ${styles.active}` : `${activeLink}`
              }>
              Исполнителям
            </NavLink>
            <NavLink
              to="/customer"
              className={({ isActive }) =>
                isActive ? `${activeLink} ${styles.active}` : `${activeLink}`
              }>
              Заказчикам
            </NavLink>
            <NavLink
              to="/university"
              className={({ isActive }) =>
                isActive ? `${activeLink} ${styles.active}` : `${activeLink}`
              }>
              {isMobile ? 'Университетам' : 'Учебным заведениям'}
            </NavLink>
            <NavLink
              to="/startup"
              className={({ isActive }) =>
                isActive ? `${activeLink} ${styles.active}` : `${activeLink}`
              }>
              Стартапам
            </NavLink>
          </div>
        </div>
      </Box>
    </>
  );
}
