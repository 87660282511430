import {
  RemoveRequisiteRequest,
  RequisiteRemoveResponse,
  updateRequisiteRequest,
  RequisiteUpdateResponse,
} from './contracts';
import { ResponseBody, RequestBody, PathParameters } from '@store/utility';
import { api } from '@store/api-client';
import Api from '@api-schema';

const requisiteApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listRequisites: builder.query<
      ResponseBody<Api.operations['getRequisites']>,
      RequestBody<Api.operations['getRequisites']>
    >({
      query: (request: RequestBody<Api.operations['getRequisites']>) => ({
        url: 'api/requisite/list',
        method: 'POST',
        body: {
          start: request.start,
          limit: request.limit,
          sortOrder: request.sortOrder,
        },
      }),
      providesTags: ['RequisiteList'],
    }),

    getRequisiteDetail: builder.query<
      ResponseBody<Api.operations['getRequisite']>,
      { id: PathParameters<Api.operations['getRequisite'], 'id'> }
    >({
      query: ({ id }) => ({
        url: `/api/requisite/${id}`,
        method: 'GET',
      }),
      providesTags: ['Requisite'],
    }),

    createRequisite: builder.mutation<
      ResponseBody<Api.operations['createRequisite']>,
      RequestBody<Api.operations['createRequisite']>
    >({
      query: (request) => ({
        url: '/api/requisite',
        method: 'POST',
        body: {
          accountNumber: request.accountNumber,
          bankName: request.bankName,
          bik: request.bik,
          corporateAccount: request.corporateAccount,
          inn: request.inn,
          kpp: request.kpp,
        },
      }),
      invalidatesTags: ['RequisiteList'],
    }),

    updateRequisite: builder.mutation<RequisiteUpdateResponse, updateRequisiteRequest>({
      query: (request) => ({
        url: `/api/requisite`,
        method: 'PUT',
        body: {
          accountNumber: request.accountNumber,
          bankName: request.bankName,
          bik: request.bik,
          corporateAccount: request.corporateAccount,
          inn: request.inn,
          kpp: request.kpp,
          id: request.id,
        },
      }),
      invalidatesTags: ['RequisiteList', 'Requisite'],
    }),

    removeRequisite: builder.mutation<RequisiteRemoveResponse, RemoveRequisiteRequest>({
      query: (request) => ({
        url: `/api/requisite/${request.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['RequisiteList'],
    }),
  }),
});

export const {
  useListRequisitesQuery,
  useGetRequisiteDetailQuery,
  useCreateRequisiteMutation,
  useUpdateRequisiteMutation,
  useRemoveRequisiteMutation,
} = requisiteApi;
