import { Helper } from '@components/ui/Helper';
import styles from './Effectiveness.module.scss';
import { EffectivenessChart } from './EffectivenessChart';
import { useFormikContext } from 'formik';
import { FormValues } from '../AdvancedAnalytics';
import { useParams } from 'react-router-dom';
import { useGetEffectivenessAnalyticQuery } from '@store/project/project.slice';
import { useChartData } from '@hooks/useChartData';
import { EffectivenessAnalyticResponse } from '@store/project/contracts';
import dayjs from 'dayjs';

export const Effectiveness = () => {
  const { values } = useFormikContext<FormValues>();
  const { projectId } = useParams();
  const projectIdToUse = projectId || '';

  const { data } = useGetEffectivenessAnalyticQuery({
    path: {
      projectId: projectIdToUse,
    },
    query: {
      startDate: values.period[0].format('YYYY-MM-DD'),
      endDate: values.period[1].format('YYYY-MM-DD'),
    },
  });

  const { chartData, axisXData, isEmpty } = useChartData<EffectivenessAnalyticResponse>({
    data: data ?? [],
    chartProperty: 'percent',
    axisXProperty: 'date',
  });

  const formattedAxisXDate = axisXData.map((date) => dayjs(date).format('DD.MM'));
  return (
    <>
      <div
        className={styles.container}
        style={{
          order: isEmpty ? 2 : 1,
        }}>
        <h2 className={styles.header}>
          Effectiveness (Эффективность){' '}
          {
            <Helper title="Диаграмма, которая отображает процент времени, которое тратится непосредственно на работу с задачей, а не на ожидания в различных очередях. " />
          }
        </h2>
        <p className={styles.period}>
          {values.period[0].format('DD.MM.YYYY')} - {values.period[1].format('DD.MM.YYYY')}
        </p>
        {!isEmpty ? (
          <>
            <div className={styles.chart_container}>
              <EffectivenessChart axisXData={formattedAxisXDate} chartData={chartData} />
            </div>
            <div className={styles.chart_legend}>
              <div className={styles.chart_legend__mark}></div>
              <div className={styles.chart_legend__label}>Задачи</div>
            </div>
          </>
        ) : (
          <div className={styles.emptyData}>
            <p>Нет данных</p>
          </div>
        )}
      </div>
    </>
  );
};
