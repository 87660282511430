import { PaginationItem as MPaginationItem, styled } from '@mui/material';

export const PaginationItem = styled(MPaginationItem)({
  '&.Mui-selected': {
    backgroundColor: 'transparent',
    outline: 'solid 1px #199B3E',
  },
  backgroundColor: 'transparent',
  color: '#78858B',
});
