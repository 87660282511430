import { Modal, ModalFooter, ModalProps } from '@components/MUI/Modal/Modal';
import { FC, useCallback } from 'react';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import { Requisite } from '@store/requisite/contracts';
import { FormWrapper } from '@components/Form/Form';
import { Button } from '@components/MUI/Button';
import styles from './Requisite.module.scss';

type RequisiteDeleteModalProps = {
  requisite: Requisite;
  onDelete: () => void;
} & ModalProps;

export const RequisiteDeleteModal: FC<RequisiteDeleteModalProps> = (
  props: RequisiteDeleteModalProps,
) => {
  const dispatch = useAppDispatch();
  const { onDelete, ...modalProps } = props;

  const cancel = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <Modal {...modalProps}>
      <FormWrapper
        label={`Вы действительно хотите удалить реквизиты ${props.requisite.accountNumber}?`}
        isThinTitle>
        <div>
          <p>Отменить это действие будет невозможно</p>
        </div>
        <ModalFooter>
          <Button onClick={cancel} className={`btn btn-sm me-3 ${styles.cancelBtn}`}>
            Отменить
          </Button>
          <Button onClick={onDelete} variant="contained">
            Удалить
          </Button>
        </ModalFooter>
      </FormWrapper>
    </Modal>
  );
};
