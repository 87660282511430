import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, ListItemText, MenuItem, Paper, Select, SelectChangeEvent } from '@mui/material';
import { ProjectBars } from './ProjectBars';
import { ProjectTasksAnalytics } from '@store/dashboard/contracts';
import { TaskStatusList } from './TaskStatusList';
import styles from './TasksByStatus.module.scss';
import { useLazyGetTaskStatusesQuery } from '@store/dashboard/dashboard.slice';

export const TasksByStatus: React.FC = () => {
  const [projectsForSelect, setProjectsForSelect] = useState<ProjectTasksAnalytics[]>([]);
  const [selectedProjectsNames, setSelectedProjectsNames] = useState<string[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<ProjectTasksAnalytics[]>([]);
  const [getTaskStatuses] = useLazyGetTaskStatusesQuery();

  useEffect(() => {
    getTaskStatuses({}).then((projectsTasksTime) => {
      if (projectsTasksTime.data) setProjectsForSelect(projectsTasksTime.data);
    });
  }, []);

  const selectedProjectsIds = useMemo(() => {
    const selectedProjects = projectsForSelect
      ?.filter((project) =>
        selectedProjectsNames.find((projectName) => project.projectName === projectName),
      )
      .map((project) => project.projectId || '');
    return selectedProjects;
  }, [selectedProjectsNames]);

  useEffect(() => {
    if (selectedProjectsIds.length > 0) {
      getTaskStatuses({ projectIds: selectedProjectsIds }).then((projects) => {
        if (projects.data) setSelectedProjects(projects.data);
      });
    }
  }, [selectedProjectsIds]);

  const handleChange = (event: SelectChangeEvent<typeof selectedProjectsNames>) => {
    const {
      target: { value },
    } = event;
    setSelectedProjectsNames(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Paper elevation={0} variant="outlined" className={styles.container}>
      <h2 className={styles.title}>Количество задач по статусам</h2>
      <p className={styles.select__label}>Проекты</p>
      <Select
        displayEmpty
        onChange={handleChange}
        className={styles.select}
        multiple
        value={selectedProjectsNames}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <span>Все проекты</span>;
          }
          return selected.join(', ');
        }}
        sx={{
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#21B84B',
            },
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #21B84B',
            boxShadow:
              '-2px -2px 4px 0 hsla(174, 71%, 88%, 0.3), 2px 2px 4px 0 hsla(174, 71%, 88%, 0.3)',
          },
          outline: 'none',
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#A7A7A7',
            },
            opacity: 0.6,
            pointerEvents: 'none',
            background: '#A7A7A7',
          },
        }}>
        {projectsForSelect ? (
          projectsForSelect.map((project) => (
            <MenuItem value={project.projectName}>
              <Checkbox
                checked={
                  project.projectName
                    ? selectedProjectsNames.indexOf(project.projectName) > -1
                    : false
                }
              />
              <ListItemText primary={project.projectName} />
            </MenuItem>
          ))
        ) : (
          <></>
        )}
      </Select>
      <div className={styles.content}>
        <ProjectBars
          projects={
            selectedProjectsNames.length > 0
              ? selectedProjects
              : projectsForSelect.length > 0
              ? projectsForSelect
              : null
          }
        />
        <TaskStatusList />
      </div>
    </Paper>
  );
};
