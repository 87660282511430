import { ApiClient } from '@store/api-client';
import { plainToInstance } from 'class-transformer';
import { YandexOrganization, YandexOrganizationProject } from '@store/user/contracts';

export const resolveConnectLinkToYandex = async (redirect: string) => {
  const { data } = await ApiClient.get<string>(`api/integration/yandex/connect`, {
    params: {
      redirect,
    },
  });
  return data;
};

export const disconnectYandexIntegration = async () => {
  await ApiClient.post(`api/integration/yandex/disconnect`);
};

export const listYandexOrganization = async () => {
  const { data } = await ApiClient.get<any[]>(`api/integration/yandex/organizations`);
  return plainToInstance(YandexOrganization, data);
};

export const listProjectInYandexOrganization = async (orgId: string) => {
  const { data } = await ApiClient.get<any[]>(
    `api/integration/yandex/organizations/${orgId}/projects`,
  );
  return plainToInstance(YandexOrganizationProject, data);
};
