import React, { useEffect, useState } from 'react';
import { CardsBlock } from './CardsBlock/CardsBlock';
import { PieBlock } from './PieBlock/PieBlock';
import { getCommonAnalytics } from '@store/project/project.service';
import { CommonAnalytics } from '@store/project/contracts';
import styles from './CommonAnalyticsBlock.module.scss';

export type CommonAnalyticsBlockProps = {
  commonAnalytics: CommonAnalytics | null;
};

export const CommonAnalyticsBlock: React.FC = () => {
  const [commonAnalytics, setCommonAnalytics] = useState<CommonAnalytics | null>(null);

  useEffect(() => {
    getCommonAnalytics().then((analytics) => setCommonAnalytics(analytics));
  }, []);

  return (
    <div>
      <div className={styles.common__analytics}>
        <CardsBlock commonAnalytics={commonAnalytics} />
        <PieBlock commonAnalytics={commonAnalytics} />
      </div>
    </div>
  );
};
