import React, { useCallback, useEffect, useState } from 'react';
import { Messages } from './Messages';
import { MessageForm, MessageFormValue } from './InputMessage';
import { ChatHeader } from './ChatHeader';
import { useParams } from 'react-router-dom';
import { useChatQuery, useSendMessageMutation } from '@store/messages/messages.service';
import { Message } from '@store/messages/contracts';

export const Chat: React.FC = () => {
  const { chatId, page, messageId } = useParams();

  const [start, setStart] = useState(0);
  const [messages, setMessages] = useState<Message[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [id, setId] = useState(chatId);

  const { data: chat } = useChatQuery({
    path: {
      id: chatId || '',
    },
    query: {
      limit: 10,
      start: start,
    },
  });

  useEffect(() => {
    setId(chatId);

    if (chatId !== id) {
      setStart(0);
      setMessages([]);
    }

    if (messageId && page) {
      const messagePage = Number(page);
      setStart((messagePage - 1) * 10);
    }

    if (chat?.messages && chatId === id) {
      const newMessges = [...messages, ...chat.messages.data];
      setMessages(newMessges);
    }

    if (chat?.messages.totalCount) setTotalCount(chat?.messages.totalCount);
  }, [chat, id]);

  const [sendMessage] = useSendMessageMutation();
  const sendMessageAtChat = useCallback(
    ({ message }: MessageFormValue) => {
      if (chatId === undefined) return;
      sendMessage({
        chatId,
        text: message,
      });
    },
    [sendMessage, chatId],
  );

  if (chatId === undefined) {
    return <></>;
  }
  if (chat === undefined) {
    return <></>;
  }

  return (
    <div
      className="col-sm-12 col-lg-8 d-none d-md-block border-start p-0  position-relative"
      style={{ background: '#FAFAFA' }}>
      <ChatHeader title={chat.chatName} />
      <Messages messages={messages} start={start} setStart={setStart} totalCount={totalCount} />
      <MessageForm value={{ message: '' }} submit={sendMessageAtChat} />
    </div>
  );
};
