import { useState } from 'react';
import { AnalyticsVacancies } from '@store/dashboard/contracts';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { StatusLabel, statusColors } from '@components/ui/StatusLabel/StatusLabel';
import { useRequestBody } from '@hooks/useRequestBody';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { AdaptiveActionButtons } from '@components/adaptives/AdaptiveActionButtons';
import { useGetVacanciesAnalyticsQuery } from '@store/dashboard/dashboard.slice';
import { useAcceptOfferMutation, useDenyOfferMutation } from '@store/offer/offer.slice';

export const DashboardInfoAboutVacancy: React.FC = () => {
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();
  const [denyOffer] = useDenyOfferMutation();
  const [acceptOffer] = useAcceptOfferMutation();

  const { data: vacancies } = useGetVacanciesAnalyticsQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handleApprove = (row: AnalyticsVacancies) => {
    acceptOffer(row.id);
    dispatch(closeModal());
  };

  const handleClose = (row: AnalyticsVacancies) => {
    denyOffer(row.id);
    dispatch(closeModal());
  };

  return (
    <AdaptiveTableMUI
      rows={vacancies?.data || []}
      setRequestBody={setRequestBody}
      setSortColumn={setSortColumn}
      setSortOrder={setSortOrder}
      sortOrder={sortOrder}
      sortColumn={sortColumn}
      columns={{
        vacancyName: {
          label: 'Вакансия',
          children: (value, row: AnalyticsVacancies) => (
            <Link to={`/vacancies/${row.vacancyId}`}>{value}</Link>
          ),
          filterInput: true,
          isSortColumn: true,
        },
        actionsRaw: {
          children: () => <div></div>,
        },
        offerStatusName: {
          label: 'Статус',
          children: (value) => <StatusLabel text={value} color={statusColors.get(value)} />,
          filterInput: true,
        },
        payment: {
          label: 'Ставка',
          filterInput: true,
          isSortColumn: true,
        },
        actions: {
          children: (_, row: AnalyticsVacancies) =>
            row.offerStatusName === 'Оффер от менеджера' ? (
              <AdaptiveActionButtons
                handleApprove={() => handleApprove(row)}
                handleClose={() => handleClose(row)}
                withoutSend
              />
            ) : (
              <></>
            ),
        },
      }}>
      <TablePaginationMUI
        limit={limit}
        count={vacancies?.totalCount}
        entityLabel="вакансий"
        page={page}
        handleChangePage={(_, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </AdaptiveTableMUI>
  );
};
