import React, { useMemo, useState } from 'react';
import { Columns } from '@components/Table/TableMUI';
import { useRequestBody } from '@hooks/useRequestBody';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { Link, useParams } from 'react-router-dom';
import { TaskStatus } from '@store/project/contracts';
import styles from './EmployeesAnalytics.module.scss';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { useGetTeamAnalyticQuery } from '@store/project/project.slice';

type TableEmployeesRaw = Record<string, string | number>;

export const EmployessAnalytics: React.FC = () => {
  const { projectId } = useParams();
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();

  const { data } = useGetTeamAnalyticQuery({
    projectId: projectId ? projectId : '',
    requestBody: {
      limit: limit,
      start: (page - 1) * limit,
      sortOrder: sortOrder,
      ...requestBody,
    },
  });

  const employees = useMemo(() => {
    const employees = data?.data || [];
    return employees;
  }, [data]);

  const tableEmployeesColumns = useMemo(() => {
    const tableEmployeesColumns: Record<string, Columns> = {
      userName: {
        label: 'Username',
        filterInput: true,
        children: (value, row) => <Link to={`/userPage/${row.id}`}>{value}</Link>,
      },
      positionName: {
        label: 'Роль',
        filterInput: true,
      },
    };

    let taskStatuses: TaskStatus[] = [];
    if (employees.length > 0) taskStatuses = employees[0].tasks;

    taskStatuses.forEach((status) => {
      tableEmployeesColumns[status.statusName] = {
        label: status.statusName,
      };
    });

    tableEmployeesColumns.workedHours = {
      label: 'Отработанные часы',
    };

    return tableEmployeesColumns;
  }, [employees]);

  const tableEmployeesRaws = useMemo(() => {
    const tableEmployeesRaws: TableEmployeesRaw[] = employees.map((employee) => {
      const tableEmployeesRaw: TableEmployeesRaw = {
        positionName: employee.positionName,
        userName: employee.userName,
        workedHours: employee.workedHours,
        id: employee.id,
      };

      employee.tasks.forEach((task) => {
        tableEmployeesRaw[task.statusName] = task.count;
      });

      return tableEmployeesRaw;
    });
    return tableEmployeesRaws;
  }, [employees]);

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <div className={styles.employee__block}>
      <h2 className={styles.title}>Аналитика по сотрудникам</h2>
      <AdaptiveTableMUI
        setRequestBody={setRequestBody}
        setSortColumn={setSortColumn}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        sortColumn={sortColumn}
        columns={tableEmployeesColumns}
        rows={tableEmployeesRaws}
        emptyLabel={'У вас пока нет сотрудников'}>
        <TablePaginationMUI
          limit={limit}
          count={data?.totalCount}
          entityLabel="сотрудников"
          page={page}
          handleChangePage={(_, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </AdaptiveTableMUI>
    </div>
  );
};
