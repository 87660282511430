import React from 'react';
import styles from './CycleTimeChart.module.scss';
import { BarChart } from '@mui/x-charts/BarChart';
import { CustomBar } from '../components/CustomBar/CustomBar';

interface ICycleTimeChart {
  chartData: number[];
  axisData: string[];
}

const sx = {
  '& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel': {
    fill: '#199B3E',
    fontSize: '14px',
    fontWeight: 400,
  },
};

export const CycleTimeChart: React.FC<ICycleTimeChart> = ({ chartData, axisData }) => {
  return (
    <div className={styles.cycle__time__chart}>
      <div className={styles.cycle__time__chart__wrapper}>
        <BarChart
          height={300}
          sx={sx}
          xAxis={[
            {
              data: axisData,
              scaleType: 'band',
            },
          ]}
          slotProps={{
            bar: {
              clipPath: `inset(0px round 4px 4px 0px 0px)`,
            },
          }}
          slots={{
            bar: (props) => (
              <>
                <CustomBar barChartProps={props} seriesData={chartData} />
              </>
            ),
          }}
          series={[
            {
              color: '#FE5959',
              data: chartData,
            },
          ]}
        />
      </div>
    </div>
  );
};
