import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styles from './Table.module.scss';
import { Input } from '@mui/base';
import { ReactComponent as ArrowDownIcon } from '@assets/img/sortArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from '@assets/img/sortArrowUp.svg';
import { debounce } from 'lodash';

export type RequestParams = {
  searchType: string; //'LIKE' | 'EQUAL' | 'LESS' | 'GREATER';
  value: string | undefined;
  sort: boolean;
};

interface TableHeadCellProps {
  value: RequestParams;
  label?: string;
  sortColumn?: string;
  setSortColumn?: (column: string) => void;
  column: string;
  isSortColumn: boolean | undefined;
  isFilterColumn: boolean | undefined;
  isCheckboxColumn?: boolean | undefined;
  sortOrder?: 'asc' | 'desc';
  additionalItem?: JSX.Element;
  setSortOrder?: (sortOrder: 'asc' | 'desc') => void;
  onChange: (value: RequestParams) => void;
}

export const TableHeadCell = ({
  value = {
    searchType: 'LIKE',
    value: undefined,
    sort: true,
  },
  onChange,
  label,
  setSortColumn,
  column,
  isSortColumn,
  isFilterColumn,
  isCheckboxColumn,
  setSortOrder,
  sortOrder,
  sortColumn,
  additionalItem,
}: TableHeadCellProps) => {
  const [requestParams, setRequestParams] = useState<RequestParams>(value);

  useEffect(() => {
    if (requestParams !== value) {
      onChange(requestParams);
    }
  }, [requestParams]);

  const setAscending = useCallback(() => {
    setSortColumn && setSortColumn(column);
    setSortOrder && setSortOrder('asc');
    setRequestParams((prev) => {
      return {
        ...prev,
        sort: true,
      };
    });
  }, [setRequestParams]);

  const setDescending = useCallback(() => {
    setSortColumn && setSortColumn(column);
    setSortOrder && setSortOrder('desc');
    setRequestParams((prev) => {
      return {
        ...prev,
        sort: true,
      };
    });
  }, [setRequestParams]);

  const setValue = useCallback(
    debounce(
      (event: ChangeEvent<HTMLInputElement>) =>
        setRequestParams((prev) => {
          return {
            ...prev,
            value: event.target.value.trim(),
          };
        }),
      500,
    ),
    [setRequestParams],
  );

  return (
    <>
      <div className={styles.columnRow}>
        <p className={styles.columnLabel}>{label}</p>
        {additionalItem ? additionalItem : <></>}
        {isSortColumn ? (
          <div className={styles.sort__button}>
            <button onClick={setAscending}>
              <ArrowUpIcon
                fill={sortOrder === 'asc' && column === sortColumn ? '#21B84B' : '#A7A7A7'}
              />
            </button>
            <button onClick={setDescending}>
              <ArrowDownIcon
                fill={sortOrder === 'desc' && column === sortColumn ? '#21B84B' : '#A7A7A7'}
              />
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
      {isCheckboxColumn ? <input type="checkbox" className={styles.tableCheckbox} /> : null}
      {isFilterColumn ? (
        column === 'created' ||
        column === 'deadLine' ||
        column === 'deadline' ||
        column === 'changed' ? (
          <Input
            placeholder="Выберите дату"
            type="date"
            slotProps={{
              input: {
                className: styles.filterInput,
                onChange: setValue,
                'aria-label': label,
              },
            }}
          />
        ) : (
          <Input
            placeholder="Поиск"
            slotProps={{
              input: {
                className: styles.filterInput,
                onChange: setValue,
                'aria-label': label,
              },
            }}
          />
        )
      ) : (
        <></>
      )}
    </>
  );
};
