import { Button } from '@components/MUI/Button';
import { useAppDispatch } from '@hooks/index';
import { CreateVacancy } from '@pages/vacancy/CreateVacancy';
import { openModal } from '@store/ui/ui.slice';
import React from 'react';

type AssignVacancyButtonProps = {
  positionId: string;
  teamId: string;
  stack?: string;
};

export const AssignVacancyButton: React.FC<AssignVacancyButtonProps> = ({
  positionId,
  stack,
  teamId,
}) => {
  const dispatch = useAppDispatch();
  const handleOpenModal = () => {
    dispatch(openModal(<CreateVacancy positionId={positionId} stack={stack} teamId={teamId} />));
  };

  return (
    <Button onClick={handleOpenModal} variant="contained" disableElevation={true}>
      Назначить
    </Button>
  );
};
