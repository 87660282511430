import { DictionaryName, DictionaryValue } from '@store/dictionary/contracts';
import { useSelector } from 'react-redux';
import { selectDictionary } from '@store/dictionary/dictionary.slice';

export function useDictionary<R>(
  name: DictionaryName,
  mapFn: (dictValue: DictionaryValue) => R,
): R[] {
  const result = useSelector(selectDictionary(name));

  return result.map(mapFn);
}
