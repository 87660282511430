import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@hooks/index';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { usePagination } from '@hooks/usePagination';
import { useRequestBody } from '@hooks/useRequestBody';
import { getTemplateList } from '@store/cloud/cloud.service';
import { Template } from '@store/cloud/contracts';
import { CreateTemplateButton } from './CreateTemplateButton';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import styles from '@pages/cloud/Templates.module.scss';
import { TemplateDetail } from '@pages/cloud/templates/TemplateDetail';
import { openModal } from '@store/ui/ui.slice';
import { formatDate } from '@components/ui/formatDate';

const Templates = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();

  const {
    data: fetchedData,
    total,
    loading,
  } = usePagination({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    accumulate: false,
    action: (query) => getTemplateList(query),
    additionalParams: requestBody,
  });

  useEffect(() => {
    setTemplates(fetchedData);
  }, [dispatch, fetchedData]);

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handleEditTemplates = useCallback((templateId: string) => {
    dispatch(openModal(<TemplateDetail templateId={templateId} />));
  }, []);

  return (
    <div className="col">
      <div className={styles.templateTable}>
        <AdaptiveTableMUI
          emptyLabel="У вас нет шаблонов"
          setRequestBody={setRequestBody}
          setSortColumn={setSortColumn}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          sortColumn={sortColumn}
          isLoading={loading}
          columns={{
            name: {
              label: 'Название',
              filterInput: true,
              children: (value, row) => (
                <span className={styles.templatesLink} onClick={() => handleEditTemplates(row.id)}>
                  {value}
                </span>
              ),
            },
            created: {
              label: 'Дата создания',
              format: (value) => formatDate(value, 'DD.MM.YYYY', 'DD.MM.YYYY HH:mm'),
              filterInput: true,
              isSortColumn: true,
            },
            memory: {
              label: 'Память',
              filterInput: true,
              isSortColumn: true,
              format: (value) => `${value / 1024 / 1024 / 1024} Gb`,
            },
            diskSize: {
              label: 'Дисковое пространство',
              filterInput: true,
              isSortColumn: true,
              format: (value) => `${value / 1024 / 1024 / 1024} Gb`,
            },
            cores: {
              label: 'Ядра',
              filterInput: true,
              isSortColumn: true,
            },
          }}
          rows={templates}
          additionalElement={
            <CreateTemplateButton action={() => navigate('/clouds/templates/new')} />
          }>
          <TablePaginationMUI
            limit={limit}
            count={total}
            entityLabel="шаблонов"
            page={page}
            handleChangePage={(_, newPage) => setPage(newPage)}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </AdaptiveTableMUI>
      </div>
    </div>
  );
};

export { Templates };
