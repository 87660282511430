import { TransferToProjectFormProps } from '@pages/requests/forms/TransferToProjectForm';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@hooks/index';
import { useTransferRequestToProjectMutation } from '@store/requests/requests.slice';
import { TransferToProjectRequest } from '@store/requests/requests.service';
import { closeModal } from '@store/ui/ui.slice';

export const useHandleRequestToProject = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [transferRequestToProjectMutation] = useTransferRequestToProjectMutation();

  const handleRequestToProject: TransferToProjectFormProps['submit'] = async (
    transferToProjectData: TransferToProjectRequest,
    form,
  ) => {
    try {
      await transferRequestToProjectMutation({
        id: transferToProjectData.id || '',
        projectPrefix: transferToProjectData.projectPrefix || '',
        projectName: transferToProjectData.projectName,
      });
      dispatch(closeModal());
      navigate('/projects/manager');
    } catch (e: any) {
      form.setErrors({
        projectPrefix: e.message.name,
      });
    }
  };

  return handleRequestToProject;
};
