import { publicApi } from '@store/public-api';
import Api from '../public-api-schema';
import { ResponseBody } from '@store/utility';

export const legalDocumentsApi = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    getLegalDocuments: builder.query<ResponseBody<Api.operations['api_legal_documents_list']>, {}>({
      query: () => ({
        method: 'GET',
        url: 'legal_documents/',
      }),
    }),
    getLegalDocumentByCode: builder.query({
      query: (code) => ({
        method: 'GET',
        url: `legal_documents/symbol_code/${code}/`,
      }),
    }),
  }),
});

export const { useGetLegalDocumentsQuery, useGetLegalDocumentByCodeQuery } = legalDocumentsApi;
