import React from 'react';
import { CommonAnalyticsBlockProps } from '../../CommonAnalyticsBlock';
import { PieChart } from '@mui/x-charts/PieChart';
import { PieCenterLabel } from '@components/PieCenterLabel/PieCenterLabel';
import { TaskStatusType } from '@store/tasks/contracts';
import { localisedStatusesColors } from '../StatusTypesList/StatusTypesList';
import { useMediaQuery, useTheme } from '@mui/material';
import { useProjectCommonAnalytics } from '@hooks/useProjectCommonAnalytics';
import styles from './TasksPie.module.scss';

export const TasksPie: React.FC<CommonAnalyticsBlockProps> = ({ commonAnalytics }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('mobile'));

  const { localisedStatuses, tasksCount } = useProjectCommonAnalytics(commonAnalytics);

  return (
    <PieChart
      series={[
        {
          data: Object.entries(localisedStatuses || {})?.map(([key, value]) => ({
            id: key,
            value: value,
            color: localisedStatusesColors.get(key as TaskStatusType),
            label: key,
          })),
          innerRadius: !mobile ? 70 : 50,
        },
      ]}
      width={!mobile ? 400 : 250}
      height={200}
      className={styles.tasks__pie}
      slotProps={{ legend: { hidden: true } }}>
      <PieCenterLabel label={'Задач'} count={tasksCount} />
    </PieChart>
  );
};
