import {
  GetAvailablePositionsRequest,
  GetAvailablePositionsResponse,
  Team,
  TeamCreationRequest,
  TeamItem,
  TeamListResponse,
  TeamRemovingRequest,
  TeamRemovingResponse,
  TeamUpdatingRequest,
} from './contracts';
import { ApiClient, apiErrorHandler } from '../api-client';
import { Pagination, PaginationQuery } from '@store/common';
import { plainToInstance } from 'class-transformer';
import Api from '@api-schema';
import { enqueueSnackbar } from 'notistack';

export const createTeam = async (request: TeamCreationRequest) => {
  try {
    const { data } = await ApiClient.post<
      Api.operations['createTeam']['responses']['200']['content']['application/json']
    >('api/team', {
      name: request.name,
      teamLeadId: request.teamLeadId,
      teamTypeId: request.teamTypeId,
      telegramUrl: request.telegramUrl,
      positions: request.participants,
    });
    enqueueSnackbar(`Команда создана`, {
      variant: 'success',
    });
    return data;
  } catch (error) {
    apiErrorHandler(error);
  }
};

export const saveTeam = async (request: TeamUpdatingRequest) => {
  const { data } = await ApiClient.put<
    Api.operations['saveTeam']['responses']['200']['content']['application/json']
  >(`/api/team`, request);
  return data;
};

export const updateTeam = async (
  requestBody: Api.operations['updateTeam']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.patch<
    Api.operations['updateTeam']['responses']['200']['content']['application/json']
  >('api/team', requestBody);
  return data;
};

export const setFavoriteTeam = async (
  requestBody: Api.operations['changeFavoriteStatus_1']['requestBody']['content']['application/json'],
  teamId: Api.operations['changeFavoriteStatus_1']['parameters']['path']['id'],
) => {
  const { data } = await ApiClient.put<
    Api.operations['changeFavoriteStatus_1']['responses']['200']['content']['application/json']
  >(`/api/team/${teamId}/favorite`, requestBody);

  enqueueSnackbar('Избранное команды успешно обновлено', {
    variant: 'success',
  });
  return data;
};

export const leaveTeam = async (id: Api.operations['leaveTeam']['parameters']['path']['id']) => {
  const { data } = await ApiClient.post<
    Api.operations['leaveTeam']['responses']['200']['content']['application/json']
  >(`api/team/${id}/leave`, id);
  return data;
};

export const removeTeam = async (request: TeamRemovingRequest) => {
  const { id } = request;
  const { data } = await ApiClient.delete<TeamRemovingResponse>(`api/team/${id}`);
  return data;
};

export const getTeams = async (request: Partial<PaginationQuery>) => {
  const { data } = await ApiClient.post<Pagination<Team>>('/api/team/list', {
    params: {
      start: request.start || 0,
      limit: request.limit || 10,
      sortOrder: request.sortOrder || 'asc',
    },
  });
  return data;
};

export const getFavoriteTeams = async (
  requestBody: Api.operations['getFavoriteTeams']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getFavoriteTeams']['responses']['200']['content']['application/json']
  >('/api/team/favorite', requestBody);
  return plainToInstance(TeamListResponse, data);
};

export const getTeam = async (teamId: Api.operations['getTeam']['parameters']['path']['id']) => {
  const { data } = await ApiClient.get<
    Api.operations['getTeam']['responses']['200']['content']['application/json']
  >(`/api/team/${teamId}`);
  return plainToInstance(TeamItem, data);
};

export const getTeamList = async (
  request: Api.operations['getTeams']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getTeams']['responses']['200']['content']['application/json']
  >(`api/team/list`, request);
  return plainToInstance(TeamListResponse, data);
};

export const getAvailablePositions = async (request: GetAvailablePositionsRequest) => {
  const { data } = await ApiClient.get<GetAvailablePositionsResponse[]>(
    `api/team/${request.id}/positions`,
  );
  return data;
};
