import styles from './AboutTheProject.module.scss';
import { Title } from '@components/AboutBenefits/AboutBenefits';
import { styled, Typography as MTypography } from '@mui/material';
import { ReactNode } from 'react';

export interface AboutTheProjectProps {
  titleColor: string;
  title: string;
  description: string;
  children?: ReactNode;
  image: string;
}

export const DescriptionField = styled(MTypography)`
  color: #39393a;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  @media (max-width: 768px) {
    font-size: 16px;
    margin-top: 0.75rem;
    margin-bottom: 1.25rem;
  }
`;

export function AboutTheProject({
  titleColor,
  children,
  title,
  description,
  image,
}: AboutTheProjectProps) {
  return (
    <div className={styles.aboutSetUpProfile}>
      <div className={styles.titleBlock}>
        <Title>
          {title} <span>{titleColor}</span>
        </Title>
        <DescriptionField>{description}</DescriptionField>
        <div className={styles.typographyBlock}>{children}</div>
      </div>
      <div>
        <img className={styles.image} src={image} alt="" />
      </div>
    </div>
  );
}
