import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';
import styles from './RangePicker.module.scss';
import { FormControl } from '@mui/material';
import { useField, useFormikContext } from 'formik';

export type RangeDate = [Dayjs, Dayjs];

interface IRangePicker {
  name: string;
}

enum PickerState {
  None,
  Start,
  End,
}

const formatedStyle = {
  '& .MuiInputBase-root': {
    paddingRight: '0px',
    '& input': {
      paddingRight: '14px',
    },
    '& .MuiInputAdornment-root': {
      display: 'none',
    },
  },
};

export const RangePicker = ({ name }: IRangePicker) => {
  const contextForm = useFormikContext();
  const [field] = useField(name);

  const [pickerState, setPickerState] = useState(PickerState.None);

  const handlePickerOpen = (picker: 'start' | 'end') => {
    setPickerState(picker === 'start' ? PickerState.Start : PickerState.End);
  };

  const handleStartPickerChange = (value: Dayjs | null) => {
    const isDateAfter = value?.isAfter(field.value[1]);
    contextForm.setFieldValue(name, [value, field.value[1]]);
    setPickerState(PickerState.None);

    if (isDateAfter) {
      contextForm.setFieldValue(name, [field.value[0], value]);
      setPickerState(PickerState.End);
    }
  };

  const handleEndPickerChange = (value: Dayjs | null) => {
    const isDateBefore = value?.isBefore(field.value[0]);
    if (!isDateBefore) {
      contextForm.setFieldValue(name, [field.value[0], value]);
      setPickerState(PickerState.None);
    } else {
      contextForm.setFieldValue(name, [value, field.value[1]]);
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <FormControl>
          <div className={styles.container}>
            <DatePicker
              label="Начало"
              open={pickerState === PickerState.Start}
              format="DD.MM.YYYY"
              onOpen={() => setPickerState(PickerState.Start)}
              value={field?.value[0]}
              sx={formatedStyle}
              onChange={(value) => handleStartPickerChange(value)}
              slotProps={{
                textField: {
                  onClick: () => handlePickerOpen('start'),
                },
              }}
              slots={{
                openPickerButton: () => null,
              }}
            />
            <span className={styles.delimeter}>-</span>
            <DatePicker
              label="Конец"
              open={pickerState === PickerState.End}
              format="DD.MM.YYYY"
              onOpen={() => setPickerState(PickerState.End)}
              value={field?.value[1]}
              onChange={(value) => handleEndPickerChange(value)}
              sx={formatedStyle}
              slotProps={{
                textField: {
                  onClick: () => handlePickerOpen('end'),
                },
              }}
              slots={{
                openPickerButton: () => null,
              }}
            />
          </div>
        </FormControl>
      </LocalizationProvider>
    </>
  );
};
