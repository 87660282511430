import { useCallback, useMemo, useState } from 'react';
import styles from './dranAndDrop.module.scss';
import uploadImage from 'src/assets/img/upload.svg';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { useField, useFormikContext } from 'formik';
import { ApiClient } from '@store/api-client';
import classNames from 'classnames';

interface IDragAndDrop {
  name: string;
  tag: 'PROFILE' | 'ICON' | 'PROJECT' | 'REQUEST';
  maxSize?: number;
  accept?: Accept;
}

export const DragAndDrop = ({ name, tag, maxSize = 100 * 1024 * 1024, accept }: IDragAndDrop) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField<string[]>(name);

  const ids = useMemo(() => field.value ?? [], [field.value]);

  const [loading, setLoading] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setLoading(true);
      for (let file of acceptedFiles) {
        const formData = new FormData();
        formData.append('file', file);

        try {
          const res = await ApiClient.post(`/api/file/${tag}/upload`, formData);
          const { data } = res;
          ids.push(data);
        } catch (e: any) {
          setError(e?.response?.data?.message || e.message);
        }
      }

      await setFieldValue(name, ids);
      setLoading(false);
    },
    [ids, name, setFieldValue, tag],
  );

  const onDropRejected = useCallback(
    (event: FileRejection[]) => {
      switch (event[0].errors[0].code) {
        case 'file-too-large':
          setError(`Файл ${event[0].file.name} слишком большой`);
          break;
        case 'file-invalid-type':
          setError(`Неверный формат файла ${event[0].file.name}`);
          break;
        default:
          setError(event[0].errors[0].message);
      }
    },
    [setError],
  );

  const validator = useCallback(
    (file: File) => {
      if (
        (file.type.startsWith('image/') && file.size > 3 * 1024 * 1024) || // images
        (tag === 'ICON' && file.size > 128 * 1024) || // icons
        file.size > 100 * 1024 * 1024 // default (server-side max 100мб)
      ) {
        return {
          code: 'file-too-large',
          message: `Файл ${file.name} слишком большой`,
        };
      }
      return null;
    },
    [tag],
  );

  const className = classNames(styles.drag_drop, {
    [styles.drag_drop_active]: dragging,
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize,
    onDropRejected,
    validator,
    multiple: tag !== 'ICON',
    maxFiles: tag === 'ICON' ? 1 : undefined,
    accept: accept ?? {
      'image/*': ['.png', '.jpeg', '.jpg', '.webp'],
      'application/*': ['.doc', '.docx', '.pdf'],
      'text/*': ['.txt'],
    },
    onDragEnter: () => setDragging(true),
    onDragLeave: () => setDragging(false),
  });

  return (
    <div {...getRootProps()} className={className}>
      {error && <div className="text-danger mt-2">{error}</div>}
      <input {...getInputProps()} />
      {loading ? (
        <p>Загрузка...</p>
      ) : (
        <>
          <p>
            Перетащите или <span>выберите</span> файлы,
            <br />
            которые хотите прикрепить
          </p>
          <img alt="Загрузить файл" src={uploadImage} />
        </>
      )}
      {ids?.map((file: any) => (
        <div className={styles.file} key={file?.id}>
          <p>{file?.name}</p>
        </div>
      ))}
    </div>
  );
};
