import React from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { ReactComponent as OpenEye } from '@assets/img/openEye.svg';
import { ReactComponent as CloseEye } from '@assets/img/closeEye.svg';

type PasswordVisibilityControllerProps = {
  showPassword: boolean;
  handleClickShowPassword: () => void;
  handleMouseDownPassword?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const PasswordVisibilityController: React.FC<PasswordVisibilityControllerProps> = ({
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
}) => {
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end">
        {showPassword ? <OpenEye /> : <CloseEye />}
      </IconButton>
    </InputAdornment>
  );
};
