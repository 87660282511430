import { useCallback } from 'react';
import { useEditVacancyMutation } from '@store/vacancy/vacancy.slice';
import { useAppDispatch } from '@hooks/index';
import { EditVacancyForm } from '@pages/vacancy/modals/EditVacancyModal';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { EditVacancyRequest, Vacancy } from '@store/vacancy/contracts';

export const useEditVacancy = () => {
  const dispatch = useAppDispatch();
  const [editVacancy] = useEditVacancyMutation();

  const handleChangeVacancy = useCallback(
    (vacancy: EditVacancyRequest) => {
      editVacancy({
        id: vacancy.id,
        name: vacancy.name,
        teamId: vacancy.teamId,
        teamPositionId: vacancy.teamPositionId,
        directionId: vacancy.directionId,
        currencyId: vacancy.currencyId,
        stack: vacancy.stack,
        comment: vacancy.comment,
        payment: vacancy.payment,
        isOpen: vacancy.isOpen,
      });
      dispatch(closeModal());
    },
    [dispatch, editVacancy],
  );

  const handleEditVacancy = useCallback(
    (vacancy: Vacancy) => {
      dispatch(
        openModal(
          <EditVacancyForm
            value={{
              id: vacancy.id,
              name: vacancy.name,
              teamId: vacancy.teamId,
              teamPositionId: vacancy.positionId,
              directionId: vacancy.directionId,
              stack: vacancy.stack,
              comment: vacancy.comment,
              payment: vacancy.payment,
              currencyId: vacancy.currencyId,
              isOpen: vacancy.isOpen,
            }}
            submit={(vacancy) => {
              handleChangeVacancy(vacancy);
            }}
          />,
        ),
      );
    },
    [dispatch, editVacancy],
  );

  return { handleEditVacancy };
};
