import { Modal, ModalFooter, ModalProps } from '@components/MUI/Modal/Modal';
import '../../../scss/components/modal.scss';
import { useAppDispatch } from '@hooks/index';
import { useCallback } from 'react';
import { closeModal } from '@store/ui/ui.slice';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/MUI/Button';
import { FormWrapper } from '@components/Form/Form';
import { useLeaveTeamMutation } from '@store/team/team.slice';

interface TeamLeaveModalProps {
  modalProps?: ModalProps;
  teamId: string;
}

export const TeamLeaveModal = ({ modalProps, teamId }: TeamLeaveModalProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [leaveTeamMutation] = useLeaveTeamMutation();

  const onSubmit = useCallback(() => {
    leaveTeamMutation({ id: teamId })
      .unwrap()
      .then(() => {
        dispatch(closeModal());
        navigate('/teams');
      });
  }, [teamId]);

  return (
    <Modal {...modalProps}>
      <FormWrapper label="Вы действительно хотите выйти из команды?" isThinTitle={true}>
        <div>Данное действие нельзя будет отменить. Продолжить?</div>
        <ModalFooter>
          <Button
            onClick={() => dispatch(closeModal())}
            size="large"
            style={{ color: '#78858B', marginRight: '0.25rem' }}>
            Отменить
          </Button>
          <Button type="submit" onClick={onSubmit} variant="contained" size="large">
            Выйти
          </Button>
        </ModalFooter>
      </FormWrapper>
    </Modal>
  );
};
