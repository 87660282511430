import React, { useMemo } from 'react';
import { Button } from '@components/MUI/Button';
import { Select, SelectOption } from '@components/MUI/Select';
import { SelectChangeEvent } from '@mui/material';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import { object } from 'yup';
import { selectDictionary } from '@store/dictionary/dictionary.slice';
import { useAppSelector } from '@hooks/index';
import { DictionaryName } from '@store/dictionary/contracts';
import styles from './AddPosition.module.scss';

export type AddPositionValue = {
  positionId: string;
};

export type AddPositionFormProps = {
  submit: (
    value: AddPositionValue,
    form: FormikBag<AddPositionFormProps, AddPositionValue>,
  ) => void;
  cancel: () => void;
};

const addPositionSchema = object({});

const AddPosition: React.FC = () => {
  const form = useFormikContext<AddPositionValue>();
  const cancel = form.getFieldProps('cancel').value;
  const positions = useAppSelector(selectDictionary(DictionaryName.POSITION));

  const positionsSelectOptions = useMemo(() => {
    const positionsSelectOptions = positions?.map((position) => {
      const positionSelectOption: SelectOption = {
        value: position.id,
        label: position.name,
      };
      return positionSelectOption;
    });
    return positionsSelectOptions;
  }, [positions]);

  return (
    <Form onSubmit={form.handleSubmit}>
      <Select
        name="positionId"
        values={positionsSelectOptions}
        onChange={(event: SelectChangeEvent) => {
          const position = positions.find((position) => position.id === event.target.value);
          form.setFieldValue('positionId', position?.id);
        }}
        isOutlined={true}
        label="Роль"
        className={styles.select__project}
      />
      <div className="d-flex justify-content-end mt-5">
        <Button className={styles.button__cancel} onClick={cancel}>
          Отменить
        </Button>
        <Button
          disabled={!form.isValid}
          className={styles.button__submit}
          variant="contained"
          type="submit"
          disableElevation={true}>
          Добавить
        </Button>
      </div>
    </Form>
  );
};

export const AddPositionForm = withFormik<AddPositionFormProps, AddPositionValue>({
  displayName: 'AddPositionForm',
  mapPropsToValues: (props) => {
    return {
      cancel: props.cancel,
      positionId: '',
    };
  },
  isInitialValid: false,
  enableReinitialize: true,
  validationSchema: addPositionSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(AddPosition);
