import Api from '@api-schema';
import { api } from '@store/api-client';
import { Notification } from './contracts';
import { plainToInstance } from 'class-transformer';

export const notificationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    notifications: builder.query<Notification[], string>({
      query: () => ({
        method: 'GET',
        url: '/api/notification/system',
      }),
      transformResponse: (
        response: Api.operations['getSystemNotifications']['responses']['200']['content']['application/json'],
      ) => {
        return plainToInstance(Notification, response);
      },
    }),
  }),
});

export const { useNotificationsQuery } = notificationApi;
