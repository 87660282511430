import { useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Box as MBox, styled } from '@mui/material';
import { InputField } from '@components/MUI/InputField/InputField';
import { Button } from '@components/MUI/Button';

export type IdentityFormProps = {
  value: IdentityFormValue;
  submit: (value: IdentityFormValue) => void;
};

export type IdentityFormValue = {
  firstName: string;
  lastName: string;
};

const IdentityFormSchema = Yup.object({
  firstName: Yup.string()
    .required('Поле обязательно для заполнения')
    .matches(/^[A-Za-zА-Яа-яЁё\s]*$/, 'Имя не должно содержать цифры')
    .test(
      'no-empty-spaces',
      'Имя не может состоять только из пробелов',
      (value: string | undefined) => {
        return value !== undefined && value.trim() !== '';
      },
    ),

  lastName: Yup.string()
    .required('Поле обязательно для заполнения')
    .matches(/^[A-Za-zА-Яа-яЁё\s]*$/, 'Фамилия не должно содержать цифры')
    .test(
      'no-empty-spaces',
      'Имя не может состоять только из пробелов',
      (value: string | undefined) => {
        return value !== undefined && value.trim() !== '';
      },
    ),
});

const Box = styled(MBox)`
  margin-top: 58px;
`;

const FormComponent = () => {
  const IdentityForm = useFormikContext<IdentityFormValue>();
  IdentityForm.validationSchema = IdentityFormSchema;

  useEffect(() => {
    IdentityForm.setFieldValue('disabledNextButton', !IdentityForm.isValid);
  }, [IdentityForm.isValid]);

  return (
    <Form onSubmit={IdentityForm.handleSubmit}>
      <div className="d-flex flex-column gap-4">
        <InputField name="firstName" type="text" placeholder="Имя" />
        <InputField name="lastName" type="text" placeholder="Фамилия" />
      </div>
      <Box>
        <Button
          variant="contained"
          className="pt-3 pb-3 fs-7 fw-600 w-100"
          type="submit"
          disabled={!IdentityForm.isValid}>
          Далее
        </Button>
      </Box>
    </Form>
  );
};

export const IdentityForm = withFormik<IdentityFormProps, IdentityFormValue>({
  displayName: 'IdentityForm',
  mapPropsToValues: (props) => {
    return {
      firstName: props.value.firstName,
      lastName: props.value.lastName,
    };
  },
  enableReinitialize: true,
  validationSchema: IdentityFormSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values);
  },
})(FormComponent);
