import * as Yup from 'yup';
import styles from './OrganizationRequestForm.module.scss';
import egile from '@assets/img/university/egile.png';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import { InputField } from '@components/MUI/InputField/InputField';
import { Button } from '@components/MUI/Button';
import { Checkbox } from '@components/ui/Checkbox/Checkbox';
import { Link } from 'react-router-dom';

export type OrganizationRequestFormValue = {
  fullName: string;
  phone: string;
  institutionName: string;
  email: string;
  agreement: boolean;
};

export type OrganizationRequestFormProps = {
  value: OrganizationRequestFormValue;
  submit: (
    value: OrganizationRequestFormValue,
    form: FormikBag<OrganizationRequestFormProps, OrganizationRequestFormValue>,
  ) => void;
  organizationPlaceholder: string;
};

const OrganizationRequestFormSchema = Yup.object({
  fullName: Yup.string()
    .matches(/^[A-Za-zА-Яа-яЁё\s]*$/, 'Поле не должно содержать цифр')
    .required('Поле обязательно для заполнения'),
  phone: Yup.string()
    .matches(/^\+79\d{9}$/, 'Номер должен начинаться с +7 и состоять из 11 цифр')
    .required('Поле обязательно для заполнения'),
  institutionName: Yup.string().required('Поле обязательно для заполнения'),
  email: Yup.string().required('Поле обязательно для заполнения'),
  agreement: Yup.boolean().oneOf([true], 'Вы должны согласиться с условиями'),
});

const checkboxStyle = {
  '&.MuiButtonBase-root': {
    padding: 0,
  },
};

export const OrganizationRequest = (props: OrganizationRequestFormProps) => {
  const organizationRequestForm = useFormikContext<OrganizationRequestFormValue>();
  return (
    <>
      <div className={styles.formMain}>
        <div className={styles.formMain__container}>
          <h2 className={styles.formMain__container_text}>Заявка на подключение</h2>
          <Form onSubmit={organizationRequestForm.handleSubmit}>
            <div className={styles.form}>
              <InputField name="fullName" type="text" placeholder="ФИО*" />
              <InputField name="phone" type="text" placeholder="Телефон*" />
              <InputField
                name="institutionName"
                type="text"
                placeholder={props.organizationPlaceholder}
              />
              <InputField name="email" type="email" placeholder="Email*" />
            </div>
            <div className={styles.formMain__container__agreement}>
              <Checkbox
                fieldClassName={`align-items-start m-0 ${styles.formMain__container__agreement_checkbox}`}
                sx={checkboxStyle}
                name="agreement"
              />
              <p>
                Нажимая кнопку «Далее», я подтверждаю, что ознакомился и даю согласие с условиями{' '}
                <Link to="/legalDocuments/terms/" target="_blank" rel="noopener noreferrer">
                  пользовательского соглашения
                </Link>
              </p>
            </div>
            <div className={styles.form__actions}>
              <Button
                type="submit"
                variant="contained"
                disabled={!organizationRequestForm.isValid}
                className={styles.form_btn}>
                Оставить заявку
              </Button>
              {organizationRequestForm?.status === 'success' && (
                <p className={styles.form__actions__success_text}>
                  Ваша заявка успешно отправлена!
                </p>
              )}
            </div>
          </Form>
        </div>
        <img src={egile} alt="" className={styles.formMain__img} />
      </div>
    </>
  );
};

export const OrganizationRequestForm = withFormik<
  OrganizationRequestFormProps,
  OrganizationRequestFormValue
>({
  displayName: 'UniversityRequestForm',
  mapPropsToValues: (props) => {
    return {
      fullName: props.value.fullName,
      phone: props.value.phone,
      institutionName: props.value.institutionName,
      email: props.value.email,
      agreement: props.value.agreement,
    };
  },
  enableReinitialize: true,
  validationSchema: OrganizationRequestFormSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(OrganizationRequest);
