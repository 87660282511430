import React from 'react';
import { Paper } from '@mui/material';
import { Sort } from './Sort/Sort';
import { Filter } from './Filter/Filter';
import { ItemsList } from './ItemsList/ItemsList';
import styles from './ListMUI.module.scss';
import { AdaptiveTableMUIProps } from '@components/Table/TableMUI';

export const ListMUI: React.FC<AdaptiveTableMUIProps> = ({
  rows,
  columns,
  sortOrder,
  setRequestBody,
  setSortOrder,
  setSortColumn,
  additionalElement,
  children,
}) => {
  return (
    <div className={styles.list__wrapper}>
      <div className={styles.list__top}>
        <Sort
          rows={rows}
          columns={columns}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          setSortColumn={setSortColumn}
          setRequestBody={setRequestBody}
        />
        {additionalElement ? (
          <div className={styles.additional__element}>{additionalElement}</div>
        ) : (
          <></>
        )}
      </div>
      <Paper className={styles.list__content} variant="outlined">
        <Filter setRequestBody={setRequestBody} columns={columns} rows={rows} />
        <ItemsList columns={columns} rows={rows} />
      </Paper>
      {children}
    </div>
  );
};
