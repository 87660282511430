import { Modal, ModalFooter, ModalProps } from '@components/MUI/Modal/Modal';
import '../../../scss/components/modal.scss';
import { ReactNode, useCallback } from 'react';
import { FormWrapper } from '@components/Form/Form';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import { Button } from '@components/MUI/Button';
import styles from './ConfirmDeleteModal.module.scss';

interface ConfirmModalProps {
  modalProps?: ModalProps;
  title: string;
  children: ReactNode;
  onSubmit: () => void;
  btnProp?: string;
  cancelBtnProp?: string | null;
}

export const ConfirmDeleteModal = ({
  modalProps,
  title,
  children,
  onSubmit,
  btnProp,
  cancelBtnProp,
}: ConfirmModalProps) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <Modal {...modalProps}>
      <FormWrapper label={title} isThinTitle isSmallModal>
        <div>{children}</div>
        <ModalFooter>
          {cancelBtnProp === null ? null : (
            <Button className={`me-3 fw-bold ${styles.cancelBtn}`} onClick={handleCloseModal}>
              {cancelBtnProp || 'Отменить'}
            </Button>
          )}
          <Button type="submit" variant="contained" onClick={onSubmit}>
            {btnProp}
          </Button>
        </ModalFooter>
      </FormWrapper>
    </Modal>
  );
};
