import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@hooks/index';
import { getCloudInfrastructure } from '@store/cloud/cloud.service';
import { CloudInfrastructure } from '@store/cloud/contracts';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { FormWrapper } from '@components/Form/Form';
import { Button } from '@components/MUI/Button';
import { closeModal, openModalError } from '@store/ui/ui.slice';
import styles from './InfrastructureForm.module.scss';
import { ReactComponent as EditIconGreen } from '@assets/img/editIconGreen.svg';
import { CircularProgress } from '@mui/material';

export type InfrastructureDetailProps = {
  infrastructureId: string;
};

const InfrastructureDetail: React.FC<InfrastructureDetailProps> = ({ infrastructureId }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fetched = useRef(false);
  const [open, setOpen] = useState(true);
  const [infrastructure, setInfrastructure] = useState<CloudInfrastructure>();

  useEffect(() => {
    if (!fetched.current && infrastructureId) {
      (async () => {
        try {
          const item = await getCloudInfrastructure(infrastructureId);
          setInfrastructure(item);
        } catch (e: any) {
          dispatch(openModalError(e));
        } finally {
          fetched.current = true;
        }
      })();
    }
  }, [dispatch]);

  const editInfrastructureDetail = useCallback(() => {
    navigate(`/clouds/infrastructures/${infrastructureId}/edit`);
    dispatch(closeModal());
  }, [dispatch]);

  const cancel = useCallback(() => {
    dispatch(closeModal());
    setOpen(false);
  }, [dispatch]);

  return (
    <>
      <Modal
        open={open}
        onClose={cancel}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ maxWidth: '480px', width: '100%' }}>
          <FormWrapper label={'Инфраструктура'}>
            {fetched.current ? (
              <div className={styles.detailInfrastructureMain}>
                <div className={styles.detailInfrastructure}>
                  <h2 className={styles.detailInfrastructureTitle}>Основание</h2>
                  <p className={styles.detailInfrastructureResult}>
                    {infrastructure?.delegation?.name || '-'}
                  </p>
                </div>
                <div className={styles.detailInfrastructure}>
                  <h2 className={styles.detailInfrastructureTitle}>Название</h2>
                  <p className={styles.detailInfrastructureResult}>{infrastructure?.name || '-'}</p>
                </div>
                <div className={styles.detailInfrastructure}>
                  <h2 className={styles.detailInfrastructureTitle}>Облако*</h2>
                  <p className={styles.detailInfrastructureResult}>
                    {infrastructure?.cloud?.resourceName || '-'}
                  </p>
                </div>
                <div className={styles.detailInfrastructure}>
                  <h2 className={styles.detailInfrastructureTitle}>Организация*</h2>
                  <p className={styles.detailInfrastructureResult}>
                    {infrastructure?.organization?.resourceName || '-'}
                  </p>
                </div>
                <div className={styles.detailInfrastructure}>
                  <h2 className={styles.detailInfrastructureTitle}>Папка</h2>
                  <p className={styles.detailInfrastructureResult}>
                    {infrastructure?.folder?.resourceName || '-'}
                  </p>
                </div>
                <div className={styles.detailInfrastructure}>
                  <h2 className={styles.detailInfrastructureTitle}>Сеть</h2>
                  <p className={styles.detailInfrastructureResult}>
                    {infrastructure?.network?.resourceName || '-'}
                  </p>
                </div>
                <div className={styles.btnsInfrastructure}>
                  <Button className={styles.btnCancel} onClick={cancel}>
                    Закрыть
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<EditIconGreen />}
                    onClick={editInfrastructureDetail}>
                    Редактировать
                  </Button>
                </div>
              </div>
            ) : (
              <div className={styles.loading}>
                <CircularProgress />
              </div>
            )}
          </FormWrapper>
        </Box>
      </Modal>
    </>
  );
};
export { InfrastructureDetail };
