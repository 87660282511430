import styles from './RatesForSpecialist.module.scss';
import specialists from '@assets/img/specialist/specialists.png';
import React from 'react';
import { Typography } from '@components/AboutInfo/AboutInfo';
import { Box } from '@mui/material';
import { CardOptionsRates } from '@pages/rates/additionalOptionsCard/CardOptionsRates';
import { useScrollToTop } from '@hooks/useScrollToTop';

export const RatesForSpecialist = () => {
  const topRef = useScrollToTop();

  return (
    <>
      <div ref={topRef} className={styles.mainInfoBackground}>
        <div className={styles.container}>
          <div className={styles.mainInfoBlock}>
            <div>
              <p className={styles.title}>
                Тарифы для <span>исполнителя</span>
              </p>
              <p className={styles.subTitle}>
                Подписка на «Эджайл» — это возможность для Пользователя за абонентскую плату в
                течение определенного промежутка времени получать удаленный доступ к платформе
              </p>
              <div className={styles.description}>
                <Typography>Для новых пользователей подписка на 3 месяца бесплатная</Typography>
                <Typography>
                  Для расширения возможностей выберете подходящий для вас тариф
                </Typography>
              </div>
            </div>
            <div>
              <img src={specialists} className={styles.specialistsPic} alt="специалисты" />
            </div>
          </div>
        </div>
      </div>
      <Box>
        <div className={styles.addGoodsWrapper}>
          <div className={styles.container}>
            <div className={styles.wrapper}>
              <Box>
                <div className={styles.container}>
                  <h2 className={styles.options}>Дополнительные услуги (в разработке)</h2>
                  <div className={styles.gridLayout}>
                    <CardOptionsRates
                      title="Закрепить ваше резюме в ленте на 24 часа"
                      subTitle="Разовая оплата"
                      description="После закрепления резюме останется наверху ленты, и его увидят больше заказчиков"
                      rate="Для тарифа «Стартап» "
                      allRates="Для тарифа «Персональный» и тарифа «Премиум» "
                      price="100 ₽"
                      priceForAnotherRates="50 ₽"
                    />
                    <CardOptionsRates
                      title="Статус «Готов приступить к проекту прямо сейчас»"
                      subTitle="Разовая оплата"
                      description="Статус появляется и действует 24 часа. Вы быстрее получите отклики и привлечете внимание большего количества заказчиков."
                      rate="Для тарифа «Стартап» "
                      allRates="Для тарифа «Персональный» и тарифа «Премиум» "
                      price="250 ₽"
                      priceForAnotherRates="125 ₽"
                    />
                    <CardOptionsRates
                      title="Комплексное продвижение вакансии"
                      subTitle="Разовая оплата"
                      description="Все по выгодной цене: закрепление в ленте на 24 часа, статус «Готов приступить к проекту прямо сейчас», выделение вакансии цветом"
                      rate="Для тарифа «Стартап» "
                      allRates="Для тарифа «Персональный» и тарифа «Премиум» "
                      price="100 ₽"
                      priceForAnotherRates="50 ₽"
                    />
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};
