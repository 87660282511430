import React from 'react';
import styles from './LeadTime.module.scss';
import { LeadTimeChart } from './LeadTimeChart';
import { Helper } from '@components/ui/Helper';
import { useFormikContext } from 'formik';
import { FormValues } from '../AdvancedAnalytics';
import { useParams } from 'react-router-dom';
import { useGetLeadTimeAnalyticQuery } from '@store/project/project.slice';
import { useChartData } from '@hooks/useChartData';
import { LeadTimeAnalyticResponse } from '@store/project/contracts';

export const LeadTime: React.FC = () => {
  const { values } = useFormikContext<FormValues>();
  const { projectId } = useParams();
  const projectIdToUse = projectId || '';

  const { data } = useGetLeadTimeAnalyticQuery({
    path: {
      projectId: projectIdToUse,
    },
    query: {
      startDate: values.period[0].format('YYYY-MM-DD'),
      endDate: values.period[1].format('YYYY-MM-DD'),
    },
  });

  const { chartData, axisXData, isEmpty } = useChartData<LeadTimeAnalyticResponse>({
    data: data ?? [],
    chartProperty: 'taskCount',
    axisXProperty: 'daysCount',
  });
  return (
    <div
      className={styles.lead__time}
      style={{
        order: isEmpty ? 2 : 1,
      }}>
      <h2 className={styles.lead__time__title}>
        Время выполнения (Lead Time){' '}
        {
          <Helper title="Диаграмма, которая показывает, насколько быстро задачи берутся в работу." />
        }
      </h2>
      <div className={styles.lead__time__dates}>
        {values.period[0].format('DD.MM.YYYY')} - {values.period[1].format('DD.MM.YYYY')}
      </div>
      {!isEmpty ? (
        <>
          <div className={styles.lead__time__tasks__count}>
            <span>Кол-во задач</span>
          </div>
          <div>
            <LeadTimeChart chartData={chartData} axisXData={axisXData} />
            <div className={styles.lead__time__footer}>
              <span>Дни до завершения задачи</span>
            </div>
          </div>
          <div className={styles.lead__time__list}>
            <div className={styles.lead__time__list__color}></div>
            <span className={styles.lead__time__list__text}>Задачи</span>
          </div>
        </>
      ) : (
        <div className={styles.emptyData}>
          <p>Нет данных</p>
        </div>
      )}
    </div>
  );
};
