import React from 'react';
import { NavLink } from 'react-router-dom';
import { Can } from '@access-control';
import styles from '@components/teams/Header/TeamsHeader.module.scss';

export const ProjectsHeader = () => {
  return (
    <header className={styles.header}>
      <ul>
        <Can I="read" a="CustomerProjects">
          <li>
            <NavLink
              to="/projects/customer"
              className={({ isActive }) => (isActive ? 'active' : '')}
              end>
              Я заказчик
            </NavLink>
          </li>
        </Can>

        <Can I="read" a="ManagerProjects">
          <li>
            <NavLink
              to="/projects/manager"
              className={({ isActive }) => (isActive ? 'active' : '')}>
              Я менеджер
            </NavLink>
          </li>
        </Can>

        <Can I="read" a="ExecutorProjects">
          <li>
            <NavLink
              to="/projects/executor"
              className={({ isActive }) => (isActive ? 'active' : '')}>
              Я исполнитель
            </NavLink>
          </li>
        </Can>
      </ul>
    </header>
  );
};
