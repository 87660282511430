import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as TrackerService from '@store/tasks/tracker.service';
import {
  CreateTaskRequest,
  CreateTaskResponse,
  GetTasksResponse,
  LoadProjectsRequest,
  Project,
  Task,
  TaskTransition,
  TrackerProvider,
  UpdateTaskRequest,
} from './contracts';
import { RootState } from '../index';
import { enqueueSnackbar } from 'notistack';
import { api } from '@store/api-client';
import { getTasksReq } from '@store/tasks/tracker.service';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';
import { PathParameters, RequestBody, ResponseBody } from '@store/utility';

type TrackerState = {
  projects: Project[];
  tasks: Task[];
  provider: TrackerProvider;
  task: Task | null;
  projectIds: string[] | null;
};

const initialState: TrackerState = {
  projects: [],
  tasks: [],
  provider: TrackerProvider.Yandex,
  task: null,
  projectIds: null,
};

type SetProjectsActionPayload = {
  provider: TrackerProvider;
  projects: Project[];
};

type SetTasksActionPayload = {
  tasks: Task[];
};

type MakeTransitionTaskPayload = {
  taskId: string;
  transitionId: string;
};

export const trackerSlice = createSlice({
  name: 'tracker',
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<SetProjectsActionPayload>) => {
      state.provider = action.payload.provider;
      state.projects = [...action.payload.projects];
    },
    setTasks: (state, action: PayloadAction<SetTasksActionPayload>) => {
      state.tasks = [...action.payload.tasks];
    },
    setTask: (state, action) => {
      state.task = action.payload;
    },
    setSelectedProjectId: (state, action: PayloadAction<string[]>) => {
      state.projectIds = action.payload;
    },
  },
});

export const loadProjects = createAsyncThunk(
  'tracker/loadProjects',
  async (request: LoadProjectsRequest, { dispatch }) => {
    const projects = await TrackerService.getTrackerProjects(request.provider);
    await dispatch(setProjects({ projects, provider: request.provider }));
  },
);

export const loadTaskDetail = createAsyncThunk(
  'tracker/loadTaskDetail',
  async (taskId: string, { dispatch }) => {
    const task = await TrackerService.loadTaskDetail(taskId);
    task.transitions = await TrackerService.loadTaskTransitions(taskId);
    dispatch(setTask(task));
  },
);

export const makeTransitionTask = createAsyncThunk(
  `tracker/transition`,
  async ({ taskId, transitionId }: MakeTransitionTaskPayload, { dispatch }) => {
    await TrackerService.executeTaskTransition(taskId, transitionId);
    await dispatch(loadTaskDetail(taskId));
  },
);

export const updateTask = createAsyncThunk(
  'tracker/taskUpdate',
  async (request: UpdateTaskRequest, { dispatch }) => {
    await TrackerService.updateTask(request);
    enqueueSnackbar(`Задача обновлена`, {
      variant: 'success',
    });
  },
);
export const createTask = createAsyncThunk(
  'tracker/createUpdate',
  async (request: CreateTaskRequest, { dispatch }) => {
    const response = await TrackerService.createTask(request);
    enqueueSnackbar(`Задача создана`, {
      variant: 'success',
    });
    return response;
  },
);

export const { setProjects, setTask, setSelectedProjectId } = trackerSlice.actions;
export const selectProjects = (state: RootState) => state.tracker.projects;

export const selectTasks = (state: RootState) => state.tracker.tasks;
export const selectTask = (state: RootState) => state.tracker.task;

export const selectProjectIdTask = (state: RootState) => state.tracker.projectIds;

export const selectProvider = (state: RootState) => state.tracker.provider;

export const trackerReducer = trackerSlice.reducer;

export const tasksApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query<GetTasksResponse, getTasksReq>({
      query: (params) => ({
        method: `GET`,
        url: `/api/tracker/tasks`,
        params: params,
      }),
      transformResponse: (response: ResponseBody<Api.operations['list']>) => {
        return plainToInstance(GetTasksResponse, response);
      },
      providesTags: ['TasksList'],
    }),

    loadTaskDetail: builder.query<Task, PathParameters<Api.operations['get'], 'taskId'>>({
      query: (taskId) => ({
        method: `GET`,
        url: `/api/tracker/tasks/${taskId}`,
      }),
      transformResponse: (response: ResponseBody<Api.operations['get']>) => {
        return plainToInstance(Task, response);
      },
      providesTags: ['TaskDetail'],
    }),

    loadTaskTransitions: builder.query<
      TaskTransition[],
      PathParameters<Api.operations['transitions'], 'taskId'>
    >({
      query: (taskId) => ({
        method: `GET`,
        url: `/api/tracker/tasks/${taskId}/transitions`,
      }),
      transformResponse: (response: TaskTransition[]) => {
        return plainToInstance(TaskTransition, response);
      },
      providesTags: ['TaskTransitions'],
    }),

    executeTaskTransition: builder.mutation<
      ResponseBody<Api.operations['executeTransition']>,
      {
        taskId: PathParameters<Api.operations['executeTransition'], 'taskId'>;
        transitionId: RequestBody<Api.operations['executeTransition']>;
      }
    >({
      query: ({ taskId, transitionId }) => ({
        method: `POST`,
        url: `/api/tracker/tasks/${taskId}/transitions/execute`,
        body: transitionId,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Переход статуса совершен', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['TaskDetail', 'TaskTransitions'],
    }),

    updateTask: builder.mutation<CreateTaskResponse, UpdateTaskRequest>({
      query: ({ taskId, data }) => ({
        method: `PATCH`,
        url: `/api/tracker/tasks/${taskId}`,
        body: data,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Задача отредактирована', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['TaskDetail', 'TasksList', 'DashboardTaskStatuses'],
    }),

    createTask: builder.mutation<CreateTaskResponse, CreateTaskRequest>({
      query: ({ data }) => ({
        method: `POST`,
        url: `/api/tracker/tasks`,
        body: data,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Задача создана', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['TasksList', 'DashboardTaskStatuses', 'TaskDetail'],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useLoadTaskDetailQuery,
  useLoadTaskTransitionsQuery,
  useExecuteTaskTransitionMutation,
  useUpdateTaskMutation,
  useCreateTaskMutation,
} = tasksApi;
