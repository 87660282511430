import React from 'react';
import { useAppDispatch } from '@hooks/index';
import { openModal } from '@store/ui/ui.slice';
import { AddPositionModal } from '../modals/AddPositionModal';
import { ReactComponent as AddIcon } from '@assets/img/add.svg';
import { TeamItem } from '@store/team/contracts';

type AddPositionButtonProps = {
  team?: TeamItem | null;
};

export const AddPositionButton: React.FC<AddPositionButtonProps> = ({ team }) => {
  const dispatch = useAppDispatch();

  return (
    <button
      className="color-green border-0 bg-transparent mb-3 fw-500"
      onClick={() => dispatch(openModal(<AddPositionModal team={team} />))}>
      <AddIcon style={{ marginRight: '0.375rem' }} />
      Добавить роль
    </button>
  );
};
