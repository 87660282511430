import { CSSProperties } from 'react';
import { IconButton } from '@components/ui/Button/IconButton';
import { ApproveIcon } from '@components/ui/Icons/ApproveIcon';
import { CloseIcon } from '@components/ui/Icons/CloseIcon';
import styles from './ActionButtons.module.scss';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopIcon from '@mui/icons-material/Stop';

type DeploymentButtonsProps = {
  handleBuild: (id: string) => void;
  handleDestroy: (id: string) => void;
  handleBuildAndStart: (id: string) => void;
  handleStart: (id: string) => void;
  handleStop: (id: string) => void;
  showBuild: boolean;
  showDestroy: boolean;
  showBuildAndStart: boolean;
  showStart: boolean;
  showStop: boolean;
  objectId: string;
  className?: string;
  style?: CSSProperties;
};

export const ActionButtons: React.FC<DeploymentButtonsProps> = ({
  handleBuild,
  handleDestroy,
  handleBuildAndStart,
  handleStart,
  handleStop,
  showBuild,
  showDestroy,
  showBuildAndStart,
  showStop,
  showStart,
  objectId,
  className,
  style,
}) => {
  return (
    <>
      <span className={className} style={style}>
        {showBuild && (
          <IconButton className={styles.icon__button} onClick={() => handleBuild(objectId)}>
            <ApproveIcon />
          </IconButton>
        )}
        {showDestroy && (
          <IconButton className={styles.icon__button} onClick={() => handleDestroy(objectId)}>
            <CloseIcon />
          </IconButton>
        )}
        {showBuildAndStart && (
          <IconButton className={styles.icon__button} onClick={() => handleBuildAndStart(objectId)}>
            <PlayCircleOutlineIcon className={styles.playCircleOutlineIcon} />
          </IconButton>
        )}
        {showStart && (
          <IconButton className={styles.icon__button} onClick={() => handleStart(objectId)}>
            <PlayArrowIcon className={styles.playArrowIcon} />
          </IconButton>
        )}
        {showStop && (
          <IconButton className={styles.icon__button} onClick={() => handleStop(objectId)}>
            <StopIcon className={styles.stopIcon} />
          </IconButton>
        )}
      </span>
    </>
  );
};
