import React from 'react';
import styles from './StatusLabel.module.scss';
import { TaskStatusType } from '@store/tasks/contracts';

interface StatusProps {
  text: string;
  color: string | undefined;
  className?: string;
}

export const statusColors = new Map([
  ['Новое', '#dc3545ff'],
  ['В работе', '#189ced'],
  ['Завершено', '#78858b'],
  ['Приемка', '#eeba00'],
  ['Принято', '#10C943'],
  ['Неактивный', '#78858B'],
  ['На рассмотрении', '#EEBA00'],
  ['Отказ', '#E04040'],
  ['Принят', '#10C943'],
  ['Открыт', '#189CED'],
  ['Ожидание', '#189CED'],
  ['Переведено в проект', '#189CED'],
  ['В проекте', '#189CED'],
]);

export const statusProjectColors = new Map([
  ['Новое', '#FE5959'],
  ['В работе', '#3BABF0'],
  ['Завершено', '#34D15F'],
  ['Приемка', '#F4CB3A'],
  ['Принято', '#FE9417'],
  ['Неактивный', '#78858B'],
]);

export const statusTaskColors = new Map<TaskStatusType, string>([
  [TaskStatusType.New, '#3485FF'],
  [TaskStatusType.Paused, '#F4CB3A'],
  [TaskStatusType.InProgress, '#E04040'],
  [TaskStatusType.Done, '#10C943'],
  [TaskStatusType.Cancelled, '#78858B'],
]);

export const StatusLabel: React.FC<StatusProps> = ({ text, color, className }) => {
  return (
    <div
      className={`${styles.status} ${className}`}
      style={{ color: color || '#78858B', border: `solid ${color || '#78858B'} 1px` }}>
      <span>{text}</span>
    </div>
  );
};
