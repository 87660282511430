import React from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import { MyAssignedRequestsIcon } from './Icons/MyAssignedRequestsIcon';
import { RequestListIcon } from './Icons/RequestListIcon';
import { RequestsCreatedByMeIcon } from './Icons/RequestsCreatedByMeIcon';
import { CreateRequestIcon } from './Icons/CreateRequestIcon';
import { Can } from '@access-control';
import { ReactComponent as ApprovedIcon } from '@assets/img/approve.svg';
import styles from '@components/teams/Header/TeamsHeader.module.scss';
import navBarStyles from './RequestsNavBar.module.scss';
import { Button } from '@components/MUI/Button';
import { useMediaQuery } from '@mui/material';

export function RequestNavigationBar() {
  const { requestId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isTablet = useMediaQuery('(max-width: 991px)');

  if (requestId || location.pathname === '/requests/new') {
    return (
      <div className={navBarStyles.button__back}>
        <NavigationBack />
      </div>
    );
  }

  return (
    <header className={styles.header}>
      <ul>
        <Can I="read" a="RequestList">
          <li>
            <NavLink to="/requests" className={({ isActive }) => (isActive ? 'active' : '')} end>
              <RequestListIcon />
              Лента заявок
            </NavLink>
          </li>
        </Can>

        <Can I="read" a="MyAssignedRequests">
          <li>
            <NavLink
              to="/requests/assigned"
              className={({ isActive }) => (isActive ? 'active' : '')}
              style={{ textDecoration: 'none' }}>
              <MyAssignedRequestsIcon />
              Назначенные на меня
            </NavLink>
          </li>
        </Can>

        <Can I="read" a="ApprovedRequests">
          <li>
            <NavLink
              to="/requests/approved"
              className={({ isActive }) => (isActive ? 'active' : '')}
              style={{ textDecoration: 'none' }}>
              <ApprovedIcon />
              Одобренные заявки
            </NavLink>
          </li>
        </Can>

        <Can I="read" a="RequestsCreatedByMe">
          <li>
            <NavLink
              to="/requests/my"
              className={({ isActive }) => (isActive ? 'active' : '')}
              style={{ textDecoration: 'none' }}>
              <RequestsCreatedByMeIcon />
              Мои заявки
            </NavLink>
          </li>
        </Can>
      </ul>

      {!isTablet ? (
        <Can I="create" a="Request">
          <Button
            variant="contained"
            startIcon={<CreateRequestIcon />}
            onClick={() => navigate('/requests/new')}>
            Создать заявку
          </Button>
        </Can>
      ) : (
        <></>
      )}
    </header>
  );
}
