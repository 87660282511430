import { ApiClient } from '@store/api-client';
import {
  AttachProjectRequest,
  CreateTaskRequest,
  CreateTaskResponse,
  GetTasksResponse,
  Project,
  SpentTimeMock,
  Task,
  TaskTransition,
  UpdateTaskRequest,
} from './contracts';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';
import { enqueueSnackbar } from 'notistack';
import { PathParameters, ResponseBody } from '@store/utility';

export interface getTasksReq {
  start: number;
  limit: number;
  projects?: string[] | null;
}

export const getTasks = async (params: getTasksReq) => {
  const { data } = await ApiClient.get<ResponseBody<Api.operations['list']>>(`/api/tracker/tasks`, {
    params: {
      start: params.start,
      limit: params.limit,
      projects: params.projects ? params.projects : undefined,
    },
  });
  return plainToInstance(GetTasksResponse, data);
};

// Doesnt exist in swagger
export const getTrackerProjects = async (provider: string) => {
  const { data } = await ApiClient.get<Project[]>(`/api/tracker/${provider}/projects`);
  return data;
};

export const loadTaskDetail = async (taskId: PathParameters<Api.operations['get'], 'taskId'>) => {
  const { data } = await ApiClient.get<ResponseBody<Api.operations['get']>>(
    `/api/tracker/tasks/${taskId}`,
  );
  return plainToInstance(Task, data);
};

export const loadTaskTransitions = async (taskId: string) => {
  const { data } = await ApiClient.get<TaskTransition[]>(
    `/api/tracker/tasks/${taskId}/transitions`,
  );
  return plainToInstance(TaskTransition, data);
};

export const executeTaskTransition = async (taskId: string, transitionId: string) => {
  await ApiClient.post(`/api/tracker/tasks/${taskId}/transitions/execute`, {
    transitionId,
  });
};

export const updateTask = async (request: UpdateTaskRequest): Promise<CreateTaskResponse> => {
  const { data } = await ApiClient.patch<CreateTaskResponse>(
    `/api/tracker/tasks/${request.taskId}`,
    request.data,
  );
  return data;
};

export const createTask = async (request: CreateTaskRequest): Promise<CreateTaskResponse> => {
  const { data } = await ApiClient.post<CreateTaskResponse>(`/api/tracker/tasks`, request.data);
  return data;
};

// Doesnt exist in swagger
export const attachProject = async (request: AttachProjectRequest) => {
  await ApiClient.post(`/api/tracker/${request.provider}/attach`, {
    easyProjectId: request.easyProjectId,
    trackerProjectId: request.trackerProjectId,
    organizationId: request.organizationId,
  });
};

// Doesnt exist in swagger
export const getTasksFavorite = async (request: any) => {
  // const { data } = await ApiClient.post(`/api/task/favorite`, request);
  return {
    pages: 0,
    page: 0,
    totalCount: 0,
    queriedCount: 0,
    startRecord: 0,
    data: [],
  };
};

// Doesnt exist in swagger
export const setTasksFavorite = async (
  requestBody: Api.operations['changeFavoriteStatus_2']['requestBody']['content']['application/json'],
  taskId: Api.operations['changeFavoriteStatus_2']['parameters']['path']['id'],
) => {
  const { data } = await ApiClient.put<
    Api.operations['changeFavoriteStatus_2']['responses']['200']['content']['application/json']
  >(`/api/task/${taskId}/favorite`, requestBody);
  enqueueSnackbar('Избранное задачи успешно обновлено', {
    variant: 'success',
  });
  return data;
};

export const getSpentTime = async (taskId: string) => {
  const data = await SpentTimeMock;
  return data;
};
