import React from 'react';
import styles from './LeadTimeChart.module.scss';
import { BarChart } from '@mui/x-charts/BarChart';
import { CustomBar } from '../components/CustomBar/CustomBar';

interface ILeadTimeChart {
  chartData: number[];
  axisXData: string[];
}

export const LeadTimeChart: React.FC<ILeadTimeChart> = ({ chartData, axisXData }) => {
  return (
    <div className={styles.lead__time__chart}>
      <div style={{ width: '100%' }}>
        <BarChart
          height={300}
          xAxis={[
            {
              data: axisXData,
              scaleType: 'band',
            },
          ]}
          slots={{
            bar: (props) => (
              <>
                <CustomBar barChartProps={props} seriesData={chartData} />
              </>
            ),
          }}
          series={[
            {
              color: '#34D15F',
              data: chartData,
            },
          ]}
        />
      </div>
    </div>
  );
};
