import { Dayjs } from 'dayjs';
import React, { useCallback } from 'react';
import styles from './ProfileFooter.module.scss';
import { Button } from '@components/MUI/Button';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { DeleteAccountModal } from './DeleteAccountModal';
import { FormControlLabel } from '@mui/material';
import { MuiSwitch } from '@components/ui/Switch/MuiSwith';
import { ConfirmDeleteModal } from '@components/MUI/ConfirmDeleteModal/ConfirmDeleteModal';
import { useParams } from 'react-router-dom';
import {
  disconnectYandexIntegration,
  isEnabledYandexIntegration,
  redirectToYandexIntegration,
} from '@store/user/user.slice';

type ProfileFooterProps = {
  registration?: Dayjs;
};

export const ProfileFooter: React.FC<ProfileFooterProps> = ({ registration }) => {
  const dispatch = useAppDispatch();
  const yandexIntegrationStatus = useAppSelector(isEnabledYandexIntegration);
  const { userID } = useParams();

  const toggleYandexIntegration = useCallback(async () => {
    if (!yandexIntegrationStatus) {
      await dispatch(redirectToYandexIntegration()).unwrap();
    } else {
      confirmTurnOfIntegration();
    }
  }, [yandexIntegrationStatus]);

  const disableYandexIntegration = useCallback(async () => {
    await dispatch(disconnectYandexIntegration()).unwrap();
    dispatch(closeModal());
  }, []);

  const confirmTurnOfIntegration = useCallback(() => {
    dispatch(
      openModal(
        <ConfirmDeleteModal
          title="Вы действительно хотите отключить интеграцию?"
          btnProp="Отключить"
          onSubmit={disableYandexIntegration}>
          <div></div>
        </ConfirmDeleteModal>,
      ),
    );
  }, [dispatch]);

  return (
    <div className={styles.footer}>
      <div className={styles.registration}>
        <p className="m-0 p-0 color-gray">Дата регистрации</p>
        <span className={styles.footer__content}>
          {registration ? registration.format('DD.MM.YYYY') : '-'}
        </span>
      </div>
      {!userID ? (
        <>
          <div className="d-flex gap-4 align-items-center">
            Интеграция с YandexApi {yandexIntegrationStatus ? 'включена' : 'отключена'}
            <FormControlLabel
              control={
                <MuiSwitch
                  checked={yandexIntegrationStatus}
                  onChange={toggleYandexIntegration}
                  name="integration"
                />
              }
              label=""
            />
          </div>
          <div className="mt-4">
            <Button
              className={styles.button__delete}
              color="error"
              size="large"
              onClick={() => dispatch(openModal(<DeleteAccountModal />))}>
              Удалить аккаунт
            </Button>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
