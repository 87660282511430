import React from 'react';
import { useField } from 'formik';
import { InputField } from '@components/MUI/InputField/InputField';
import { IMaskInput } from 'react-imask';

type PhoneInputFieldProps = {
  name: string;
  placeholder?: string;
  className?: string;
};

interface MaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, MaskProps>(function TextMaskCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+7(000) 000-00-00"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value: any) => {
        if (value.trim() === '+7()') {
          value = '+7(';
        }
        onChange({ target: { name: props.name, value } });
      }}
      overwrite
    />
  );
});

export const PhoneInputField: React.FC<PhoneInputFieldProps> = ({ name, ...props }) => {
  const [field, , helpers] = useField(name);

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    helpers.setValue(inputValue);
  };

  return (
    <InputField
      type="text"
      {...field}
      {...props}
      value={field.value || ''}
      onChange={handlePhoneNumberChange}
      InputProps={{
        inputComponent: TextMaskCustom as any,
      }}
    />
  );
};
