import React, { useEffect } from 'react';
import { YfmEditorView, useYfmEditor } from '@doc-tools/yfm-editor/_/bundle';
import { toaster } from '@gravity-ui/uikit/toaster-singleton-react-18';
import { FormikValues, useFormikContext } from 'formik';
import { ThemeProvider } from '@gravity-ui/uikit';
import { fileUploadHandler } from './utils/fileUploader';
import '@gravity-ui/uikit/styles/styles.scss';
import styles from './YfmEditor.module.scss';

type EditorV12Props = {
  initialContent?: string;
  name: string;
};

export const YfmEditor: React.FC<EditorV12Props> = ({ name }) => {
  const form = useFormikContext<FormikValues>();

  const editor = useYfmEditor({
    linkify: true,
    allowHTML: true,
    initialMarkup: form.values[name],
    initialToolbarVisible: true,
    initialEditorType: 'markup',
    initialSplitModeEnabled: true,
    fileUploadHandler: fileUploadHandler,
  });

  editor.setEditorType('wysiwyg');

  useEffect(() => {
    const handleChange = () => {
      const currentValue = editor.getValue();
      form.setFieldValue(name, currentValue);
    };

    editor.on('change', handleChange);

    return () => {
      editor.off('change', handleChange);
    };
  }, []);

  return (
    <ThemeProvider theme="light" scoped={true}>
      <YfmEditorView
        autofocus={false}
        editor={editor}
        toaster={toaster}
        settingsVisible={false}
        stickyToolbar
        className={styles.editor}
      />
    </ThemeProvider>
  );
};
