import React, { useCallback, useEffect } from 'react';
import telegram from '@assets/img/teams/telegram.svg';
import { Card } from '@mui/material';
import { Navigate, NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { TeamEditModal } from '@pages/teams/modals/TeamEditModal';
import { ConfirmDeleteModal } from '@components/MUI/ConfirmDeleteModal/ConfirmDeleteModal';
import {
  useLoadTeamQuery,
  useSetFavoriteTeamMutation,
  useTeamRemovingMutation,
} from '@store/team/team.slice';
import styles from './TeamPageDetail.module.scss';
import dayjs from 'dayjs';
import avatar from '../../assets/img/avatar.svg';
import { Nav, TabContainer, TabContent } from 'react-bootstrap';
import { TeamLeaveModal } from './modals/TeamLeaveModal';
import { selectCurrentUserId } from '@store/user/user.slice';
import { TeamActionsButton } from './buttons/TeamActionsButton';
import { Star } from '@components/Star/Star';

export function TeamPageRedirect() {
  const { teamId } = useParams();
  return <Navigate to={`/teams/${teamId}/members`} />;
}

export const TeamPageDetail: React.FC = () => {
  const { teamId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const myId = useAppSelector(selectCurrentUserId);
  const [setFavoriteTeamMutation] = useSetFavoriteTeamMutation();
  const [removeTeamMutation] = useTeamRemovingMutation();
  const { data: team, isError } = useLoadTeamQuery(teamId ? teamId : '');

  useEffect(() => {
    if (isError) {
      navigate(`/not-found`);
    }
  }, [teamId]);

  const handleDelete = useCallback(async () => {
    try {
      if (teamId) {
        await removeTeamMutation({ id: teamId }).unwrap();
        navigate('/teams');
        dispatch(closeModal());
      }
    } catch (error) {
      dispatch(
        openModal(
          <ConfirmDeleteModal
            title="Вы не можете удалить команду пока в ней есть участники и открытые проекты"
            btnProp="Понятно"
            cancelBtnProp={null}
            onSubmit={() => dispatch(closeModal())}>
            <div>
              <div>Для удаления необходимо:</div>
              <div>1. Исключить всех участников команды</div>
              <div>1. Все проекты перевести в статус "Завершено"</div>
            </div>
          </ConfirmDeleteModal>,
        ),
      );
    }
  }, [teamId]);

  const openTeamEditModal = useCallback(() => {
    if (!teamId) return;

    dispatch(
      openModal(
        <TeamEditModal
          value={{
            id: team?.id,
            name: team?.name,
            teamLeadId: team?.teamLead?.userId,
            teamTypeId: team?.teamTypeName,
            telegramUrl: team?.telegramUrl,
            positions: team?.positions,
          }}
          submit={(team, formik) => {
            // TODO save team
          }}
        />,
      ),
    );
  }, [dispatch, team, teamId]);

  const removeTeamModal = useCallback(() => {
    dispatch(
      openModal(
        <ConfirmDeleteModal
          title="Вы уверены, что хотите удалить команду?"
          btnProp="Удалить"
          onSubmit={handleDelete}>
          <div>Данное действие нельзя будет отменить. Продолжить?</div>
        </ConfirmDeleteModal>,
      ),
    );
  }, [dispatch]);

  const openLeaveTeamModal = useCallback(() => {
    if (!teamId) return;
    dispatch(openModal(<TeamLeaveModal teamId={teamId} />));
  }, [dispatch, teamId]);

  if (!team) {
    return <></>;
  }

  return (
    <div className="col container-content mb-5">
      <div className={styles.navigation__back}>
        <NavigationBack back={{ to: '/teams' }} />
      </div>
      <Card className="form-container">
        <div className="d-flex justify-content-between mt-4 mb-4">
          <div className="d-flex align-items-center">
            <h2 className="form-title m-0 me-1">{team?.name}</h2>
            {team?.teamLead?.userId === myId ? (
              <TeamActionsButton
                openLeaveTeamModal={openLeaveTeamModal}
                openTeamEditModal={openTeamEditModal}
                removeTeamModal={removeTeamModal}
              />
            ) : (
              <></>
            )}
            {teamId ? (
              <Star
                checked={team.favorite}
                onChange={(value) =>
                  setFavoriteTeamMutation({ requestBody: { status: value }, teamId })
                }
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="d-flex flex-column mb-3">
          <span className="color-gray">Дата создания</span>
          <span className={styles.team__created__date}>
            {dayjs(team?.created).format('DD.MM.YYYY')}
          </span>
        </div>
        <div className="mb-4">
          <div className={styles.teamlead}>
            <h6 className={styles.teamlead__label}>Тимлид</h6>
            <div className="d-flex align-items-center">
              <img src={avatar} alt="teamLead" width={52} height={52} />
              <p className="ms-2 p-0 m-0">{team?.teamLead?.userName}</p>
            </div>
          </div>
        </div>
        <a href={team?.telegramUrl || ''}>
          <div className={styles.telegram}>
            <img src={telegram} className={styles.telegram__img} alt="telegram icon" />
            <div className={styles.telegram__title}>
              <span>Telegram группа</span>
            </div>
          </div>
        </a>
        <div className={styles.team__type}>
          <h6 className={styles.team__type__label}>Тип команды</h6>
          <span className={styles.team__type__name}>{team?.teamTypeName}</span>
        </div>
        <TabContainer>
          <Nav className="mb-3 detail_Menu">
            <NavLink to={`/teams/${teamId}/members`} className={styles.participants__link}>
              Участники
            </NavLink>
            <NavLink to={`/teams/${teamId}/projects`} className={styles.projects__link}>
              Проекты
            </NavLink>
          </Nav>
        </TabContainer>
        <TabContent>
          <Outlet />
        </TabContent>
      </Card>
    </div>
  );
};
