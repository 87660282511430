import React, { useState } from 'react';
import { Star } from '@components/Star/Star';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { useRequestBody } from '@hooks/useRequestBody';
import { TeamItem } from '@store/team/contracts';
import { Dayjs } from 'dayjs';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '@hooks/index';
import { setTeamsCount } from '@store/favorite/favorite.slice';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { useGetFavoriteTeamsQuery, useSetFavoriteTeamMutation } from '@store/team/team.slice';

export const FavoriteTeams: React.FC = () => {
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();
  const [setFavoriteTeam] = useSetFavoriteTeamMutation();

  const { data: favoriteTeams } = useGetFavoriteTeamsQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <AdaptiveTableMUI
      columns={{
        name: {
          label: 'Название команды',
          children: (value, row) => <Link to={`/teams/${row.id}`}>{value}</Link>,
          filterInput: true,
        },
        created: {
          label: 'Дата создания',
          format: (value: Dayjs) => value.format('DD.MM.YYYY'),
          filterInput: true,
          isSortColumn: true,
        },
        membersCount: {
          label: 'Участники',
          filterInput: true,
        },
        teamTypeName: {
          label: 'Тип команды',
          filterInput: true,
        },
        telegramUrl: {
          label: 'Telegram группа',
          children: (value) => <a href={value}>{value}</a>,
        },
        teamLead: {
          label: 'Тимлид',
          children: (_, row: TeamItem) => (
            <Link to={`/userPage/${row.teamLead.userId}`}>{row.teamLead.userName}</Link>
          ),
        },
        star: {
          children: (value, row: TeamItem) => (
            <Star
              checked={true}
              onChange={() =>
                setFavoriteTeam({ requestBody: { status: false }, teamId: row.id }).then(() => {
                  if (favoriteTeams?.totalCount) {
                    dispatch(setTeamsCount(favoriteTeams?.totalCount - 1));
                  }
                })
              }
              style={{ marginRight: '1rem' }}
            />
          ),
        },
      }}
      rows={favoriteTeams?.data || []}
      setRequestBody={setRequestBody}
      setSortColumn={setSortColumn}
      setSortOrder={setSortOrder}
      sortOrder={sortOrder}
      sortColumn={sortColumn}>
      <TablePaginationMUI
        limit={limit}
        count={favoriteTeams?.totalCount}
        entityLabel="команд"
        page={page}
        handleChangePage={(_, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </AdaptiveTableMUI>
  );
};
