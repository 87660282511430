import { BarChart } from '@mui/x-charts/BarChart';
import styles from './EffectivenessChart.module.scss';
import { CustomBar } from '../components/CustomBar/CustomBar';

interface IEffectivenessChart {
  chartData: number[];
  axisXData: string[];
}

const chartStyles = {
  '& .MuiChartsAxis-label': {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: '500',
    '& tspan': {
      fill: '#78858B',
    },
  },
};

export const EffectivenessChart = ({ axisXData, chartData }: IEffectivenessChart) => {
  return (
    <>
      <div>
        <div className={styles.yAxisLabel}>
          <p>Процент времени по задаче</p>
        </div>
        <BarChart
          height={300}
          slotProps={{
            bar: {
              clipPath: `inset(0px round 4px 4px 0px 0px)`,
            },
          }}
          sx={chartStyles}
          xAxis={[
            {
              data: axisXData,
              scaleType: 'band',
              label: 'Дни периода',
            },
          ]}
          slots={{
            bar: (props) => (
              <>
                <CustomBar barChartProps={props} seriesData={chartData} />
              </>
            ),
          }}
          series={[
            {
              color: '#3BABF0',
              data: chartData,
            },
          ]}
        />
      </div>
    </>
  );
};
