import { memo, useEffect, useState } from 'react';
import styles from './CustomBar.module.scss';

interface ICustomBar {
  barChartProps: any;
  seriesData: number[];
}

export interface IAnimationStyle {
  height: number;
  width: number;
  x: number;
  y: number;
}

export const CustomBar = memo(({ barChartProps, seriesData }: ICustomBar) => {
  /*
  Приходится использовать дополнительный state
  Библиотека ассинхронно обновляет параметры для отрисовки <rect>
  */
  const [animationStyle, setAnimationStyle] = useState<IAnimationStyle>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });
  useEffect(() => {
    if (barChartProps?.style?.x?.animation) {
      const { height, width, x, y } = barChartProps.style;
      setAnimationStyle({
        height: height.animation?.to,
        width: width.animation?.to,
        x: x.animation?.to,
        y: y.animation?.to,
      });
    }
  }, [barChartProps.style]);

  const { className, ownerState, style } = barChartProps;
  const { dataIndex, color } = ownerState;

  const clipPath = 'inset(0px round 4px 4px 0px 0px)';

  const textContent = seriesData[dataIndex] ?? '';

  return (
    <g>
      <rect
        className={className}
        fill={color}
        style={style}
        height={animationStyle.height}
        width={animationStyle.width}
        x={animationStyle.x}
        y={animationStyle.y}
        clipPath={clipPath}
      />
      <text
        className={styles.textInside}
        x={animationStyle.x + animationStyle.width / 2}
        y={animationStyle.y + 21}
        dominantBaseline="middle"
        textAnchor="middle">
        {textContent}
      </text>
    </g>
  );
});
