import { publicApi } from '@store/public-api';
import Api from '../public-api-schema';
import { ResponseBody } from '@store/utility';

type NewsParams = {
  page?: string;
  page_size?: string;
  ordering?: string;
  tags__id?: string;
};

export const newsListApi = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    getNewsList: builder.query<ResponseBody<Api.operations['api_news_list']>, {}>({
      query: (newsParams: NewsParams) => ({
        params: newsParams,
        method: 'GET',
        url: 'news/',
      }),
    }),
    getNewsTags: builder.query<ResponseBody<Api.operations['api_news_tags_list']>, {}>({
      query: () => ({
        method: 'GET',
        url: '/news/tags/',
      }),
    }),
    getNewsDetail: builder.query<ResponseBody<Api.operations['api_news_retrieve']>, {}>({
      query: (newsId) => ({
        method: 'GET',
        url: `/news/${newsId}/`,
      }),
    }),
  }),
});

export const { useGetNewsListQuery, useGetNewsTagsQuery, useGetNewsDetailQuery } = newsListApi;
