import { BarChart } from '@mui/x-charts/BarChart';
import styles from './ThroughputChart.module.scss';
import { memo, useCallback } from 'react';

type AdditionalExecutorInfo = {
  percentage: number;
  total: number;
} | null;

export type ChartDataType = {
  data: number[];
  label: string;
  id: string;
  stack: string;
  color: string;
  additionalInfo: AdditionalExecutorInfo[] | null;
};

type ThroughputChartProps = {
  chartData: ChartDataType[];
  xAxisLabels: string[];
  maxTotal: number;
};

const chartStyles = {
  '& .MuiChartsLegend-root': {
    '& .MuiChartsLegend-mark': {
      clipPath: `inset(0px round 4px 4px 4px 4px)`,
    },
  },
  '& .MuiChartsAxis-label': {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: '500',
    '& tspan': {
      fill: '#78858B',
    },
  },
};

export const ThroughputChart = ({ chartData, xAxisLabels, maxTotal }: ThroughputChartProps) => {
  const barsData = Object.fromEntries(chartData.map((item) => [item.id, item]));

  const CustomBar = memo((props: any) => {
    const { className, ownerState, style } = props;
    const { id, dataIndex, color } = ownerState;
    const { y, x, height, width } = style;

    const getLastKey = useCallback(() => {
      const keys = Object.keys(barsData);
      let lastKey = keys[keys.length - 1];

      for (let i = keys.length - 1; i >= 0; i--) {
        const currentKey = keys[i];
        if (barsData[currentKey].data[dataIndex] !== 0) {
          lastKey = currentKey;
          break;
        }
      }

      return lastKey;
    }, [dataIndex]);

    // to make sure we don't assign last key to bar with 0 value
    const actualLastKey = getLastKey();

    const isLast = ownerState.id === actualLastKey;

    const clipPath = isLast ? 'inset(0px round 4px 4px 0px 0px)' : '';

    const textContent = barsData[id].data[dataIndex];

    const percentage = barsData[id].additionalInfo?.[dataIndex]?.percentage ?? '';
    const total = barsData[id].additionalInfo?.[dataIndex]?.total ?? '';

    // calculate y coordinate for top text so it correctly shown above the bar
    const yCordForTopText = maxTotal === total ? y.animation.to - 20 : y.animation.to - 30;
    return (
      <g>
        {isLast && (
          // top text render
          <text
            className={styles.customBar_textOutside}
            fill="#000"
            x={x.animation.to + width.animation.to / 2}
            y={yCordForTopText}
            dominantBaseline="hanging"
            textAnchor="middle"
            style={{ fontWeight: 'bold' }}>
            {total}
          </text>
        )}
        {textContent !== 0 ? (
          <>
            <rect
              className={className}
              fill={color}
              height={height.animation.to}
              width={width.animation.to}
              x={x.animation.to}
              y={y.animation.to}
              clipPath={clipPath}
            />
            <text
              className={styles.customBar_textInside}
              x={x.animation.to + width.animation.to / 2}
              y={y.animation.to + height.animation.to / 2}
              dominantBaseline="middle"
              textAnchor="middle">
              {`${textContent} (${percentage}%)`}
            </text>
          </>
        ) : (
          <rect
            className={className}
            fill={color}
            height={height.animation.to}
            width={width.animation.to}
            x={x.animation.to}
            y={y.animation.to}
            clipPath={clipPath}
          />
        )}
      </g>
    );
  });

  return (
    <>
      <BarChart
        height={551}
        series={chartData}
        sx={chartStyles}
        tooltip={{
          trigger: 'none',
        }}
        slotProps={{
          legend: {
            hidden: true,
          },
        }}
        slots={{
          bar: (props) => (
            <>
              <CustomBar {...props} />
            </>
          ),
        }}
        xAxis={[{ data: xAxisLabels, scaleType: 'band', label: 'Периоды', hideTooltip: true }]}
      />
    </>
  );
};
