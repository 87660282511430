import { ApiClient } from '@store/api-client';
import { PaginationQuery } from '@store/common';
import {
  authCloudsRequest,
  Cloud,
  CreateInstanceRequest,
  CreateInstanceResponse,
  CreateTemplateRequest,
  CreateTemplateResponse,
  InstanceActionRequest,
  InstanceActionResponse,
  InstanceListResponse,
  BillingAccount,
  BillingAccountResponse,
  ProjectsWithCloudsResponse,
  SelectCloudResponse,
  TemplateListResponse,
  UpdateInstanceRequest,
  UpdateInstanceResponse,
  CreateBudgetRuleResponse,
  BudgetRuleItem,
  ObjectSaveResponse,
  PureCloudResource,
  CloudDelegationListResponse,
  CloudDelegation,
  ItemCloudInfrastructureRequest,
  CloudInfrastructureListResponse,
  CloudInfrastructure,
  ItemCloudDeploymentResourceRequest,
  CloudDeploymentResourceListResponse,
  CloudDeploymentResource,
  ItemCloudInstanceResourceRequest,
  CloudInstanceResourceListResponse,
  CloudInstanceResource,
  CloudDeploymentRuleListResponse,
  CloudDeploymentRule,
  ItemCloudImageResourceRequest,
  CloudImageResourceListResponse,
  CloudImageResource,
  ItemCloudSheduleRequest,
  CloudSheduleListResponse,
  CloudShedule,
  CloudDeploymentActionListResponse,
  CloudDeploymentAction,
  EditTemplateParams,
  CloudResourceStatus,
} from '@store/cloud/contracts';
import Api from '@api-schema';

import { BudgetRuleValue } from '@pages/project/components/BudgetRule/BudgetRuleForm';
import dayjs from 'dayjs';
import { RequestBody, ResponseBody } from '@store/utility';

export const authClouds = async (payload?: authCloudsRequest): Promise<string> => {
  const { data } = await ApiClient.get<string>(`/api/cloud/oauth/yandex`, {
    params: payload,
  });
  return data;
};

export const authCloudList = async (projectId: string): Promise<Array<Cloud>> => {
  const { data } = await ApiClient.get<Array<Cloud>>(`/api/cloud/yandex/${projectId}/clouds`);
  return data;
};

export const selectCloud = async (request: {
  projectId: string;
  cloudProjectId: string;
}): Promise<SelectCloudResponse> => {
  const { data } = await ApiClient.post<SelectCloudResponse>(
    `/api/cloud/yandex/${request.projectId}/cloud?cloudProjectId=${request.cloudProjectId}`,
  );
  return data;
};

export const getInstanceList = async (
  request: Partial<PaginationQuery>,
): Promise<InstanceListResponse> => {
  const { data } = await ApiClient.post<InstanceListResponse>(`/api/cloud/instance/list`, {
    start: request.start || 0,
    limit: request.limit || 10,
    sortOrder: request.sortOrder || 'asc',
  });
  return data;
};

export const createInstance = async (
  request: CreateInstanceRequest,
): Promise<CreateInstanceResponse> => {
  const { data } = await ApiClient.post<CreateInstanceResponse>(
    `/api/cloud/instance/machine`,
    request,
  );
  return data;
};

export const getTemplateList = async (
  request: Partial<PaginationQuery>,
): Promise<TemplateListResponse> => {
  const { data } = await ApiClient.post<TemplateListResponse>(
    `/api/cloud/instance/params/template/list`,
    request,
  );
  return data;
};

export const createTemplate = async (
  request: CreateTemplateRequest,
): Promise<CreateTemplateResponse> => {
  const { data } = await ApiClient.post<CreateTemplateResponse>(
    `/api/cloud/instance/params/template`,
    request,
  );
  return data;
};

export const editTemplate = async (params: EditTemplateParams) => {
  const { data } = await ApiClient.put<ResponseBody<Api.operations['saveInstanceParams']>>(
    `/api/cloud/instance/params/template/${params.id}`,
    params.requestBody,
  );
  return data;
};

export const getProjectsWithClouds = async (
  request: Partial<PaginationQuery>,
): Promise<ProjectsWithCloudsResponse> => {
  const { data } = await ApiClient.post<ProjectsWithCloudsResponse>(
    `/api/cloud/yandex/project/list`,
    {
      start: request.start || 0,
      limit: request.limit || 10,
      sortOrder: request.sortOrder || 'asc',
    },
  );
  return data;
};

export const instanceAction = async (
  request: InstanceActionRequest,
): Promise<InstanceActionResponse> => {
  const { data } = await ApiClient.post<InstanceActionResponse>(`/api/cloud/instance`, request);
  return data;
};

export const updateInstance = async (
  request: UpdateInstanceRequest,
): Promise<UpdateInstanceResponse> => {
  const { data } = await ApiClient.put<UpdateInstanceResponse>(`/api/cloud/instance`, request);
  return data;
};

export const getBillingAccounts = async (projectId: string): Promise<BillingAccount[]> => {
  const { data } = await ApiClient.get<BillingAccountResponse>(
    `/api/cloud/budget/account?projectId=${projectId}`,
  );
  return data.data;
};

export const createBudgetRule = async (
  request: BudgetRuleValue,
): Promise<CreateBudgetRuleResponse> => {
  const { data } = await ApiClient.post<CreateBudgetRuleResponse>(`/api/cloud/budget`, request);
  return data;
};

export const getBudgetRule = async (projectId: string): Promise<BudgetRuleItem> => {
  const { data } = await ApiClient.get<BudgetRuleItem>(`/api/cloud/budget?projectId=${projectId}`);
  return data;
};

export const getOrganizationsWithClouds = async (
  request: Partial<PaginationQuery>,
): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(`/api/cloud/organization/list`);
  return data;
};

export const getCloudsWithClouds = async (organizationId: string): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(
    `/api/cloud/cloud/list?parentId=${organizationId}`,
  );
  return data;
};

export const getFoldersWithClouds = async (cloudId: string): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(
    `/api/cloud/folder/list?parentId=${cloudId}`,
  );
  return data;
};

export const getNetworksWithClouds = async (folderId: string): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(
    `/api/cloud/network/list?parentId=${folderId}`,
  );
  return data;
};

export const getInstancesWithClouds = async (folderId: string): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(
    `/api/cloud/instance/list?parentId=${folderId}`,
  );
  return data;
};

export const getZoneWithClouds = async (
  request: Partial<PaginationQuery>,
): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(`/api/cloud/zone/list`);
  return data;
};

export const getPlatformWithClouds = async (
  request: Partial<PaginationQuery>,
): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(`/api/cloud/platform/list`);
  return data;
};

export const getBillingAccountsWithClouds = async (
  request: Partial<PaginationQuery>,
): Promise<PureCloudResource[]> => {
  const { data } = await ApiClient.get<PureCloudResource[]>(`/api/cloud/account/list`);
  return data;
};

/*****************************************/
/*   Делегирование                       */
/*****************************************/
export const saveCloudDelegation = async (request: RequestBody<Api.operations['saveItem_5']>) => {
  // ItemCloudDelegationRequest
  const modifiedDelegation = {
    ...request,
    budgetEndDate: dayjs(request.budgetEndDate).format('DD.MM.YYYY HH:mm'),
  };
  const { data } = await ApiClient.post<ObjectSaveResponse>(
    '/api/cloud/delegation',
    modifiedDelegation,
  );
  return data;
};

export const getCloudDelegationList = async (
  request: Partial<PaginationQuery>,
): Promise<CloudDelegationListResponse> => {
  const { data } = await ApiClient.post<CloudDelegationListResponse>(
    `/api/cloud/delegation/list`,
    request,
  );
  return data;
};
export const getCloudDelegation = async (id: string) => {
  const { data } = await ApiClient.get<CloudDelegation>(`/api/cloud/delegation/${id}`);
  return data;
};

/*****************************************/
/*   Инфраструктура                      */
/*****************************************/
export const saveCloudInfrastructure = async (request: ItemCloudInfrastructureRequest) => {
  const { data } = await ApiClient.post<ObjectSaveResponse>('/api/cloud/infrastructure', request);
  return data;
};

export const getCloudInfrastructureList = async (
  request: Partial<PaginationQuery>,
): Promise<CloudInfrastructureListResponse> => {
  const { data } = await ApiClient.post<CloudInfrastructureListResponse>(
    `/api/cloud/infrastructure/list`,
    request,
  );
  return data;
};
export const getCloudInfrastructure = async (id: string) => {
  const { data } = await ApiClient.get<CloudInfrastructure>(`/api/cloud/infrastructure/${id}`);
  return data;
};
/************************************** ************/
/*   Правила развертывания                        */
/**************************************************/
export const saveCloudDeploymentResource = async (request: ItemCloudDeploymentResourceRequest) => {
  const { data } = await ApiClient.post<ObjectSaveResponse>('/api/cloud/deployment', request);
  return data;
};

export const getCloudDeploymentResourceList = async (
  request: Partial<PaginationQuery>,
): Promise<CloudDeploymentResourceListResponse> => {
  const { data } = await ApiClient.post<CloudDeploymentResourceListResponse>(
    `/api/cloud/deployment/list`,
    request,
  );
  return data;
};
export const getCloudDeploymentResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentResource>(`/api/cloud/deployment/${id}`);
  return data;
};

/**************************************************/
/*   Инстансы виртуальных машин                   */
/**************************************************/
export const saveCloudInstanceResource = async (request: ItemCloudInstanceResourceRequest) => {
  const { data } = await ApiClient.post<ObjectSaveResponse>('/api/cloud/instance', request);
  return data;
};

export const getCloudInstanceResourceList = async (
  request: Partial<PaginationQuery>,
): Promise<CloudInstanceResourceListResponse> => {
  const { data } = await ApiClient.post<CloudInstanceResourceListResponse>(
    `/api/cloud/instance/list`,
    request,
  );
  return data;
};
export const getCloudInstanceResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudInstanceResource>(`/api/cloud/instance/${id}`);
  return data;
};

/**************************************************/
/*   Справочник правил развертывания              */
/**************************************************/
export const getCloudDeploymentRuleList = async (
  request: Partial<PaginationQuery>,
): Promise<CloudDeploymentRuleListResponse> => {
  const { data } = await ApiClient.post<CloudDeploymentRuleListResponse>(
    `/api/cloud/deployment/rule/list`,
    request,
  );
  return data;
};
export const getCloudDeploymentRule = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentRule>(`/api/cloud/deployment/rule/${id}`);
  return data;
};

/**************************************************/
/*   Справочник образов                           */
/**************************************************/
export const saveCloudImageResource = async (request: ItemCloudImageResourceRequest) => {
  const { data } = await ApiClient.post<ObjectSaveResponse>('/api/cloud/image/resource', request);
  return data;
};

export const getCloudImageResourceList = async (
  request: Partial<PaginationQuery>,
): Promise<CloudImageResourceListResponse> => {
  const { data } = await ApiClient.post<CloudImageResourceListResponse>(
    `/api/cloud/image/resource/list`,
    request,
  );
  return data;
};
export const getCloudImageResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudImageResource>(`/api/cloud/image/resource/${id}`);
  return data;
};
/**************************************************/
/*   Расписание                                   */
/**************************************************/
export const saveCloudShedule = async (request: ItemCloudSheduleRequest) => {
  const { data } = await ApiClient.post<ObjectSaveResponse>('/api/cloud/schedule', request);
  return data;
};

export const getCloudSheduleList = async (
  request: Partial<PaginationQuery>,
): Promise<CloudSheduleListResponse> => {
  const { data } = await ApiClient.post<CloudSheduleListResponse>(
    `/api/cloud/schedule/list`,
    request,
  );
  return data;
};
export const getCloudShedule = async (id: string) => {
  const { data } = await ApiClient.get<CloudShedule>(`/api/cloud/schedule/${id}`);
  return data;
};

/**************************************************/
/*   Справочник действий по расписанию            */
/**************************************************/
export const getCloudDeploymentActionList = async (
  request: Partial<PaginationQuery>,
): Promise<CloudDeploymentActionListResponse> => {
  const { data } = await ApiClient.post<CloudDeploymentActionListResponse>(
    `/api/cloud/deployment/action/list`,
    request,
  );
  return data;
};
export const getCloudDeploymentAction = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(`/api/cloud/deployment/action/${id}`);
  return data;
};

/**************************************************/
/*   Операции над облаком                         */
/**************************************************/

/**************************************************/
/*   Операции над инфраструктурой                 */
/**************************************************/
// Построить инфраструктуру
export const buildCloudInfrastructure = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/infrastructure/operation/build/${id}`,
  );
  return data;
};
// Разрушть инфраструктуру
export const destroyCloudInfrastructure = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/infrastructure/operation/destroy/${id}`,
  );
  return data;
};

/**************************************************/
/*   Операции над правилами развертывания         */
/**************************************************/
// Развернуть виртуальные машины
export const buildCloudDeploymentResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/deployment/operation/build/${id}`,
  );
  return data;
};
// Разрушить виртуальные машины
export const destroyCloudDeploymentResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/deployment/operation/destroy/${id}`,
  );
  return data;
};
// Запустить виртуальные машины
export const startCloudDeploymentResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/deployment/operation/start/${id}`,
  );
  return data;
};
// Остановть виртуальные машины
export const stopCloudDeploymentResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/deployment/operation/stop/${id}`,
  );
  return data;
};
// Развернуть и запустить виртуальные машины
export const buildAndStartCloudDeploymentResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/deployment/operation/build/start/${id}`,
  );
  return data;
};

/**************************************************/
/*   Операции над виртуальной машиной             */
/**************************************************/
// Развернуть виртуальную машину
export const buildCloudInstanceResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/instance/operation/build/${id}`,
  );
  return data;
};
// Разрушить виртуальную машину
export const destroyCloudInstanceResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/instance/operation/destroy/${id}`,
  );
  return data;
};
// Запустить виртуальную машину
export const startCloudInstanceResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/instance/operation/start/${id}`,
  );
  return data;
};
// Остановть виртуальную машину
export const stopCloudInstanceResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/instance/operation/stop/${id}`,
  );
  return data;
};
// Развернуть и запустить виртуальную машину
export const buildAndStartCloudInstanceResource = async (id: string) => {
  const { data } = await ApiClient.get<CloudDeploymentAction>(
    `/api/cloud/instance/operation/build/start/${id}`,
  );
  return data;
};

export const getCloudResourceStatus = async (
  request: Partial<PaginationQuery>,
): Promise<CloudResourceStatus[]> => {
  const { data } = await ApiClient.get<CloudResourceStatus[]>(`/api/cloud/resource/status/list`);
  return data;
};
