import React from 'react';

export const RequestsCreatedByMeIcon = () => {
  return (
    <svg
      className="icon me-2"
      width="20"
      height="20"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 12.7508V11.2508H9V12.7508H0ZM0 4.75078V3.25078H9V4.75078H0ZM14.475 14.6508L10.925 11.1008L11.975 10.0508L14.475 12.5258L18.95 8.05078L20 9.12578L14.475 14.6508ZM14.475 6.65078L10.925 3.10078L11.975 2.05078L14.475 4.52578L18.95 0.0507812L20 1.12578L14.475 6.65078Z"
        fill="currentColor"
      />
    </svg>
  );
};
