// import emojiDefs from 'markdown-it-emoji/lib/data/full.json';

// some emojis from markdown-it-emoji/lib/data/full.json
export const emojiDefs: Record<string, string> = {
  grinning: '😀',
  smiley: '😃',
  smile: '😄',
  grin: '😁',
  laughing: '😆',
  satisfied: '😆',
  sweat_smile: '😅',
  rofl: '🤣',
  joy: '😂',
  slightly_smiling_face: '🙂',
  upside_down_face: '🙃',
  wink: '😉',
  blush: '😊',
  innocent: '😇',
  smiling_face_with_three_hearts: '🥰',
  heart_eyes: '😍',
  star_struck: '🤩',
  kissing_heart: '😘',
  kissing: '😗',
  // relaxed: '☺️',
  kissing_closed_eyes: '😚',
  kissing_smiling_eyes: '😙',
  smiling_face_with_tear: '🥲',
  yum: '😋',
  stuck_out_tongue: '😛',
  stuck_out_tongue_winking_eye: '😜',
  zany_face: '🤪',
  stuck_out_tongue_closed_eyes: '😝',
  money_mouth_face: '🤑',
  hugs: '🤗',
  hand_over_mouth: '🤭',
  shushing_face: '🤫',
  thinking: '🤔',
  zipper_mouth_face: '🤐',
  raised_eyebrow: '🤨',
  neutral_face: '😐',
  expressionless: '😑',
  no_mouth: '😶',
  smirk: '😏',
  unamused: '😒',
  roll_eyes: '🙄',
  grimacing: '😬',
  lying_face: '🤥',
  relieved: '😌',
  pensive: '😔',
  sleepy: '😪',
  drooling_face: '🤤',
  sleeping: '😴',
  mask: '😷',
  face_with_thermometer: '🤒',
  face_with_head_bandage: '🤕',
  nauseated_face: '🤢',
  vomiting_face: '🤮',
  sneezing_face: '🤧',
  hot_face: '🥵',
  cold_face: '🥶',
  woozy_face: '🥴',
  dizzy_face: '😵',
  exploding_head: '🤯',
  cowboy_hat_face: '🤠',
  partying_face: '🥳',
  disguised_face: '🥸',
  sunglasses: '😎',
  nerd_face: '🤓',
  monocle_face: '🧐',
  confused: '😕',
  worried: '😟',
  slightly_frowning_face: '🙁',
  // frowning_face: '☹️',
  open_mouth: '😮',
  hushed: '😯',
  astonished: '😲',
  flushed: '😳',
  pleading_face: '🥺',
  frowning: '😦',
  anguished: '😧',
  fearful: '😨',
  cold_sweat: '😰',
  disappointed_relieved: '😥',
  cry: '😢',
  sob: '😭',
  scream: '😱',
  confounded: '😖',
  persevere: '😣',
  disappointed: '😞',
  sweat: '😓',
  weary: '😩',
  tired_face: '😫',
  yawning_face: '🥱',
  triumph: '😤',
  rage: '😡',
  pout: '😡',
  angry: '😠',
  cursing_face: '🤬',
  smiling_imp: '😈',
  imp: '👿',
  skull: '💀',
  skull_and_crossbones: '☠️',
  shit: '💩',
  clown_face: '🤡',
  japanese_ogre: '👹',
  japanese_goblin: '👺',
  ghost: '👻',
  alien: '👽',
  space_invader: '👾',
  robot: '🤖',
  smiley_cat: '😺',
  smile_cat: '😸',
  joy_cat: '😹',
  heart_eyes_cat: '😻',
  smirk_cat: '😼',
  kissing_cat: '😽',
  scream_cat: '🙀',
  crying_cat_face: '😿',
  pouting_cat: '😾',
  see_no_evil: '🙈',
  hear_no_evil: '🙉',
  speak_no_evil: '🙊',
  kiss: '💋',
  broken_heart: '💔',
  heart: '❤️',
  zzz: '💤',
  wave: '👋',
  raised_back_of_hand: '🤚',
  raised_hand_with_fingers_splayed: '🖐️',
  hand: '✋',
  raised_hand: '✋',
  vulcan_salute: '🖖',
  ok_hand: '👌',
  pinched_fingers: '🤌',
  pinching_hand: '🤏',
  v: '✌️',
  crossed_fingers: '🤞',
  love_you_gesture: '🤟',
  metal: '🤘',
  call_me_hand: '🤙',
  point_left: '👈',
  point_right: '👉',
  point_up_2: '👆',
  middle_finger: '🖕',
  fu: '🖕',
  point_down: '👇',
  point_up: '☝️',
  '+1': '👍',
  thumbsup: '👍',
  '-1': '👎',
  thumbsdown: '👎',
  fist: '✊',
  punch: '👊',
  fist_left: '🤛',
  fist_right: '🤜',
  clap: '👏',
  raised_hands: '🙌',
  open_hands: '👐',
  palms_up_together: '🤲',
  handshake: '🤝',
  pray: '🙏',
  writing_hand: '✍️',
  nail_care: '💅',
  selfie: '🤳',
  muscle: '💪',
  mechanical_arm: '🦾',
  eyes: '👀',
  eye: '👁️',
  footprints: '👣',
  monkey_face: '🐵',
  monkey: '🐒',
  dog: '🐶',
  wolf: '🐺',
  fox_face: '🦊',
  cat: '🐱',
  horse: '🐴',
  unicorn: '🦄',
  cow: '🐮',
  pig: '🐷',
  mouse: '🐭',
  chicken: '🐔',
  rooster: '🐓',
  bird: '🐦',
  four_leaf_clover: '🍀',
  egg: '🥚',
  cake: '🍰',
  coffee: '☕',
  wine_glass: '🍷',
  beer: '🍺',
  beers: '🍻',
  clinking_glasses: '🥂',
  house: '🏠',
  bank: '🏦',
  sunrise: '🌅',
  taxi: '🚕',
  car: '🚗',
  stop_sign: '🛑',
  construction: '🚧',
  anchor: '⚓',
  rocket: '🚀',
  hourglass: '⌛',
  alarm_clock: '⏰',
  moon: '🌔',
  zap: '⚡',
  fire: '🔥',
  tada: '🎉',
  gift: '🎁',
  trophy: '🏆',
  '8ball': '🎱',
  video_game: '🎮',
  spades: '♠️',
  hearts: '♥️',
  diamonds: '♦️',
  clubs: '♣️',
  chess_pawn: '♟️',
  art: '🎨',
  crown: '👑',
  mega: '📣',
  bell: '🔔',
  no_bell: '🔕',
  mag: '🔍',
  lock: '🔒',
  unlock: '🔓',
  key: '🔑',
  gun: '🔫',
  link: '🔗',
  drop_of_blood: '🩸',
  pill: '💊',
  bed: '🛏️',
  moyai: '🗿',
  warning: '⚠️',
  children_crossing: '🚸',
  no_entry: '⛔',
  no_entry_sign: '🚫',
  no_bicycles: '🚳',
  no_smoking: '🚭',
  do_not_litter: '🚯',
  'non-potable_water': '🚱',
  no_pedestrians: '🚷',
  no_mobile_phones: '📵',
  underage: '🔞',
  radioactive: '☢️',
  biohazard: '☣️',
  back: '🔙',
  end: '🔚',
  on: '🔛',
  soon: '🔜',
  top: '🔝',
  cinema: '🎦',
  infinity: '♾️',
  bangbang: '‼️',
  interrobang: '⁉️',
  question: '❓',
  exclamation: '❗',
  o: '⭕',
  x: '❌',
  copyright: '©️',
  registered: '®️',
  tm: '™️',
  new: '🆕',
  ok: '🆗',
  sos: '🆘',
  up: '🆙',
  vs: '🆚',
  radio_button: '🔘',
  checkered_flag: '🏁',
};
