import React from 'react';
import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import { Card } from '@mui/material';
import { TemplateForm, TemplateFormProps } from '../TemplateForm';
import styles from './CreateTemplate.module.scss';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@hooks/index';
import { createTemplate } from '@store/cloud/cloud.slice';

const defaultTemplate = { name: '', memory: 2, cores: 1, diskSize: 20 };

export const CreateTemplate: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const submitTemplateForm: TemplateFormProps['submit'] = async (template, form) => {
    try {
      await dispatch(
        createTemplate({
          name: template.name as string,
          memory: template.memory as number,
          cores: template.cores as number,
          diskSize: template.diskSize as number,
        }),
      ).unwrap();
      navigate(`/clouds/templates/`);
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    }
  };

  return (
    <div>
      <NavigationBack />
      <Card className={styles.create__template__card} elevation={0} variant="outlined">
        <div className={styles.create__template__card__title}>
          <h2>Создание шаблона</h2>
        </div>
        <TemplateForm submit={submitTemplateForm} value={defaultTemplate} />
      </Card>
    </div>
  );
};
