import React, { useEffect } from 'react';
import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import { Card } from '@mui/material';
import { TemplateForm, TemplateFormProps } from '../TemplateForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { editTemplate, loadTemplates, selectDetailTemplate } from '@store/cloud/cloud.slice';
import styles from './EditTemplate.module.scss';

export const EditTemplate: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { templateId } = useParams();
  const template = useAppSelector((state) => selectDetailTemplate(state, templateId || ''));

  useEffect(() => {
    dispatch(loadTemplates({}));
  }, [dispatch]);

  const submitTemplateForm: TemplateFormProps['submit'] = async (template, form) => {
    try {
      const { id } = await dispatch(
        editTemplate({
          id: templateId || '',
          //@ts-ignore
          requestBody: {
            name: template.name as string,
            memory: (template.memory as number) * 1024 * 1024 * 1024,
            cores: template.cores as number,
            diskSize: (template.diskSize as number) * 1024 * 1024 * 1024,
          },
        }),
      ).unwrap();
      navigate(`/clouds/templates/${id}`);
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    }
  };

  return (
    <div>
      <NavigationBack />
      <Card className={styles.edit__template__card} elevation={0} variant="outlined">
        <div className={styles.edit__template__card__title}>
          <h2>Редактирование шаблона</h2>
        </div>
        <TemplateForm
          submit={submitTemplateForm}
          value={{
            name: template?.name,
            diskSize: template?.diskSize,
            cores: template?.cores,
            memory: template?.memory,
          }}
        />
      </Card>
    </div>
  );
};
