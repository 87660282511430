import React from 'react';
import styles from './YandexBenefit.module.scss';

export interface YandexBenefitProps {
  picture: string;
  title: string;
  description: string;
}

export const YandexBenefit: React.FC<YandexBenefitProps> = ({ picture, title, description }) => {
  return (
    <>
      <div className={styles.main}>
        <div>
          <img src={picture} alt="" />
        </div>
        <div>
          <p className={styles.title}>{title}</p>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
    </>
  );
};
