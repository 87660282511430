import React from 'react';
import { ActionButtons, ActionButtonsProps } from '../ActionButtons/ActionButtons';
import { ActionsModalButton } from '@components/MUI/ActionsModalButton/ActionsModalButton';

export const ActionsButtonsModal: React.FC<ActionButtonsProps> = ({
  handleApprove,
  handleClose,
  handleSendMessage,
  isOnlySend,
  withoutSend,
}) => {
  return (
    <ActionsModalButton
      children={() => (
        <ActionButtons
          isOnlySend={isOnlySend}
          handleApprove={handleApprove}
          handleClose={handleClose}
          handleSendMessage={handleSendMessage}
          withoutSend={withoutSend}
        />
      )}
    />
  );
};
