import React from 'react';
import { Button } from '@components/MUI/Button';
import { ModeSelection } from '@components/ModeSelection/ModeSelection';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logo from '@assets/img/logo.svg';
import styles from './MainHeader.module.scss';
import { Link } from 'react-router-dom';
import { ButtonMUI } from '@components/MUI/ButtonMUI';
import { useAppSelector } from '@hooks/index';
import { isAuthenticated } from '@store/user/user.slice';

export function MainHeader() {
  const isAuth = useAppSelector(isAuthenticated);
  return (
    <>
      <ModeSelection />
      <AppBar
        position="static"
        sx={{
          top: '46px',
          paddingRight: '72px',
          paddingLeft: '72px',
          boxShadow: 'none',
          zIndex: '1',
          '@media (max-width: 768px)': {
            paddingRight: '15px',
            paddingLeft: '15px',
          },
          '@media (min-width: 768px) and (max-width: 1200px)': {
            paddingRight: '36px',
            paddingLeft: '36px',
          },
        }}>
        <Toolbar className={styles.toolbar}>
          <div className={styles.headerBlock}>
            <div>
              <Link to="/">
                <img src={logo} alt="Логотип" />
              </Link>
            </div>
            {!isAuth ? (
              <div>
                <Link to="/registration">
                  <ButtonMUI variant="contained" className={styles.button_connect}>
                    Присоединиться
                  </ButtonMUI>
                </Link>
                <Link to="/authorization">
                  <Button variant="outlined" className={styles.button__login}>
                    Войти
                  </Button>
                </Link>
              </div>
            ) : (
              <div>
                <Link to="/">
                  <ButtonMUI variant="contained" className={styles.button_connect}>
                    Профиль
                  </ButtonMUI>
                </Link>
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}
