import React from 'react';
import { Card } from './Card';
import { ReactComponent as TimeGreenIcon } from '@assets/img/timeGreenIcon.svg';
import { ReactComponent as TeamGreenIcon } from '@assets/img/teamGreenIcon.svg';
import { ReactComponent as TaskGreenIcon } from '@assets/img/taskGreenIcon.svg';
import { ReactComponent as EventGreenIcon } from '@assets/img/eventGreenIcon.svg';
import { CommonAnalyticsBlockProps } from '../CommonAnalyticsBlock';
import styles from './CradsBlock.module.scss';

export const CardsBlock: React.FC<CommonAnalyticsBlockProps> = ({ commonAnalytics }) => {
  return (
    <div className={styles.card__block}>
      <Card
        Icon={TimeGreenIcon}
        text="Затраченное время"
        count={commonAnalytics?.spentHours || 0}
      />
      <Card Icon={TeamGreenIcon} text="Исполнителей" count={commonAnalytics?.executors || 0} />
      <Card
        Icon={EventGreenIcon}
        text="Дней до дедлайна"
        count={commonAnalytics?.daysUntilDeadline || 0}
      />
      <Card
        Icon={TaskGreenIcon}
        text="Задач в работе"
        count={commonAnalytics?.tasks.inProgress || 0}
      />
    </div>
  );
};
