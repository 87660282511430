import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import returnBack from '@assets/img/registration/arrow-back.svg';
import { Button } from '@components/MUI/Button';
import styles from '@pages/authentication/Authentication.module.scss';
import { useRegistrationMutation } from '@store/user/user.slice';

export function VerifyEmail() {
  const [seconds, setSeconds] = useState(59);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const handlerRemoveItem = () => {
    sessionStorage.removeItem('email');
  };
  const [userRegistration] = useRegistrationMutation();

  useEffect(() => {
    const storedEmail = sessionStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
    }
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds((seconds) => seconds - 1);
      } else {
        setIsButtonDisabled(false);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [seconds]);

  const handleResendClick = async () => {
    try {
      const response = await userRegistration({ email });
      if ('data' in response) {
        setSeconds(59);
        setIsButtonDisabled(true);
      }
    } catch (error: any) {}
  };

  return (
    <div className={`form-data mt-5 mb-5 ${styles.formAuth}`}>
      <div className="fw-bold fs-2 color-black mb-3">Проверьте почту</div>
      <div style={{ marginBottom: '32px' }}>
        <p>Отправили письмо на {email} перейдите по ссылке, чтобы продолжить регистрацию.</p>
        <p>
          Если не получили письмо, нажмите «Отправить повторно» через 00:
          {seconds < 10 ? `0${seconds}` : seconds} или напишите на
          <span className="color-green text-decoration-underline ms-1">help@egile.ru</span>
        </p>
        <Box
          sx={{
            marginTop: '56px',
          }}>
          <Button
            variant="contained"
            className="pt-3 pb-3 fs-7 fw-600 w-100 text-white"
            onClick={handleResendClick}
            disabled={isButtonDisabled}>
            Отправить повторно
          </Button>
        </Box>
        <Box
          sx={{
            marginTop: '38px',
          }}>
          <Link to="/registration" className="link__return-back" onClick={handlerRemoveItem}>
            <img src={returnBack} alt="Вернуться ко входу" />
            Вернуться ко входу
          </Link>
        </Box>
      </div>
    </div>
  );
}
