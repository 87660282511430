import { FC, PropsWithChildren } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { MainHeader } from '@components/MainHeader/MainHeader';
import { MainFooter } from '@components/MainFooter/MainFooter';

export const PublicLayout: FC<PropsWithChildren> = function AuthLayout() {
  return (
    <div>
      <MainHeader />
      <Outlet />
      <MainFooter />
      <ScrollRestoration />
    </div>
  );
};
