import React from 'react';
import { ReactComponent as MoreIcon } from '@assets/img/moreVertical.svg';
import { useAppDispatch } from '@hooks/index';
import { ActionsModal } from './ActionsModal';
import { openModal } from '@store/ui/ui.slice';
import { TableChildren } from '@components/Table/TableMUI';

type ActionsModalButtonProps = {
  children?: TableChildren;
  value?: string | number;
  row?: any;
};

export const ActionsModalButton: React.FC<ActionsModalButtonProps> = ({ children, value, row }) => {
  const dispatch = useAppDispatch();

  const handleActionsModal = () => {
    dispatch(openModal(<ActionsModal children={children} value={value} row={row} />));
  };

  return (
    <button onClick={handleActionsModal}>
      <MoreIcon />
    </button>
  );
};
