import { useAppSelector } from '@hooks/index';
import { modalContext } from '@store/ui/ui.slice';
import { createPortal } from 'react-dom';
import { Outlet } from 'react-router-dom';

export const ModalsLayoutProvider = () => {
  const modal = useAppSelector(modalContext);
  return (
    <>
      <Outlet />
      {createPortal(modal, document.body, 'ui.modal')}
    </>
  );
};
