import React, { useState } from 'react';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { StatusLabel, statusColors } from '@components/ui/StatusLabel/StatusLabel';
import { useRequestBody } from '@hooks/useRequestBody';
import { Project } from '@store/project/contracts';
import { Dayjs } from 'dayjs';
import { Link } from 'react-router-dom';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { useGetExecutorProjectsQuery } from '@store/project/project.slice';

export const ProjectsExecutor: React.FC = () => {
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();

  const { data: projects, isLoading } = useGetExecutorProjectsQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <AdaptiveTableMUI
      setRequestBody={setRequestBody}
      setSortColumn={setSortColumn}
      setSortOrder={setSortOrder}
      sortOrder={sortOrder}
      sortColumn={sortColumn}
      isLoading={isLoading}
      columns={{
        name: {
          label: 'Проект',
          children: (value, row: Project) => (
            <Link to={`/projects/${row.id}/details`}>{value}</Link>
          ),
          filterInput: true,
          isSortColumn: true,
        },
        teamName: {
          label: 'Команда',
          filterInput: true,
          children: (value, row: Project) => <Link to={`/teams/${row.teamId}`}>{value}</Link>,
        },
        created: {
          label: 'Старт проекта',
          format: (value: Dayjs) => value.format('DD.MM.YYYY'),
          filterInput: true,
          isSortColumn: true,
        },
        deadLine: {
          label: 'Дедлайн',
          format: (value: Dayjs) => value.format('DD.MM.YYYY'),
          filterInput: true,
          isSortColumn: true,
        },
        teamLeadName: {
          label: 'Менеджер',
          children: (value, row: Project) => (
            <Link to={`/userPage/${row.teamLeadId}`}>{value}</Link>
          ),
          filterInput: true,
        },
        projectStatusName: {
          label: 'Статус',
          children: (value: string) => <StatusLabel color={statusColors.get(value)} text={value} />,
          filterInput: true,
        },
      }}
      rows={projects?.data || []}
      emptyLabel={'У вас пока нет проектов'}>
      <TablePaginationMUI
        limit={limit}
        count={projects?.totalCount}
        entityLabel="проектов"
        page={page}
        handleChangePage={(_, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </AdaptiveTableMUI>
  );
};
