import { PropsWithChildren, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { closeModal, isModalOpen } from '@store/ui/ui.slice';
import { Box, Modal as MModal, ModalProps as MModalProps } from '@mui/material';
import styles from './Modal.module.scss';
import classNames from 'classnames';

export type ModalProps = Omit<MModalProps, 'open'> & {
  boxClassName?: string;
};

export const ModalFooter = function (props: PropsWithChildren) {
  return <div className={styles.modal__footer}>{props.children}</div>;
};

export const Modal: React.FC<ModalProps> = (props) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isModalOpen);

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <MModal {...props} open={isOpen} className={styles.modal} onClose={handleCloseModal}>
      <Box className={classNames(styles.container, props.boxClassName)}>
        {props.children ? props.children : <></>}
      </Box>
    </MModal>
  );
};
