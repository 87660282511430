import styles from '@pages/news/newsList/NewsList.module.scss';
import { CircularProgress } from '@mui/material';
import { useGetNewsListQuery } from '@store/news/news';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { useState } from 'react';
import { useNewsSearchParams } from '@hooks/useNewsSearchParams';
import { NewsTags } from '../components/tags/NewsTags';
import { NewsCard, NewsItem } from '../components/newsCard/NewsCard';

export const NewsList = () => {
  const { currentQuery, setSearchParams } = useNewsSearchParams();

  const {
    data: newsListResponse,
    isLoading: isLoadingNews,
    isError: isErrorInNews,
  } = useGetNewsListQuery(currentQuery);

  let newsList: NewsItem[] | undefined = newsListResponse?.results;

  let resultNews;

  if (isErrorInNews || !newsList) {
    resultNews = <h2 className="text-center mt-5">Ошибка отображения списка новостей</h2>;
  } else if (newsList.length === 0) {
    resultNews = <h2 className="text-center mt-5">Список новостей пуст</h2>;
  } else {
    resultNews = newsList?.map((newsItem: NewsItem) => (
      <>
        <NewsCard item={newsItem} key={newsItem.id} />
      </>
    ));
  }

  const [page, setPage] = useState(Number(currentQuery.page) || 1);
  const limit = Number(currentQuery.page_size) || 5;

  function handlePageChange(newPage: number) {
    setPage(newPage);
    setSearchParams((search) => {
      search.set('page', String(newPage));
      return search;
    });
  }

  return (
    <div className={`${styles.container} ${styles.wrapper}`}>
      <div className={`${styles.header}`}>
        <h1>Новости</h1>
      </div>

      <NewsTags />

      {isLoadingNews ? <CircularProgress /> : resultNews}

      {newsListResponse && newsListResponse?.count > limit ? (
        <div className={'pt-2'}>
          <TablePaginationMUI
            withoutChangingItemsCount={true}
            limit={limit}
            count={newsListResponse?.count}
            entityLabel="news"
            page={page}
            handleChangePage={(_, newPage) => handlePageChange(newPage)}
            handleChangeRowsPerPage={() => {}}
            aligning="left"
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
