import React from 'react';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';
import * as Yup from 'yup';
import logoCompany from '@assets/img/profile/logoCompany.png';
import { ProfileAboutMeValue } from '@pages/profile/profileEditForms/ProfileAboutMeForm';
import { Paper } from '@mui/material';
import styles from './ProfileAboutCompanyForm.module.scss';
import { InputField } from '@components/MUI/InputField/InputField';
import { Button } from '@components/MUI/Button';
import { IconDropZone } from '@components/ui/IconDropZone/IconDropZone';
import { PhoneInputField } from '@components/MUI/PhoneInputField/PhoneInputField';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export type ProfileAboutCompanyValue = {
  logo?: string;
  companyName?: string;
  url?: string;
  phone?: string;
  email?: string;
  inn?: string;
  officeLocation?: string;
  description?: string;
  rules?: string;
};

export type ProfileFormAboutCompanyProps = {
  value: ProfileAboutCompanyValue;
  submit: (
    value: ProfileAboutCompanyValue,
    form: FormikBag<ProfileFormAboutCompanyProps, ProfileAboutCompanyValue>,
  ) => void;
};

const ProfileFormAboutCompanySchema = Yup.object({
  companyName: Yup.string()
    .matches(
      /^[A-ZА-ЯЁ][a-zа-яё]*( [a-zа-яёA-ZА-ЯЁ][a-zа-яёA-ZА-ЯЁ-]*)*$/,
      'Поле должно содержать только буквы и начинаться с заглавной буквы',
    )
    .required('Поле обязательно для заполнения'),
  url: Yup.string().required('Поле обязательно для заполнения'),
  phone: Yup.string()
    .test('is-valid-phone', 'Введите корректный номер телефона', (value) => {
      if (!value) return false;
      const phoneNumber = parsePhoneNumberFromString(value, 'RU');
      return phoneNumber ? phoneNumber.isValid() : false;
    })
    .nullable()
    .required('Обязательное поле'),
  inn: Yup.string()
    .required('Поле обязательно для заполнения')
    .matches(/^\d{10}$|^\d{12}$/, 'ИНН должен содержать 10 или 12 цифр'),
  email: Yup.string()
    .email('Введите корректный E-mail')
    .required('Поле обязательно для заполнения'),
  officeLocation: Yup.string(),
  description: Yup.string(),
  rules: Yup.string(),
});

export const FormComponentProfileAboutCompany = () => {
  const profileAboutCompanyForm = useFormikContext<ProfileAboutMeValue>();

  profileAboutCompanyForm.validationSchema = ProfileFormAboutCompanySchema;

  return (
    <Paper elevation={0} className={styles.profile__company__wrapper} variant="outlined">
      <div className={styles.profile__company__title}>
        <h3>О компании</h3>
      </div>
      <Form
        onSubmit={profileAboutCompanyForm.handleSubmit}
        className={styles.profile__company__form}>
        <div className="d-flex flex-column">
          <div className={styles.company__logo}>
            <IconDropZone name="logo" alt="company-logo" defaultSrc={logoCompany} />
          </div>
          <InputField
            name="companyName"
            type="text"
            placeholder="Название организации"
            className={styles.form_field}
          />
          <InputField
            name="url"
            type="text"
            placeholder="Ссылка на организацию"
            className={styles.form_field}
          />
          <PhoneInputField name="phone" placeholder="Телефон" className={styles.form_field} />
          <InputField name="email" type="text" placeholder="Почта" className={styles.form_field} />
          <InputField name="inn" type="number" placeholder="ИНН" className={styles.form_field} />
          <InputField
            name="officeLocation"
            type="text"
            placeholder="Адрес"
            className={styles.form_field}
          />
          <InputField
            name="description"
            type="text"
            placeholder="Описание организации"
            className={styles.form_field}
            multiline
            rows={4}
          />
          <InputField
            name="rules"
            type="text"
            placeholder="Основные правила взаимодействия по разработке"
            className={styles.form_field}
            multiline
            rows={4}
          />
        </div>
        <Button
          type="submit"
          disabled={!profileAboutCompanyForm.isValid}
          variant="contained"
          size="large">
          Сохранить
        </Button>
        <RequiredFieldsIndicator className={styles.footer} />
      </Form>
    </Paper>
  );
};

export const ProfileAboutCompanyForm = withFormik<
  ProfileFormAboutCompanyProps,
  ProfileAboutCompanyValue
>({
  displayName: 'ProfileAboutCompanyForm',
  mapPropsToValues: (props) => {
    return {
      logo: props.value.logo,
      companyName: props.value.companyName,
      url: props.value.url,
      phone: props.value.phone,
      email: props.value.email,
      inn: props.value.inn,
      officeLocation: props.value.officeLocation,
      description: props.value.description,
      rules: props.value.rules,
    };
  },
  enableReinitialize: true,
  validationSchema: ProfileFormAboutCompanySchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponentProfileAboutCompany);
