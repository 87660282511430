import React, { useEffect, useState } from 'react';
import { PasswordForm, PasswordFormProps } from '@pages/authentication/PasswordForm';
import { useConfirmRegistrationMutation } from '@store/user/user.slice';
import { useNavigate } from 'react-router-dom';
import styles from '@pages/authentication/Authentication.module.scss';

export function PasswordEntryScreen() {
  const navigate = useNavigate();
  const [userId, setUserId] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [userConfirmRegistration] = useConfirmRegistrationMutation();

  useEffect(() => {
    const sessionDataKeys = ['userId', 'code', 'firstName', 'lastName'];

    sessionDataKeys.forEach((key) => {
      const value = sessionStorage.getItem(key);
      if (value) {
        switch (key) {
          case 'userId':
            setUserId(value);
            break;
          case 'code':
            setCode(value);
            break;
          case 'firstName':
            setFirstName(value);
            break;
          case 'lastName':
            setLastName(value);
            break;
          default:
            break;
        }
      }
    });
  }, [firstName, lastName]);

  const handleFormRegistration: PasswordFormProps['submit'] = async (passwordFormValue, form) => {
    try {
      await userConfirmRegistration({
        userId: userId,
        code: code,
        firstName: firstName,
        lastName: lastName,
        password: passwordFormValue.password,
      });
      navigate('/registration/success');
    } catch (e: any) {
      form.setErrors(e);
    }
  };

  return (
    <div className={`form-data mt-5 mb-5 ${styles.formAuth}`}>
      <div className="fw-bold fs-2 color-black mb-4">Придумайте пароль</div>
      <div className="display-flex flex-direction-column">
        <PasswordForm
          value={{ password: '', passwordRepeat: '' }}
          submit={handleFormRegistration}
        />
      </div>
    </div>
  );
}
