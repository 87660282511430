import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import { Card } from 'react-bootstrap';
import { TeamForm } from '@pages/teams/createTeam/TeamForm';
import { useNavigate } from 'react-router-dom';
import { CreateTeamFormProps } from '@pages/teams/createTeam/TeamForm';
import { useAppSelector } from '@hooks/index';
import { selectCurrentUserId } from '@store/user/user.slice';
import { useCreateTeamMutation } from '@store/team/team.slice';

const CreateTeam = () => {
  const navigate = useNavigate();
  const myId = useAppSelector(selectCurrentUserId);

  const [createTeamRequest] = useCreateTeamMutation();

  const createTeam: CreateTeamFormProps['submit'] = async (createTeamForm, form) => {
    try {
      await createTeamRequest({
        name: createTeamForm.name,
        teamTypeId: createTeamForm.teamTypeId,
        telegramUrl: createTeamForm.telegramUrl,
        positions: createTeamForm.participants,
      });
      navigate('/teams');
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    }
  };

  return (
    <div className="col container-content mb-5">
      <div className="ms-4 pt-4">
        <NavigationBack />
      </div>
      <Card className={'form-container'}>
        <div className={'row'}>
          <div className={'d-flex'}>
            <h2 className={'form-title'}>Создание команды</h2>
            <TeamForm
              value={{
                id: null,
                name: '',
                teamLeadId: myId || '',
                teamTypeId: '',
                telegramUrl: '',
                participants: [],
              }}
              submit={createTeam}
              isParticipants={true}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
export { CreateTeam };
