import React, { useEffect, useState } from 'react';
import { ChatLink } from './ChatLink';
import { useChatsQuery } from '@store/messages/messages.service';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Chat } from '@store/messages/contracts';
import { Skeleton } from '@mui/material';
import styles from './ChatList.module.scss';

export const ChatList: React.FC = () => {
  const [start, setStart] = useState(0);
  const [chats, setChats] = useState<Chat[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const { data } = useChatsQuery({
    limit: 10,
    start: start,
    sortOrder: `desc`,
  });

  useEffect(() => {
    if (data?.data) setChats([...chats, ...data.data]);
    if (data?.totalCount) setTotalCount(data.totalCount);
  }, [data]);

  if (chats?.length === 0)
    return (
      <div className={styles.empty__chat__list}>
        <div>У вас пока нет ни одного чата</div>
      </div>
    );

  return (
    <div style={{ height: '650px', overflowY: 'scroll' }}>
      <InfiniteScroll
        dataLength={chats.length}
        next={() => setStart(start + 10)}
        hasMore={totalCount !== chats.length}
        loader={<Skeleton />}
        inverse={false}>
        {chats.map((chat) => (
          <ChatLink
            chatId={chat.id}
            unreadMessagesCount={chat.unreadMessagesCount}
            lastMessage={chat.messages[chat.messages.length - 1]}
            key={chat.id}
            chatName={chat.name}
            author={chat.authorName}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};
