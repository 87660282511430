import { useMemo } from 'react';

interface IUseChartData<T> {
  data: T[];
  chartProperty: keyof T;
  axisXProperty: keyof T;
}

export const useChartData = <T>({ data, chartProperty, axisXProperty }: IUseChartData<T>) => {
  const chartData = useMemo(() => {
    if (data && data.length > 0) return data.map((item) => item?.[chartProperty] as number);
    return [];
  }, [data, chartProperty]);

  const axisXData = useMemo(() => {
    if (data && data.length > 0) return data.map((item) => item?.[axisXProperty] as string);
    return [];
  }, [data, axisXProperty]);

  const isEmpty = axisXData.length === 0 && chartData.length === 0;

  return { chartData, axisXData, isEmpty };
};
