import styles from './CardMechanism.module.scss';
import { Card } from '@components/AboutBenefits/AboutCard/AboutCard';
import { ReactNode } from 'react';
import { CardContent as MCardContent, Typography as MTypography, styled } from '@mui/material';

export interface CardMechanismProps {
  avatar: string;
  titleCard: string;
  children: ReactNode;
}

export const CardContent = styled(MCardContent)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  @media (max-width: 768px) {
    padding: 16px 12px 24px 14px;
  }
`;

export const Typography = styled(MTypography)`
  color: #39393a;
  font-size: 24px;
  font-weight: 600;
  margin-top: 15px;
  @media (max-width: 768px) {
    font-size: 21px;
    margin-top: 8px;
  }
`;

export function CardMechanism({ avatar, titleCard, children }: CardMechanismProps) {
  return (
    <Card>
      <CardContent>
        <div className={styles.avatarCardMechanism}>
          <img src={avatar} alt="" className={styles.avatar} />
        </div>
        <Typography>{titleCard}</Typography>
        <div>{children}</div>
      </CardContent>
    </Card>
  );
}
