import { formatDate } from '@components/ui/formatDate';
import { useState } from 'react';
import { AnalyticsRequests } from '@store/dashboard/contracts';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { StatusLabel, statusColors } from '@components/ui/StatusLabel/StatusLabel';
import { useRequestBody } from '@hooks/useRequestBody';
import { Link } from 'react-router-dom';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { useGetRequestsAnalyticsQuery } from '@store/dashboard/dashboard.slice';

type Manager = {
  managerId: string;
  managerName: string;
  statusId: string;
  statusName: string;
};

export const DashboardAnalyticsRequests = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState<number>(10);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();

  const { data: requests } = useGetRequestsAnalyticsQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const formatManager = (value: Manager[]): string => {
    if (value && value.length > 0) return value[0].managerName;
    else return '';
  };

  const formatProjectType = (value: string) => {
    if (value === 'Коммерческий проект') return 'Платный';
    else return value;
  };

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <AdaptiveTableMUI
      rows={requests?.data || []}
      filter={requestBody}
      setRequestBody={(value) => setRequestBody(value)}
      setSortColumn={setSortColumn}
      setSortOrder={setSortOrder}
      sortOrder={sortOrder}
      sortColumn={sortColumn}
      columns={{
        name: {
          label: 'Заявка',
          filterInput: true,
          children: (value, row: AnalyticsRequests) => (
            <Link to={`/requests/${row.id}`}>{value}</Link>
          ),
          isSortColumn: true,
        },
        projectType: {
          label: 'Тип проекта',
          format: (value) => formatProjectType(value),
          isSortColumn: true,
        },
        created: {
          label: 'Дата создания',
          format: (value) => formatDate(value, 'DD.MM.YYYY', 'DD.MM.YYYY'),
          isSortColumn: true,
        },
        deadLine: {
          label: 'Дедлайн',
          format: (value) => formatDate(value, 'DD.MM.YYYY', 'DD.MM.YYYY'),
          isSortColumn: true,
        },
        budget: {
          label: 'Бюджет',
          isSortColumn: true,
          format: (value) => `${value} ₽`,
        },
        managers: {
          label: 'Менеджер',
          format: (value) => formatManager(value),
          children: (value: string, row: AnalyticsRequests) =>
            row?.managers?.length > 0 ? (
              <Link to={`/userPage/${row.managers[0].managerId}`}>{value}</Link>
            ) : (
              <></>
            ),
          isSortColumn: true,
        },
        statusName: {
          label: 'Статус заявки',
          children: (value) => <StatusLabel text={value} color={statusColors.get(value)} />,
          isSortColumn: true,
        },
      }}>
      <TablePaginationMUI
        limit={limit}
        count={requests?.totalCount}
        entityLabel="заявок"
        page={page}
        handleChangePage={(_, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </AdaptiveTableMUI>
  );
};
