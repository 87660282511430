import { useMemo } from 'react';
import DOMPurify from 'dompurify';

export function DynamicHtmlContainer({ input, className }: { input: string; className?: string }) {
  const html = useMemo(() => {
    return DOMPurify.sanitize(input);
  }, [input]);

  return <div className={className} dangerouslySetInnerHTML={{ __html: html }}></div>;
}
