import { ApiClient } from '@store/api-client';
import { GetRequests, PublishRequestResponse, RequestDetail } from '@store/requests/contracts';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';

export type ListMyRequestsParams = {
  managerId?: string;
  start?: number;
  limit?: number;
  sortField?: 'created';
  sortOrder?: 'asc' | 'desc';
};

export type TransferToProjectRequest = {
  id: string;
  projectPrefix: string;
  projectName?: string;
};

export const listRequests = async (
  request: Api.operations['getRequestsOnSearchForManager']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getRequestsOnSearchForManager']['responses']['200']['content']['application/json']
  >(`/api/request/open`, request);
  return plainToInstance(GetRequests, data);
};

export const getFavoriteRequests = async (
  requestBody: Api.operations['getFavoriteRequests']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getFavoriteRequests']['responses']['200']['content']['application/json']
  >('/api/request/favorite', requestBody);
  return plainToInstance(GetRequests, data);
};

export const publishRequest = async (id: string) => {
  const { data } = await ApiClient.post<PublishRequestResponse>(`/api/request/publish/${id}`);
  return data;
};

export const detailPageRequest = async (id: string) => {
  const { data } = await ApiClient.get<RequestDetail>(`/api/request/${id}`);
  return data;
};
