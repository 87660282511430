import React from 'react';
import { Can } from '@access-control';
import { More } from '@components/ui/More/More';
import { MenuItem } from '@mui/material';
import { ReactComponent as DeleteBlack } from '@assets/img/teams/DeleteBlack.svg';
import { ReactComponent as EditBlack } from '@assets/img/teams/editBlack.svg';

type TeamActionsButtonProps = {
  openTeamEditModal: () => void;
  removeTeamModal: () => void;
  openLeaveTeamModal: () => void;
};

export const TeamActionsButton: React.FC<TeamActionsButtonProps> = ({
  openTeamEditModal,
  removeTeamModal,
  openLeaveTeamModal,
}) => {
  return (
    <More>
      <Can I="update" a="Team">
        <MenuItem onClick={openTeamEditModal} divider>
          <EditBlack style={{ marginRight: '0.25rem' }} />
          Редактировать
        </MenuItem>
      </Can>
      <Can I="delete" a="Team">
        <MenuItem onClick={removeTeamModal}>
          <DeleteBlack style={{ marginRight: '0.25rem' }} />
          Удалить команду
        </MenuItem>
      </Can>
      <Can I="leave" a="Team">
        <MenuItem onClick={openLeaveTeamModal}>
          <DeleteBlack style={{ marginRight: '0.25rem' }} />
          Выйти из команды
        </MenuItem>
      </Can>
    </More>
  );
};
