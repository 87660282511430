import { SelectOption } from '@components/ui/Select/Select';
import { CloudDelegation } from '@store/cloud/contracts';
import { FormikContextType } from 'formik';
import { useEffect, useMemo } from 'react';
import { DelegationFormValue } from '../DelegationForm';

export function useGetBudgetLimitActionWithParent(
  budgetLimitAction: SelectOption[],
  form: FormikContextType<DelegationFormValue>,
  cloudDelegation?: CloudDelegation,
) {
  useEffect(() => {
    if (cloudDelegation?.budgetLimitAction?.id === '8fc0b088-e89d-4cb7-909e-f1315436c410') {
      form.setFieldValue('budgetLimitActionId', '8fc0b088-e89d-4cb7-909e-f1315436c410');
    }
    if (cloudDelegation?.budgetLimitAction?.id === '8fc0b088-e89d-4cb7-909e-f1315436c420') {
      form.setFieldValue('budgetLimitActionId', '8fc0b088-e89d-4cb7-909e-f1315436c420');
    }
    if (cloudDelegation?.budgetLimitAction?.id === '8fc0b088-e89d-4cb7-909e-f1315436c430') {
      form.setFieldValue('budgetLimitActionId', '8fc0b088-e89d-4cb7-909e-f1315436c430');
    }
    if (cloudDelegation?.budgetLimitAction?.id === '8fc0b088-e89d-4cb7-909e-f1315436c440') {
      form.setFieldValue('budgetLimitActionId', '8fc0b088-e89d-4cb7-909e-f1315436c440');
    }
  }, [cloudDelegation]);

  const budgetLimitActionWithParent = useMemo(() => {
    if (cloudDelegation?.budgetLimitAction?.id === '8fc0b088-e89d-4cb7-909e-f1315436c410') {
      return [
        { label: 'Выключить виртуальные машины', value: '8fc0b088-e89d-4cb7-909e-f1315436c410' },
      ];
    }
    if (cloudDelegation?.budgetLimitAction?.id === '8fc0b088-e89d-4cb7-909e-f1315436c420') {
      return [
        { label: 'Выключить виртуальные машины', value: '8fc0b088-e89d-4cb7-909e-f1315436c410' },
        {
          label: 'Запретить создание новых виртуальных машин',
          value: '8fc0b088-e89d-4cb7-909e-f1315436c420',
        },
      ];
    }
    if (cloudDelegation?.budgetLimitAction?.id === '8fc0b088-e89d-4cb7-909e-f1315436c430') {
      return [
        {
          label: 'Выключить текущие и запретить создание новых виртуальных машин',
          value: '8fc0b088-e89d-4cb7-909e-f1315436c430',
        },
      ];
    }
    if (cloudDelegation?.budgetLimitAction?.id === '8fc0b088-e89d-4cb7-909e-f1315436c440') {
      return [
        { label: 'Выключить виртуальные машины', value: '8fc0b088-e89d-4cb7-909e-f1315436c440' },
        {
          label: 'Запретить создание новых виртуальных машин',
          value: '8fc0b088-e89d-4cb7-909e-f1315436c420',
        },
        {
          label: 'Выключить текущие и запретить создание новых виртуальных машин',
          value: '8fc0b088-e89d-4cb7-909e-f1315436c430',
        },
        { label: 'Не выполнять действия', value: '8fc0b088-e89d-4cb7-909e-f1315436c440' },
      ];
    }
    return budgetLimitAction;
  }, [cloudDelegation]);

  return budgetLimitActionWithParent;
}
