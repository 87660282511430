import React, { useState, useRef, ReactNode, useCallback } from 'react';
import { ReactComponent as MoreIcon } from '@assets/img/moreVertical.svg';
import styles from './More.module.scss';
import { Menu, styled } from '@mui/material';

const StyledMenu = styled(Menu)({
  '& .MuiMenu-list': {
    paddingTop: 0,
    paddingBottom: 0,
    boxShadow: 'none',
  },
});

interface IMore {
  children: ReactNode;
}

export const More = ({ children }: IMore) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  return (
    <div className="d-flex align-items-center">
      <button ref={anchorRef} className={styles.button} onClick={handleClick}>
        <MoreIcon />
      </button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              boxShadow: 'none',
              border: '1px solid #DCDCDC',
            },
          },
        }}>
        {children}
      </StyledMenu>
    </div>
  );
};
