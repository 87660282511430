import React from 'react';
import { useAppDispatch } from '@hooks/index';
import { openModal } from '@store/ui/ui.slice';
import { CreateVacancy } from '@pages/vacancy/CreateVacancy';
import { CreateRequestIcon } from '@layouts/requests/Icons/CreateRequestIcon';
import { Button } from '@components/MUI/Button';

export const ButtonCreateVacancy = () => {
  const dispatch = useAppDispatch();
  const handleOpenModal = () => {
    dispatch(openModal(<CreateVacancy />));
  };
  return (
    <div>
      <Button
        variant="contained"
        startIcon={<CreateRequestIcon />}
        onClick={() => handleOpenModal()}>
        Создать вакансию
      </Button>
    </div>
  );
};
