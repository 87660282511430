import React from 'react';

export const MyAssignedRequestsIcon = () => {
  return (
    <svg
      className="icon me-2"
      width="20"
      height="20"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 9.925C12.15 9.925 11.4458 9.64583 10.8875 9.0875C10.3292 8.52917 10.05 7.825 10.05 6.975C10.05 6.125 10.3292 5.42083 10.8875 4.8625C11.4458 4.30417 12.15 4.025 13 4.025C13.85 4.025 14.5542 4.30417 15.1125 4.8625C15.6708 5.42083 15.95 6.125 15.95 6.975C15.95 7.825 15.6708 8.52917 15.1125 9.0875C14.5542 9.64583 13.85 9.925 13 9.925ZM7 16V14.35C7 14.0356 7.075 13.7362 7.225 13.4517C7.375 13.1672 7.575 12.9667 7.825 12.85C8.575 12.3167 9.39583 11.9208 10.2875 11.6625C11.1792 11.4042 12.0833 11.275 13 11.275C13.9167 11.275 14.8167 11.4167 15.7 11.7C16.5833 11.9833 17.4083 12.3667 18.175 12.85C18.4083 13.0167 18.6042 13.2292 18.7625 13.4875C18.9208 13.7458 19 14.0333 19 14.35V16H7ZM8.375 14.225V14.5H17.625V14.225C16.975 13.7917 16.225 13.4417 15.375 13.175C14.525 12.9083 13.7333 12.775 13 12.775C12.2667 12.775 11.4708 12.9083 10.6125 13.175C9.75417 13.4417 9.00833 13.7917 8.375 14.225ZM13 8.425C13.4333 8.425 13.7833 8.29167 14.05 8.025C14.3167 7.75833 14.45 7.40833 14.45 6.975C14.45 6.54167 14.3167 6.19167 14.05 5.925C13.7833 5.65833 13.4333 5.525 13 5.525C12.5667 5.525 12.2167 5.65833 11.95 5.925C11.6833 6.19167 11.55 6.54167 11.55 6.975C11.55 7.40833 11.6833 7.75833 11.95 8.025C12.2167 8.29167 12.5667 8.425 13 8.425ZM0 9.75V8.25H7.65V9.75H0ZM0 1.5V0H11.825V1.5H0ZM8.725 5.625H0V4.125H9.5C9.31667 4.34167 9.16061 4.57434 9.03183 4.82302C8.90303 5.07169 8.80075 5.33902 8.725 5.625Z"
        fill="currentColor"
      />
    </svg>
  );
};
