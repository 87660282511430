import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import React from // useState // useEffect,
'react';
import styles from './EditProject.module.scss';
import {
  // CircularProgress,
  Paper,
} from '@mui/material';
import // EditProjectForm,
// EditProjectFormProps
'./EditProjectForm';
// import { ProjectStatusesResponse } from '@store/project/contracts';
// import { useNavigate, useParams } from 'react-router-dom';
// import dayjs from 'dayjs';

// import {
//   useDetailProjectQuery,
//   useLazyGetProjectStatusesQuery,
//   useUpdateProjectMutation,
// } from '@store/project/project.slice';
// import { useSnackbar } from 'notistack';

export const EditProject: React.FC = () => {
  // const navigate = useNavigate();
  // const { projectId } = useParams();
  // const [availableStatuses, setAvailableStatuses] = useState<ProjectStatusesResponse[] | null>(
  //   null,
  // );
  // const { enqueueSnackbar } = useSnackbar();
  // const [updateProjectMutation, { isLoading }] = useUpdateProjectMutation();
  // const { data: project, isLoading: projectLoading } = useDetailProjectQuery(projectId || '');
  // const [getProjectStatuses] = useLazyGetProjectStatusesQuery();

  // useEffect(() => {
  //   if (project?.projectStatusId && projectId) {
  //     getProjectStatuses(projectId).then((availableStatuses) => {
  //       if (availableStatuses.data) {
  //         setAvailableStatuses([
  //           { statusId: project.projectStatusId, statusName: project.projectStatusName },
  //           ...availableStatuses.data,
  //         ]);
  //       }
  //     });
  //   }
  // }, [project]);

  // const submitEditProject: EditProjectFormProps['submit'] = async (form, editProjectForm) => {
  //   try {
  //     if (dayjs(form.deadLine, 'YYYY-MM-DDTHH:mm').isValid()) {
  //       form.deadLine = dayjs(form.deadLine, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY HH:mm');
  //     }
  //     // await updateProjectMutation({
  //     //   id: projectId || '',
  //     //   projectTypeId: form.projectTypeId || '',
  //     //   deadLine: form.deadLine || '',
  //     //   description: form.description || '',
  //     //   budget: form.budget || 0,
  //     //   attachments: form?.attachments?.map((at) => at.id),
  //     //   stack: form.stack || '',
  //     //   requirement: form.requirement || '',
  //     //   task: form.task || '',
  //     //   name: form.name || '',
  //     //   teamId: form.teamId,
  //     //   projectStatusId: form.projectStatusId || '',
  //     //   visibilityStatusId: form.visibilityStatusId || '',
  //     // })
  //     //   .unwrap()
  //     //   .then(() => {
  //     //     enqueueSnackbar('Проект отредактирован', { variant: 'success' });
  //     //   });
  //     navigate(`/projects/${projectId}/details`);
  //   } catch (e: any) {}
  // };

  return (
    <div className="col container-content">
      <div className={styles.wrapper}>
        <div className={styles.nav__back}>
          <NavigationBack />
        </div>
        <Paper className={styles.container} elevation={0} variant="outlined">
          <div className={styles.title}>
            <h2>Редактирование проекта временно недоступно</h2>
          </div>
          {/*{isLoading || projectLoading ? (*/}
          {/*  <CircularProgress />*/}
          {/*) : (*/}
          {/*  <EditProjectForm*/}
          {/*    value={{*/}
          {/*      projectTypeId: project?.projectTypeId || undefined,*/}
          {/*      deadLine: project?.deadLine?.format('YYYY-MM-DDTHH:MM'),*/}
          {/*      description: project?.description || undefined,*/}
          {/*      budget: project?.budget,*/}
          {/*      attachments: project?.attachments,*/}
          {/*      stack: project?.stack,*/}
          {/*      requirement: project?.requirement,*/}
          {/*      task: project?.task,*/}
          {/*      name: project?.name,*/}
          {/*      teamId: project?.teamId || undefined,*/}
          {/*      projectStatusId: project?.projectStatusId,*/}
          {/*    }}*/}
          {/*    submit={submitEditProject}*/}
          {/*    availableStatuses={availableStatuses}*/}
          {/*  />*/}
          {/*)}*/}
        </Paper>
      </div>
    </div>
  );
};
