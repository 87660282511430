import React from 'react';
import { Outlet } from 'react-router-dom';
import { TeamsHeader } from '@components/teams/Header/TeamsHeader';
import { MainContentLayout } from '@layouts/MainContentLayout';

export type TeamsLayoutOutletContext = {
  setRightSideButton: (button: React.ReactNode | undefined) => void;
};

const TeamsLayout = () => {
  const [rightSideButton, setRightSideButton] = React.useState<React.ReactNode>(undefined);

  return (
    <MainContentLayout>
      <TeamsHeader rightSideButton={rightSideButton} />
      <Outlet context={{ setRightSideButton }} />
    </MainContentLayout>
  );
};

export { TeamsLayout };
