import { CSSProperties } from 'react';
import { IconButton } from '@components/ui/Button/IconButton';
import { ApproveIcon } from '@components/ui/Icons/ApproveIcon';
import { CloseIcon } from '@components/ui/Icons/CloseIcon';
import styles from './ActionButtons.module.scss';

type InfrastructureButtonsProps = {
  handleBuild: (id: string) => void;
  handleDestroy: (id: string) => void;
  showBuild: boolean;
  showDestroy: boolean;
  objectId: string;
  className?: string;
  style?: CSSProperties;
};

export const ActionButtons: React.FC<InfrastructureButtonsProps> = ({
  handleBuild,
  handleDestroy,
  showBuild,
  showDestroy,
  objectId,
  className,
  style,
}) => {
  return (
    <>
      <span className={className} style={style}>
        {showBuild && (
          <IconButton className={styles.icon__button} onClick={() => handleBuild(objectId)}>
            <ApproveIcon />
          </IconButton>
        )}
        {showDestroy && (
          <IconButton className={styles.icon__button} onClick={() => handleDestroy(objectId)}>
            <CloseIcon />
          </IconButton>
        )}
      </span>
    </>
  );
};
