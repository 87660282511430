import { Box, Button, styled, Typography as MTypography } from '@mui/material';
import React from 'react';
import styles from './jobCard.module.scss';
import { Link } from 'react-router-dom';

export interface JobCardProps {
  name: string;
  title: string;
  status?: string;
  role: string;
  stack: string | string[];
  payment: string | number;
  titleBtn: string;
}

const Typography = styled(MTypography)`
  color: var(--count-dark);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const Title = styled(MTypography)`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

export const JobCard: React.FC<JobCardProps> = ({
  name,
  title,
  status,
  role,
  stack,
  payment,
  titleBtn,
}) => {
  return (
    <Box className={styles.box}>
      <div className={styles.gap}>
        <div>
          <Typography>{name}</Typography>
          <Title>
            {title} {status && <span className={styles.status}>{status}</span>}
          </Title>
        </div>
        <div className={styles.description}>
          Роль:
          <div className={styles.text}>{role}</div>
        </div>
        <div className={styles.description}>
          Стек:
          <div className={styles.text}>{stack}</div>
        </div>
        <div className={styles.description}>
          Ставка
          <div className={styles.text}>{payment}</div>
        </div>
      </div>
      <div className="mt-4">
        <Link to="/authorization">
          <Button variant="contained" className="text-white">
            {titleBtn}
          </Button>
        </Link>
      </div>
    </Box>
  );
};
