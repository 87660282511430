import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/system';
import { Button } from '@components/MUI/Button';
import { Link } from 'react-router-dom';
import styles from './NotificationAgreement.module.scss';
import { useCookie } from '@hooks/useCookie';

const StyledSnackbar = styled(Snackbar)({
  bottom: '0 !important',
  position: 'fixed',
  boxShadow: '0px -4px 20.1px 0px rgba(147, 147, 147, 0.25)',
});

const MAlert = styled(Alert)({
  backgroundColor: '#fff',
  color: 'black',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
  width: '100vw',
  padding: '25px 72px 22px 73px',
  display: 'flex',
  '.MuiAlert-message': {
    width: '100%',
    padding: 0,
  },
  '@media (max-width: 1024px)': {
    padding: '15px 42px 12px 43px',
  },

  '@media (max-width: 768px)': {
    padding: '16px 32px 12px 33px',
  },
});

export const NotificationAgreement = () => {
  const cookieKey = 'accept_terms';
  const [value, updateCookie] = useCookie(cookieKey, 'false');
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (value !== 'true') {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  const handleAcceptTerms = () => {
    setShow(false);
    updateCookie(true);
  };

  return (
    <StyledSnackbar
      open={show}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}>
      <MAlert elevation={6} variant="filled" icon={false}>
        <div className={styles.notificationBlock}>
          <div className={styles.mainNotification}>
            <span className={styles.contentText}>
              Продолжая пользоваться сайтом, вы соглашаетесь с условиями{' '}
              <Link className="color-green color-green_hover" to="/legalDocuments/privаcy/">
                использования файлов cookie
              </Link>
            </span>
          </div>
          <div className={styles.blockAgreeBtn}>
            <Button className={styles.agreeBtn} onClick={handleAcceptTerms} variant="contained">
              Соглашаюсь с условиями
            </Button>
          </div>
        </div>
      </MAlert>
    </StyledSnackbar>
  );
};
