import React from 'react';
import styles from './DeploymentResourceDetail.module.scss';

type AttributeBlockProps = {
  title: string;
  value: string;
};

export const AttributeBlock: React.FC<AttributeBlockProps> = ({ title, value }) => {
  return (
    <div className={styles.attribute__block}>
      <div className={styles.attribute__block__title}>{title}</div>
      <div className={styles.attribute__block__value}>{value}</div>
    </div>
  );
};
