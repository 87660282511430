import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';

//Todo: ручка - Аналитика по оплате отсутсвует

export const DashboardInfoAboutPay: React.FC = () => {
  return (
    <AdaptiveTableMUI
      rows={[]}
      columns={{
        project: {
          label: 'Проект',
          filterInput: true,
          children: (value) => <a href="/project">{value}</a>,
        },
        manager: {
          label: 'Менеджер',
          filterInput: true,
        },
        budget: {
          label: 'Бюджет',
          filterInput: true,
          isSortColumn: true,
        },
        dataOfPay: {
          label: 'Дата предоплаты',
          filterInput: true,
          isSortColumn: true,
        },
        sumOfPay: {
          label: 'Сумма предоплаты',
          filterInput: true,
          isSortColumn: true,
        },
        status: {
          label: 'Статус предоплаты',
          filterInput: true,
        },
        dataPayOfEnd: {
          label: 'Дата внесения остатка',
          filterInput: true,
        },
      }}>
      <TablePaginationMUI
        limit={10}
        count={0}
        entityLabel="проектов"
        page={1}
        handleChangePage={() => {}}
        handleChangeRowsPerPage={() => {}}
      />
    </AdaptiveTableMUI>
  );
};
