import { ReactNode } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';

interface Props {
  children: ReactNode;
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
  }
}

const themeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#199b3e',
    },
    secondary: {
      main: '#21B84B',
    },
    background: {
      default: '#fafafa',
      paper: '#ffffff',
    },
    action: {
      disabledBackground: `#8CCD9E`,
      disabled: '#8CCD9E',
    },
    error: {
      main: '#e04040',
    },
    text: {
      primary: '#39393A',
      secondary: '#78858B',
    },
  },
  breakpoints: {
    values: {
      mobile: 767,
      tablet: 991,
    },
  },
  zIndex: {
    modal: 3100,
  },
});

export const ApplicationThemeProvider = ({ children }: Props) => {
  return <ThemeProvider theme={themeOptions}>{children}</ThemeProvider>;
};
