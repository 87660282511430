import styles from './ChartLegend.module.scss';

interface IChartLegend {
  colors: string[];
  labels: string[];
}

export const ChartLegend = ({ colors, labels }: IChartLegend) => {
  return (
    <>
      <div className={styles.container}>
        {labels.map((label, i) => (
          <div className={styles.container__item} key={label}>
            <div
              className={styles.container__item_mark}
              style={{
                backgroundColor: colors[i],
              }}></div>
            <div className={styles.container__item_label}>{label}</div>
          </div>
        ))}
      </div>
    </>
  );
};
