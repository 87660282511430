import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { To } from '@remix-run/router';
import { NavigateOptions } from 'react-router/dist/lib/context';
import { ReactComponent as ArrowLeftSmall } from '@assets/img/arrowLeftSmall.svg';
import classNames from 'classnames';
import styles from './NavigationBack.module.scss';

type NavigationBackProps = {
  back?: {
    to: To;
    options?: NavigateOptions;
  };
};
export const NavigationBack = ({ back }: NavigationBackProps) => {
  const navigate = useNavigate();
  const navigateBack = useCallback(() => {
    if (back) {
      navigate(back.to, back.options);
    } else {
      navigate(-1);
    }
  }, [navigate, back]);

  return (
    <Button className={classNames('btn btn_back', styles.nav__back__button)} onClick={navigateBack}>
      <ArrowLeftSmall />
      <span className={styles.nav__back__button__title}>Назад</span>
    </Button>
  );
};
