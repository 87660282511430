import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EmailFormValue, ResettingFormStepOne } from '@pages/authentication/ResettingFormStepOne';
import styles from '@pages/authentication/Authentication.module.scss';
import { useResetPasswordRequestMutation } from '@store/user/user.slice';

export function Resetting() {
  const navigate = useNavigate();
  const [userResetPasswordRequest] = useResetPasswordRequestMutation();

  const onSubmit = async (data: EmailFormValue) => {
    await userResetPasswordRequest({
      email: data.email,
    });
    sessionStorage.setItem('email', data.email);
    navigate('/resetting-password/check-email');
  };

  return (
    <div className={`form-data mt-5 mb-5 ${styles.formAuth}`}>
      <div className="fw-bold fs-2 color-black mb-3">Восстановление пароля</div>
      <ResettingFormStepOne
        value={{
          email: '',
        }}
        submit={onSubmit}
      />
    </div>
  );
}
