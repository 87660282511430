import { ActionButtons } from '@components/ui/ActionButtons/ActionButtons';
import { ActionsButtonsModal } from '@components/ui/ActionButtonsModal/ActionsButtonsModal';
import { withAdaptive } from 'src/hocs/withAdaptive';

export const AdaptiveActionButtons = withAdaptive({
  mobile: ActionsButtonsModal,
  tablet: ActionsButtonsModal,
  desktop: ActionButtons,
  default: ActionButtons,
});
