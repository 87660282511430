import { Checkbox, FormControlLabel, Paper, Tooltip } from '@mui/material';
import styles from './ProfileRoleSelectForm.module.scss';
import { Form, FormikBag, useFormikContext, withFormik, Field } from 'formik';
import { ReactComponent as HelpIcon } from '@assets/img/help.svg';
import { Role } from '@store/user/contracts';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';

const RoleCheckbox = styled(Checkbox)`
  padding: 0;
`;

const RoleFormControlLabel = styled(FormControlLabel)`
  width: fit-content;
  margin: 0;
`;

export type ProfileRoleSelectValue = {
  roles: Role[];
};

export type ProfileRoleSelectProps = {
  value: ProfileRoleSelectValue;
  submit: (
    value: ProfileRoleSelectValue,
    form: FormikBag<ProfileRoleSelectProps, ProfileRoleSelectValue>,
  ) => void;
};

export const FormComponentProfileRoleSelect = () => {
  const profileRoleSelectForm = useFormikContext<ProfileRoleSelectValue>();
  const [initialValues, setInitialValues] = useState(profileRoleSelectForm.values.roles);

  useEffect(() => {
    if (JSON.stringify(initialValues) !== JSON.stringify(profileRoleSelectForm.values.roles)) {
      profileRoleSelectForm.handleSubmit();
    }
  }, [profileRoleSelectForm.values.roles]);

  useEffect(() => {
    setInitialValues(profileRoleSelectForm.values.roles);
  }, [profileRoleSelectForm.values.roles]);

  return (
    <Paper elevation={0} variant="outlined" className={styles.profile__role__wrapper}>
      <div className={styles.profile__role__title}>
        <h3>Выбор роли</h3>
      </div>

      <Form
        onChange={(e) => {
          profileRoleSelectForm.handleChange(e);
        }}
        className={styles.profile__role__form}>
        <div className={styles.profile__role__form_container}>
          <Field
            type="checkbox"
            name="roles"
            value="Заказчик"
            as={RoleFormControlLabel}
            control={<RoleCheckbox />}
            label={
              <p className={styles.profile__role__checkbox_description}>
                Заказчик{' '}
                <Tooltip title="Роль заказчика предоставляет возможность создавать заявки, контролировать проекты, управлять распределением задач и получать аналитику">
                  <HelpIcon />
                </Tooltip>
              </p>
            }
            checked={profileRoleSelectForm.values.roles.some((role) => role === Role.Customer)}
          />
          <Field
            type="checkbox"
            name="roles"
            value="Менеджер"
            as={RoleFormControlLabel}
            control={<RoleCheckbox />}
            label={
              <p className={styles.profile__role__checkbox_description}>
                Менеджер{' '}
                <Tooltip title="Роль менеджера позволяет управлять проектами, создавать команды, формировать задачи и контролировать выполнение проектных целей">
                  <HelpIcon />
                </Tooltip>
              </p>
            }
            checked={profileRoleSelectForm.values.roles.some((role) => role === Role.Manager)}
          />
          <Field
            type="checkbox"
            name="roles"
            value="Исполнитель"
            as={RoleFormControlLabel}
            control={<RoleCheckbox />}
            label={
              <p className={styles.profile__role__checkbox_description}>
                Исполнитель{' '}
                <Tooltip title="Роль исполнителя дает возможность принимать участие в проектах, выполнять задачи, работать в команде">
                  <HelpIcon />
                </Tooltip>
              </p>
            }
            checked={profileRoleSelectForm.values.roles.some((role) => role === Role.Executor)}
          />
          <p className={styles.profile__role__description}>
            От выбранных ролей зависит уровень заполняемости профиля и доступность функционала на
            платформе
          </p>
        </div>
      </Form>
    </Paper>
  );
};

export const ProfileRoleSelectForm = withFormik<ProfileRoleSelectProps, ProfileRoleSelectValue>({
  displayName: 'ProfileRoleSelect',
  mapPropsToValues: (props) => {
    return {
      roles: props.value.roles,
    };
  },
  handleSubmit(values, formikBag) {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponentProfileRoleSelect);
