import React from 'react';
import avatar from '@assets/img/avatar.svg';
import { SendMessageIcon } from '@components/ui/Icons/SendMessageIcon';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@hooks/index';
import { selectCurrentUserId } from '@store/user/user.slice';
import { useGetPersonalChatMutation } from '@store/messages/messages.service';
import styles from './CustomerBlock.module.scss';

type CustomerBlockProps = {
  customerName?: string;
  customerId?: string;
  managerId?: string;
};

export const CustomerBlock: React.FC<CustomerBlockProps> = ({
  customerName,
  customerId,
  managerId,
}) => {
  const navigate = useNavigate();
  const myId = useAppSelector(selectCurrentUserId);
  const [createChat] = useGetPersonalChatMutation();

  const handleMessage = () => {
    if (customerId) {
      createChat(customerId).then((response) => {
        if ('data' in response) navigate(`/messages/${response.data.id}`);
      });
    }
  };

  return (
    <div className="mb-3">
      <h6 className="color-gray fs-6 fw-normal mb-1">Заказчик</h6>
      <div className="d-flex align-items-center">
        <img src={avatar} alt="customer" width={52} height={52} />
        <p className="ms-2 p-0 m-0">{customerName}</p>
      </div>
      {myId === managerId && myId !== customerId ? (
        <div className={styles.customer__block__buttons}>
          <button className={styles.customer__block__buttons__message} onClick={handleMessage}>
            <SendMessageIcon />
            <span className={styles.customer__block__buttons__message__title}>Написать</span>
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
