import axios, { AxiosError, isAxiosError } from 'axios';
import * as qs from 'qs';
import type { AsyncThunk } from '@reduxjs/toolkit';
import { Store } from '@reduxjs/toolkit';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { ApiError } from './exceptions';
import { createApi } from '@reduxjs/toolkit/query/react';
import { SignInResponse } from '@store/user/contracts';
import { TOKEN_KEY } from '@store/user/user.slice';

export const ApiClient = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/' : process.env.REACT_APP_API_URL,
  paramsSerializer: {
    serialize(params, options) {
      return qs.stringify(params);
    },
  },
});

export function setupApiClient(store: Store) {
  ApiClient.interceptors.request.use((config) => {
    const userToken = localStorage.getItem(TOKEN_KEY);
    if (userToken) {
      const token = JSON.parse(userToken);
      config.headers.set('Authorization', `Bearer ${token.access_token}`);
    }
    return config;
  });
  ApiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        const userToken = localStorage.getItem(TOKEN_KEY);
        if (userToken) {
          const token = JSON.parse(userToken);
          localStorage.removeItem(TOKEN_KEY);
          let { data } = await ApiClient.post<SignInResponse>(`api/auth/token/refreshToken`, {
            refreshToken: token.refresh_token,
          });

          localStorage.setItem(TOKEN_KEY, JSON.stringify(data));
          originalRequest.headers.set('Authorization', `Bearer ${data.access_token}`);
          originalRequest._retry = true;
          return ApiClient(originalRequest);
        }
      } else if (error.response.status === 401) {
        localStorage.removeItem(TOKEN_KEY);
        if (originalRequest.headers.get('Authorization') !== undefined) {
          originalRequest.headers.delete('Authorization');
          originalRequest._retry = true;
          return ApiClient(originalRequest);
        }
      }
      throw new ApiError(error.response?.data?.message || error.response?.data?.code);
    },
  );
}

function toApiError(error: AxiosError) {
  return error.response?.data;
}

export function apiErrorHandler(error: unknown): never {
  if (isAxiosError(error)) {
    throw toApiError(error);
  }
  throw error;
}

export const baseQueryLocal = fetchBaseQuery({
  baseUrl: process.env.NODE_ENV === 'development' ? '/' : process.env.REACT_APP_API_URL,
  mode: 'cors',
  prepareHeaders: (headers) => {
    const userToken = localStorage.getItem(TOKEN_KEY);
    if (userToken) {
      const token = JSON.parse(userToken);
      headers.set('Authorization', `Bearer ${token.access_token}`);
    }
    return headers;
  },
});

const checkAuthStatus = (result: any) => {
  if (!('error' in result)) return false;
  if (result?.error) {
    if ('originalStatus' in result.error) return result.error?.originalStatus === 401;
    if ('status' in result.error) return result.error?.status === 401;
  }
  return false;
};

export const baseQuery: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  /*
               Выключение notification
            
               if(api.endpoint === "notifications")
                 return {
                   data: [],
                 };
            */

  let result = await baseQueryLocal(args, api, extraOptions);

  if (checkAuthStatus(result)) {
    const userToken = localStorage.getItem(TOKEN_KEY);
    if (userToken) {
      const token = JSON.parse(userToken);

      localStorage.removeItem(TOKEN_KEY);
      const { data } = await ApiClient.post<SignInResponse>(`api/auth/token/refreshToken`, {
        refreshToken: token.refresh_token,
      });

      localStorage.setItem(TOKEN_KEY, JSON.stringify(data));

      result = await baseQueryLocal(args, api, extraOptions);
      if (checkAuthStatus(result)) {
        localStorage.removeItem(TOKEN_KEY);
        if (api.dispatch !== undefined && userLogoutFnc !== undefined) {
          api.dispatch(userLogoutFnc());
          return { data: [] };
        }
        result = await baseQueryLocal(args, api, extraOptions);
      }
    }
  }

  return result;
};

let userLogoutFnc: AsyncThunk<void, void, {}>;
export const setUserLogout = (userLogout: AsyncThunk<void, void, {}>) => {
  userLogoutFnc = userLogout;
};

export const api = createApi({
  reducerPath: `api`,
  baseQuery,
  tagTypes: [
    'RequisiteList',
    'Requisite',
    'RequestDetail',
    'RequestList',
    'MyAssignedRequests',
    'ApprovedRequests',
    'RequestsCreatedByMe',
    'FavoriteRequests',
    'TeamsList',
    'TeamDetail',
    'FavoriteTeams',
    'TeamAvailablePositions',
    'EmployeesAnalytics',
    'ProjectDetail',
    'CustomerProjects',
    'ManagerProjects',
    'ManagerCustomerProjects',
    'ProjectList',
    'ExecutorProjects',
    'ProjectStatuses',
    'ProjectQueues',
    'ProjectTypes',
    'ProjectPriorities',
    'ProjectExecutors',
    'DashboardTimeAnalytics',
    'DashboardTaskStatuses',
    'DashboardProjectStatuses',
    'DashboardRequestAnalytics',
    'DashboardVacanciesAnalytics',
    'FavoriteDocs',
    'OffersList',
    'Vacancy',
    'VacancyResponses',
    'MyVacancies',
    'FavaoriteVacancies',
    'VacanciesExecutor',
    'MyOffers',
    'TasksList',
    'TaskDetail',
    'TaskTransitions',
    'FavoriteProfiles',
    'Profiles',
    'Profile',
    'MyProfile',
    'SearchProfiles',
    'OauthProviders',
    'OauthToken',
  ],
  endpoints: () => ({}),
});
