import React, { useEffect, useMemo, useState } from 'react';
import { Author, Vacancy } from '@store/vacancy/contracts';
import { useRequestBody } from '@hooks/useRequestBody';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { Link, useOutletContext } from 'react-router-dom';
import { statusColors, StatusLabel } from '@components/ui/StatusLabel/StatusLabel';
import { CreateVacancyOffer } from '@components/teams/CreateVacancyOffer/CreateVacancyOffer';
import styles from './Teams.module.scss';
import { Star } from '@components/Star/Star';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { Can } from '@access-control';
import { ButtonCreateVacancy } from '@components/teams/Header/ButtonCreateVacancy';
import { VacanciesLayoutOutletContext } from '@layouts/vacancies/VacanciesLayout';
import { useGetVacanciesQuery, useSetFavoriteVacancyMutation } from '@store/vacancy/vacancy.slice';

type TableVacancy = {
  id: string;
  name: string;
  positionName: string;
  stack: string;
  payment: number;
  comment: string;
  statusName: {};
  star: {};
  teamId: string;
  author: Author;
};

export const TeamVacancies: React.FC = () => {
  const outletContext = useOutletContext<VacanciesLayoutOutletContext>();
  useEffect(() => {
    outletContext.setRightSideButton(
      <Can I="create" a="Vacancies">
        <ButtonCreateVacancy />
      </Can>,
    );
  }, []);

  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();
  const [setFavoriteVacancy] = useSetFavoriteVacancyMutation();

  const { data: vacancies } = useGetVacanciesQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const tableVacancies = useMemo(() => {
    if (vacancies?.data) {
      const tableVacancies: TableVacancy[] = vacancies?.data?.map((vacancy) => ({
        id: vacancy.id,
        name: vacancy.teamName,
        comment: vacancy.comment,
        payment: vacancy.payment,
        positionName: vacancy.positionName,
        directionName: vacancy.directionName,
        stack: vacancy.stack,
        star: {},
        statusName: vacancy.statusName,
        teamId: vacancy.teamId,
        author: vacancy.author,
      }));
      return tableVacancies;
    }
    return [];
  }, [vacancies]);

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <div className={styles.table__wrap}>
      <AdaptiveTableMUI
        sortOrder={sortOrder}
        sortColumn={sortColumn}
        setSortColumn={setSortColumn}
        setSortOrder={setSortOrder}
        setRequestBody={setRequestBody}
        columns={{
          name: {
            label: 'Название команды',
            filterInput: true,
            isSortColumn: true,
            children: (value, row: Vacancy) => <Link to={`/teams/${row.teamId}`}>{value}</Link>,
          },
          positionName: {
            label: 'Роль',
            filterInput: true,
            isSortColumn: true,
          },
          directionName: {
            label: 'Направление',
            filterInput: true,
            isSortColumn: false,
          },
          stack: {
            label: 'Стэк',
            filterInput: true,
            isSortColumn: true,
          },
          payment: {
            label: 'Ставка',
            filterInput: true,
            isSortColumn: true,
            format: (value) => `${value} р/ч`,
          },
          comment: {
            label: 'Комментарий',
            filterInput: true,
            isSortColumn: true,
          },
          statusName: {
            children: (value: string, row: Vacancy) =>
              value ? (
                value === 'Оффер от исполнителя' || value === 'Оффер от менеджера' ? (
                  <StatusLabel
                    color={statusColors.get('На рассмотрении')}
                    text={'На рассмотрении'}
                  />
                ) : (
                  <StatusLabel color={statusColors.get(value)} text={value} />
                )
              ) : (
                <CreateVacancyOffer
                  vacancyId={row.id}
                  receiverId={row.author.userId}
                  fromManager={false}
                />
              ),
          },
          star: {
            children: (_, row: Vacancy) => (
              <Star
                checked={row.favorite}
                onChange={(value) =>
                  setFavoriteVacancy({ requestBody: { status: value }, vacancyId: row.id })
                }
                className={styles.star}
              />
            ),
          },
        }}
        rows={tableVacancies}
        emptyLabel={'Вакансии отсутствуют'}>
        <TablePaginationMUI
          limit={limit}
          count={vacancies?.totalCount}
          entityLabel="вакансий"
          page={page}
          handleChangePage={(_, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </AdaptiveTableMUI>
    </div>
  );
};
