import React from 'react';
import { NavLink } from 'react-router-dom';
import { AnalyticsRequestIcon } from './Icons/AnalyticsRequestIcon';
import { AnalyticsPropjectsIcon } from './Icons/AnalyticsPropjectsIcon';
import styles from '@components/teams/Header/TeamsHeader.module.scss';
import { InfoAboutVacancyIcon } from './Icons/InfoAboutVacancyIcon';
import { Can } from '@access-control';
import { useAppSelector } from '@hooks/index';
import { selectProfile } from '@store/user/user.slice';
import { InfoAboutPayIcon } from '@pages/dashboard/Icons/InfoAboutPayIcon';
import dashboardStyles from './DashboardNavagationBar.module.scss';
import classNames from 'classnames';

export function DashboardNavigationBar() {
  const myRoles = useAppSelector(selectProfile)?.roles;
  const HasManagerOrCustomer = myRoles?.some((role) =>
    ['Заказчик', 'Менеджер'].includes(role.name),
  );
  const HasExecutor = myRoles?.some((role) => ['Исполнитель'].includes(role.name));

  return (
    <header className={classNames(styles.header, dashboardStyles.dashboaed__header)}>
      <ul>
        <Can I="read" an="AnalyticsRequest">
          <li>
            <NavLink
              to="/dashboard/requests"
              className={({ isActive }) => (isActive ? 'active' : '')}
              style={{
                textDecoration: 'none',
                display: HasManagerOrCustomer ? 'd-block' : 'none',
              }}>
              <AnalyticsRequestIcon />
              Аналитика по заявкам
            </NavLink>
          </li>
        </Can>

        <Can I="read" an="AnalyticsProjects">
          <li>
            <NavLink
              to="/dashboard/projects"
              className={({ isActive }) => (isActive ? 'active' : '')}
              style={{ display: HasManagerOrCustomer ? 'd-block' : 'none' }}>
              <AnalyticsPropjectsIcon />
              Аналитика по проектам
            </NavLink>
          </li>
        </Can>

        <Can I="read" an="InfoAboutPay">
          <li>
            <NavLink
              to="/dashboard/infoPay"
              className={({ isActive }) => (isActive ? 'active' : '')}
              style={{ display: HasManagerOrCustomer ? 'd-block' : 'none' }}>
              <InfoAboutPayIcon />
              Аналитика по оплате
            </NavLink>
          </li>
        </Can>

        <Can I="read" an="InfoAboutVacancy">
          <li>
            <NavLink
              to="/dashboard/infoVacancy"
              className={({ isActive }) => (isActive ? 'active' : '')}
              style={{ display: HasExecutor ? 'd-block' : 'none' }}>
              <InfoAboutVacancyIcon />
              Информация по вакансиям
            </NavLink>
          </li>
        </Can>
      </ul>
    </header>
  );
}
