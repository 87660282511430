import React from 'react';
import { CommonAnalyticsBlockProps } from '../../CommonAnalyticsBlock';
import { TaskStatusType } from '@store/tasks/contracts';
import { useProjectCommonAnalytics } from '@hooks/useProjectCommonAnalytics';
import styles from './StatusTypesList.module.scss';

export const localisedStatusesColors = new Map<string, string>([
  ['Начальный', '#3485FF'],
  ['На паузе', '#F4CB3A'],
  ['В работе', '#E04040'],
  ['Завершен', '#10C943'],
  ['Отменен', '#78858B'],
]);

export const StatusTypesList: React.FC<CommonAnalyticsBlockProps> = ({ commonAnalytics }) => {
  const { localisedStatuses } = useProjectCommonAnalytics(commonAnalytics);

  return (
    <div className={styles.tags__list}>
      {Object.entries(localisedStatuses).map(([key, value]) => (
        <div className={styles.tags__list__tag}>
          <div
            className={styles.tags__list__tag__color}
            style={{ backgroundColor: localisedStatusesColors.get(key as TaskStatusType) }}></div>
          <span className={styles.tags__list__tag__title}>{key}</span>
          <span className={styles.tags__list__tag__count}>{value}</span>
        </div>
      ))}
    </div>
  );
};
