import React, { ReactNode } from 'react';
import {
  FormControl,
  Paper,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import styles from './ParticipantsForm.module.scss';
import { FieldArray, useFormikContext } from 'formik';
import { useDictionary } from '@hooks/useDictionary';
import { DictionaryName } from '@store/dictionary/contracts';
import { fromDictionaryToSelectOption } from '@components/ui/Select/Select';
import { EMenu } from './EMenu';
import { Participant, TeamCreationRequest } from '@store/team/contracts';
import { IconRemove } from './IconRemove';
import { Select } from '@components/MUI/Select';
import { ReactComponent as IconAddMember } from '@assets/img/addMember.svg';

interface ParticipantsFormProps {
  renderExtraCell?: (participant: Participant) => ReactNode;
}

export const ParticipantsForm: React.FC<ParticipantsFormProps> = ({ renderExtraCell }) => {
  const roles = useDictionary(DictionaryName.POSITION, fromDictionaryToSelectOption);
  const form = useFormikContext<TeamCreationRequest>();
  const { setFieldValue } = useFormikContext();
  const meta = form.getFieldMeta<Participant>('participants');
  const isEditable = form.values.id !== null;

  const handleEdit = () => {};

  const handleCancel = () => {};

  const handleDelete = () => {};

  return (
    <FormControl>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: 0, border: 1, borderRadius: 1, borderColor: '#DCDCDC' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className={styles.tableBody}>
          <TableHead className={styles.tableHead}>
            <TableRow>
              <TableCell
                className={styles.tableHeadCell}
                sx={{ color: '#78858B', fontWeight: 400 }}>
                Роль
              </TableCell>
              <TableCell className={styles.tableHeadCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={styles.tableBody}>
            <FieldArray
              name="participants"
              render={(arrayHelpers) => (
                <>
                  {form.values.participants?.map((participant, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          padding: '0px 0px 0px 4px',
                        }}>
                        <Select
                          name={`participants[${index}].positionId`}
                          values={roles}
                          onChange={(event: SelectChangeEvent) => {
                            const newParticipant = {
                              ...form.values.participants[index],
                              positionId: event.target.value,
                            };
                            const newParticipants = form.values.participants;
                            newParticipants[index] = newParticipant;
                            setFieldValue(`participants[${index}]`, newParticipant);
                          }}
                          label="Роль"
                          value={form.values.participants[index]?.positionId || ''}
                          isOutlined={false}
                        />
                      </TableCell>
                      {renderExtraCell ? renderExtraCell(participant) : <></>}
                      <TableCell
                        sx={{
                          '&.MuiTableCell-root': {
                            paddingRight: '14px',
                          },
                        }}
                        style={{ display: 'flex', justifyContent: 'end' }}>
                        {!isEditable ? (
                          <button
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                            className={styles.buttonRemove}>
                            <IconRemove />
                          </button>
                        ) : (
                          <></>
                        )}
                        {isEditable ? (
                          <EMenu
                            handleCancel={handleCancel}
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                          />
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {!isEditable ? (
                    <TableRow>
                      <TableCell>
                        <button
                          type="button"
                          className={styles.addMemberButton}
                          onClick={() => {
                            arrayHelpers.push({
                              userId: meta.value.userId,
                              positionId: meta.value.positionId,
                            });
                          }}>
                          <IconAddMember style={{ marginRight: '0.625rem' }} />
                          Добавить роль
                        </button>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </>
              )}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};
