import React from 'react';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { Select } from '@components/MUI/Select';
import { useDictionary } from '@hooks/useDictionary';
import { DictionaryName } from '@store/dictionary/contracts';
import { fromDictionaryToSelectOption } from '@components/ui/Select/Select';
import { SelectChangeEvent, Tooltip } from '@mui/material';
import styles from './EditProject.module.scss';
import { FormField } from '@components/Form/FormField';
import { InputField } from '@components/MUI/InputField/InputField';
import { DragAndDrop } from '@components/ui/DranAndDrop/DragAndDrop';
import { Button } from '@components/MUI/Button';
import { Attachments, ProjectStatusesResponse } from '@store/project/contracts';
import { ReactComponent as HelpIcon } from '@assets/img/help.svg';

export type EditProjectFormProps = {
  value: EditProjectValues;
  submit: (
    value: EditProjectValues,
    form: FormikBag<EditProjectFormProps, EditProjectValues>,
  ) => void;
  availableStatuses: ProjectStatusesResponse[] | null;
};

export type EditProjectValues = {
  projectTypeId?: string;
  deadLine?: string;
  description?: string;
  budget?: number;
  currencyId?: string;
  url?: string;
  attachments?: Attachments[];
  stack?: string;
  requirement?: string;
  task?: string;
  name?: string;
  teamId?: string;
  projectStatusId?: string;
  visibilityStatusId?: string;
};

const Schema = Yup.object({
  projectTypeId: Yup.string().required('Поле обязательно для заполнения'),
  deadLine: Yup.string().required('Поле обязательно для заполнения'),
  description: Yup.string().required('Поле обязательно для заполнения'),
  budget: Yup.string().required('Поле обязательно для заполнения'),
  stack: Yup.string().required('Поле обязательно для заполнения'),
  requirement: Yup.string().required('Поле обязательно для заполнения'),
  task: Yup.string().required('Поле обязательно для заполнения'),
  visibilityStatusId: Yup.string().optional(),
});

const FormComponent: React.FC<EditProjectFormProps> = ({ availableStatuses }) => {
  const form = useFormikContext<EditProjectValues>();
  const projectTypes = useDictionary(DictionaryName.PROJECT_TYPE, fromDictionaryToSelectOption);
  const currencies = useDictionary(DictionaryName.CURRENCY, fromDictionaryToSelectOption);
  const fallbackProjectStatuses = useDictionary(
    DictionaryName.PROJECT_STATUS,
    fromDictionaryToSelectOption,
  );
  const projectStatuses = availableStatuses?.map((status) => ({
    value: status.statusId,
    label: status.statusName,
  }));

  return (
    <Form className={styles.edit__form} onSubmit={form.handleSubmit}>
      <Select
        name={'projectTypeId'}
        values={projectTypes}
        label={'Тип проекта*'}
        onChange={(event: SelectChangeEvent) => {
          form.setFieldValue('projectTypeId', event.target.value);
        }}
        isOutlined={true}
        value={form.values.projectTypeId}
        className={styles.form__item}
      />
      {/*TODO: как будет готов бэк добавить доступность, пока просто попросили добавить селект*/}
      <div className={styles.visibility}>
        <Select
          name="visibilityStatusId"
          onChange={(event: SelectChangeEvent) => {
            form.setFieldValue('visibilityStatusId', event.target.value);
          }}
          values={[{ label: 'Приватный', value: '' }]}
          label="доступность (в разработке...)"
          isOutlined
          disabled
          className={styles.form__item}
        />
        <Tooltip
          title="Публичный проект доступен всем пользователем системы.
           Приватный проект только тем пользователям, которые были подключены к проекту  ">
          <HelpIcon />
        </Tooltip>
      </div>
      <FormField
        name="deadLine"
        type="datetime-local"
        placeholder="Дедлайн проекта*"
        className="mb-4 input-classical"
        floating
        floatingLabelClassName="mb-3 req-label-custom"
      />
      <InputField
        name="description"
        type="textarea"
        className={styles.form__item}
        multiline
        rows={4}
        value={form.values.description}
        label={'Описание проекта'}
      />
      <InputField
        name="budget"
        type="number"
        placeholder="Бюджет проекта*"
        className={styles.form__item}
      />
      <Select
        name={'currencyId'}
        values={currencies}
        label={'Валюта*'}
        onChange={(event: SelectChangeEvent) => {
          form.setFieldValue('currencyId', event.target.value);
        }}
        isOutlined={true}
        value={form.values.currencyId}
        className={styles.form__item}
      />
      <InputField name="url" type="text" placeholder="Доступы" className={styles.form__item} />
      <Select
        name={'projectStatusId'}
        values={projectStatuses ?? fallbackProjectStatuses}
        disabled={!projectStatuses}
        label={'Статус проекта*'}
        onChange={(event: SelectChangeEvent) => {
          form.setFieldValue('projectStatusId', event.target.value);
        }}
        isOutlined={true}
        value={form.values.projectStatusId}
        className={styles.form__item}
      />
      <DragAndDrop
        name="attachments"
        tag="PROJECT"
        accept={{ 'application/*': ['.doc', '.docx', '.pdf'] }}
      />
      <InputField
        name="stack"
        type="text"
        placeholder="Стек технологий*"
        className={styles.form__item}
        style={{ marginTop: '1.5rem' }}
      />
      <InputField
        name="requirement"
        type="textarea"
        placeholder="Требования к разработке*"
        className={styles.form__item}
        multiline
        rows={4}
      />
      <InputField
        name="task"
        type="textarea"
        placeholder="Что необходимо сделать*"
        style={{ marginBottom: '3rem', width: '100%' }}
        multiline
        rows={4}
      />
      <Button variant="contained" className={styles.sumbit__button} size="large" type="submit">
        Сохранить
      </Button>
      <p className={styles.bottom__text}>* обозначены поля обязательные для заполнения</p>
    </Form>
  );
};

export const EditProjectForm = withFormik<EditProjectFormProps, EditProjectValues>({
  displayName: 'EditProjectForm',
  mapPropsToValues: (props) => {
    return {
      projectTypeId: props.value.projectTypeId,
      deadLine: props.value.deadLine,
      description: props.value.description,
      budget: props.value.budget,
      attachments: props.value.attachments,
      stack: props.value.stack,
      requirement: props.value.requirement,
      task: props.value.task,
      name: props.value.name,
      teamId: props.value.teamId,
      projectStatusId: props.value.projectStatusId,
    };
  },
  enableReinitialize: true,
  validationSchema: Schema,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
