import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { ParticipantsForm } from './ParticipantsForm';
import { InputField } from '@components/MUI/InputField/InputField';
import { TeamCreationRequest } from '@store/team/contracts';
import { Select } from '@components/MUI/Select';
import { useDictionary } from '@hooks/useDictionary';
import { DictionaryName } from '@store/dictionary/contracts';
import { fromDictionaryToSelectOption } from '@components/ui/Select/Select';
import { SelectChangeEvent } from '@mui/material';
import styles from './TeamForm.module.scss';
import { Button } from '@components/MUI/Button';

export type CreateTeamFormProps = {
  value: TeamCreationRequest;
  submit: (
    value: TeamCreationRequest,
    form: FormikBag<CreateTeamFormProps, TeamCreationRequest>,
  ) => void;
  isParticipants: boolean;
  cancel?: () => void;
};

const Schema = Yup.object({
  name: Yup.string().required('Поле обязательно для заполнения'),
  teamLeadId: Yup.string(),
  teamTypeId: Yup.string().required('Поле обязательно для заполнения'),
  telegramUrl: Yup.string().matches(/^https:\/\/t.me\//, 'Поле должно начинаться с https://t.me/'),
  participants: Yup.array().of(
    Yup.object().shape({
      positionId: Yup.string().required('Поле обязательно для заполнения'),
    }),
  ),
});
const FormComponent = () => {
  const form = useFormikContext<TeamCreationRequest>();
  form.validationSchema = Schema;
  const types = useDictionary(DictionaryName.TEAM_TYPE, fromDictionaryToSelectOption);
  const { setFieldValue } = useFormikContext();
  const isEditable = form.values.id !== null;
  const isParticipants = form.getFieldProps('isParticipants').value;
  const cancel = form.getFieldProps('cancel').value;

  return (
    <Form onSubmit={form.handleSubmit}>
      <div className="display-flex flex-direction-column mt-5">
        <div className={styles.formContainer}>
          <div className={styles.form__item}>
            <InputField
              name="name"
              placeholder="Название команды"
              type="text"
              className={styles.form__name}
            />
          </div>
          <div className={styles.form__item}>
            <Select
              name="teamTypeId"
              values={types}
              onChange={(event: SelectChangeEvent) => {
                setFieldValue('teamTypeId', event.target.value);
              }}
              label="Тип команды"
              isOutlined={true}
              value={form.values.teamTypeId}
            />
          </div>
          <div className={styles.form__item}>
            <InputField
              name="telegramUrl"
              placeholder="Telegram группа"
              type="text"
              className={styles.form__telegram}
            />
          </div>
        </div>
        {isParticipants ? (
          <div className="">
            <h3 className="form-lable">Участники</h3>
            <ParticipantsForm />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div>
        {!isEditable ? (
          <div className={styles.form__footer}>
            <div className={styles.button__group}>
              <Button className={styles.button__cancel} onClick={cancel} size="large">
                Отменить
              </Button>
              <Button
                variant="contained"
                className={styles.button__save}
                disabled={!form.isValid}
                onClick={() => {}}
                type="submit"
                size="large">
                Сохранить
              </Button>
            </div>
          </div>
        ) : (
          <button
            type="submit"
            className="btn btn-cyan btn-active mt-5 w-214"
            disabled={!form.isValid}>
            Создать команду
          </button>
        )}
      </div>
    </Form>
  );
};

export const TeamForm = withFormik<CreateTeamFormProps, TeamCreationRequest>({
  displayName: 'TeamForm',
  mapPropsToValues: (props) => {
    return {
      id: null,
      name: props.value.name,
      teamLeadId: props.value.teamLeadId,
      teamTypeId: props.value.teamTypeId,
      telegramUrl: props.value.telegramUrl,
      participants: props.value.participants,
      isParticipants: props.isParticipants,
      cancel: props.cancel,
    };
  },
  enableReinitialize: true,
  validationSchema: Schema,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
