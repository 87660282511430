import React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { DashboardProjects } from '@store/dashboard/contracts';
import { projectStatusColors } from './AmountOfProjects';
import { PieCenterLabel } from '../../../../components/PieCenterLabel/PieCenterLabel';
import styles from './ProjectsPie.module.scss';

type ProjectPieProps = {
  projects: DashboardProjects;
};

export const ProjectsPie: React.FC<ProjectPieProps> = ({ projects }) => {
  return (
    <PieChart
      series={[
        {
          data: projects.statusCountList
            ? projects.statusCountList.map((project) => ({
                id: project?.statusType,
                value: project?.taskCount || 0,
                color: projectStatusColors.get(project?.statusType || 'default'),
                label: project?.statusType,
              }))
            : [{ value: 1 }],
          innerRadius: 70,
        },
      ]}
      width={400}
      height={200}
      className={styles.projects__pie}
      slotProps={{ legend: { hidden: true } }}>
      <PieCenterLabel
        label="Проектов"
        count={projects?.projectsCount ? projects?.projectsCount : 0}
      />
    </PieChart>
  );
};
