import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './FavoriteNavigationBar.module.scss';
import { useAppSelector } from '@hooks/index';
import {
  selectDocumentsCount,
  selectRequestsCount,
  selectTasksCount,
  selectTeamsCount,
  selectUsersCount,
  selectVacanciesCount,
} from '@store/favorite/favorite.slice';

type FavoriteNavigationBarProps = {
  messagesCount: number | string;
};

export const FavoriteNavigationBar: React.FC<FavoriteNavigationBarProps> = ({ messagesCount }) => {
  const usersCount = useAppSelector(selectUsersCount);
  const teamsCount = useAppSelector(selectTeamsCount);
  const tasksCount = useAppSelector(selectTasksCount);
  const documentsCount = useAppSelector(selectDocumentsCount);
  const requestsCount = useAppSelector(selectRequestsCount);
  const vacanciesCount = useAppSelector(selectVacanciesCount);

  return (
    <header className={styles.header}>
      <ul>
        <li>
          <NavLink to="/favorite" className={({ isActive }) => (isActive ? 'active' : '')} end>
            {`Пользователи ${typeof usersCount === 'number' ? `(${usersCount})` : ''} `}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/favorite/teams"
            className={({ isActive }) => (isActive ? 'active' : '')}
            end>
            {`Команды ${typeof teamsCount === 'number' ? `(${teamsCount})` : ''} `}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/favorite/tasks"
            className={({ isActive }) => (isActive ? 'active' : '')}
            end>
            {`Задачи ${typeof tasksCount === 'number' ? `(${tasksCount})` : ''} `}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/favorite/documents"
            className={({ isActive }) => (isActive ? 'active' : '')}
            end>
            {`Документы ${typeof documentsCount === 'number' ? `(${documentsCount})` : ''} `}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/favorite/requests"
            className={({ isActive }) => (isActive ? 'active' : '')}
            end>
            {`Заявки ${typeof requestsCount === 'number' ? `(${requestsCount})` : ''} `}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/favorite/vacancies"
            className={({ isActive }) => (isActive ? 'active' : '')}
            end>
            {`Вакансии ${typeof vacanciesCount === 'number' ? `(${vacanciesCount})` : ''} `}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/favorite/messages"
            className={({ isActive }) => (isActive ? 'active' : '')}
            end>
            {`Сообщения ${typeof messagesCount === 'number' ? `(${messagesCount})` : ''} `}
          </NavLink>
        </li>
      </ul>
    </header>
  );
};
