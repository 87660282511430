import React from 'react';
import styles from './Card.module.scss';

type CardProps = {
  Icon: React.FC;
  text: string;
  count: number;
};

export const Card: React.FC<CardProps> = ({ Icon, text, count }) => {
  return (
    <div className={styles.card}>
      <div className={styles.card__icon}>
        <Icon />
      </div>
      <div className={styles.card__text}>{text}</div>
      <div className={styles.card__count}>{count}</div>
    </div>
  );
};
