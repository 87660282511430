import { useAppSelector } from '@hooks/index';
import { isAuthenticated } from '@store/user/user.slice';
import { ComponentType } from 'react';
import { Navigate } from 'react-router-dom';

interface IWithAuthGuardOptions {
  redirectTo?: string;
  inverse?: boolean;
}

export const withAuthGuard = <T extends object>(
  Component: ComponentType<T>,
  options?: IWithAuthGuardOptions,
) => {
  return (props: T) => {
    const isAuth = useAppSelector(isAuthenticated);
    if (options?.inverse ? isAuth : !isAuth) {
      return <Navigate to={options?.redirectTo ?? '/'} />;
    }
    return <Component {...props} />;
  };
};
