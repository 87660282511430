import { FormikBag, useFormikContext, withFormik } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { Modal, ModalProps } from '@components/MUI/Modal/Modal';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import { CreateTeamFormProps, TeamForm } from '../createTeam/TeamForm';
import styles from './TeamEditModal.module.scss';
import { Participant, TeamUpdatingRequest } from '@store/team/contracts';
import { selectCurrentUserId } from '@store/user/user.slice';
import { useDictionary } from '@hooks/useDictionary';
import { DictionaryName } from '@store/dictionary/contracts';
import { fromDictionaryToSelectOption } from '@components/ui/Select/Select';
import { FormWrapper } from '@components/Form/Form';
import { useSaveTeamMutation } from '@store/team/team.slice';

export type TeamEditFormProps = {
  value: TeamEdit;
  submit: (value: TeamEdit, form: FormikBag<TeamEditFormProps, TeamEdit>) => void;
  modalProps?: ModalProps;
  cancel?: () => void;
};

export type TeamEdit = {
  id: string | undefined;
  name?: string;
  teamLeadId?: string | null;
  teamTypeId?: string;
  telegramUrl?: string | null;
  positions?: Array<Participant> | null;
};

const TeamEditFormSchema = Yup.object({
  name: Yup.string().required('Поле обязательно для заполнения'),
  type: Yup.string().required('Поле обязательно для заполнения'),
  telegram: Yup.string().required('Поле обязательно для заполнения'),
});

const FormComponent: FC<TeamEditFormProps> = (props: TeamEditFormProps) => {
  const TeamEditForm = useFormikContext<TeamEdit>();
  const dispatch = useAppDispatch();
  const { value, modalProps } = props;
  const myId = useAppSelector(selectCurrentUserId);
  const teamTypes = useDictionary(DictionaryName.TEAM_TYPE, fromDictionaryToSelectOption);

  const [saveTeamMutation] = useSaveTeamMutation();

  TeamEditForm.validationSchema = TeamEditFormSchema;

  const cancel = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const modalTitle = useMemo(() => {
    return value.id ? `Редактирование команды` : `Создание команды`;
  }, [value.id]);

  const updateTeam: CreateTeamFormProps['submit'] = async (
    updateTeamForm: TeamUpdatingRequest,
    form,
  ) => {
    try {
      await saveTeamMutation({
        name: updateTeamForm.name || '',
        teamTypeId: updateTeamForm.teamTypeId || '',
        telegramUrl: updateTeamForm.telegramUrl,
        id: value.id || '',
        positions: value.positions || [],
      });
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    } finally {
      dispatch(closeModal());
    }
  };

  return (
    <Modal {...modalProps} className={styles.modal}>
      <FormWrapper label={modalTitle}>
        <TeamForm
          isParticipants={false}
          value={{
            id: TeamEditForm.values.id || '',
            name: TeamEditForm.values.name || '',
            teamLeadId: myId || '',
            teamTypeId:
              teamTypes.find((type) => type.label === TeamEditForm.values.teamTypeId)?.value || '',
            telegramUrl: TeamEditForm.values.telegramUrl || '',
            participants: [],
          }}
          submit={updateTeam}
          cancel={cancel}
        />
      </FormWrapper>
    </Modal>
  );
};

export const TeamEditModal = withFormik<TeamEditFormProps, TeamEdit>({
  displayName: 'TeamEditModal',
  mapPropsToValues: (props) => {
    return {
      name: props.value.name,
      id: props.value.id,
      positions: props.value.positions,
      teamLeadId: props.value.teamLeadId,
      teamTypeId: props.value.teamTypeId,
      telegramUrl: props.value.telegramUrl,
    };
  },
  enableReinitialize: true,
  validationSchema: TeamEditFormSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
