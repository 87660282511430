import styles from '@pages/rates/RatesForSpecialist.module.scss';
import { Typography } from '@components/AboutInfo/AboutInfo';
import customer from '@assets/img/customer/customer.png';
import { Box } from '@mui/material';
import { CardOptionsRates } from '@pages/rates/additionalOptionsCard/CardOptionsRates';
import React from 'react';
import { RatesPageForCustomer } from '@pages/rates/ratesCard/RatesPageForCustomer';
import { useScrollToTop } from '@hooks/useScrollToTop';

export const RatesForCustomer = () => {
  const topRef = useScrollToTop();
  return (
    <>
      <div ref={topRef} className={styles.mainInfoBackground}>
        <div className={styles.container}>
          <div className={styles.mainInfoBlock}>
            <div>
              <p className={styles.title}>
                Тарифы для <span>заказчика</span>
              </p>
              <p className={styles.subTitle}>
                Подписка на «Эджайл» — это возможность для Пользователя за абонентскую плату в
                течение определенного промежутка времени получать удаленный доступ к платформе
              </p>
              <div className={styles.description}>
                <Typography>Для новых пользователей подписка на 3 месяца бесплатная</Typography>
                <Typography>
                  Для расширения возможностей выберете подходящий для вас тариф
                </Typography>
              </div>
            </div>
            <div>
              <img src={customer} className={styles.specialistsPic} alt="Заказчик" />
            </div>
          </div>
        </div>
      </div>
      <Box>
        <div className={styles.addGoodsWrapper}>
          <div className={styles.container}>
            <div className={styles.wrapper}>
              <h2 className={styles.title_rates}>Тарифы</h2>
              <RatesPageForCustomer />
              <Box>
                <div className={styles.container}>
                  <h2 className={styles.options}>Дополнительные услуги (в разработке)</h2>
                  <div className={styles.gridLayout}>
                    <CardOptionsRates
                      title="Закрепить ваш проект в ленте на 24 часа"
                      subTitle="(Разовая оплата)"
                      description="После закрепления, проект останется наверху ленты, и его увидят больше исполнителей"
                      rate="Для тарифа «Стартап» "
                      allRates="Для тарифа «Персональный» и тарифа «Премиум» "
                      price="100 ₽"
                      priceForAnotherRates="50 ₽"
                    />
                    <CardOptionsRates
                      title="Срочность проекта"
                      subTitle="(Разовая оплата)"
                      description="Появление статуса «срочно» около проекта. Привлечет большое внимание среди исполнителей"
                      rate="Для тарифа «Стартап» "
                      allRates="Для тарифа «Персональный» и тарифа «Премиум» "
                      price="250 ₽"
                      priceForAnotherRates="125 ₽"
                    />
                    <CardOptionsRates
                      title="Дополнительный проект к тарифу"
                      subTitle=""
                      description="Расширение тарифа. Возможность вести еще один проект в рамках тарифа"
                      rate="Для тарифа «Стартап» "
                      allRates="Для тарифа «Персональный» и тарифа «Премиум» "
                      price="300 ₽"
                      priceForAnotherRates="150 ₽"
                    />
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};
