import Api from '@api-schema';
import { ApiClient } from '@store/api-client';

export const sendSupportMessage = async (
  request: Api.operations['sendSupportMessage']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['sendSupportMessage']['responses']['200']['content']['application/json']
  >('/api/support/send', request);
  return data;
};
