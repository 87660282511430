import React, { useCallback, useMemo } from 'react';
import { Message } from './Message';
import { selectCurrentUserId } from '@store/user/user.slice';
import styles from './Messages.module.scss';
import { useAppSelector } from '@hooks/index';
import { EmptyMessages } from '@pages/chats/messages/EmptyMessages';
import { ChatDetailed } from '@store/messages/contracts';
import { groupBy } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';

export interface MessagesProps {
  messages: ChatDetailed['messages']['data'];
  start: number;
  setStart: (limit: number) => void;
  totalCount: number;
}

export const Messages: React.FC<MessagesProps> = ({ messages, start, setStart, totalCount }) => {
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { messageId } = useParams();

  const messagesByDay = useMemo(() => {
    return groupBy(messages, (message) => message.date.startOf('day').format('DD MMMM YYYY'));
  }, [messages]);

  const scrollToMessage = useCallback(() => {
    if (messageId) {
      const favoriteMessage = document.getElementById(messageId);
      favoriteMessage?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  if (messages.length === 0) {
    return <EmptyMessages />;
  }

  return (
    <div className={styles.listMessages} id="list_messages">
      <InfiniteScroll
        dataLength={messages.length}
        next={() => setStart(start + 10)}
        hasMore={totalCount === messages.length || totalCount < messages.length ? false : true}
        loader={''}
        inverse={true}
        scrollableTarget={'list_messages'}>
        {Object.entries(messagesByDay).flatMap(([day, messages]) => {
          return [
            <div key={day} className={styles.messageDate}>
              {day}
            </div>,
            messages.map((message, index) => {
              return (
                <Message
                  id={message.id}
                  outgoing={message.author.userId === currentUserId}
                  key={message.id}
                  text={message.text}
                  sentAt={message.created}
                  read={message.read}
                  isFavorite={message.favorite}
                  isNextMsgDifferentUser={
                    messages[index + 1]?.author.userId === message.author.userId
                  }
                  scrollToMessage={scrollToMessage}
                />
              );
            }),
          ];
        })}
      </InfiniteScroll>
    </div>
  );
};
