import { AboutInfo, Typography } from '@components/AboutInfo/AboutInfo';
import { AboutMechanism } from '@components/AboutMechanism/AboutMechanism';
import { AboutTheProject } from '@components/AboutTheProject/AboutTheProject';
import profileScreen from '@assets/img/aboutTheProject/profileScreen.svg';
import vacancyScreen from '@assets/img/aboutTheProject/vacancyScreen.svg';
import messageScreen from '@assets/img/aboutTheProject/messageScreen.svg';
import requestsScreen from '@assets/img/aboutTheProject/requestsScreen.svg';
import styles from '../AboutTheProject/AboutTheProject.module.scss';
import { useScrollToTop } from '@hooks/useScrollToTop';
import { CardFeatures } from '@components/Card/Card';
import spec_state from '@assets/img/customer/spec_state.svg';
import analitics from '@assets/img/customer/analitics.svg';
import money from '@assets/img/customer/money.svg';
import flexble from '@assets/img/customer/flexble.svg';
import hands from '@assets/img/customer/hands.svg';
import React from 'react';
import css from '@pages/customer/CustomerPage.module.scss';
import { ToolsPlatform } from '@components/ToolsPlatform/ToolsPlatform';
import { Title } from '@components/AboutBenefits/AboutBenefits';
import { Link } from 'react-router-dom';

export function About() {
  const topRef = useScrollToTop();

  return (
    <div ref={topRef}>
      <AboutInfo />
      <div className={styles.aboutPopular}>
        <div className={css.container}>
          <h1 className={styles.titleAboutBenefits}>Наши особенности и преимущества</h1>
          <div className={styles.aboutCardsLayout}>
            <CardFeatures
              cardDescription="Наличие базы специалистов под любой запрос"
              iconURL={spec_state}
              style={styles.cardWithoutShadow}
            />
            <CardFeatures
              cardDescription="Аналитика для отслеживания актуального статуса работы удаленной команды"
              iconURL={analitics}
              style={styles.cardWithoutShadow}
            />
            <CardFeatures
              cardDescription="Уменьшение затрат на содержание штатных команд"
              iconURL={money}
              style={styles.cardWithoutShadow}
            />
            <CardFeatures
              cardDescription="Быстрая и гибкая масштабируемость бизнеса, благодаря исполнению проектов на аутсорсе"
              iconURL={flexble}
              style={styles.cardWithoutShadow}
            />
            <CardFeatures
              cardDescription="Прозрачность и безопасность финансовых отношений благодаря сервису «Безопасная сделка»"
              iconURL={hands}
              style={styles.cardWithoutShadow}
            />
          </div>
        </div>
      </div>
      <AboutMechanism />
      <ToolsPlatform />
      <div className={styles.aboutSetUpProfileBlock}>
        <div className={styles.container}>
          <div className={styles.aboutSetUpProfileMain}>
            <AboutTheProject
              title="Настрой"
              titleColor="профиль"
              description="Хорошо заполненный профиль позволяет членам команды
               и потенциальным заказчиками узнать больше о вас, вашем опыте, навыках и
               квалификации, а также увеличить доверие и лояльность. Кроме этого, при работе
               на открытых проектах алгоритмы платформы смогут более точно определять вашу
               специализацию и отображать ваш профиль в поисковых результатах для соответствующих
               запросов"
              image={profileScreen}></AboutTheProject>
            <AboutTheProject
              title="Найди"
              titleColor="вакансию"
              description="Правильный поиск вакансий на фриланс-платформе может повысить ваши шансы на успешное заключение контракта."
              image={requestsScreen}>
              <h1 className={styles.titleRecommendation}>
                Несколько рекомендаций от нашей команды:
              </h1>
              <p className={styles.findVacancyList}>
                {' '}
                <span className={styles.marker}>1.</span> Определите свои навыки и предпочтения по
                области работы
              </p>
              <p className={styles.findVacancyList}>
                {' '}
                <span className={styles.marker}>2.</span> Используйте фильтры для точного поиска
              </p>
              <p className={styles.findVacancyList}>
                <span className={styles.marker}>3.</span> Внимательно изучайте технические задания и
                требования заказчика
              </p>
              <p className={styles.findVacancyList}>
                <span className={styles.marker}>4.</span> Подавайте индивидуальные и качественные
                заявки, адаптированные под вакансию
              </p>
              <p className={styles.findVacancyList}>
                <span className={styles.marker}>5.</span> Будьте активными: чтобы найти больше
                возможностей, регулярно проверяйте новые вакансии на платформе и активно участвуйте
                в проектах
              </p>
            </AboutTheProject>
            <AboutTheProject
              title="Общайся"
              titleColor="с командой"
              description="Проектная деятельность всегда связана с работой в команда, где важно эффективно настроить работу и
              поддерживать коммуникацию. Чат стимулирует совместное обсуждение и сотрудничество, помогает улучшить коммуникацию,
              обмен идеями, обсуждение проблем и принятие решений, что способствует более
              эффективной работе команды"
              image={messageScreen}>
              <Typography>Быстрая и удобная коммуникация</Typography>
              <Typography>Удобство организации и структурирования информации</Typography>
              <Typography>Сохранение истории переписки</Typography>
            </AboutTheProject>
            <AboutTheProject
              title="Получай"
              titleColor="опыт и зарабатывай"
              description="Egile предоставляет не только возможность удаленной работы и свободу выбора проектов, но
              и полную инфраструктуру и инструментарий для работы. Работай в одном окне, храни
              все рабочие материалы на платформе и зарабатывай без преград!"
              image={vacancyScreen}></AboutTheProject>
          </div>
        </div>
      </div>
      <div className={styles.pricing} id="pricing">
        <div className={styles.container}>
          <div className={styles.pricing__content}>
            <Title>Стоимость</Title>
            <div className={styles.pricing__content__description}>
              <p>
                Узнать стоимость тарифов при использовании облачного решения можно{' '}
                <Link to="/ratesForCustomer">здесь.</Link>
              </p>
              <p>
                При необходимости использования автономного серверного решения, стоимость лицензии
                рассчитывается индивидуально по запросу на почту{' '}
                <a href="mailto:mail@egile.ru">mail@egile.ru.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.documenation}>
        <div className={styles.container}>
          <div className={styles.documenation__content}>
            <Title>Документация</Title>
            <div className={styles.documenation__content__links}>
              <a
                href="http://s3.egile.ru/public-news/files/functionalCharacteristics.pdf"
                target="_blank"
                rel="noreferrer">
                Функциональные характеристики
              </a>
              <a
                href="http://s3.egile.ru/public-news/files/installationGuide.pdf"
                target="_blank"
                rel="noreferrer">
                Руководство по установке для сайта
              </a>
              <a
                href="http://s3.egile.ru/public-news/files/manual.pdf"
                target="_blank"
                rel="noreferrer">
                Руководство по эксплуатации
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
