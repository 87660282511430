import { Switch } from '@mui/material';
import styled from '@emotion/styled';

export const MuiSwitch = styled((props: any) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 47,
  height: 28,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    marginTop: 4,
    marginLeft: 4,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(19px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        border: '1px solid #DCDCDC',
        background: '#FAFAFA',
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#199B3E !important',
        transition: '550ms',
        boxShadow: 'none',
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#DCDCDC !important',
      boxShadow: 'none',
    },
  },
  '& .MuiSwitch-thumb.Mui-checked': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    border: '1px solid #DCDCDC',
    backgroundColor: '#FAFAFA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
