import { withAdaptive } from '../../hocs/withAdaptive';
import { TableMUI } from '@components/Table/TableMUI';
import { ListMUI } from '@components/List/ListMUI';

export const AdaptiveTableMUI = withAdaptive({
  mobile: ListMUI,
  tablet: ListMUI,
  desktop: TableMUI,
  default: TableMUI,
});
