import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { formatDate } from '@components/ui/formatDate';
import { StatusLabel, statusColors } from '@components/ui/StatusLabel/StatusLabel';
import { useRequestBody } from '@hooks/useRequestBody';
import { RequestItem } from '@store/requests/contracts';
import { Star } from '@components/Star/Star';
import { CreateRequestButton } from '../components/CreateRequestButton';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { RespondButton } from './RespondButton';
import {
  useListRequestsQuery,
  useSendProposalMutation,
  useSetFavoriteRequestMutation,
} from '@store/requests/requests.slice';

export function RequestList() {
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();
  const [setFavoriteRequest] = useSetFavoriteRequestMutation();
  const [sendProposal] = useSendProposalMutation();

  const { data: fetchedRequests, isLoading } = useListRequestsQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <AdaptiveTableMUI
      sortOrder={sortOrder}
      sortColumn={sortColumn}
      setRequestBody={setRequestBody}
      setSortColumn={setSortColumn}
      setSortOrder={setSortOrder}
      isLoading={isLoading}
      columns={{
        name: {
          label: 'Заявка',
          isSortColumn: true,
          filterInput: true,
          children: (value, row: RequestItem) => <Link to={`/requests/${row.id}`}>{value}</Link>,
        },
        customerName: {
          label: 'Заказчик',
          children: (value, row) => <Link to={`/userPage/${row.customerId}`}>{value}</Link>,
          isSortColumn: true,
          filterInput: true,
        },
        created: {
          label: 'Дата отправки',
          format: (value) => formatDate(value, 'DD.MM.YYYY'),
          isSortColumn: true,
          filterInput: true,
        },
        deadLine: {
          label: 'Дедлайн',
          format: (value) => formatDate(value, 'DD.MM.YYYY'),
          isSortColumn: true,
          filterInput: true,
        },
        budget: {
          label: 'Бюджет',
          isSortColumn: true,
          filterInput: true,
        },
        currencyName: {
          label: 'Валюта',
          filterInput: true,
          isSortColumn: false,
          format: (value) => `${value}`,
        },
        statusName: {
          label: 'Статус',
          children: (value) => (
            <StatusLabel text={value || 'Статус не указан'} color={statusColors.get(value)} />
          ),
          isSortColumn: true,
          filterInput: true,
        },
        actionButton: {
          children: (_, row: RequestItem) => (
            <RespondButton row={row} onClick={() => sendProposal(row.id)} />
          ),
        },
        star: {
          children: (_, row: RequestItem) => (
            <Star
              checked={row.favorite}
              onChange={(value) =>
                setFavoriteRequest({ requestBody: { status: value }, requestId: row.id })
              }
              style={{ marginRight: '1rem' }}
            />
          ),
        },
      }}
      emptyLabel="Заявки отсутствуют"
      rows={fetchedRequests?.data || []}
      additionalElement={<CreateRequestButton />}>
      <TablePaginationMUI
        limit={limit}
        count={fetchedRequests?.totalCount || 0}
        entityLabel="заявок"
        page={page}
        handleChangePage={(_, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </AdaptiveTableMUI>
  );
}
