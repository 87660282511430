import { useEffect, useState } from 'react';

interface FileReaderProps<T extends readAs> {
  file: Blob | undefined;
  readAs: T;
}
type readAs = keyof Pick<FileReader, 'readAsDataURL' | 'readAsArrayBuffer'>;
type ReaderResultMap = {
  readAsDataURL: string;
  readAsArrayBuffer: ArrayBuffer;
};

export function useFileReader<T extends readAs>({
  file,
  readAs,
}: FileReaderProps<T>): ReaderResultMap[T] | undefined {
  const [readerResult, setReaderResult] = useState<ReaderResultMap[T]>();
  useEffect(() => {
    if (file === undefined) return;
    const reader = new FileReader();
    reader.onload = (event) => {
      setReaderResult(event.target?.result as ReaderResultMap[T]);
    };
    reader[readAs](file);
  }, [file, readAs]);
  return readerResult;
}
