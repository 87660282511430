import styles from './WorkInProgress.module.scss';
import { Tooltip } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { ReactComponent as HelpIcon } from '@assets/img/help.svg';
import { useFormikContext } from 'formik';
import { FormValues } from '../AdvancedAnalytics';
import { CustomBar } from '../components/CustomBar/CustomBar';
import { useGetWIPAnalyticQuery } from '@store/project/project.slice';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
export const WorkInProgress = () => {
  const { values } = useFormikContext<FormValues>();

  const { projectId } = useParams();
  const projectIdToUse = projectId || '';

  const { data } = useGetWIPAnalyticQuery({
    path: {
      projectId: projectIdToUse,
    },
    query: {
      startDate: values.period[0].format('YYYY-MM-DD'),
      endDate: values.period[1].format('YYYY-MM-DD'),
    },
  });

  const chartData = useMemo(() => {
    if (data) return data.map((user) => user?.data);
    return [];
  }, [data]);

  const axisXData = useMemo(() => {
    if (data) return data.map((user) => user?.userName);
    return [];
  }, [data]);

  const isEmpty = axisXData.length === 0 && chartData.length === 0;

  return (
    <>
      <div
        style={{
          order: isEmpty ? 2 : 1,
        }}>
        <div className={styles.block}>
          <h4 className={styles.block__title}>В работе (WIP - Work In Progress)</h4>
          <Tooltip title="Диаграмма отображает количество задач, которые одновременно находятся в работе у каждого участника проекта">
            <HelpIcon />
          </Tooltip>
        </div>
        <div>
          <div className={styles.period}>
            {values.period[0].format('DD.MM.YYYY')} - {values.period[1].format('DD.MM.YYYY')}
          </div>
          {!isEmpty ? (
            <>
              <div className={styles.subBlock}>
                <h6 className={styles.subtitle}>Кол-во задач</h6>
              </div>
              <BarChart
                sx={{
                  '& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel': {
                    fill: '#199B3E',
                    fontSize: '14px',
                    fontWeight: 400,
                  },
                }}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: axisXData,
                  },
                ]}
                series={[
                  {
                    color: '#F3CD47',
                    data: chartData,
                  },
                ]}
                height={290}
                margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                slotProps={{
                  bar: {
                    clipPath: `inset(0px round 4px 4px 0px 0px)`,
                  },
                }}
                slots={{
                  bar: (props) => (
                    <>
                      <CustomBar barChartProps={props} seriesData={chartData} />
                    </>
                  ),
                }}
              />
              <div className={styles.periodBlock}>
                <h6 className={styles.subtitle}>Дни периода</h6>
              </div>

              <div>
                <li className={styles.status}>
                  <div className={styles.status__color}></div>
                  Задачи
                </li>
              </div>
            </>
          ) : (
            <div className={styles.emptyData}>
              <p>Нет данных</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
